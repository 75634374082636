import { useEffect, useState } from 'react'
import { Loading } from '../../components'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import { ReadMyOrders } from '../../interfaces'
import api from '../../services/api'
import * as S from './style'
import Row from './Row'
import HorizontalGrabbableBlock from '../../components/HorizontalGrabbableBlock/HorizontalGrabbableBlock'
import PageLayoutClient from '../../components/layout/PageLayoutClient'


export default function Home() {
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? ''
    const planInfo = localStorage.getItem("planInfo") ?? '{}';
    const planName = JSON.parse(planInfo).plan_name ?? ''
    const [orders, setOrders] = useState<ReadMyOrders[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const {setShowPopup} = usePopupTrueFalse()
    
    let lastOrdersAwaitingApproval: ReadMyOrders[] | undefined
    let lastOrdersInNegotiation: ReadMyOrders[] | undefined
    let newLastOrders: ReadMyOrders[] | undefined
    let lastOrdersClosed: ReadMyOrders[] | undefined
    let lastOrdersDistributed: ReadMyOrders[] | undefined
    let status: string[] = []
    
    if(typeUser === 'admin'){
        status = ['Aberto', 'Delegado', 'Em produção', 'Aguardando aprovação interna', 'Aguardando mudança interna', 'Aguardando aprovação cliente', 'Aguardando mudança cliente', 'Em negociação', 'Publicado']
    } else if(typeUser === 'editor'){
        status = ['Delegado', 'Em produção', 'Aguardando aprovação interna', 'Aguardando mudança interna', 'Aguardando aprovação cliente', 'Aguardando mudança cliente', 'Em negociação', 'Publicado']
    } else if(typeUser === 'company'){
        if(planName === 'StartUp'){
            status = ['Em produção', 'Aguardando aprovação cliente', 'Disparado']
        }else{
            status = ['Em produção', 'Aguardando aprovação cliente', 'Em negociação', 'Publicado', 'Disparado']
        }
    }
    
    const [selectedItems, setSelectedItems] = useState<string[]>(status)

    const defaultSort = (a: string, b: string) => status.indexOf(a) - status.indexOf(b)

    useEffect(() => {
        setShowPopup(false)
        setLoading(true)
        const promise = api.readPlanInfo(authToken)
        promise.then((response) => {
            setOrders(response.data?.orders)
            setSelectedItems(status)
        }).catch((error) => {
            console.error(error)
        })
        setLoading(false)
        // eslint-disable-next-line
    },[authToken, typeUser])


    let lastOrders = orders?.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA.getTime() - dateB.getTime() || b.id - a.id;
      });
      
    lastOrdersAwaitingApproval = lastOrders?.filter(item => item.status.status === 'awaiting_approval');
    lastOrdersInNegotiation = lastOrders?.filter(item => item.status.status === 'in_negotiation');
    lastOrdersClosed = lastOrders?.filter(item => item.status.status === 'closed');
    lastOrdersDistributed = lastOrders?.filter(item => item.status.status === 'distributed');
    newLastOrders = lastOrders?.filter(item => (item.status.status !== 'awaiting_approval' && item.status.status !== 'in_negotiation' && item.status.status !== 'closed' && item.status.status !== 'distributed'))
    lastOrders = [...lastOrdersAwaitingApproval ?? [], ...lastOrdersInNegotiation ?? [], ...lastOrdersClosed ?? [], ...lastOrdersDistributed ?? [], ...newLastOrders ?? []].slice(0, 8) ?? []

    function handleOrderType(type: string){
        switch(type){
          case 'Em produção':
            return newLastOrders
          
          case 'Aguardando aprovação cliente':
            return lastOrdersAwaitingApproval
          
          case 'Em negociação':
            return lastOrdersInNegotiation
          
          case 'Publicado':
            return lastOrdersClosed
      
          case 'Disparado':
            return lastOrdersDistributed
        }}
  
      if(loading){
        return(
            <S.Container>
                <S.LoadingContainer>
                    <Loading color="black" withText={true}/>
                </S.LoadingContainer>
            </S.Container>
        )
      }

  return (
    <PageLayoutClient>
        <HorizontalGrabbableBlock>
            <S.RowsContainer>  
                {selectedItems.sort(defaultSort).map(item => {
                    return(
                        <>
                            <Row key={item} status={item} orders={handleOrderType(item)}/>
                        </>
                    )
                })}
            </S.RowsContainer>
        </HorizontalGrabbableBlock>
    </PageLayoutClient>
  )
}