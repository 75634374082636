import styled from "styled-components";

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 55rem;
height: auto;
min-height: 15rem;
max-height: 80%;
overflow-y: auto;
z-index:100;
border-radius: 10px;
background-color: #f9f9f9;
padding: 2rem;
overflow-x: hidden;
label{
    font-size: 0.9rem;
}
textarea{
    width: 100%;
    max-height: 8rem;
}
`

const ClosePopup = styled.div`
position: absolute;
top: 1.5rem;
right: 1.5rem;
cursor: pointer;
`

const Title = styled.div`
color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
`

const Subtitle = styled.div`
color: #333333;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    line-height: 1.2rem;
    justify-content: center;
    margin-bottom: 0.8rem;
`
const Cancel = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: 0.1rem solid #5C49DD;
border-radius: 1.875rem;
background-color: #f9f9f9;
color: #5C49DD;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-right: 0.625rem;
`

const Confirm = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
:hover{
    opacity: 0.8;
}
`

const ConfirmText = styled.button`
cursor: pointer;
width: 12.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
:hover{
    opacity: 0.8;
}
`

const Buttons = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

const LeftButtons = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 1rem;
`


const Text = styled.textarea`
padding-bottom: 2rem;
margin-top: 1.5rem;
margin-bottom: 2rem;
height: auto;
min-height: 35rem;
resize: none;
max-height: 4rem;
`

const NextText = styled.div`
position: absolute;
top: 50%;
right: -0.25rem;
cursor: pointer;
svg{
    width: 2rem;
    height: 2rem;
}
`

const BackText = styled.div`
position: absolute;
top: 50%;
left: -0.25rem;
cursor: pointer;
svg{
    width: 2rem;
    height: 2rem;
}
`

const LoadingContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
width: 100%;
height: 15rem;
padding-top: 2.25rem;

h1,h2{
    color: #6D6D6D;
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.42rem;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1rem;
}

@media(max-width: 1650px){
    width: 90%;
}
`

export {
 Container,
 ClosePopup,
 Title,
 Subtitle,
 Cancel,
 Confirm,
 ConfirmText,
 Buttons,
 Text,
 NextText,
 BackText,
 LeftButtons,
 LoadingContainer
}
