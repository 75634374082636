import styled from "styled-components";

const ConfirmSendOrderContainer = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index:100;
width: 44.12rem;
height:24.18rem;
border-radius: 20px;
background-color:#f9f9f9;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const ImageConfirm = styled.div`
width: 15.26rem;
heigh: 13.9rem;
img{
    width: 15.26rem;
    heigh: 13.9rem; 
}
`

const ConfirmTitle = styled.div`
width: 21rem;
font-family: inter;
font-weight: 500;
font-size: 1.3rem;
line-height: 1.82rem;
margin-bottom: 1rem;
`

const ConfirmTextPrimary = styled.div`
font-family: inter;
font-weight: 600;
font-size: 1.2rem;
line-height: 1.63rem;
color: #6D6D6D;
`

const ConfirmTextSecondary = styled.div`
font-family: inter;
font-weight: 500;
font-size: 1.2rem;
line-height: 1.63rem;
color: #6D6D6D;
`

const ButtonConfirmSendOrder = styled.div`
height: 3rem;
border-radius: 30px;
font-family: inter;
font-weight: 500;
font-size: 1.1rem;
line-height: 1.45rem;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`

const CancelButton = styled(ButtonConfirmSendOrder)`
width: 11.5rem;
color: #5c49dd;
border: 1px solid #5c49dd;
margin-right: 1.5rem;
:hover{
    opacity: 0.8;
}
`

const ConfirmButton = styled(ButtonConfirmSendOrder)`
width: 16.54rem;
background-color: #5c49dd;
color: #ffffff;
margin-right: 1rem;
border: none;
:hover{
    opacity: 0.8;
}
`

const ConfirmAllButtons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
padding-right: 1rem;
width: 100%;
`


const InnerContainer = styled.div`
display: flex;
padding: 2rem 1rem 2rem;
`

const TextConfirm = styled.div`
width: 13rem;
`

export{
ConfirmSendOrderContainer,
ImageConfirm,
ConfirmTitle,
ConfirmTextPrimary,
ConfirmTextSecondary,
CancelButton,
ConfirmButton,
ConfirmAllButtons,
InnerContainer,
TextConfirm
}