import styled from "styled-components";



const Container = styled.div`
width: 100%;
`

const InnerContainer = styled.div`
`

const LongInput = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
textarea{
    font-family: 'Inter', sans-serif;
    padding-top: 0.5rem;
    resize: none;
    width: 80%;
    max-width: 80rem;
    min-height: 2.45625rem;
    max-height: 10rem;
    height: auto;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;
    
    
    margin-bottom: 0.9375rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
}
textarea::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
}

textarea:disabled {
  background-color: #fff;
  color: #000;
}

h1{
color: #333333;
    font-weight: 400;
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
}

`

export {
    Container,
    InnerContainer,
    LongInput,
}