import * as S from './style'
import Input from '../../../components/Input';
import { useEffect, useRef, useState } from "react";
import { AccordionSummaryProps, CompanyData, InputData, InputsProps, ReadMyOrders } from "../../../interfaces";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from '@mui/icons-material/Settings';
import api from '../../../services/api';
import Avatar from '@mui/material/Avatar';
import { Link, useParams } from 'react-router-dom';
import InsertPlan from '../../../components/InsertPlan';
import { BackgroundPopup } from '../../PedidoDetalhado/style';
import LaunchIcon from '@mui/icons-material/Launch';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangePlan from '../../../components/ChangePlan';
import { acertarDatas } from '../../../utils';
import { Switch, styled } from '@mui/material';

export default function CompanyInformation() {
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token ?? ''
  const user = localStorage.getItem("user") ?? '{}'
  const typeUser = JSON.parse(user).role.name ?? ''
  const [aba, setAba] = useState(0)
  const { idCompany } = useParams()
  const companyId = idCompany ?? ''
  const [inputs, setInputs] = useState([])
  const [companyInfo, setCompanyInfo] = useState<CompanyData>({})
  const [companyOrders, setCompanyOrders] = useState<ReadMyOrders[]>([])
  const [communicationPlan, setCommunicationPlan] = useState('')
  const [showInsertPlan, setShowInsertPlan] = useState(false)
  const [oldInputs, setOldInputs] = useState<InputData[]>([])
  const { setErrorMessage, setTypeError, setShowPopup } = usePopupTrueFalse()
  const [changePlan, setChangePlan] = useState(false)
  const [summaries, setSummaries] = useState([])
  const [showAddSummaryPopup, setShowAddSummaryPopup] = useState(false)
  const [kickoffStatus, setKickoffStatus] = useState<boolean>()
  const [clippingStatus, setClippingStatus] = useState<boolean>()

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [newSummary, setNewSummary] = useState('')

  function readCompanyInfo() {
    setLoading(true)
    const promise = api.readCompanyInfo(authToken, companyId)
    promise.then((response) => {
      setInputs(response.data.company_fields)
      setOldInputs(JSON.parse(JSON.stringify(response.data.company_fields)))
      setCompanyInfo(response.data.company_data)
      setCompanyOrders(response.data.company_orders)
      setCommunicationPlan(response.data.communication_plan)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  function SaveNewSummary() {
    setLoading(true)
    const body = {
      "company_id": companyId,
      "summary":  newSummary
    }
    const promise = api.addCompanySummary(authToken, body)
    promise.then((response) => {
      setSummaries(response.data)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
      setShowAddSummaryPopup(false)
    })
  }

  function removeSummary(summaryId: number) {
    const promise = api.deleteCompanySummary(authToken, summaryId)
    promise.then((response) => {
      readCompanySummaries()
    }).catch((error) => {
      console.log(error)
    }).finally(() => {})
  }

  function readCompanySummaries() {
    setLoading(true)
    const promise = api.readCompanySummaries(authToken, companyId)
    promise.then((response) => {
      setSummaries(response.data)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  function showNewSummaryPopup() {
    setShowAddSummaryPopup(true)
  }

  function adjustTextareaHeight() {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }

  const filteredOrders = companyOrders?.filter(order => {
    const createdAt = new Date(order.created_at);
    const currentDate = new Date();
    return createdAt.getMonth() === currentDate.getMonth();
  }).length;

  function openPlan() {
    if (!!communicationPlan) {
      window.open(communicationPlan, '_blank')
    }
  }

  function DiscardInformationAccount() {
    setInputs(JSON.parse(JSON.stringify(oldInputs)))
  }

  function setError(error: any) {
    console.error(error)
    setTypeError(false)
    if (typeof error.response?.data?.detail === 'string') {
      setErrorMessage(error.response?.data?.detail)
    } else {
      setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
  }

  function UpdateData() {
    setLoading(true)
    const newData: Array<object> = []
    inputs?.forEach((input: InputsProps) => {
      newData.push({
        field_id: input.field_id,
        value: input.value
      })
    })
    const promise = api.editOneOtherCompany(newData, authToken, companyId)
    promise.then(() => {
      setTypeError(true)
      setErrorMessage('Suas informações foram atualizadas com sucesso!')
      setShowPopup(true)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  function UpdateKickOff() {
    const data = {};
    const promise = api.updateCompanyKickOffStatus(authToken, companyId, data);

    promise.then(() => {
        setTypeError(true);
        setErrorMessage('Informações foram atualizadas com sucesso!');
        setShowPopup(true);
    }).catch((error) => {
        setError(error);
    }).finally(() => {
        setKickoffStatus(!kickoffStatus);
    });
}

  function GetKickOff() {
    setLoading(true)
    const promise = api.getCompanyKickOffStatus(authToken, companyId)
    promise.then((response) => {
      setKickoffStatus(response.data)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  function UpdateClipping() {
    const data = {};
    const promise = api.updateCompanyClippingStatus(authToken, companyId, data);

    promise.then(() => {
        setTypeError(true);
        setErrorMessage('Informações foram atualizadas com sucesso!');
        setShowPopup(true);
    }).catch((error) => {
        setError(error);
    }).finally(() => {
        setClippingStatus(!clippingStatus);
    });
}

  function GetClipping() {
    setLoading(true)
    const promise = api.getCompanyClippingStatus(authToken, companyId)
    promise.then((response) => {
      setClippingStatus(response.data)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const IOSSwitch = styled((props: any) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 36,
    height: 19,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#5C49DD' : '#5C49DD',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#5C49DD',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 15,
      height: 15,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E5E5E5' : '#E5E5E5',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    readCompanyInfo()
    readCompanySummaries()
    GetKickOff()
    GetClipping()
    // eslint-disable-next-line
  }, [authToken, companyId])

  useEffect(() => {
    adjustTextareaHeight()
  }, [newSummary])

  return (
    <>
      {(showInsertPlan || changePlan) &&
        (<BackgroundPopup>
          {showInsertPlan && <InsertPlan updateFuncion={readCompanyInfo} setShowInsertPlan={setShowInsertPlan} />}
          {changePlan && <ChangePlan authToken={authToken} companyId={companyId} setChangePlan={setChangePlan} />}
        </BackgroundPopup>)}
      <S.Container>
        <S.Title>Empresas</S.Title>
        <S.InnerContainer isLoading={loading}>
          {loading ? <></> : (
            <>
              <S.ContainerInputs>
                {(typeUser === 'admin' || typeUser === 'editor') &&
                  (<>
                    <S.ChooseTab>
                      <span onClick={() => { setAba(0) }}>Informações da empresa</span>  <span onClick={() => { setAba(1) }}>Resumos atendimento</span>
                    </S.ChooseTab>
                    <S.Line></S.Line>
                  </>
                  )}

                {
                  (aba === 0 && showAddSummaryPopup === false) &&
                  (
                    <>
                      <S.CompanyDetails>
                        <S.InformationContainer>
                          <S.CompanyImage>
                            <Avatar
                              sx={{ width: 40, height: 40 }}
                              src={companyInfo.user?.profile_picture ? companyInfo.user?.profile_picture : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}>
                            </Avatar>
                          </S.CompanyImage>
                          <S.CompanyName>
                            <h1><span>Cliente:</span> {companyInfo.user?.name}</h1>
                          </S.CompanyName>
                          <S.CompanyPlan>
                            <h1><span>Plano:</span> {companyInfo.plan?.plan_name}
                            </h1>
                            <S.ChangePlanButton onClick={() => setChangePlan(true)}><SettingsIcon /></S.ChangePlanButton>
                          </S.CompanyPlan>
                          <S.CompanyOrders>
                            <h1><span>Pedidos esse mês:</span> {filteredOrders}</h1>
                          </S.CompanyOrders>
                        </S.InformationContainer>
                        <S.CompanyComunication>
                          <h1><S.ComunicationSpan>Plano de comunicação:</S.ComunicationSpan> <S.ComunicationPlanSpan onClick={openPlan} hasPlan={!!communicationPlan}>{!!communicationPlan ? 'Acessar plano ' : 'Ainda não cadastrado'}{!!communicationPlan && <LaunchIcon />}</S.ComunicationPlanSpan></h1>
                          <S.ComunicationButton onClick={() => setShowInsertPlan(true)}>
                            {!!communicationPlan ? `Editar plano de comunicação` : `Adicionar plano de comunicação`}
                          </S.ComunicationButton>
                        </S.CompanyComunication>
                      </S.CompanyDetails>
                      <S.ContentInputs>
                        {inputs.map((input: InputsProps, indice: number) => {
                          return (<Input
                            disabled={false}
                            key={input.field_id}
                            id={input.field_id}
                            indice={indice}
                            description={input.description}
                            fieldName={input.field_name}
                            value={input.value}
                            input={input}
                            inputs={inputs}
                            setInputs={setInputs} />)
                        })}
                      </S.ContentInputs>
                    </>
                  )
                }

                {
                  (aba === 1 && showAddSummaryPopup === false) &&
                  (
                    <S.CompanySummariesContainer>
                      <S.CompanySummariesContent>
                        <S.NewSummaryButtonContainer>
                            <S.SwitchContainer>
                                <span>Kick-off realizado</span>
                                <IOSSwitch
                                    checked={kickoffStatus}
                                    onChange={() => UpdateKickOff()}
                                />
                            </S.SwitchContainer>
                            <S.SwitchContainer>
                                <span>Clipping</span>
                                <IOSSwitch
                                    checked={clippingStatus}
                                    onChange={() => UpdateClipping()}
                                />
                            </S.SwitchContainer>
                            <S.NewButton onClick={showNewSummaryPopup}>
                                Novo resumo
                            </S.NewButton>
                        </S.NewSummaryButtonContainer>
                        {summaries.map((summary: AccordionSummaryProps, indice: number) => {
                          return (
                            <S.CompanySummary>

                              <S.CreatedAt>
                                <S.SummaryTitle>
                                  Data
                                </S.SummaryTitle>
                                {acertarDatas(summary.created_at)}
                              </S.CreatedAt>

                              <S.Summary>
                                <textarea disabled >
                                  {summary.summary}
                                </textarea>
                              </S.Summary>

                              <S.Author>
                                <S.SummaryTitle>
                                  Autor
                                </S.SummaryTitle>

                                {summary.user.name}
                              </S.Author>

                              <S.EditButtons>
                                <S.DeleteButton onClick={() => {removeSummary(summary.id)}}>
                                  <DeleteIcon />
                                </S.DeleteButton>
                              </S.EditButtons>

                            </S.CompanySummary>
                          )
                        })}
                      </S.CompanySummariesContent>
                    </S.CompanySummariesContainer>
                  )
                }

                {
                  (showAddSummaryPopup === true) &&
                  (
                    <S.CompanySummariesContainer>
                      <S.CompanySummariesContent>
                        <textarea
                          onChange={e => {setNewSummary(e.target.value)}}
                          ref={textareaRef}
                        />
                      </S.CompanySummariesContent>
                    </S.CompanySummariesContainer>
                  )
                }

              </S.ContainerInputs>
            </>)}

          <S.Buttons>
            {
              showAddSummaryPopup === true &&
              (
                <>
                  <S.Voltar onClick={e => { setShowAddSummaryPopup(false) }}><ArrowBackIcon />Voltar</S.Voltar>
                  <S.ChangeButtons>
                    <S.Save onClick={SaveNewSummary}>Salvar</S.Save>
                  </S.ChangeButtons>
                </>
              )
            }

            {
              showAddSummaryPopup === false &&
              (
                <Link style={{ textDecoration: 'none' }} to={`/empresas`}>
                  <S.Voltar><ArrowBackIcon />Voltar</S.Voltar>
                </Link>
              )
            }

            {
              aba === 0 &&
              (
                <S.ChangeButtons>
                  <S.Discard onClick={DiscardInformationAccount}>Descartar</S.Discard>
                  <S.Save onClick={UpdateData}>Salvar</S.Save>
                </S.ChangeButtons>
              )
            }
          </S.Buttons>
        </S.InnerContainer>
      </S.Container>
    </>
  )
}