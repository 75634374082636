import Information from './Information'
import Options from './Options'
import { Drawer } from '@mui/material'
import { SidebarProps } from '../../interfaces'
import { useLocation } from 'react-router-dom'
import { pathsWithoutHeaderAndMenu } from '../../App'
import {
  Container,
  Line
} from './style'


export default function Sidebar({ showSidebar, setShowSidebar, isScreenSmall }: SidebarProps) {
    const location = useLocation()
    if (pathsWithoutHeaderAndMenu.includes(location.pathname)) {
        return <></>;
    }

    function handleDrawerClose(){
        setShowSidebar(false)
    }
  return (

    <Drawer anchor="left" 
    open={showSidebar} 
    variant={isScreenSmall ? 'temporary' : 'permanent'} 
    ModalProps={{ onBackdropClick: handleDrawerClose }}
    sx= {{ marginLeft: '17.5rem' }} 
    >
      <Container>
        <Information />
        <Line></Line>
        <Options/>
      </Container>
    </Drawer>

  )
}

