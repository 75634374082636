import {useEffect} from 'react'
import { Loading } from '../../../components';
import { User } from '../../../interfaces';
import Usuario from './Usuario'

interface UsuariosCadastradosProps{
  users: Array<User>
  updateRegisteredUsers: () => void;
  setIsLoading: (value: boolean) => void;
  setShowPopupEdit: any;
  isLoading: boolean
  setUserEdit: any;
}

export default function UsuariosCadastrados({ isLoading, setShowPopupEdit, updateRegisteredUsers, users, setUserEdit, setIsLoading}: UsuariosCadastradosProps) {

  useEffect(() => {
    updateRegisteredUsers()
    /* eslint-disable-next-line */
  },[])
  
  if(isLoading){
    return <Loading color="black" withText={true}/>
  }

  return (
    <div>
      {users.length !== 0 && users.map(user => (
        <Usuario setUserEdit={() => setUserEdit(user)} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={user.id} updateRegisteredUsers={updateRegisteredUsers} id={user.id} name={user.name} email={user.email} type={user.role.name} profile_picture={user.profile_picture}/>
      ))}
    </div>
  )

}
