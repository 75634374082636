import { Avatar } from '@mui/material'
import { useState, useEffect } from 'react'
import Input from '../../../components/Input';
import api from '../../../services/api';
import useAuth from '../../../hooks/useAuth';
import { InputsProps, InputData } from '../../../interfaces';
import {
    Container,
    Image,
    Logo,
    Line,
    ChooseTab,
    InformationsAccount,
    Buttons,
    Discard,
    Save,
    ContainerInputs,
    ContentInputs,
    PhotoInput,
    LabelInputPhoto,
    ContainerLoading
} from './style'
import { Loading } from '../../../components';
import Account from '../Account';
import { useNavigate } from 'react-router-dom';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';

interface InformationProps{
  setAba: (value: number) => void;
  aba: number
}

export default function Informations({aba, setAba}: InformationProps) {
  
  const { token } = useAuth()
  const authToken = token?.access_token ?? ''
  const user = localStorage.getItem("user") ?? '{}';
  const userInfo = JSON.parse(user);
  const [userData, setUserData] = useState({...userInfo, password:'', passwordconfirm:''})
  const typeUser = JSON.parse(user).role.name ?? ''
  const urlPhotoUser= JSON.parse(user).profile_picture ?? ''
  const [inputs, setInputs] = useState<InputData[]>([])
  const [oldInputs, setOldInputs] = useState<InputData[]>([])
  const [loading, setLoading] = useState(true)
  const [photoName, setPhotoName] = useState('')
  const [photoFile, setPhotoFile] = useState<File>()
  const [selectedImage, setSelectedImage] = useState('');
  const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse()
  const navigate = useNavigate()
  

  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
  }

  useEffect(() => {
    if(typeUser === 'company'){
        const promise = api.readInformation(authToken)
      promise.then((response) => {
        setInputs(response.data)
        setOldInputs(JSON.parse(JSON.stringify(response.data)))
        setLoading(false)
      })
      promise.catch((error) => {
        setError(error)
      })
      promise.finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  },[authToken, typeUser, selectedImage])

  function DiscardDataAccount(){
    setUserData({...userInfo, password: '', passwordconfirm: '' })
    setPhotoName('')
    setPhotoFile(undefined)
    setSelectedImage('');

    const fileInput = document.getElementById('file-input') as HTMLInputElement
    if (fileInput) {
      fileInput.value = '';
    }
  }

  function DiscardInformationAccount(){
    setInputs(JSON.parse(JSON.stringify(oldInputs)))
  }

  function UpdateDataAccount(){
    setLoading(true)
    const body = new FormData();

    var hasFields = false

    if(userData?.password) {
      if(userData?.password === userData?.passwordconfirm) {
        body.append('password', userData?.password)
        hasFields = true
      } else {
        setTypeError(false)
        setLoading(false)
        setErrorMessage('As senhas não coincidem')
        setShowPopup(true)
        return
      }
    }

    if(photoName.length > 0) {
      body.append('file', photoFile ?? '')
      hasFields = true
    }

    if(userData?.name !== userInfo?.name) {
      body.append('name', userData?.name)
      hasFields = true
    }


    if(hasFields) {
      const promise = api.editMyUser(authToken, body)
      promise.then((response)=>{
        localStorage.setItem('user',JSON.stringify(response.data))
        navigate('/configuracoes')
        setTypeError(true)
        setErrorMessage('Suas informações foram atualizadas com sucesso!')
        setShowPopup(true)
        userData.password = ''
        userData.passwordconfirm = ''
      }).catch((error)=>{
        setError(error)
      }).finally(()=>{
        setLoading(false)
      })
    } else {
      setLoading(false)
      navigate('/configuracoes')
      setTypeError(true)
      setErrorMessage('Suas informações foram atualizadas com sucesso!')
      setShowPopup(true)
    }
    
   } 

   function UpdateData(){
    setLoading(true)
    const newData: Array<object> = []
    inputs?.forEach((input: InputsProps) => {
      newData.push({
        field_id: input.field_id,
        value: input.value
       })
    })
    const promise = api.editOneCompany(newData, authToken)
    promise.then(()=>{
      setTypeError(true)
      setErrorMessage('Suas informações foram atualizadas com sucesso!')
      setShowPopup(true)
    }).catch((error)=>{
      setError(error)
    }).finally(()=>{
      setLoading(false)
    })
   } 

    function handlePhotoInputChange(e: React.ChangeEvent<HTMLInputElement>){
    const files = e.target.files;
    if( files && files.length > 0){
      const file = files[0];
      setPhotoName(file.name)
      setPhotoFile(file)

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedImage(reader.result as string)
      }
    }
  }

  if(loading){
    return(
    <ContainerLoading>
      <Loading color="black" withText={true}/>
    </ContainerLoading>)
  }

  return (
    <Container>
      { typeUser === 'company' && 
      (<>
        <ChooseTab>
          <span onClick={() => {setAba(0)}}>Informações da Conta</span>  <span onClick={() => {setAba(1)}}>Informações da Empresa</span>
        </ChooseTab>
        <Line></Line>
      </>
      )}
      
      { aba === 0 && 
      (
        <>
        <Image>
          <Logo>
          <Avatar 
              alt="Remy Sharp" 
              src={
                selectedImage !== '' ?
                selectedImage :
                (urlPhotoUser !== '' ?
                urlPhotoUser :
                'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png')
              }
              
              sx={{ 
                width: '100px' , 
                height: '100px' ,
                marginRight: '44px'
              }}
          />
        </Logo>
                <>
                  {/*<h1>{orderFileName}</h1>*/}
                  <LabelInputPhoto htmlFor='file-input' title='Selecionar arquivo'>
                      {photoName.length === 0 ? 'Selecionar foto' : 'Foto selecionada'}
                  </LabelInputPhoto>
                  <PhotoInput 
                    id='file-input'
                    onChange={e => handlePhotoInputChange(e)}/>
                  </>
            {/*<DeleteLogo>Deletar ícone</DeleteLogo>*/}
        </Image>
      <Line></Line>
      <Account userData={userData} setUserData={setUserData} UpdateDataAccount={UpdateDataAccount} DiscardDataAccount={DiscardDataAccount}/>
        </>
      )}
      
      { aba === 1 && 
      (
        <>
        <ContainerInputs>
          <ContentInputs>
            {inputs.map((input: InputsProps, indice: number) => {
              return (<Input 
                key={input.field_id} 
                id={input.field_id} 
                indice={indice} 
                description={input.description} 
                fieldName={input.field_name} 
                value={input.value} 
                input={input} 
                inputs={inputs} 
                setInputs={setInputs}/>)
            })}
          </ContentInputs>
          </ContainerInputs>
  
        <InformationsAccount>
          <Buttons>
            <Discard onClick={DiscardInformationAccount}>Descartar</Discard>
            <Save onClick={UpdateData}>Salvar</Save>
          </Buttons>
        </InformationsAccount>
        </>
      )}
      
    </Container>
  )
}
