import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import graphImage from '../../../assets/images/graph.png'
import worldImage from '../../../assets/images/world.png'
import {Link} from 'react-router-dom'
import Loading from '../../../components/Loading';
import { ReadOneProduct } from '../../../interfaces';
import {
  Container,
  Content,
  Information,
  Data,
  Vector,
  Text,
  Buttons,
  Back,
  FinishOrder
} from './style'

interface InfoproductsProps{
  idProduto: string;
  setShowInputs: (value: boolean) => void;
  infoProduct: ReadOneProduct
  loading: boolean

}

export default function Infoproducts({ idProduto, setShowInputs, infoProduct, loading }: InfoproductsProps) {
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''

  if(loading){
    return (
    <Container>
      <Content>
      <Loading color="black" withText={true}/>
      </Content>
    </Container>)
  }

  return (
    <Container>

        <Content>
            <Information>
                <h1>
                  {infoProduct?.product_name} 
                </h1>
                <h3><div dangerouslySetInnerHTML={{ __html: infoProduct?.description }} /></h3>
                <Data>
                    <Vector>
                      <img src={graphImage} alt="" />
                      <Text>
                        <h1>Crescimento de</h1>
                        <h2>38%</h2>
                      </Text>
                    </Vector>
                    <Vector>
                      <img src={worldImage} alt="" />
                      <Text>
                      <h1>Alcance superior a</h1>
                      <h2>2.000.000</h2>
                      </Text>
                    </Vector>
                </Data>
                <Buttons>
                  <Link style={{textDecoration: 'none'}} to="/produtos">
                      <Back><ArrowBackIcon />Voltar</Back>
                  </Link>
                  {typeUser === 'company' && <FinishOrder onClick={() => setShowInputs(true)}>Realizar Pedido</FinishOrder>}
                </Buttons>
            </Information>
        </Content>
    </Container>
  )
}
