import styled from "styled-components";

const LongInput = styled.div`
font-size: 1rem;
flex: 1;
padding-top: 0.625rem;
padding-bottom:0.625rem;
input{
    width: 18.75rem;
    height: 2.65625rem;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }

}
input::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
}
`
const Buttons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
position: absolute;
bottom: 0.625rem;
right: 0.625rem;
`

const Discard = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: 0.0625rem solid #5C49DD;
border-radius: 1.875rem;
background-color: #f9f9f9;
color: #5C49DD;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-right: 0.625rem;
`

const Save = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
`

const Inputs = styled.div`
font-size: 1rem;
display: flex;
flex-direction: column;
align-items: center;

`;

export {
    Inputs,
    LongInput,
    Buttons,
    Discard,
    Save
}