import styled from "styled-components";

const Container = styled.div`
height: 4rem;
width: 100%;
background-color: #f9f9f9;
display: flex;
align-items: center;
justify-content: space-between;
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
`

const Leftcontainer = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
margin-left: 1.5625rem;
@media(max-width: 1200px){
    width: 5rem;
    margin-left: 1.25rem;
}
`
const Rightcontainer = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
margin-right: 1.25rem;
`

const Notification = styled.div`
cursor: pointer;
`

const Menu = styled.span`
display: none;
@media(max-width: 1200px){
    display: inline;
    cursor: pointer;
}
`
const Data = styled.div`
display: flex;
align-items: center;
cursor: pointer;
`

const Information = styled.div`
@media (max-width: 1200px){
    display:none;
}
h2{
    font-weight: 500;
    font-size: 0.9375rem;
    color: #6D6D6D;
    margin-bottom: 0.25rem;
}
h3{
    font-size: 0.75rem;
    font-weight: 500;
    color: #6D6D6D;
}
`

export {
    Container,
    Leftcontainer,
    Menu,
    Rightcontainer,
    Notification,
    Data,
    Information
}