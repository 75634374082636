import { AllUsers } from '../../../interfaces'
import {
    Container,
    Image,
    Name,
    Email,
    Role,
    Plan,
    Segmentation,
    Options,
    Delete,
    Edit
} from './style'
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse'
import api from '../../../services/api'
import useAuth from '../../../hooks/useAuth'

interface UserProps {
  user: AllUsers
  setUserEdit: () => void
  updateRegisteredUsers: () => void;
  setShowPopupEdit: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
}

interface UserDataProps {
  user: AllUsers
  setUserEdit: () => void
  updateRegisteredUsers: () => void;
  setShowPopupEdit: (value: boolean) => void;
  hasUser: boolean
  setIsLoading: (value: boolean) => void;
}

export default function Users({ user, setUserEdit, updateRegisteredUsers, setShowPopupEdit, setIsLoading }: UserProps) {
  const hasUser = !!user
  return(
    <UserData user={user} hasUser={hasUser} setUserEdit={setUserEdit} updateRegisteredUsers={updateRegisteredUsers} setShowPopupEdit={setShowPopupEdit} setIsLoading={setIsLoading}/>
  )
}

function UserData({ user, hasUser, setUserEdit, updateRegisteredUsers, setShowPopupEdit, setIsLoading}: UserDataProps) {
  const { token } = useAuth()
  const authToken = token?.access_token ?? ''
  const { setShowPopup, setErrorMessage, setTypeError } = usePopupTrueFalse()
  let usuario = user?.role?.name

  if(usuario === 'admin') usuario = 'Admin'
  if(usuario === 'editor') usuario = 'Editor'
  if(usuario === 'company') usuario = 'Empresa'


  function deleteAndUpdateUser(token: string, id: number){
    setIsLoading(true)
    const promise = api.deleteOneUser(token, id)
    promise.then(() => {
      updateRegisteredUsers();
    }).catch((error) => {
      setTypeError(false)
      console.error(error)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
    }).finally(()=>{
      setIsLoading(false)
    })
  }

  function editUser(){
    setShowPopupEdit(true)
    setUserEdit()
  }


  return(
    <Container hasUser={hasUser}>
      <Image>
        {user?.name && <img 
        src={user?.profile_picture !== null ? 
        user?.profile_picture : 
        'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'} 
        alt="" />}
      </Image>
      <Name>
        {user?.name && user?.name} 
      </Name>
      <Email>
        {user?.email && user?.email}
      </Email>
      <Role>
        {usuario && usuario}
      </Role>
      <Plan>
        {user?.name && (user?.company?.plan ? user?.company?.plan?.plan_name : '-')}
      </Plan>
      <Segmentation>
        {user?.name && (user?.company?.segmentation ? user?.company?.segmentation?.segmentation : '-')}
      </Segmentation>
      <Options>
        {user?.name && <>
          <Edit onClick={editUser}>Editar</Edit>
          <Delete onClick={() => {deleteAndUpdateUser(authToken,user?.id)}}>Deletar</Delete>
        </>}
      </Options>
    </Container>
  )
}