import {useState, useEffect} from 'react'
import { PopupProps,  PlansProps, SegmentationProps} from '../../../interfaces'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../../components/Loading'
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import {
  Container,
  InnerContainer,
  Close,
  FormsContainer,
  LongInput,
  Icon,
  BackgroundPopup,
} from './style';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import AddSegmentation from '../../../components/AddSegmentation';


export default function Popup({ setShowPopupAddUser, updateRegisteredUsers }:PopupProps) {

  const [newUserName, setNewUserName] = useState("")
  const [newUserEmail, setNewUserEmail] = useState("")
  const [newUserPassword, setNewUserPassword] = useState("")
  const [newTypeUser, setNewTypeUser] = useState('')
  const [newUserPlan, setNewUserPlan] = useState('')
  const [newUserSegmentation, setNewUserSegmentation] = useState('')
  const [plansOptions, setPlansOptions] = useState([])
  const [segmentationsOptions, setSegmentationsOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showAddSegmentationPopup, setShowAddSegmentationPopup] = useState(false)
  const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse()
  const { token } = useAuth()
  const authToken = token?.access_token ?? ''

  useEffect(() => {
    Promise.all([
      api.readPlans(authToken),
      api.readSegmentations(authToken),

    ]).then((responses) => {
      setPlansOptions(responses[0].data)
      setSegmentationsOptions(responses[1].data)
    }).catch((error) => {
      setTypeError(false)
      setErrorMessage('Tente novamente em instantes!')
      setShowPopup(true)
    }).finally(() => {

    })
    // eslint-disable-next-line
  }, [authToken])

  interface Body{
    name: string,
    email: string,
    password: string,
    role_id: string,
    company_id?: string,
    segmentation_id?: string,
    plan_id?: string
    company_name?: string
  }

  function submitUser(){
    setIsLoading(true)
    let body : Body;
    if(newTypeUser === "2") {
      body = {
        name: newUserName,
        email: newUserEmail,
        password: newUserPassword,
        role_id: newTypeUser,
        segmentation_id: newUserSegmentation,
        plan_id: newUserPlan
      }
    } else {
      body = {
        name: newUserName,
        email: newUserEmail,
        password: newUserPassword,
        role_id: newTypeUser
      }
    }

      const promise = api.createOneUser(body, authToken)

      promise.then((response) => {
        setNewUserEmail('')
        setNewUserName('')
        setNewTypeUser('')
        setNewUserPassword('')
        setNewUserPlan('')
        setShowPopupAddUser(false)
        updateRegisteredUsers()
        setTypeError(true)
        setErrorMessage('Usuário cadastrado!')
        setShowPopup(true)
      })
      
      promise.catch((error)=>{
        console.error(error)
        setTypeError(false)
      if(typeof error.response?.data?.detail === 'string'){
            setErrorMessage(error.response?.data?.detail)
      }else{
            setErrorMessage('Verifique se todas as informações foram preenchidas e tente novamente em instantes.')
      }
      setShowPopup(true)
      })

      promise.finally(()=> {
        setIsLoading(false)
      })
      
  }

      segmentationsOptions.sort(function (a: SegmentationProps, b: SegmentationProps) {
        var nameA = a?.segmentation.toUpperCase(); 
        var nameB = b?.segmentation.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

  return (
    <>
      {(showAddSegmentationPopup) && 
      (<BackgroundPopup>
        <AddSegmentation authToken={authToken} setSegmentationsOptions={setSegmentationsOptions} setShowAddSegmentationPopup={setShowAddSegmentationPopup}/>
      </BackgroundPopup>)}
            
    <Container>
      <InnerContainer>
      <Close onClick={() => {setShowPopupAddUser(false)}}>
        <CloseIcon />
      </Close>

      <FormsContainer>
        <LongInput>
          <label htmlFor="userName">Nome do usuário</label>
          <input
            autoComplete='off' 
            type="text" 
            placeholder="Nome do Usuário" 
            name="userName" id='userName' 
            value={newUserName}
            onChange={e=>setNewUserName(e.target.value)}
            />
          </LongInput>

          <LongInput>
          <label htmlFor="userEmail">Email do usuário</label>
          <input 
            autoComplete='off' 
            type="text" 
            placeholder="Email do usuário" 
            name="userEmail" id='userEmail' 
            value={newUserEmail}
            onChange={e=>setNewUserEmail(e.target.value)}
            />
          </LongInput>

          <LongInput>
          <label htmlFor="userPassword">Senha do usuário</label>
          <input
            autoComplete='off'  
            type="text" 
            placeholder="Senha do usuário" 
            name="userPassword" id='userPassword' 
            value={newUserPassword}
            onChange={e=>setNewUserPassword(e.target.value)}
            />
            </LongInput>

          <LongInput>
          <label htmlFor="typeUser">Tipo de usuário</label>
          <select onChange={e => setNewTypeUser(e.target.value)} value={newTypeUser} name="typeUser" placeholder=''>
              <option value="">  </option>
              <option value="1">Administrador</option>
              <option value="2">Empresa</option>
              <option value="3">Redator</option>
          </select>
            </LongInput>

          {(newTypeUser === "2") && (
          <LongInput>
          <label htmlFor="userPlan">Plano da Empresa</label>
          <select onChange={e => setNewUserPlan(e.target.value)} value={newUserPlan} name="userPlan" placeholder='Selecione um plano'>
          <option value="">  </option>
          {plansOptions.map((plan: PlansProps) => (
            <option key={plan?.id} value={plan.id}>
              {plan.plan_name}
            </option>
          ))}
          </select>
          </LongInput>
          )}

          {(newTypeUser === "2") && (
          <LongInput>
          <Icon onClick={() => setShowAddSegmentationPopup(true)}><AddIcon></AddIcon></Icon> <label htmlFor="userSegmentation">  Segmentação</label>
          <select onChange={e => setNewUserSegmentation(e.target.value)} value={newUserSegmentation} name="userSegmentation" placeholder='Selecione uma segmentação'>
          <option value="">  </option>
          {segmentationsOptions.map((segmentation: SegmentationProps) => (
            <option key={segmentation?.id} value={segmentation.id}>
              {segmentation.segmentation}
            </option>
          ))}
          </select>
          </LongInput>
          )}

          <button onClick={submitUser}>Cadastrar usuário</button>
          {isLoading && <Loading color="black" withText={true}/>}
          </FormsContainer>
      </InnerContainer>
    </Container>
    </>
  )
}
