import styled from "styled-components";

interface InvalidProps{
    invalidInformation: boolean;
}

const Container = styled.div`
h1{
    font-weight: 700;
    font-size: 3rem;
    color: #333333;
    padding-bottom:1.875rem;
    @media (max-width: 500px){
        font-size: 1.875rem;
    }
}

h2{
  font-weight: 400;
    font-size: 1.5rem;
    color: #333333;
    padding-bottom:1.875rem;
    @media (max-width: 500px){
        font-size: 1.0rem;
    }
}

form{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
`

const ContainerInput = styled.div`
width: 31.6875rem;
@media (max-width: 560px){
    width: 18.75rem;
}
height: 4.25rem;
position: relative;
  padding: 0.9375rem 0 0;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  position: relative;
`
const Label = styled.label<InvalidProps>`
position: absolute;
top: 0;
display: block;
transition: 0.2s;
font-size: 0.9375rem;
color: ${props => props.invalidInformation ? '#ff0000' : '#333333'};
`

const Input = styled.input<InvalidProps>`
font-family: inherit;
border-radius: 0.3125rem;
width: 100%;
border: 0;
border-bottom: 0.125rem solid #333333;
outline: 0;
font-size: 1.125rem;
color: #333333;
padding: 0.4375rem 0;
background: transparent;
transition: border-color 0.2s;
border-bottom: 0.0625rem solid ${props => props.invalidInformation ? '#ff0000' : '#333333'};
color: ${props => props.invalidInformation ? '#ff0000' : '#333333'};


&::placeholder {
color: transparent;
}

&:placeholder-shown ~ label {
font-size: 1.125rem;
cursor: text;
top: 1.25rem;
}

:focus {
    ~ ${Label} {
      position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 0.9375rem;
        font-weight:700; 
        color: ${props => props.invalidInformation ? '#ff0000': '#333333'};
    }
    padding-bottom: 0.375rem;  
      font-weight: 700;
      border-width: 0.1875rem;
      border-image-slice: 1;
      border-image: ${props => props.invalidInformation ? '#ff0000': '#333333'};
  /* reset input */
  
    &:required,&:invalid { box-shadow:none; }
  }
`

const ShowPassword = styled.div`
width: 5.625rem;
height: 1.9375rem;
border-radius: 1.875rem;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
position: absolute;
right: 1.125rem;
top: 0.75rem;
border: 0.0625rem solid #333333;
color: #333333;
`

export {
    Container,
    ContainerInput,
    Input,
    Label,
    ShowPassword
}