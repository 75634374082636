import { ChangeEvent, ReactNode } from "react"
import * as S from "./style"
type TextareaProps = {
	labelClass?: string
	label?: string | ReactNode
	description?: string
	type?: string
	name?: string
	cols?: number
	rows?: number
	className?: string
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
	defaultValue?: string
	value?: string
	placeholder?: string
	maxLength?: number
	disabled?: boolean
	required?: boolean
}

export default function Textarea({
	label = "",
	name,
	className = "",
	onChange,
	defaultValue,
	value = "",
	cols,
	rows,
	placeholder,
	required,
	disabled,
}: TextareaProps) {
	return (
		<S.Label className="flex flex-column justify-content-flex-end small-gap">
			<span className="medium semibold-font">
				{label}
			</span>
			<textarea
				cols={cols}
				rows={rows}
				name={name}
				className={className}
				onChange={onChange && (e => onChange(e))}
				defaultValue={defaultValue}
				value={value}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</S.Label>
	)
}
