import { FilesContainer, FilesContent } from "./style"
import download from '../../../assets/images/download.png'
import CloseIcon from '@mui/icons-material/Close';

interface FileProps{
  file: File
  setUserOrderFiles: (value: File[]) => void;
  userOrderFiles: File[];
}

export default function Files({file, userOrderFiles, setUserOrderFiles}: FileProps) {

    function confirmDeleteFile(event: any){
      event.stopPropagation()
      const newFiles = userOrderFiles.filter((fileItem) => {
        return fileItem !== file
      })
      setUserOrderFiles(newFiles)
    }
  

    function limitarString(string: string, limite: number) {
        if (string?.length <= limite) {
          return string; 
        } else {
          return string?.slice(0, limite) + '...'; 
        }
      }


  return (
    <FilesContainer>
      <FilesContent>
        <img 
          src={download} 
          title="Download" 
          alt=""
        /> 
          {limitarString(file.name, 20)} 
          </FilesContent>
          <div onClick={(e) => {
          confirmDeleteFile(e)
          }}>
            <CloseIcon/>
          </div>
    </FilesContainer>
  )
}
