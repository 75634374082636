// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrolling-wrapper {
	display: flex;
	flex-wrap: no-wrap;
	overflow-x: auto;
	cursor: grab;
	min-height: -moz-fit-content;
	min-height: fit-content;
	padding-bottom: .2rem;
	width: 100%;
	max-width: 100%;
}
.scrolling-wrapper.active {cursor: grabbing}
.scrolling-wrapper[data-dragging="true"] a {pointer-events: none}
`, "",{"version":3,"sources":["webpack://./src/components/HorizontalGrabbableBlock/HorizontalGrabbableBlock.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;CAClB,gBAAgB;CAChB,YAAY;CACZ,4BAAuB;CAAvB,uBAAuB;CACvB,qBAAqB;CACrB,WAAW;CACX,eAAe;AAChB;AACA,2BAA2B,gBAAgB;AAC3C,4CAA4C,oBAAoB","sourcesContent":[".scrolling-wrapper {\r\n\tdisplay: flex;\r\n\tflex-wrap: no-wrap;\r\n\toverflow-x: auto;\r\n\tcursor: grab;\r\n\tmin-height: fit-content;\r\n\tpadding-bottom: .2rem;\r\n\twidth: 100%;\r\n\tmax-width: 100%;\r\n}\r\n.scrolling-wrapper.active {cursor: grabbing}\r\n.scrolling-wrapper[data-dragging=\"true\"] a {pointer-events: none}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
