import useAuth from '../../../hooks/useAuth'
import Option from '../../Option'
import { Logout } from './style'

export default function Options() {

  const { user } = useAuth() 
  const userData = localStorage.getItem("user") ?? '{}'
  const typeUser = user?.role?.name ?? ''
  const admin = (typeUser === 'admin' ? true : false)
  const company = (typeUser === 'company' ? true : false)
  const editor = (typeUser === 'editor' ? true: false)

  const clippingStatus = company && JSON.parse(userData).company ? JSON.parse(userData).company.clipping : false

  return (
    <>
      <Option iconName="barChart" propriedade="Home" href="/home"/>
      <Option iconName="cart" propriedade="Produtos" href="/produtos"/>
      <Option iconName="bulb" propriedade="Sugestões" href="/sugestoes"/>
      {company && <Option iconName="pendingActions" propriedade="Meus Pedidos" href="/pedidos" />}
      {(editor || admin) && <Option iconName="pendingActions" propriedade="Todos os pedidos" href="/pedidos" />}
      <Option iconName="settings" propriedade="Minha Conta" href="/configuracoes" />
      {false && <Option iconName="notificationsNone" propriedade='Notificações' href="/notificacoes" />}
      {false && <Option iconName="help" propriedade="Ajuda" href="/ajuda" />}
      {admin && <Option iconName="group" propriedade="Usuários" href="/usuarios"/>}
      {(editor || admin) && <Option iconName="Apartment" propriedade="Empresas" href="/empresas"/>}
      {((company && clippingStatus) || admin ) && <Option iconName="fire" propriedade="Resultados" href="/resultados"/>}

      <Logout>
        <Option iconName="logout" propriedade="Sair" href="/login" />
      </Logout>
    </>
  )
}



