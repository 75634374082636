import { useEffect, useState } from 'react'
import { Loading } from '../../components'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import { PlanProps, ReadMyOrders } from '../../interfaces'
import api from '../../services/api'
import * as S from './style'
import Banner from './Banner'
import InformationHome from './InformationHome'
import ComunicationPlan from './ComunicationPlan'

export default function PageLayoutClient({children}:any) {

    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? ''
    const plan: PlanProps | null = JSON.parse(localStorage.getItem("planInfo") || 'null');
    const orders: ReadMyOrders[] | null = JSON.parse(localStorage.getItem("orders") || 'null');
    const [loading, setLoading] = useState<boolean>(false)
    const {setShowPopup} = usePopupTrueFalse()

    
    useEffect(() => {
        setShowPopup(false)
        setLoading(true)
        const promise = api.readPlanInfo(authToken)
        promise.then((response) => {
            localStorage.setItem("planInfo", JSON.stringify(response.data?.plan));
            localStorage.setItem("orders", JSON.stringify(response.data?.orders));
        }).catch((error) => {
            console.error(error)
        })
        .finally( () => {
            setLoading(false)
        })
        // eslint-disable-next-line
    },[authToken, typeUser])
    

  return (
    <S.Container>
        {loading ? 
            <Loading color="black" withText={true}/> :
            <S.InnerContainer>
                <S.Main>
                    {(typeUser === 'company' && plan !== undefined) && (<Banner plan={plan?.plan_name}/>)}
                    {children}
                </S.Main>
                <S.PlanCard>
                    {(typeUser === 'company') && (
                        <>
                        
                            <InformationHome plan={plan?.plan_name} orders={orders}/>
                            <ComunicationPlan/>
                        
                        </>
                    )}
                </S.PlanCard>
            </S.InnerContainer>
        }
    </S.Container>
  )
}