import { userSchema } from '../schemas';

export function validateLogin(schema :object) : boolean {
    const validation = userSchema.validate(schema);

    
    if(!validation.error){
        return true;
    } else{
        return false;
    }


}