import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'

const Container = styled.div`
background-color: #E5E5E5;
min-height: calc(100vh - 4rem);
height: auto;
width: 100%;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right ;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const Title = styled.div`
font-weight: 600;
font-size: 1.5rem;
line-height: 1.8rem;
color: #333333;
width: 90%;
margin-bottom: 1.5rem;
@media(max-width: 1735px){
    width: 90%;
}
`

const InnerContainer = styled.div`
width: 90%;
height: 51.87rem;
background: #F9F9F9;
box-shadow: 0.376rem 0.376rem 1.5rem rgba(0, 0, 0, 0.1);
border-radius: 1.5rem;
padding-bottom: 5.187rem;
`

const Header = styled.div`
width: 100%;
height: 5.187rem;
border-top-left-radius: 1.5rem;
border-top-right-radius: 1.5rem;
border-bottom: 1px solid #e2e2e2;
font-weight: 500;
font-size: 1.2rem;
line-height: 1.428rem;
color: #6D6D6D;
display: flex;
`

const Companies = styled.div`
width: 100%;
height: 46.69rem;
`

const Image = styled.div`
width: 10%; 
min-width: 5rem;
height: 5.187rem;
@media(max-width: 700px){
    min-width: 4rem;
}
`

const Name = styled.div`
width: 25%;
min-width: 9.5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 700px){
    min-width: 7rem;
}
`

const Email = styled.div`
width: 25%;
min-width: 16rem;
height: 5.187rem;
display: flex;
align-items: center;
`

const Plan = styled.div`
width: 10%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 764px){
    display: none;
}
`

const RegistrationDate = styled.div`
width: 20%;
min-width: 16rem;
height: 5.187rem;
display: flex;
align-items: center;
`

const Segmentation = styled.div`
    width:  10%;
    height: 5.187rem;
    display: flex;
    align-items: center;
`

const ContainerPagination = styled.div`
height: 5.11rem;
display: flex;
padding-right: 2.93rem;
align-items: center;
justify-content: end;
.MuiPaginationItem-root.Mui-selected{
    background-color: #5C49DD;
}

.MuiPaginationItem-root.Mui-selected:hover{
    background-color: #5C49DD;
}

`
const IconSpan = styled.span`
cursor: pointer;
`

const SearchContainer = styled.div`
width: 90%;
`

const SearchInnerContainer = styled.div`
width: 50%;
height: 3rem;
background: #FFFFFF;
box-shadow: 0.376rem 0.376rem 1.5rem rgba(0, 0, 0, 0.1);
border-radius: 10px;
margin-bottom: 1rem;
display: flex;
align-items: center;
svg{
    margin-left: 1rem;
    margin-right: 1rem;
}
input{
    border: none;
    outline: none;
    width: 90%;
    height: 2.8rem;
    border-radius-top-right: 10px;
    border-radius-bottom-right: 10px;
    font-size: 1rem;
    @media(max-width: 1530px){
        width: 85%;
    }
    @media(max-width: 895px){
        width: 80%;
    }
}
`


export {
    Container,
    Title,
    InnerContainer,
    Header,
    Companies,
    Image,
    Name,
    Email,
    RegistrationDate,
    ContainerPagination,
    IconSpan,
    Plan,
    Segmentation,
    SearchContainer,
    SearchInnerContainer
}