import styled from "styled-components"
import mentionFundo from '../../assets/images/mention_fundo.png'


interface PopupContainerProps {
  showPopup: boolean;
}

interface PopupEditContainerProps {
    showPopupEdit: boolean;
}

const Container = styled.div`
width: 100%;
min-height: calc(100vh - 4rem);
height: auto;
background-color: #e5e5e5;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right ;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
position: relative;
`

const Title = styled.div`
color: #333333;
font-weight: 600;
font-size: 1.5rem;
width: 100%;
display: flex;
justify-content: center;
padding-top: 2.25rem;
`

const ContainerButton = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
margin-right: 2.8125rem;
button{
        cursor: pointer;
        width: 10.75rem;
        height: 2.65625rem;
        border: none;
        border-radius: 1.875rem;
        background-color: #5C49DD;
        color:#f9f9f9;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.21rem;
        margin-bottom: 1.25rem;
    
}
`

const InnerContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

const Dados = styled.div`
width: 95%;
min-height: 43.1875rem;
height: auto;
background-color: #f9f9f9;
border-radius: 1.25rem;
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
background-color: gray;
font-size: 1rem;
color: #f9f9f9;
width: 100%;
border-top-right-radius: 1.25rem;
border-top-left-radius: 1.25rem;
height: 2.5rem;
display: flex;
align-items: center;
justify-content: center;
h1{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
span{
    margin: 0rem auto;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
`


const PopupContainer = styled.div<PopupContainerProps>`
align-items: center;
justify-content: center;
position: relative;
display: ${props => props.showPopup ? 'block' : 'none'};
position: absolute;
top: 7.6875rem;
left: 0;
right: 0;
margin: auto;
`

const PopupEditContainer = styled.div<PopupEditContainerProps>`
align-items: center;
justify-content: center;
position: relative;
display: ${props => props.showPopupEdit ? 'block' : 'none'};
position: absolute;
top: 7.6875rem;
left: 0;
right: 0;
margin: auto;

`

export {
    Container,
    Title,
    ContainerButton,
    PopupEditContainer,
    PopupContainer,
    InnerContainer,
    Dados,
    Header
}