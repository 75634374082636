import styled from "styled-components";

const Container = styled.div<{$cardPadding?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    min-width: 21rem;
    border-radius: .5rem;

    background: rgba(249, 249, 249, 0.40);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);

    @media(max-width: 1024px){
        display: flex;
        flex-direction:column;
        align-items: center;
        max-width: 15rem;
    }
    
`

const Title = styled.div`
    text-align: left;

    padding: 1rem 1.5rem;
    color: #333;
    font-family: Inter;
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    max-width: 18rem;

    @media(max-width: 1500px){
        font-size: 1.2rem; 
    }

    span{
        color: #5C49DD;
        font-family: Inter;
        font-size: 1.35rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`
export {
    Container,
    Title
}