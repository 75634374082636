import styled from "styled-components"
import mentionFundo from '../../assets/images/mention_fundo.png'
import construcao from '../../assets/images/construcao.png'

const Container = styled.div`
background-color: #E5E5E5;
    height: calc(100vh - 4rem);
    width: 100%;
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right ;
    background-size: 16.5625rem auto;
    background-color: rgba(0, 0, 0, 0.1);
    
`

const Image = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${construcao});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16.5625rem auto;
`

export {
    Container,
    Image
}