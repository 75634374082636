import { useState } from 'react';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import api from '../../services/api';
import Loading from '../Loading';
import {
  Container,
  ClosePopup,
  ConfirmDelete,
  Voltar
} from './style'

interface PopupConfirmationProps{
  setShowConfirmDelete: (value: boolean) => void;
  setReload: (value: boolean) => void;
  fileInDeleteConfirmation: string;
  orderId: string;
  authToken: string;
  typeUser: string;

}

const CONTAINER_NAME = process.env.REACT_APP_AZURE_CONTAINER_NAME

export default function PopupConfirmation({setShowConfirmDelete, fileInDeleteConfirmation, orderId, authToken, setReload}: PopupConfirmationProps) {
  
  let nameFile = ''
    if(fileInDeleteConfirmation?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}_`)){
      nameFile = fileInDeleteConfirmation?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}_`)[1]
    } else if(fileInDeleteConfirmation?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/order_file_${orderId}_`)){
      nameFile = fileInDeleteConfirmation?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/order_file_${orderId}_`)[1]
    } else if(fileInDeleteConfirmation?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}/`)){
      nameFile = fileInDeleteConfirmation?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}/`)[1]
    } else{
      nameFile = fileInDeleteConfirmation?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/`)[1]
    }

  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse();
  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
        setErrorMessage(error.response?.data?.detail)
    }else{
        setErrorMessage('Tente novamente em instantes!')
        }
      setShowPopup(true)
  }

  function deleteFile(){
    setLoading(true)
      const promise = api.deleteOrderFile(authToken, orderId, fileInDeleteConfirmation)
      promise.then(() => {
        setLoading(false)
        setReload(true)
      }).catch((error) => {
        setError(error)
      }).finally(()=>{
        setShowConfirmDelete(false)
      })
  }

  if(loading){
    return(
      <Container>
        <Loading color="black" />
      </Container>
    )
  }

  return (
    <Container>
      <ClosePopup onClick={() => setShowConfirmDelete(false)}>
        x
      </ClosePopup>
      <h1>Você realmente quer apagar o arquivo?</h1>
      <h2>{nameFile}</h2>

      <div>
        <Voltar onClick={() => {setShowConfirmDelete(false)}}>
          Voltar
        </Voltar>
        <ConfirmDelete onClick={deleteFile}>
          Confirmar
        </ConfirmDelete>
      </div>
    </Container>
  )
}
