import styled from "styled-components";
import mentionLogo from '../../assets/favicon.png'

const Container = styled.div`
width: 100vw;
height: 100vh;
background-image: url(${mentionLogo});
background-repeat: no-repeat;
background-position: center;
background-size: 9.375rem auto;
`

export{
    Container
}