// Library imports
import  { useEffect } from "react"

// Style CSS
import "./HorizontalGrabbableBlock.css"

// https://codepen.io/SeanCodePenn/pen/OojaxJ
export default function HorizontalGrabbableBlock({ children }) {

	useEffect(() =>  {
		const slider = document.querySelector(".scrolling-wrapper")
		let isDown = false
		let startX
		let scrollLeft

		const lockCursor = (e) => {
			let rect = slider.getBoundingClientRect()
			isDown = true
			slider.classList.add("active")
			// Get initial mouse position
			startX = e.pageX - rect.left
			// Get initial scroll position in pixels from left
			scrollLeft = slider.scrollLeft
		}

		const unlockCursor = () => {
			isDown = false
			slider.dataset.dragging = false
			slider.classList.remove("active")
		}

		const dragBlock = (e) => {
			if (!isDown) return
			let rect = slider.getBoundingClientRect()
			e.preventDefault()
			slider.dataset.dragging = true
			// Get new mouse position
			const x = e.pageX - rect.left
			// Get distance mouse has moved (new mouse position minus initial mouse position)
			const walk = x - startX
			// Update scroll position of slider from left (quantity mouse has moved minus initial scroll position)
			slider.scrollLeft = scrollLeft - walk
		}

		slider.addEventListener("mousedown", lockCursor)
		slider.addEventListener("mouseleave", unlockCursor)
		slider.addEventListener("mouseup", unlockCursor)
		slider.addEventListener("mousemove", dragBlock)

		return () => {
			slider.removeEventListener("mousedown", lockCursor)
			slider.removeEventListener("mouseleave", unlockCursor)
			slider.removeEventListener("mouseup", unlockCursor)
			slider.removeEventListener("mousemove", dragBlock)
		}
	})

	return(
		<div className="scrolling-wrapper">
			{children}
		</div>
	)
}
