import { useEffect, useState } from 'react'
import DelegateOrder from '../../components/DelegateOrder'
import { CompanyProps, EditorProps, ReadMyOrders, ReadCommunicationPlans } from '../../interfaces'
import api from '../../services/api'
import { Search } from '@mui/icons-material';
import Row from './Row'
import DelegateCommunicationPlan from '../../components/DelegateCommunicationPlan';
import * as S from './style'
import HorizontalGrabbableBlock from '../../components/HorizontalGrabbableBlock/HorizontalGrabbableBlock';


export default function NewHome() {

  let OrdersOpen: ReadMyOrders[] | undefined
  let OrdersDelegated: ReadMyOrders[] | undefined
  let OrdersInProduction: ReadMyOrders[] | undefined
  let OrdersAwaitingApprovalInternal: ReadMyOrders[] | undefined
  let OrdersAwaitingChangesInternal: ReadMyOrders[] | undefined
  let OrdersAwaitingApproval: ReadMyOrders[] | undefined
  let OrdersAwaitingChanges: ReadMyOrders[] | undefined
  let OrdersInNegotiation: ReadMyOrders[] | undefined
  let OrdersInDistributed: ReadMyOrders[] | undefined
  let OrdersClosed: ReadMyOrders[] | undefined

  let CommunicationOpen: ReadCommunicationPlans[] | undefined
  let CommunicationDelegated: ReadCommunicationPlans[] | undefined
  let CommunicationInProduction: ReadCommunicationPlans[] | undefined
  let CommunicationAwaitingApprovalInternal: ReadCommunicationPlans[] | undefined
  let CommunicationAwaitingChangesInternal: ReadCommunicationPlans[] | undefined
  let CommunicationAwaitingApproval: ReadCommunicationPlans[] | undefined
  let CommunicationAwaitingChanges: ReadCommunicationPlans[] | undefined

    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? ''
    const [orders, setOrders] = useState<ReadMyOrders[]>()
    const [companies, setCompanies] = useState([])
    const [editors, setEditors] = useState([])
    const [showCheckboxes, setShowCheckboxes] = useState(localStorage.getItem('checkbox') === 'true' || false)
    const [showCompanyList, setShowCompanyList] = useState(false)
    const [delegateOrder, setDelegateOrder] = useState(false)
    const [delegateCommunicationPlan, setDelegateCommunicationPlan] = useState(false)
    const [communicationPlans, setCommunicationPlans] = useState<ReadCommunicationPlans[]>()
    const [orderIdInDelegate, setOrderIdInDelegate] = useState('')
    const [companyUserIdInDelegate, setCompanyUserIdInDelegate] = useState('')
    const [reload, setReload] = useState(true)
    const [filters, setFilters] = useState({
      priority:'',
      company: '',
      editor:'',
      id: '',
      date:''
    })
    let status: string[] = []
    if(typeUser === 'admin'){
      status = ['Aberto', 'Delegado', 'Em produção', 'Aguardando aprovação interna', 'Aguardando mudança interna', 'Aguardando aprovação cliente', 'Aguardando mudança cliente', 'Em negociação', 'Publicado', 'Disparado']
    } else if(typeUser === 'editor'){
      status = ['Delegado', 'Em produção', 'Aguardando aprovação interna', 'Aguardando mudança interna', 'Aguardando aprovação cliente', 'Aguardando mudança cliente', 'Em negociação', 'Publicado', 'Disparado']
    }
    const [selectedItems, setSelectedItems] = useState<string[]>(status)

  useEffect(() => {
    if(reload === true){
    if(typeUser === 'admin'){
      Promise.all([
        api.readAllOrdersAdminHome(authToken), 
        api.readCompanies(authToken),
        api.readEditors(authToken),
        api.getCommunicationPlans(authToken)]).then((responses)=>{
          setOrders(responses[0].data)
          setCompanies(responses[1].data)
          setEditors(responses[2].data)
          setCommunicationPlans(responses[3].data)
        }).catch((error) => {

        }).finally(() => {
        })
    } else if(typeUser === 'editor'){
      Promise.all([
        api.readMyDelegatedOrders(authToken),
        api.readCompanies(authToken),
        api.readOwnDelegatedCommunicationPlans(authToken),
      ]).then((responses) => {
        setOrders(responses[0].data)
        setCompanies(responses[1].data)
        setCommunicationPlans(responses[2].data)
      }).catch((error) => {

      }).finally(() => {

      })
    }
    const storedValue = localStorage.getItem('checkbox') ?? ''
    if(storedValue === ''){
      localStorage.setItem('checkbox', JSON.stringify(false));
    }}
    setReload(false)
    // eslint-disable-next-line
},[authToken, typeUser, showCheckboxes, reload])

const handleFilterChange = (filter: string, value: string) => {
  if(filter === 'company'){
    setShowCompanyList(true)
  }
  setFilters((prevState) => ({
    ...prevState,
    [filter]: value,
  }))
}

const handleFilterChoose = (filter: string, value: string) => {
  setShowCompanyList(false)
  setFilters((prevState) => ({
    ...prevState,
    [filter]: value,
  }))
}

const defaultSort = (a: string, b: string) => status.indexOf(a) - status.indexOf(b)

function toggleSelect(event: any) {
  event.stopPropagation();
  localStorage.setItem('checkbox', JSON.stringify(!showCheckboxes))
  setShowCheckboxes(!showCheckboxes);
}

function handleAllItem(event: any) {
  event.stopPropagation();
  if (selectedItems.length === status.length) {
    setSelectedItems([]);
  } else {
    setSelectedItems(status);
  }
}

const closeSelect = () => {
  setShowCheckboxes(false);
  localStorage.setItem('checkbox', JSON.stringify(false))
}

const handleItemClick = (item: string, e:any) => {
  e.stopPropagation();
  if (selectedItems.includes(item)) {
    setSelectedItems(selectedItems.filter((i) => i !== item));
  } else {
    setSelectedItems([...selectedItems, item]);
  }
};


  const filteredData = orders?.filter((item) => {
    if(filters.priority !== '' && item.priorities?.priority !== Number(filters.priority)) {
      return false
    }
    if(filters.company !== '' && item.company?.user?.name.toLowerCase().includes(filters.company.toLowerCase()) === false) {
      return false
    }
    if(filters.id !== '' && item.id.toString().includes(filters.id) === false){
      return false
    }
    if(filters.editor !== '' && item.editor?.user?.name !== filters.editor) {
      return false
    }
    if (filters.date !== '' && filters.date !== item.deadline.slice(0, 10)) {
        return false;
      }
    else{
      return true;
    }})
    
    const firstFourCompanyNameFiltred = companies?.filter((item: CompanyProps) => {
      if(filters.company !== '' && item.user.name.toLowerCase().includes(filters.company.toLowerCase()) === false) {
        return false
      }
      else{
        return true;
      }
    }).map((item: CompanyProps) => {
      return item.user.name
    })
  
    CommunicationOpen = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'open');
    CommunicationDelegated = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'delegated' && item?.communication_plan_editor?.name);
    CommunicationInProduction = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'in_production' && item?.communication_plan_editor?.name);
    CommunicationAwaitingApprovalInternal = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'awaiting_approval_internal'  && item?.communication_plan_editor?.name);
    CommunicationAwaitingChangesInternal = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'awaiting_changes_internal'  && item?.communication_plan_editor?.name);
    CommunicationAwaitingApproval = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'awaiting_approval'  && item?.communication_plan_editor?.name);
    CommunicationAwaitingChanges = communicationPlans?.filter(item => item?.communication_plan_status?.status === 'awaiting_changes'  && item?.communication_plan_editor?.name);

    OrdersOpen = filteredData?.filter(item => item.status.status === 'open');
    OrdersDelegated = filteredData?.filter(item => item.status.status === 'delegated');
    OrdersInProduction = filteredData?.filter(item => item.status.status === 'in_production');
    OrdersAwaitingApprovalInternal = filteredData?.filter(item => item.status.status === 'awaiting_approval_internal');
    OrdersAwaitingChangesInternal = filteredData?.filter(item => item.status.status === 'awaiting_changes_internal');
    OrdersAwaitingApproval = filteredData?.filter(item => item.status.status === 'awaiting_approval');
    OrdersAwaitingChanges = filteredData?.filter(item => item.status.status === 'awaiting_changes');
    OrdersInNegotiation = filteredData?.filter(item => item.status.status === 'in_negotiation');
    OrdersClosed = filteredData?.filter(item => item.status.status === 'closed');
    OrdersInDistributed = filteredData?.filter(item => item.status.status === 'distributed');

    function handleOrderType(type: string){
      switch(type){
        case 'Aberto':
          return OrdersOpen
      
        case 'Delegado':
          return OrdersDelegated
        
        case 'Em produção':
          return OrdersInProduction
        
        case 'Aguardando aprovação interna':
          return OrdersAwaitingApprovalInternal
        
        case 'Aguardando mudança interna':
          return OrdersAwaitingChangesInternal
        
        case 'Aguardando aprovação cliente':
          return OrdersAwaitingApproval
        
        case 'Aguardando mudança cliente':
          return OrdersAwaitingChanges
        
        case 'Em negociação':
          return OrdersInNegotiation
        
        case 'Publicado':
          return OrdersClosed

        case 'Disparado':
          return OrdersInDistributed
    }}

    function handleCommunicationPlanType(type: string){
      switch(type){
        case 'Aberto':
          return CommunicationOpen
      
        case 'Delegado':
          return CommunicationDelegated
        
        case 'Em produção':
          return CommunicationInProduction
        
        case 'Aguardando aprovação interna':
          return CommunicationAwaitingApprovalInternal
        
        case 'Aguardando mudança interna':
          return CommunicationAwaitingChangesInternal
        
        case 'Aguardando aprovação cliente':
          return CommunicationAwaitingApproval
        
        case 'Aguardando mudança cliente':
          return CommunicationAwaitingChanges
        
        case 'Em negociação':
          return []
        
        case 'Publicado':
          return []

        case 'Disparado':
          return []
    }}

  return (
    <S.Container onClick={closeSelect}>

       {(delegateOrder || delegateCommunicationPlan) && 
      (<S.BackgroundPopup>
        {delegateOrder && <DelegateOrder setDelegateOrder={setDelegateOrder} authToken={authToken} orderId={orderIdInDelegate} setReload={setReload}/>}
        {delegateCommunicationPlan && <DelegateCommunicationPlan setDelegateCommunicationPlan={setDelegateCommunicationPlan} authToken={authToken} companyUserId={companyUserIdInDelegate} setReload={setReload}/>}
      </S.BackgroundPopup>)}

      <S.Banner>
        <S.InnerBanner>
        <S.SearchContainers>
          <S.SearchInnerContainersCompany>
              <Search/>
              <input type="text"
              placeholder="Pesquisar empresa"
              value={filters.company} 
              onChange={e => handleFilterChange('company',e.target.value)}
              />
            </S.SearchInnerContainersCompany>
              {filters.company !== '' && showCompanyList && <S.CompanyList>
                {showCompanyList && firstFourCompanyNameFiltred?.sort().map((item: string) => {
                  return <S.ContainerCompanyName
                          onClick={e => handleFilterChoose('company',item)} 
                          key={item}>
                            {item}
                          </S.ContainerCompanyName>
                })}
              </S.CompanyList>}
              <S.SearchInnerContainer>
              <Search/>
              <input type="text"
              placeholder="Pesquisar #ID"
              value={filters.id}
              onChange={e => handleFilterChange('id', e.target.value)
              }/>
              </S.SearchInnerContainer>
        </S.SearchContainers>
        <S.ContentInput>
          <S.ContentInput>
              <S.LongInput>
              <label htmlFor="priority">Prioridade</label>
              <select value={filters.priority} onChange={e => handleFilterChange('priority',e.target.value)} name="status" placeholder=''>
                  <option value="">  </option>
                  <option value="1">Baixa</option>
                  <option value="2">Média</option>
                  <option value="3">Alta</option>
              </select>
                </S.LongInput>
                
            </S.ContentInput>
            <S.ContentInput>
                <S.InputDate>
                    <label htmlFor="priority">Data</label>
                    <input
                        type='date'
                        value={filters.date}
                        onChange={e => handleFilterChange('date',e.target.value)}
                        name="date"
                    />
                </S.InputDate>
                
            </S.ContentInput>
            <S.ContentInput>
                
              {typeUser === 'admin' && <S.LongInput>
              <label htmlFor="editor">Redator</label>
              <select value={filters.editor} onChange={e => handleFilterChange('editor',e.target.value)}  name="editor" placeholder=''>
                  <option value="">  </option>
                  {editors.map((editor: EditorProps) => {
                    return <option key={editor.id} value={editor?.user.name}>{editor?.user.name}</option>
                  })}
              </select>
                </S.LongInput>}
                <S.LongInput>
                  <label htmlFor="status">Status</label>
                  <S.FakeSelect onClick={(event) => toggleSelect(event)}>{selectedItems?.length} {selectedItems?.length >= 2 ? 'Selecionados': 'Selecionado'}</S.FakeSelect>
                  <S.CheckBoxes showCheckBoxes={showCheckboxes}>
                      <div onClick={(e) => handleAllItem(e)}>
                          <input type="checkbox" id='allitems' name='allitems' checked={selectedItems?.length === status.length} /> Todos os status
                      </div>
                    {status.map((item) => {
                      return(
                        <>
                        <div onClick={(e) => handleItemClick(item,e)}>
                          <input type="checkbox" id={item} name={item} checked={selectedItems?.includes(item)} value={item}/> {item}
                        </div>
                        </>
                      )
                    })}
                  </S.CheckBoxes>
                </S.LongInput>
            </S.ContentInput>
            </S.ContentInput>
        </S.InnerBanner>
      </S.Banner>
            <S.InnerContainer> 
                <HorizontalGrabbableBlock> 
                    <S.RowsContainer>  
                    {selectedItems.sort(defaultSort)?.map(item => {
                      return(
                        <>
                          <Row key={item}
                          status={item}
                          orders={handleOrderType(item)}
                          communication_plans={handleCommunicationPlanType(item)}
                          setCompanyUserIdInDelegate={setCompanyUserIdInDelegate}
                          setOrderIdInDelegate={setOrderIdInDelegate} 
                          setDelegateOrder={setDelegateOrder}
                          setDelegateCommunicationPlan={setDelegateCommunicationPlan}/>
                        </>
                      )
                    })}</S.RowsContainer>
                </HorizontalGrabbableBlock>
            </S.InnerContainer>
    </S.Container>
  )
}