import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'

export const Container = styled.div`
    background-color: #E5E5E5;
    height: calc(100vh - 4rem);
    width: calc(100vw - 16.5rem);
    overflow: scroll;
    @media(max-width: 1200px){
        width: 100vw;
    }
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 17rem auto;
    background-color: rgba(0, 0, 0, 0.1);
    
`

export const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 70% auto;
    gap: 2rem;
    align-items: start;
    justify-content: space-around;
    height: auto;
    padding: 2rem;    
`

export const RowsContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: left;
    min-height: 20rem;

    gap: 1rem;

    @media(max-width: 1600px){
        width:62rem;
        position:relative;
    }

    @media(max-width: 1024px){
        width:62rem;
        position:relative;
    }
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;

    gap: 2rem;

    @media(max-width: 1024px){
        align-items: center;
        justify-content: center;

    }
`

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PlanCard = styled.div`
    display: flex;
    flex-direction: column;

    gap: 1rem;
    @media(max-width: 1024px){
        width:10rem;
    }
`