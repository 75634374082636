import axios, {AxiosResponse} from 'axios';
import { AuthLogin, AllUsers, CreateOneUser, AddCompany, ReadOneProduct, ProductsInfo, ReadMyOrders} from '../interfaces';
import { handleInvalidCredentials } from '../middlewares/index';

const BASE_URL = process.env.REACT_APP_API_URL

function createConfig(token: string) {
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}

function createConfigFormData(token: String){
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'content-type': 'multipart/form-data'
    }
  }
}

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => handleInvalidCredentials(error)
)

function login(body: object): Promise<AxiosResponse<AuthLogin>> {
  const promise: Promise<AxiosResponse<AuthLogin>> =  axios.post(`${BASE_URL}/login`, body);
  return promise;
}

function readOneUser(token: string, userId: number): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/users/${userId}`, config)
  return promise;
}

 function readAllUsers(token: string): Promise<AxiosResponse<AllUsers[]>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<AllUsers[]>> = axiosInstance.get(`${BASE_URL}/users/`, config)
    return promise;
}

 function readMyUser(token: string): Promise<AxiosResponse> {
    const promise: Promise<AxiosResponse>=  axiosInstance.get(`${BASE_URL}/users/me`)
    return promise;
}

  function readOneProduct(token: string, productId: number): Promise<AxiosResponse<ReadOneProduct>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<ReadOneProduct>> = axiosInstance.get(`${BASE_URL}/products/${productId}`, config)
    return promise;
  }

  function readProducts(token: string): Promise<AxiosResponse<ProductsInfo[]>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<ProductsInfo[]>> = axiosInstance.get(`${BASE_URL}/products/`, config)
    return promise
  }

  function readMyOrders(token: string): Promise<AxiosResponse<ReadMyOrders[]>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<ReadMyOrders[]>> = axiosInstance.get(`${BASE_URL}/orders/me`, config)
    return promise
  }

  function makeOneOrder (token: string, body: object, IdProduct: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/make-order/${IdProduct}`, body, config)
    return promise
  }

  function delegateOneOrder (token: string, IdOrder: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/delegate-order/${IdOrder}`,body, config)
    return promise
  }
  
  function acceptOneOrder (token: string, IdOrder: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/accept/${IdOrder}`,'', config)
    return promise
  }

  function sendOneOrder (token: string, body: Object): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/send-order/`,body ,config)
    return promise
  }

  function sendOrderFiles (token: string, idOrder: string, body: FormData): Promise<AxiosResponse> {
    const config = createConfigFormData(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/send-order-files/${idOrder}`,body,config)
    return promise
  }

  function deleteOrderFile (token: string, idOrder: string, file_url: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/delete-order-files/${idOrder}?url_file=${file_url}`, config)
    return promise
  }

  function editMyUser (token: string, body: FormData): Promise<AxiosResponse> {
    const config = createConfigFormData(token)
    const promise: Promise<AxiosResponse> = axiosInstance.patch(`${BASE_URL}/users/me`,body,config)
    return promise
  }


  function requestOneOrder (token: string, IdOrder: string, body: Object): Promise<AxiosResponse>{
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/request-changes/${IdOrder}`, body, config)
    return promise
  }

  function readOneOrder (token: string, IdOrder: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/me/${IdOrder}`, config)
    return promise
  }

  function deleteOneOrder (token: string, IdOrder: string): Promise<AxiosResponse>{
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/me/${IdOrder}`, config)
    return promise;
  }

  function readInformation(token: string): Promise<AxiosResponse>{
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/infos/me`, config)
    return promise
  }

  function editOneCompany(body: object, token: string): Promise<AxiosResponse<void>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<void>> = axiosInstance.patch(`${BASE_URL}/companies/infos/me`, body, config)
    return promise;
}

  function editOneOtherCompany(body: object, token: string, companyId: string): Promise<AxiosResponse<void>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<void>> = axiosInstance.patch(`${BASE_URL}/companies/infos/${companyId}`, body, config)
    return promise;
}

 function createOneUser(body: object, token: string): Promise<AxiosResponse<CreateOneUser>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<CreateOneUser>>=  axiosInstance.post(`${BASE_URL}/users/create-user`, body, config)
    return promise;
}

 function deleteOneUser(token: string, userId: number): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> =  axiosInstance.delete(`${BASE_URL}/users/${userId}`, config)
    return promise;
}

 function editOneUser(token: string, userId: string, dados: object): Promise<AxiosResponse<void>> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse<void>> = axiosInstance.patch(`${BASE_URL}/users/${userId}`, dados, config)
    return promise;
}

  function downloadOrder(token: string, OrderId: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/download/${OrderId}`,config)
    return promise
  }

 function addCompany(dados: object): Promise<AxiosResponse<AddCompany>> {
    const promise: Promise<AxiosResponse<AddCompany>> = axiosInstance.post(`${BASE_URL}/companies/create-company`, dados)
    return promise;
}

 function deleteCompany(companyId: string): Promise<AxiosResponse<void>> {
    const promise: Promise<AxiosResponse<void>> = axiosInstance.delete(`${BASE_URL}/companies/${companyId}`)
    return promise;
}

function readAllOrdersAdminHome(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/`, config)
  return promise;
}

function readAllOrdersAdmin(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/all/`, config)
  return promise;
}

function readOneOrderAdmin(token: string, IdOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/${IdOrder}`, config)
  return promise
}

function removeOneOrderAdmin(token: string, IdOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/${IdOrder}`, config)
  return promise
}

function readPlans(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/plans/`, config)
  return promise
}

function readEditors(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/editors/`,config)
  return promise;
}

function readCompanies(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/`,config)
  return promise;
}

function readCompanyInfo(token: string, companyId: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/infos/${companyId}`, config);
  return promise;
}

function changeOrder(token: string, IdOrder: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/request-changes/${IdOrder}`, body, config)
  return promise;
}

function readMyDelegatedOrders(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/delegated/`, config)
  return promise;
}

function readOneOrderDelegated(token: string, IdOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/delegated/${IdOrder}`, config)
  return promise;
}

function readPlanInfo(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/plan/info`, config)
  return promise;
}

function addComment(token: string, order_id:string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/comment/${order_id}`, body, config)
  return promise;
}

function getAllAdmins(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/users/admins`, config)
  return promise;
}

function startOneOrderProduction(token: string, IdOrder: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/start-production/${IdOrder}`, body, config)
  return promise;
}

function sendOrderToInternalApproval(token: string, IdOrder: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/internal-approval`, body, config)
  return promise;
}

function requestInternalChanges(token: string, IdOrder: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/request-changes-internal/${IdOrder}`, body, config)
  return promise;
}


function addCommunicationPlan(token: string, company_id: string ,body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.patch(`${BASE_URL}/companies/communication-plan/${company_id}`, body, config)
  return promise;
}

function deleteComment(token: string, order_id: string, user_id: string, comment_id: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/comment/${order_id}?user_id=${user_id}&comment_id=${comment_id}`, config)
  return promise;
}

function requestPasswordChangeCode(email: string): Promise<AxiosResponse> {
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/users/request-password-change-code/${email}`)
  return promise
}

function resetPassword(body: object): Promise<AxiosResponse> {
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/users/password`, body)
  return promise
}

function CloseOneOrder(token: string, order_id: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/close/${order_id}`,'', config)
  return promise
}

function AddPublishedNews(token: string, order_id: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/add-published-news/${order_id}`, body, config)
  return promise
}

function RemovePublishedNews(token: string, order_id: string, published_news_id: number): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/remove-published-news/${order_id}?published_news_id=${published_news_id}`,config)
  return promise
}

function updateCompanyPlan(token: string, company_id: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.put(`${BASE_URL}/companies/update-plan/${company_id}`, body, config)
  return promise
}

function readOwnSugestedOrders(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-orders/me`, config)
  return promise
}

function readSugestedOrders(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-orders/`, config)
  return promise
}

function readSugestedOrdersMadeByMe(token: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-orders/by-me`, config)
  return promise
}

function readSugestedOrder(token: string, IdSugestedOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-order/${IdSugestedOrder}`, config)
  return promise
}

function readOwnSugestedOrder(token: string, IdSugestedOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-order/me/${IdSugestedOrder}`, config)
  return promise
}

function approveSugestedOrder(token: string, body: object, IdSugestedOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/sugested-order/${IdSugestedOrder}`, body, config)
  return promise
}

function readSugestedOrderMadeByMe(token: string, IdSugestedOrder: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/orders/sugested-order/by-me/${IdSugestedOrder}`, config)
  return promise
}

function makeSugestedOrder(token: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/sugested-order/`, body, config)
  return promise
}

function generateTextWithGPT(token: string, orderId: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/generate/${orderId}`, body, config)
  return promise
}

function readSegmentations(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/segmentations/`, config)
  return promise
}

function addSegmentation(token: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/segmentations/`, body, config)
  return promise
}

function approveCommunicationPlan(token: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.patch(`${BASE_URL}/companies/accept/communication_plan/`, '', config)
  return promise
}

function requestChangesCommunicationPlan(token: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.patch(`${BASE_URL}/companies/request-changes/communication_plan/`, body, config)
  return promise
}

function readCompanySummaries(token: string, companyId: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/summaries/${companyId}`, config)
  return promise
}

function addCompanySummary(token: string, body: object): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/summary`, body, config)
  return promise
}

function deleteCompanySummary(token: string, summaryId: number): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/companies/summary/${summaryId}`, config)
  return promise
}

function getCompanyKickOffStatus(token: string, companyId: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/users/onboarding/${companyId}`, config)
    return promise
}

function updateCompanyKickOffStatus(token: string, companyId: string, data: any): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.put(`${BASE_URL}/users/onboarding/${companyId}`, data, config);
    return promise;
}

function getCompanyClippingStatus(token: string, companyId: string | number | undefined): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/clipping/${companyId}`, config)
    return promise
}

function updateCompanyClippingStatus(token: string, companyId: string, data: any): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.put(`${BASE_URL}/companies/clipping/${companyId}`, data, config);
    return promise;
}

function getCompanyClips(token: string, companyId: number): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/clip/${companyId}`, config)
    return promise
}

function getAllClips(token: string): Promise<AxiosResponse> {
    const config = createConfig(token)
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/clip/`, config)
    return promise
}
  
function distributeOrder(token: string, orderId: string): Promise<AxiosResponse> {
  const config = createConfig(token)
  const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/orders/distribute/${orderId}`, '', config)
  return promise
}

function editDelegateService(token: string, orderId: string, data: any): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.put(`${BASE_URL}/orders/delegate/alter/${orderId}`,data, config)
    return promise;
}

function getCommunicationPlans(token: string): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/communication_plans/`, config)
    return promise;
}

function readOneCommunicationPlanAdmin(token: string, company_user_id: string): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/communication_plans/${company_user_id}`, config)
    return promise;
}

function readOneCommunicationPlanEditor(token: string, company_user_id: string): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/delegated/communication_plans/${company_user_id}`, config)
    return promise;
}

function delegateOneCommunicationPlan(token: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/delegate/communication_plan/`, body, config)
    return promise;
}

function readOwnDelegatedCommunicationPlans(token: string): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.get(`${BASE_URL}/companies/delegated/communication_plans/`, config)
    return promise;
}

function startOneCommunicationPlanProduction(token: string, companyUserId: string): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/start/communication_plan/${companyUserId}`,'', config)
    return promise;
}

function sendCommunicationPlanToInternasApproval(token: string, companyUserId: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/internal_approval/communication_plan/${companyUserId}`, body, config)
    return promise;
}

function requestInternalChangesCommunicationPlan(token: string, CompanyUserId: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/request-changes-internal/communication_plan/${CompanyUserId}`, body, config)
    return promise;
}

function requestCompanyChangesCommunicationPlan(token: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.patch(`${BASE_URL}/companies/request-changes/communication_plan/`, body, config)
    return promise;
}

function sendCommunicationPlanToApproval(token: string, body: object): Promise<AxiosResponse> {
    const config = createConfig(token);
    const promise: Promise<AxiosResponse> = axiosInstance.post(`${BASE_URL}/companies/send-communication-plan/`, body, config)
    return promise;
} 

function removeOneSugestion(token: string, sugestedOrderId: string): Promise<AxiosResponse> {
  const config = createConfig(token);
  const promise: Promise<AxiosResponse> = axiosInstance.delete(`${BASE_URL}/orders/sugested-order/${sugestedOrderId}`, config)
  return promise;
}

const api = {
  login,
  readAllUsers,
  readMyUser,
  readInformation,
  createOneUser,
  deleteOneUser,
  editOneUser,
  addCompany,
  deleteCompany,
  editOneCompany,
  readOneProduct,
  readProducts,
  readMyOrders,
  makeOneOrder,
  readOneOrder,
  deleteOneOrder,
  readAllOrdersAdmin,
  readAllOrdersAdminHome,
  readOneOrderAdmin,
  removeOneOrderAdmin,
  requestOneOrder,
  delegateOneOrder,
  acceptOneOrder,
  sendOneOrder,
  editMyUser,
  downloadOrder,
  readPlans,
  readEditors,
  readCompanies,
  changeOrder,
  sendOrderFiles,
  deleteOrderFile,
  readCompanyInfo,
  readMyDelegatedOrders,
  readOneOrderDelegated,
  readPlanInfo,
  addComment,
  getAllAdmins,
  startOneOrderProduction,
  sendOrderToInternalApproval,
  requestInternalChanges,
  readOneUser,
  addCommunicationPlan,
  deleteComment,
  requestPasswordChangeCode,
  resetPassword,
  readOwnSugestedOrders,
  readSugestedOrders,
  readSugestedOrdersMadeByMe,
  readSugestedOrder,
  readOwnSugestedOrder,
  approveSugestedOrder,
  readSugestedOrderMadeByMe,
  CloseOneOrder,
  AddPublishedNews,
  RemovePublishedNews,
  editOneOtherCompany,
  updateCompanyPlan,
  makeSugestedOrder,
  generateTextWithGPT,
  readSegmentations,
  addSegmentation,
  approveCommunicationPlan,
  readCompanySummaries,
  addCompanySummary,
  deleteCompanySummary,
  getCompanyKickOffStatus,
  updateCompanyKickOffStatus,
  distributeOrder,
  getCompanyClips,
  editDelegateService,
  requestChangesCommunicationPlan,
  getCommunicationPlans,
  readOneCommunicationPlanAdmin,
  delegateOneCommunicationPlan,
  readOwnDelegatedCommunicationPlans,
  readOneCommunicationPlanEditor,
  startOneCommunicationPlanProduction,
  sendCommunicationPlanToInternasApproval,
  requestInternalChangesCommunicationPlan,
  requestCompanyChangesCommunicationPlan,
  sendCommunicationPlanToApproval,
  removeOneSugestion,
  updateCompanyClippingStatus,
  getCompanyClippingStatus,
  getAllClips
}

export default api;
