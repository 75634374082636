import { useState } from 'react'
import UsuariosCadastrados from './UsuariosCadastrados'
import Popup from './Popup'
import PopupEdit from './PopupEdit'
import api from '../../services/api'
import { AllUsers } from '../../interfaces'
import useAuth from '../../hooks/useAuth'
import {
  Container,
  Title,
  ContainerButton,
  PopupContainer,
  PopupEditContainer,
  InnerContainer,
  Dados,
  Header
} from './style'

import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'


export default function Usuarios() {

  const [showPopupAddUser, setShowPopupAddUser] = useState(false)
  const [showPopupEdit, setShowPopupEdit] = useState(false)
  const [users, setUsers] = useState<AllUsers[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [userEdit, setUserEdit] = useState({id:0, name:'', email:'', profile_picture:'', role:{id:0, name:''}})
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
  const {token} = useAuth()
  const authToken = token?.access_token ?? ''

  function updateRegisteredUsers(){
    setIsLoading(true)
    const promise = api.readAllUsers(authToken)
    promise.then((response) => {
      setUsers(response.data)
    }).catch((error) => {
      setTypeError(false)
      console.error(error)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
    }).finally(()=>{
      setIsLoading(false)
    })
  }

  function handlePopup(){
    setShowPopupAddUser(!showPopupAddUser)
  }


  return (
    <Container>
      <Title>Usuarios na Plataforma</Title>
      <ContainerButton>
        <button onClick={handlePopup}>Adicionar usuário</button>
      </ContainerButton>
      <PopupContainer showPopup={showPopupAddUser}>
        <Popup updateRegisteredUsers={updateRegisteredUsers} showPopupAddUser={showPopupAddUser} setShowPopupAddUser={setShowPopupAddUser}/>
      </PopupContainer>
      <PopupEditContainer showPopupEdit={showPopupEdit}>
        <PopupEdit updateRegisteredUsers={updateRegisteredUsers} showPopupEdit={showPopupEdit} setShowPopupEdit={setShowPopupEdit} userEdit={userEdit}/>
      </PopupEditContainer>
      <InnerContainer>
        <Dados>
          <Header>
            <h1><span>Imagem</span>|<span>Nome</span>|<span>Email</span>|<span>Tipo de usuário</span>|<span>Opções</span></h1>
          </Header>
            <UsuariosCadastrados setUserEdit={setUserEdit} setIsLoading={setIsLoading} isLoading={isLoading} users={users} setShowPopupEdit={setShowPopupEdit} updateRegisteredUsers={updateRegisteredUsers}/>
        </Dados>
      </InnerContainer>
    </Container>
  )
}
