import{
    Container,
    BannerImage,
    BannerImageStatus,
    BannerButton,
    BannerLink,
    SideButton,
    SidesButton,
    ContainerWithLink,
    ContainerLoading,
    BannerImage2,
    BannerImageStatusCompany1,
    BannerImageStatusCompany2,
    BannerLinkCompany1,
    BannerImageStatusCompany3,
    BannerImage3,
    BannerButton3,
    BannerImageStatusCompany4,
    BannerImage4,
    BannerImageStatusCompany5,
    BannerImage5,
    SideButton2,
    ObservationButton,
    ObservationSideButton,
    NegociationContainerWithLink,
    SidesButtonNegotiation,
    ClosedContainer,
    BannerButtonPublished
} from './style'

import pedidorecebido from '../../../assets/images/pedidorecebido.png'
import delegado from '../../../assets/images/delegado.png'
import pedidoemproducao from '../../../assets/images/pedidoemproducao.png'
import publicado from '../../../assets/images/publicado.png'
import linkimage from '../../../assets/images/linkimage.png'
import divulgando from '../../../assets/images/divulgando.png'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

interface BannerProps {
    typeUser: string;
    etapa: string;
    authToken: string;
    orderId: string
    orderLink: string;
    setDelegateOrder: (value: boolean) => void;
    setSendOrderToRevision: (value: boolean) => void;
    setShowChange: (value: boolean) => void;
    setReload: (value: boolean) => void;
    setConfirmSendOrder: (value: boolean) => void;
    inputRef?: any;
    revisorUserId?: number;
    userId: number;
    productName?: string;
    setAddPublishedNews: (value: boolean) => void;
    setShowPopupGPT: (value: boolean) => void;
    planCompany: string;
}

export default function Banner({typeUser, etapa, authToken, orderId, orderLink, inputRef, revisorUserId, userId, productName, planCompany, setDelegateOrder, setSendOrderToRevision, setShowChange, setReload, setConfirmSendOrder, setAddPublishedNews, setShowPopupGPT}: BannerProps) {
    const canDoInternalReview = revisorUserId && revisorUserId === userId
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    function handleDelegate() {
        setDelegateOrder(true)
    }

    function handleRequestInternalChanges(){
        setShowChange(true)
    }

    function handleSendToClient(){
       setConfirmSendOrder(true)
    }

    function handleDistributeOrder(){
        setLoading(true)
        const promise = api.distributeOrder(authToken, orderId)
        promise.then(() => {
            setReload(true)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setTimeout(() => {
                setLoading(false)
            },300) 
        })
    }

    function handleCloseOrder(){
        setLoading(true)
        const promise = api.CloseOneOrder(authToken, orderId)
        promise.then(()=>{
            setReload(true)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setTimeout(() => {
                setLoading(false)
            },300) 
        })
    }

    function handleSendToInternalRevision(){
        setSendOrderToRevision(true)
    }

    function handleCancelOrder(){
        setLoading(true)
        const promise = api.deleteOneOrder(authToken, orderId)
        promise.then(() => {
            navigate('/pedidos')
        }).catch((error) => {
            console.error(error)
        }).finally(()=>{
            setLoading(false)
        })
    }

    function clientRequestChanges(){
        setShowChange(true)
    }

    function handleOpenLink(){
        window.open(orderLink, '_blank')
    }

    function acceptOrder(){
        setLoading(true)
        const promise = api.acceptOneOrder(authToken, orderId)
        promise.then(() => {
            setReload(true)
        }).catch((error) => {
         
        }).finally(() => {
          setLoading(false)
        })
      }

      const handleScrollToInput = () => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    
    if(loading){
        return(

            <ContainerLoading>
                <h1>Por favor, aguarde um instante</h1>
            </ContainerLoading>
        )
    }

  return (
    <>
        {(typeUser === 'admin' && etapa === 'open') && (
        <>
            <Container>
                <BannerImage2>
                    <img src={pedidorecebido} alt="Pedido Aberto" />
                </BannerImage2>
                <BannerImageStatus>
                    Recebemos um novo pedido 🔔
                </BannerImageStatus>
                <BannerButton onClick={handleDelegate}>
                    Delegar pedido
                </BannerButton>
            </Container>
        </>)}

        {(typeUser === 'admin' && etapa === 'delegated') && (
        <>
            <Container>
                <BannerImage>
                    <img src={delegado} alt="Pedido delegado" />
                </BannerImage>
                <BannerImageStatus>
                    Pedido encaminhado ao redator ✅
                </BannerImageStatus>
            </Container>
        </>)}

        {(typeUser === 'admin' && etapa === 'in_production') && (
        <>  
            <ContainerWithLink>
                <h1>Material em produção 📝</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'awaiting_approval_internal') && (
        <>
            {loading && (
                <ContainerLoading>
                    <h1>Por favor, aguarde um instante</h1>
                </ContainerLoading>
            )}
            {!loading && <ContainerWithLink>
                <h1>Material está sendo revisado 👀</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                {canDoInternalReview && <SidesButton>
                    <SideButton onClick={handleRequestInternalChanges}>
                        Solicitar Alteração
                    </SideButton>
                    <SideButton onClick={handleSendToClient}>
                        Enviar para o cliente
                    </SideButton>
                </SidesButton>}
            </ContainerWithLink>}
        </>)}

        {(typeUser === 'admin' && etapa === 'awaiting_changes_internal') && (
        <>
            <ContainerWithLink>
                <h1>Alterações pós-revisão em andamento⚙️</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'awaiting_approval') && (
        <>
            <ContainerWithLink>
                <h1>Aguardando aprovação do cliente ⏳</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'awaiting_changes') && (
        <>
            <ContainerWithLink>
                <h1>O cliente pediu algumas alterações⚠️</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'in_negotiation' && (planCompany !== '' && planCompany !== 'StartUp')) && (
        <>
            <NegociationContainerWithLink>
            <h1>Material em negociação 🤝</h1>
            <h2>Acesse o pedido no link abaixo:</h2>
            <BannerLink onClick={handleOpenLink}>
                <img src={linkimage} alt='Link do pedido'/> {productName}
            </BannerLink>
            <SidesButtonNegotiation>
                    <SideButton onClick={handleDistributeOrder}>
                        Pauta foi disparada
                    </SideButton>
                    <SideButton onClick={handleCloseOrder}>
                        Pauta foi divulgada
                    </SideButton>
            </SidesButtonNegotiation>
            <ObservationButton>
                <ObservationSideButton onClick={handleScrollToInput}>
                        Enviar observações
                </ObservationSideButton>
            </ObservationButton>
        </NegociationContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'closed') && (
        <>
            <ContainerWithLink>
                <h1>Material publicado 🏆</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={() => setAddPublishedNews(true)}>
                        Adicionar Matérias
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'admin' && etapa === 'distributed') && (
        <>
            <ContainerWithLink>
                <h1>Material disparado 🏆</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={() => setAddPublishedNews(true)}>
                        Adicionar Matérias
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

    {/* Redator */}

        {(typeUser === 'editor' && etapa === 'open')}

        {(typeUser === 'editor' && etapa === 'delegated') && (
        <>  
            <Container>
                <BannerImage2>
                    <img src={pedidorecebido} alt="Pedido recebido" />
                </BannerImage2>
                <BannerImageStatus>
                    Você recebeu um novo pedido 📥
                </BannerImageStatus>
                <BannerButton onClick={() => setShowPopupGPT(true)}>
                    Iniciar Produção
                </BannerButton>
            </Container>
        </>)}

        {(typeUser === 'editor' && etapa === 'in_production') && (
        <>
            <ContainerWithLink>
                <h1>Você está produzindo este material 📝
                    <h2>Acesse o pedido no link abaixo:</h2>
                </h1>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={handleSendToInternalRevision}>
                        Enviar para revisão
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'editor' && etapa === 'awaiting_approval_internal') && (
        <>
            <ContainerWithLink>
                <h1>Material está sendo revisado 👀</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'editor' && etapa === 'awaiting_changes_internal') && (
        <>
             <ContainerWithLink>
                <h1>Alterações pós-revisão em andamento⚙️</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={handleSendToInternalRevision}>
                        Enviar para revisão
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'editor' && etapa === 'awaiting_approval') && (
        <>
            <ContainerWithLink>
                <h1>Aguardando aprovação do cliente ⏳</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'editor' && etapa === 'awaiting_changes') && (
        <>
            {loading && (
            <ContainerLoading>
                <h1>Por favor, aguarde um instante</h1>
            </ContainerLoading>
            )}
           {!loading && <ContainerWithLink>
                <h1>O cliente pediu algumas alterações ⚠️</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={handleSendToClient}>
                        Enviar para o cliente
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>}
        </>)}
        
        {(typeUser === 'editor' && etapa === 'in_negotiation' && (planCompany !== '' && planCompany !== 'StartUp')) && (
        <>
            <NegociationContainerWithLink>
            <h1>Material em negociação 🤝</h1>
            <h2>Acesse o pedido no link abaixo:</h2>
            <BannerLink onClick={handleOpenLink}>
                <img src={linkimage} alt='Link do pedido'/> {productName}
            </BannerLink>
            <SidesButtonNegotiation>
                    <SideButton onClick={handleDistributeOrder}>
                        Pauta foi disparada
                    </SideButton>
                    <SideButton onClick={handleCloseOrder}>
                        Pauta foi divulgada
                    </SideButton>
            </SidesButtonNegotiation>
            <ObservationButton>
                <ObservationSideButton onClick={handleScrollToInput}>
                        Enviar observações
                </ObservationSideButton>
            </ObservationButton>
        </NegociationContainerWithLink>
        </>)}

        
        {(typeUser === 'editor' && etapa === 'closed') && (
        <>
            <ContainerWithLink>
                <h1>Material publicado 🏆</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={() => setAddPublishedNews(true)}>
                        Adicionar Matérias
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

        {(typeUser === 'editor' && etapa === 'distributed') && (
        <>
            <ContainerWithLink>
                <h1>Material disparado 🏆</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLink onClick={handleOpenLink}>
                <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLink>
                <SidesButton>
                    <SideButton onClick={() => setAddPublishedNews(true)}>
                        Adicionar Matérias
                    </SideButton>
                </SidesButton>
            </ContainerWithLink>
        </>)}

        {/* Company */}
        
        {(typeUser === 'company' && etapa === 'open') && (
        <>
            {loading && (
            <ContainerLoading>
                <h1>Por favor, aguarde um instante</h1>
            </ContainerLoading>
            )}
            {!loading && <Container>
                <BannerImage2>
                    <img src={pedidorecebido} alt="Pedido Aberto" />
                </BannerImage2>
                <BannerImageStatusCompany5>
                    <h1>Recebemos seu pedido ✅
                        <h2>Estamos encaminhando o seu pedido aos nossos redatores. Por favor, aguarde!</h2>
                    </h1>
                </BannerImageStatusCompany5>
                <BannerButton onClick={handleCancelOrder}>
                    Cancelar Pedido
                </BannerButton>
            </Container>}
        </>)}

        {(typeUser === 'company' && etapa === 'delegated') && (
            <>
            <Container>
                <BannerImage5>
                    <img src={pedidorecebido} alt="Pedido Aberto" />
                </BannerImage5>
                <BannerImageStatusCompany1>
                    <h1>Recebemos seu pedido✅
                        <h2>Estamos encaminhando o seu pedido aos nossos redatores. Por favor, aguarde!</h2>
                    </h1>
                </BannerImageStatusCompany1>
            </Container>
        </>
        )}
        

        {(typeUser === 'company' && etapa === 'in_production') && (
        <>
            <Container>
                <BannerImage>
                    <img src={pedidoemproducao} alt="Pedido em produção" />
                </BannerImage>
                <BannerImageStatusCompany2>
                    <h1>Estamos produzindo seu material 📝
                        <h2>Em breve, você receberá um link para visualizar e aprovar o seu material.</h2>
                    </h1>
                </BannerImageStatusCompany2>
            </Container>
        </>)}

        {(typeUser === 'company' && etapa === 'awaiting_approval_internal') && (
             <>
             <Container>
                 <BannerImage>
                     <img src={pedidoemproducao} alt="Pedido em produção" />
                 </BannerImage>
                 <BannerImageStatusCompany2>
                    <h1>Estamos produzindo seu material 📝
                        <h2>Em breve, você receberá um link para visualizar e aprovar o seu material.</h2>
                    </h1>
                 </BannerImageStatusCompany2>
             </Container>
         </>
        )}

        {(typeUser === 'company' && etapa === 'awaiting_changes_internal') && (
        <>
             <Container>
                 <BannerImage>
                     <img src={pedidoemproducao} alt="Pedido em produção" />
                 </BannerImage>
                 <BannerImageStatusCompany2>
                    <h1>Estamos produzindo seu material 📝
                        <h2>Em breve, você receberá um link para visualizar e aprovar o seu material.</h2>
                    </h1>
                 </BannerImageStatusCompany2>
             </Container>
         </>
        )}

        {(typeUser === 'company' && etapa === 'awaiting_approval') && (
        <>  
            {loading && (
            <ContainerLoading>
                <h1>Por favor, aguarde um instante</h1>
            </ContainerLoading>
            )}
            {!loading && <ContainerWithLink>
                <h1>Tudo pronto! Você já pode aprovar seu pedido 🎉</h1>
                <h2>Acesse o pedido no link abaixo:</h2>
                <BannerLinkCompany1 onClick={handleOpenLink}>
                   <img src={linkimage} alt='Link do pedido'/> {productName}
                </BannerLinkCompany1>
                <SidesButton>
                    <SideButton2 onClick={clientRequestChanges}>
                        Solicitar alteração
                    </SideButton2>
                    <SideButton2 onClick={acceptOrder}>
                        Aprovar
                    </SideButton2>
                </SidesButton>
            </ContainerWithLink>}
        </>)}

        {(typeUser === 'company' && etapa === 'awaiting_changes') && (
        <>
            <Container>
                <BannerImage>
                    <img src={pedidoemproducao} alt="Realizando alterações." />
                </BannerImage>
                <BannerImageStatusCompany1>
                    <h1>Estamos trabalhando nas alterações solicitadas 🚧
                        <h2>Aguarde enquanto nosos redatores efetuam as alterações pedidas!</h2>
                    </h1>
                </BannerImageStatusCompany1>
            </Container>
        </>)}

        {(typeUser === 'company' && etapa === 'in_negotiation') && (
        <>
            <Container>
                <BannerImage3>
                    <img src={divulgando} alt="Realizando alterações."/>
                </BannerImage3>
                <BannerImageStatusCompany3>
                    <h1>Estamos negociando com os veículos de imprensa🤝
                        <h2>Essa etapa pode levar até 15 dias, mas não se preocupe, você será informado sobre novidades na negociação.</h2>
                    </h1>
                </BannerImageStatusCompany3>
                <BannerButton3 onClick={handleOpenLink}>
                    <img src={linkimage} alt = 'Link do pedido'/>
                    {productName}
                </BannerButton3>
            </Container>
        </>)}

        {(typeUser === 'company' && etapa === 'closed') && (
        <>
            <ClosedContainer>
                <BannerImage4>
                    <img src={publicado} alt="Pedido pubicado." />
                </BannerImage4>
                <BannerImageStatusCompany4>
                    <h1>Seu pedido foi publicado 📰</h1>
                    <h2>Acesse o pedido no link abaixo:</h2>
                    <BannerButtonPublished>
                        <img src={linkimage} alt = 'Link do pedido' onClick={handleOpenLink}/>
                        {productName}
                    </BannerButtonPublished>
                </BannerImageStatusCompany4>
                
            </ClosedContainer>
        </>)}

        {(typeUser === 'company' && etapa === 'distributed') && (
        <>
            <ClosedContainer>
                <BannerImage4>
                    <img src={publicado} alt="Pedido pubicado." />
                </BannerImage4>
                <BannerImageStatusCompany4>
                    <h1>Seu pedido foi disparado 📰</h1>
                    <h2>Acesse o pedido no link abaixo:</h2>
                    <BannerButtonPublished>
                        <img src={linkimage} alt = 'Link do pedido' onClick={handleOpenLink}/>
                        {productName}
                    </BannerButtonPublished>
                </BannerImageStatusCompany4>
                
            </ClosedContainer>
        </>)}

    </>
)}