import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { load } from 'cheerio';
import { CloseContainer, Container, Content, ContentFakeTitle, SkeletonContainer } from './style';
import Skeleton from '@mui/material/Skeleton';
import { Close } from '@mui/icons-material';
import thumbnail from '../../../assets/images/thumbnail.png';
interface Preview {
  title: string;
  link: string;
  description: string;
  site: string;
  image: string;
}

function fetchUrlWithCorsProxy(url: string) {
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/' + url
  return axios.get(proxyUrl);
}

function extractPreviewData(html: string): Preview {
  const $ = load(html);
  const previewData: Preview = {
    title: $('meta[property="og:title"]').attr('content') || '',
    link: $('meta[property="og:url"]').attr('content') || '',
    description: $('meta[property="og:description"]').attr('content') || '',
    site: $('meta[property="og:site_name"]').attr('content') || '',
    image: $('meta[property="og:image"]').attr('content') || '',
  };
  return previewData;
}

interface PublishedNewsProps {
  tab: string;
  url: string;
  id: number;
  orderId: string;
  authToken: string;
  canRemovePublishedNews: boolean
  setPublishedNewsInConfirmation: (value: PublishedNewsInConfirmation) => void;
  setConfirmDeletePublishedNews: (value: boolean) => void;
}

interface PublishedNewsInConfirmation{
  id: number,
  title: string
}


function PublishedNews({tab, url, id, canRemovePublishedNews, setConfirmDeletePublishedNews, setPublishedNewsInConfirmation}: PublishedNewsProps) {
  const [loading, setLoading] = useState(true);
  const [previewData, setPreviewData] = useState<Preview | null>(null);
  const titleRef = useRef<string>();
  function capitalizeWords(str: string) {
    return str.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }

function getLargestElementWithDash(url: string) {
    const parts = url.split('/');
    let largestElementWithDash = '';

    parts.forEach(part => {
        if (part.includes('-') && part.length > largestElementWithDash.length) {
            largestElementWithDash = part;
        }
    });

    return capitalizeWords(largestElementWithDash);
}

    function handleOpenLink() {
      if(previewData?.link) {
        window.open(previewData?.link, '_blank');
      } else {
        window.open(url, '_blank');
      } 
    }

  useEffect(() => {
    const promise = fetchUrlWithCorsProxy(url)
    promise.then((response) => {
      const extractedData = extractPreviewData(response.data);
      setPreviewData(extractedData);
    }).catch((error) => {
      console.error(error)
      titleRef.current = getLargestElementWithDash(url);
    }).finally(() => {
      setLoading(false)
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  function openPopupConfirmationDeletePublishedNews(){
    const news = {
      id: id,
      title: previewData?.title ?? titleRef.current ?? ''
    }
    setPublishedNewsInConfirmation(news)
    setConfirmDeletePublishedNews(true)
  }

  if(loading){
    return(
    <Container tab={tab}>
          <Content>
          <Skeleton variant="rectangular" sx={{ width: '10rem', height:'6rem', borderRadius: '5px', marginLeft: '1rem'}} />
            <SkeletonContainer>
              <Skeleton variant="text" sx={{ fontSize: '1.05rem', marginRight: '1rem', marginBottom: '0.25rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1.05rem', marginRight: '1rem', marginBottom: '0.25rem' }}/>
              <Skeleton variant="text" sx={{ fontSize: '1.05rem', marginRight: '1rem', marginBottom: '0.25rem' }}/>
              <Skeleton variant="text" sx={{ fontSize: '1.05rem', marginRight: '1rem', marginBottom: '0.25rem' }}/>
            </SkeletonContainer>
          </Content>
    </Container>)
  }

  return (
    <Container tab={tab}>
      {previewData && (
        <>
          {canRemovePublishedNews && <CloseContainer onClick={openPopupConfirmationDeletePublishedNews}>
            <Close/>
          </CloseContainer>}
          <Content onClick={handleOpenLink}>
            {previewData?.image && <img src={previewData.image} alt={previewData.title} />}
            {!previewData?.image && <img src={thumbnail} alt='Mulher com megafone representando engajamento' />}
            <div>
              <small>{previewData.site}</small>
              <h5>{previewData.title}</h5>
              <p>{previewData.description}</p>
              <h4>Leia Mais...</h4>
            </div>
          </Content>
          </>
      )}
      {!previewData && <>
      {canRemovePublishedNews && <CloseContainer onClick={openPopupConfirmationDeletePublishedNews}>
      <Close/>
      </CloseContainer>}
        <ContentFakeTitle onClick={handleOpenLink}>
          <img src={thumbnail} alt='Mulher com megafone representando engajamento' />
          <div>
            <h5>{titleRef.current}</h5>
            <h4>Leia Mais...</h4>
          </div>
      </ContentFakeTitle>
      </>}
    </Container>
  );
}

export default PublishedNews;
