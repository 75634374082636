import React, { useEffect, ReactNode } from "react";
import { createPortal } from "react-dom";

import * as S from "./style"

interface ModalProps {
  onClose?: () => void;
  children: ReactNode;
}

// Can be closed by clicking outside or pressing the ESC key
const CloseableModal: React.FC<ModalProps> = ({ children, onClose }) => {

  // Closes the modal if clicked outside the modal content
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget && onClose) onClose();
  };

    // Effects
    useEffect(() => {
    // Handler for closing the modal when the ESC key is pressed
    const handleKeyup = (event: any) => {
        if (event.keyCode === 27 && onClose) onClose();
    };

    window.addEventListener("keyup", handleKeyup);
    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener("keyup", handleKeyup);
    }, [onClose]);


  return createPortal(
    <S.ModalWindow onClick={handleClick}>
      <S.ModalOverlay>{children}</S.ModalOverlay>
    </S.ModalWindow>,
    document.body
  );
};

// Basic modal component without the close functionality
const PlainModal: React.FC<ModalProps> = ({ children }) =>
  createPortal(
    <S.ModalWindow>
      <S.ModalOverlay>{children}</S.ModalOverlay>
    </S.ModalWindow>,
    document.body
  );

const Modal: React.FC<ModalProps> = (props) => {
  if (typeof props.onClose === "function") return <CloseableModal {...props} />;
  else return <PlainModal {...props} />;
};

export default Modal;
