import { Results } from "../interface";
import * as S from './style'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Placeholder from '../../../assets/images/SM-placeholder.png'
import { formatarData } from "../../../utils/datas";

interface CardProps {
    results: Results
}

export default function Card({results} : CardProps){
    return(
        <S.Container>
            <S.Content>
                <S.ImageContent $image={!results.image ? Placeholder : results.image}>
                </S.ImageContent>
                <S.Content>
                    <S.Media>{results.media}</S.Media>
                    <S.Subtitle>{results.title}</S.Subtitle>
                    <S.Date><CalendarTodayIcon sx={{ color: "#6D6D6D", fontSize: 12 }}/> {formatarData(results.publish_at)}</S.Date>
                </S.Content>
            </S.Content>
            <S.Button><a href={results.link} target={results.link}>Acesse a publicação</a></S.Button>
        </S.Container>
    )
}