import { ReadMyOrders } from '../../../interfaces'
import CardNewHome from '../Card'
import * as S from './style'

interface RowProps{
  orders: ReadMyOrders[] | undefined
  status: string
}

export default function Row({ status, orders }: RowProps) {

    if (status === 'Aguardando aprovação cliente') {
        return (
            <S.Container>
                <S.Title>Aprove seus pedidos!</S.Title>
                {orders?.map(item => (
                    <CardNewHome key={item.id} order={item} />
                ))}
            </S.Container>
        );
    } else if (status === 'Em negociação') {
        return (
            <S.Container>
                <S.Title>Estamos negociando com a imprensa...</S.Title>
                {orders?.map(item => (
                    <CardNewHome key={item.id} order={item} />
                ))}
            </S.Container>
        );
    } else if (status === 'Publicado') {
        return (
            <S.Container>
                <S.Title>Publicados com sucesso!</S.Title>
                {orders?.map(item => (
                    <CardNewHome key={item.id} order={item} />
                ))}
            </S.Container>
        );
    } else if (status === 'Disparado') {
        return (
            <S.Container>
                <S.Title>Disparados com sucesso!</S.Title>
                {orders?.map(item => (
                    <CardNewHome key={item.id} order={item} />
                ))}
            </S.Container>
        );
    } else {
        return (
            <S.Container>
                <S.Title>Pedidos em produção</S.Title>
                {orders?.map(item => (
                    <CardNewHome key={item.id} order={item} />
                ))}
            </S.Container>
        );
    }
}
