import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'
import seta from '../../assets/images/seta.png'

export const Container = styled.div`
background-color: #E5E5E5;
height: calc(100vh - 4rem);
width: calc(100vw - 17.5rem);
@media(max-width: 1200px){
    width: 100vw;
}
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
position: relative;
`

export const RowsContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: left;
    min-height: 20rem;

    gap: 1rem;

    @media(max-width: 1600px){
        width:62rem;
        position:relative;
    }

`

export const InnerContainer = styled.div`
width: 99%;
height: 100%;
display: flex;
align-items: flex-start;
overflow: scroll;
padding: 1rem 1rem 1rem;

`
export const Banner = styled.div`
width: calc(100vw - 17.5rem);
@media(max-width: 1200px){
    width: 100vw;
}
margin: 0 auto;
padding-top: 3.3rem;
padding-bottom: 2rem;
display: flex;
align-items: center;
justify-content: flex-start;
margin-left: 1.563rem;
@media(max-width: 1530px){
    width: 100%;
    justify-content: center;
    margin-left: 0;
}
`
export const InnerBanner = styled.div`
height: 3.38rem;
border-radius: 10px;
background: rgba(249, 249, 249, 0.40);
width: calc(100% - 3rem);
display: flex;
align-items: center;
justify-content: space-between;
span{
    color: #6D6D6D;
text-align: center;
font-family: Inter;
font-size: 1.05rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
@media(max-width: 1530px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 15rem;
    padding-left: 1rem;
}
@media(max-width: 835px){
    height: 25rem;
}

`



export const ContentInput = styled.div`
display: flex;
@media(max-width: 835px){
    flex-direction: column;
}
`

export const LongInput = styled.div`
flex: 1;
display: flex;
max-width: 21rem;
align-items: center;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;

label{
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

select{
    min-width: 12.5rem;
    width: 14.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1rem;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${seta}) no-repeat center right #FFF;
    font-size: 0.9375rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 11.5rem 0.625rem; 
    :focus {
        outline: none;
      }
}
`

export const InputDate = styled.div`
flex: 1;
display: flex;
max-width: 21rem;
align-items: center;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;

label{
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

input{
    min-width: 12.5rem;
    width: 14.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1rem;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.9375rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 11.5rem 0.625rem; 
    font-family:Inter;
    :focus {
        outline: none;
      }
}
`

export const FakeSelect = styled.div`
flex: 1;
display: flex;
align-items: center;
font-size: 1rem;
min-width: 12.5rem;
width: 14.5rem;
height: 2.65625rem;
border-radius: 0.625rem;
border: 0.0625rem solid #6D6D6D50;
background-color: white;
margin-right: 1.875rem;
padding-left: 0.625rem;
margin-left: 1rem;
align-items: center;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background: url(${seta}) no-repeat center right #FFF;
font-size: 0.9375rem;
padding-right: 1.25rem;
background-size: 1.25rem;
background-position: 12.5rem 0.725rem; 
:focus {
    outline: none;
}

`

interface showCheckBoxesProps {
    showCheckBoxes: boolean
}

export const CheckBoxes = styled.div<showCheckBoxesProps>`
${props => props.showCheckBoxes? 'display: block; position: absolute; top: 7rem; right:3.5rem;': 'display: none;'}
width: 17.5rem;
border: 1px solid #6d6d6d;
padding: 0.5rem;
background-color: #fff;
border-radius: 5px;
z-index: 100;
div{
    cursor: pointer;
}
input{
    margin: 0.5rem;
}

`

export const BackgroundPopup = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index:9999;
`

export const SearchContainers = styled.div`
width: 34rem;
display: flex;
margin-top: 1rem;
align-items: center;
gap: 1rem;
@media(max-width: 835px){
    flex-direction: column;
    align-items: center;
}
position: relative;
`



export const SearchInnerContainer = styled.div`
width: 16rem;
height: 3rem;
background: #FFFFFF;
box-shadow: 0.376rem 0.376rem 1.5rem rgba(0, 0, 0, 0.1);
border-radius: 10px;
margin-bottom: 1rem;
display: flex;
align-items: center;
svg{
    margin-left: 1rem;
    margin-right: 1rem;
}
input{
    border: none;
    outline: none;
    width: 11rem;
    height: 2.8rem;
    font-size: 1rem;

}
`

export const SearchInnerContainersCompany = styled(SearchInnerContainer)`

`

export const CompanyList = styled.div`
position: absolute;
top: 2.4rem;
left: -0.15rem;
z-index: 9999999;
@media(max-width: 835px){
    left: 8.9rem;
}
`
export const ContainerCompanyName = styled.div`
width: 13.1rem;
cursor: pointer;
background: #FFFFFF;
height: 3rem;
display: flex;
overflow: hidden;
align-items: center;
justify-content: flex-start;
padding-left: 3rem;
border: 1px solid #6D6D6D50;
border-top: none;
`