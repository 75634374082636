import { useEffect } from 'react'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import FormsLogin from './FormsLogin'
import ImageLogin from './ImageLogin'
import {
  Container,
  Image,
  Forms,
  Logo
} from './style'

export default function Login() {
  const {setShowPopup} = usePopupTrueFalse()

  useEffect(() => {
    setShowPopup(false)
    // eslint-disable-next-line
  },[])

  return (
    <Container>
      <Image>
        <ImageLogin />
      </Image>
      <Forms>
      <Logo></Logo>
        <FormsLogin />
        </Forms>
    </Container>
  )
}


