import { Buttons, Discard, Inputs, LongInput, Save } from "./style";

interface AccountProps{
    userData: any;
    setUserData: any;
    UpdateDataAccount: any;
    DiscardDataAccount: any;
}

export default function Account({userData, setUserData, UpdateDataAccount, DiscardDataAccount}: AccountProps) {



  return (
    <>
    <Inputs>
        <LongInput>
            <label htmlFor="name">Nome</label>
            <input 
                    type="text" 
                    placeholder="Seu nome"
                    name="name" id='name'
                    value={userData?.name}
                    onChange={e => {
                        setUserData({...userData, name: e.target.value})
                    }}
                    />
        </LongInput>
        <LongInput>
            <label htmlFor="password">Nova senha</label>
            <input 
                    type="password" 
                    placeholder="Nova senha"
                    name="password" id='password'
                    value={userData?.password}
                    onChange={e => {
                        setUserData({...userData, password: e.target.value})
                    }}
                    />
        </LongInput>
        <LongInput>
            <label htmlFor="passwordconf">Confirme sua nova senha</label>
            <input 
                    type="password" 
                    placeholder="Confirme sua nova senha"
                    name="passwordconf" id='passwordconf'
                    value={userData?.passwordconfirm}
                    onChange={e => {
                        setUserData({...userData, passwordconfirm: e.target.value})
                    }}
                    />
        </LongInput>
        </Inputs>
        <Buttons>
            <Discard onClick={DiscardDataAccount}>Descartar</Discard>
            <Save onClick={UpdateDataAccount}>Salvar</Save>
        </Buttons>
    </>
  )
}
