import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { PopupTrueFalseProvider } from './contexts/PopupTrueFalseContext';
import './style/reset.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <AuthProvider>
    <PopupTrueFalseProvider>
      <App />
    </PopupTrueFalseProvider>
    </AuthProvider>
  //</React.StrictMode>
  
);
