import styled from "styled-components";

const Container = styled.div`
background-color: #E5E5E5;
min-height: calc(100vh - 4rem);
height: auto;
width: 100%;
background-repeat: no-repeat;
background-position: bottom right;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
align-items: center;
`

const Title = styled.div`
padding: 2rem 0 2rem;
font-size: 2rem;
line-height: 2rem;
font-weight: 800;

`
const Text = styled.div`
    
    border-radius: 1.825rem;
    padding: 0 2rem 1rem;
    width: 50rem;
    color: #333333;
    p{
        margin: 1rem 0 1rem;
        font-size: 1.5rem;
    }
    li{
        margin: 1rem 0 1rem;
        font-size: 1.5rem;
        list-style-position: inside;
    }
`


export {
    Container,
    Title,
    Text
}