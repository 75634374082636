import styled from "styled-components";

export const Content = styled.div`
    display: flex;

    align-items: start;
    justify-content: left;
    
    margin-bottom: 2.5rem;
    cursor: auto !important;
    
    @media(max-width: 1600px){
        width:62rem;
        position:relative;
    }
`

export const Title = styled.div`
    color: #333;

    font-family: Inter;
    font-weight: 600;
    font-size: 1.5rem;

    display: flex;
    justify-content: flex-start;
    @media(max-width: 1645px){
        width: 90%;
    }
`