import styled from 'styled-components';


export const Container = styled.div`
    box-sizing: border-box;

    border-radius: 1rem;
    background-color: #f9f9f9;

    font-family: 'Inter', sans-serif;

    display:flex;
    flex-direction:column;
    gap: 1rem;

    padding: 2rem;
    width: 23rem;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);

`

export const Text = styled.span`
    font-size: 1.2rem;
    color: #6D6D6D;
    font-weight: bold;
`

export const TooltipContainer = styled.div`
    display:flex;

`

export const TooltipContent = styled.div`
    display:flex;
    flex-direction:column;
    gap: .5rem;
    width:  12rem;
`

export const Main = styled.div`
    display:flex;
    flex-direction:column;
    background-color: #DAD6F3;
    gap: 1rem;

    color: #5C49DD;

    padding: 1rem 1.5rem;
    border-radius: 1rem;

`

export const Title = styled.span`
    font-size: 1.2rem;
    font-weight: bold;
`
export const TitleSimple = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
`

export const Description = styled.span`
    font-size: 1rem;
`

export const ButtonComunicationPlan = styled.button`
    display:flex;
    align-items: center;
    justify-content:center;
    gap: .5rem;

    background-color: #F9F9F9;
    color: #5C49DD;

    border:none;
    border-radius: 2rem;

    padding: .5rem 1rem;

    font-family: Inter;

    cursor: pointer;
    
    a{
        text-decoration: none;
        color: #5C49DD;
    }
`

export const Button = styled.button<{ $style?: string}>`
    display:flex;
    align-items: center;
    justify-content:center;
    gap: .5rem;

    background-color: ${props => props.$style === "outline" ? "#f9f9f9" : "#5C49DD" };
    color: ${props => props.$style === "outline" ? "#5C49DD" : "#f9f9f9" };

    border:${props => props.$style === "outline" ? "1px solid #5C49DD" : "none" };
    border-radius: 2rem;

    padding: .5rem 1rem;

    font-family: Inter;
    font-weight:bold;

    cursor: pointer;

`

export const Modal = styled.div`
    display: flex;
    flex-direction:column;
    gap: 2rem;
    margin: 2rem;

    padding-right: 1.5rem;

    color: #333333;

    div{
        display:flex;
        font-size:1.4rem;
        font-weight:bold;
        align-items:center;
        gap: 1rem;
    }

    p{
        font-size:1rem;
        max-width: 35rem;
        padding-left: 2.5rem;
    }
`

export const ButtonsModal = styled.div`
    display:flex;
    gap: 1rem;

    justify-content: end;
`

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;

    h1,h2{
        color: #6D6D6D;
        font-family: Inter;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.42rem;
        letter-spacing: 0rem;
        text-align: center;
        width: max-content;
        margin-top: 2rem;
    }

    @media(max-width: 1650px){
        width: 90%;
    }
`