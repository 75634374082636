import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../services/api'
import {
  Container,
  InnerContainer,
  Cancelar,
  Trocar,
  LongInput
} from './style'
import Loading from '../Loading';
import { ChangePlanProps, PlanProps } from '../../interfaces';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';


export default function ChangePlan({authToken, companyId, setChangePlan}: ChangePlanProps){
  const [loading, setLoading] = useState(false)
  const [plan, setPlan] = useState<PlanProps[]>([])
  const [selectedPlan, setSelectedPlan] = useState('')
  
  const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse()

  useEffect(() => {
    const promise = api.readPlans(authToken)
    promise.then((response) => {
      setPlan(response.data)
    }).catch((error) => {
      console.error(error)
    })
  },[authToken])

  function changePlan(){
    if(!selectedPlan){
      setTypeError(false)
      setErrorMessage('Selecione um novo plano para esse cliente.')
      setShowPopup(true)
      return
    }
    setLoading(true)
    const body = {
      "plan_id" : selectedPlan
    }
    const promise = api.updateCompanyPlan(authToken, companyId, body)
    promise.then(() => {
      setChangePlan(false)
      window.location.reload()
    }).catch((error: any) => {
      console.error(error)
    })
    setLoading(false)
  }

    if(loading){
      return(
        <Container>
          <Loading color="white" withText={true} />
        </Container>
      )
    }

  return(
    <Container>
    <h1>Deseja alterar o plano dessa empresa?</h1>
    <InnerContainer>
      <LongInput>
          <label htmlFor="newPlan">Novo plano:</label>
          <select onChange={e => setSelectedPlan(e.target.value)} value={selectedPlan} name='userplan'>
            <option value=""></option>
            {plan.map((plan) => {
              return(
                <option value={plan.id}>{plan.plan_name}</option>
              )
            })}
          </select>
        </LongInput>
    </InnerContainer>
    
    <div>
    <Cancelar onClick={() => setChangePlan(false)}><ArrowBackIcon/>Cancelar</Cancelar>
    <Trocar onClick={changePlan}>Alterar o plano</Trocar>
    </div>
    
</Container>
  )
}