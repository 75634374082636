import { useState } from 'react';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import api from '../../services/api';
import Loading from '../Loading';
import {
  Container,
  ClosePopup,
  ConfirmDelete,
  Voltar
} from './style'
import { useNavigate } from 'react-router-dom';

interface PopupConfirmationProps{
  setShowConfirmDelete: (value: boolean) => void;
  orderId: string;
  authToken: string;
  typeUser: string;

}

export default function PopupDeleteConfirmation({setShowConfirmDelete, orderId, authToken}: PopupConfirmationProps) {

  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse();
  const navigate = useNavigate()
  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
        setErrorMessage(error.response?.data?.detail)
    }else{
        setErrorMessage('Tente novamente em instantes!')
        }
      setShowPopup(true)
  }

  function removeOneOrderAdmin(){
    setLoading(true)
    const promise = api.removeOneOrderAdmin(authToken, orderId)
    promise.then((response) => {
      setShowConfirmDelete(false)
      navigate(`/home`)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  if(loading){
    return(
      <Container>
        <Loading color="black" />
      </Container>
    )
  }

  return (
    <Container>
      <ClosePopup onClick={() => setShowConfirmDelete(false)}>
        X
      </ClosePopup>
      <h1>Você realmente quer apagar o pedido?</h1>
      <div>
        <Voltar onClick={() => {setShowConfirmDelete(false)}}>
          Voltar
        </Voltar>
        <ConfirmDelete onClick={removeOneOrderAdmin}>
          Confirmar
        </ConfirmDelete>
      </div>
    </Container>
  )
}
