import {
    Container,
    Title,
    Content,
    LeftContent,
    Line,
    FormsContent,
    LongInput,
    RightContent,
    CommentContainer,
    ContainerInputs,
    ContainerButtons,
    BackButton,
    NextButton,
    Send
} from './style'
import form from '../../assets/images/form.png'
import { useEffect, useRef, useState } from 'react'
import api from '../../services/api'
import { ProductsInfo, ProductsProps, CompanyProps, RequiredFieldsProduct, ReadOneProduct } from '../../interfaces'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
import Input from '../../components/Input'
import { useNavigate } from 'react-router-dom'

export default function SugerirConteudo() {

    const navigate = useNavigate()
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''
    const [products, setProducts] = useState<ProductsInfo[]>([{id:0, product:'', product_name:'', descripton:'', short_description:'', deadline_in_days:0, picture:''}])
    const [companies, setCompanies] = useState([])
    const [productType, setProductType] = useState('')
    const [company, setCompany] = useState('')
    const [comment, setComment] = useState('')
    const [activePage, setActivePage] = useState(0)
    const [startIndex, setStartIndex] = useState(0)
    const [infoProduct, setInfoProduct] = useState<ReadOneProduct>({id:0, product:'', product_name: '', description: '', short_description: '', deadline_in_days: 0, required_fields:[],picture:''})
    const [inputs, setInputs] = useState<RequiredFieldsProduct[]>([])
    const fieldsPerPage = 3
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const numberPages = Math.ceil(inputs.length / fieldsPerPage);
    const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()


    function handleSendSugestion() {

        if(ValidateForms()) {
            const newData: Array<object> = []

            inputs?.forEach((input: RequiredFieldsProduct) => {
                newData.push({
                    field_id: input.id,
                    value: input.value
                })
            })

            const body = {
                "product_id": productType,
                "company_id": company,
                "comment": comment,
                "sugested_order_infos": newData
            }

            const promise = api.makeSugestedOrder(authToken, body)

            promise.then((response) => {
                navigate(`/sugestoes/${response.data.id}`)
            }).catch((error) => {
                setTypeError(false)
                if(typeof error.response?.data?.detail === 'string') {
                    setErrorMessage(error.response?.data?.detail)
                } else {
                    setErrorMessage('Tente novamente em instantes!')
                }
                setShowPopup(true)
            })
        } else {
            setTypeError(false)
            setErrorMessage('Você não respondeu todas as informações obrigatórias!')
            setShowPopup(true)
        }
    }
    
    function ValidateForms(){
        let requiredInputs: Array<RequiredFieldsProduct> = []
        requiredInputs = inputs.filter(json => json.hasOwnProperty("is_required") && json.is_required);
    
        let isValid = true
        requiredInputs?.forEach((input: RequiredFieldsProduct) => {
          if (!input.value || input.value.length <= 0) {
            isValid = false;
          }
        })
        return isValid
    }

    function adjustTextareaHeight() {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    function getNSteps(n: number) {
        const components = [];
        
        for (let i = 0; i < n; i++) {
          components.push(
          <Step>
            <StepLabel></StepLabel>
          </Step>);
        }
      
        return components;
    }

    const handleBackClick = () => {
        setStartIndex(startIndex - fieldsPerPage);
        setActivePage(state => state - 1)
    }

    const handleNextClick = () => {
        setStartIndex(startIndex + fieldsPerPage);
        setActivePage(state => state + 1)
    }

    useEffect(() => {
        adjustTextareaHeight()
    }, [comment])

    useEffect(()=> {
        Promise.all([
            api.readProducts(authToken),
            api.readCompanies(authToken)
        ]).then((response) => {
            setProducts(response[0].data)
            setCompanies(response[1].data)
        }).catch((error) => {
            setTypeError(false)
            setErrorMessage('Tente novamente em instantes!')
            setShowPopup(true)
        })
    },[authToken, setErrorMessage, setTypeError, setShowPopup])

    useEffect(() => {

        const promise = api.readOneProduct(authToken, Number(productType))
        promise.then((response) => {
            const filteredInputs = response.data.required_fields.filter(
                (input) => input.field_name !== 'Informações Adicionais'
            )
            response.data.required_fields = filteredInputs
            setInfoProduct(response.data)
            setInputs(filteredInputs)
        }).catch((error) => {
            setTypeError(false)
            if(typeof error.response?.data?.detail === 'string'){
                setErrorMessage(error.response?.data?.detail)
            } else {
                setErrorMessage('Tente novamente em instantes!')
            }
        }).finally(() => {
        })
    }, [productType, authToken, setErrorMessage, setShowPopup, setTypeError])

    return (
        <Container>
            <Title>Sugerir Conteúdo</Title>
            <Content>
                <LeftContent>
                    <img src={form} alt="form" />
                    <FormsContent>
                        <LongInput>
                            <label htmlFor="productType">Formato do Conteúdo:</label>
                            <select onChange={e => setProductType(e.target.value)} value={productType} name="productType" placeholder='Selecione um plano'>
                            <option value="">  </option>
                                {products.map((product: ProductsProps) => (
                                    <option value={product.id}>
                                    {product.product_name}
                                    </option>
                                ))}
                            </select>
                        </LongInput>
                        <LongInput>
                            <label htmlFor="company">Sugerir para:</label>
                            <select onChange={e => setCompany(e.target.value)} value={company} name="company" placeholder='Selecione um cliente'>
                            <option value="">  </option>
                                {companies.map((company: CompanyProps) => (
                                    <option value={company.id}>
                                    {company.user.name}
                                    </option>
                                ))}
                            </select>
                        </LongInput>
                        <CommentContainer>
                                <label htmlFor="comment">Descrição da sugestão:</label>
                                <textarea 
                                    ref={textareaRef}
                                    name="comment"
                                    id="comment"
                                    onChange={e => {setComment(e.target.value)}}
                                />
                        </CommentContainer>
                    </FormsContent>
                </LeftContent>
                <Line></Line>
                <RightContent>
                    <ContainerInputs>
                    <Box sx={{ width: '100%', marginBottom: '2.5rem' }}>
                        <Stepper activeStep={activePage} alternativeLabel>
                            {getNSteps(numberPages).map(item => {
                                return item
                            })}
                        </Stepper>
                    </Box>

                    {infoProduct.required_fields
                        .slice(startIndex, startIndex + fieldsPerPage)
                        .map((input, index) => (
                        
                        <Input 
                            key={input.id}
                            input={input}
                            description={input.short_description}
                            example={input.example}
                            value={input.value}
                            fieldName={input.field_name}
                            inputs={inputs}
                            id={input.id}
                            setInputs={setInputs}
                            indice={startIndex + index}
                            is_required={input.is_required}
                        />
                    ))}            
                    </ContainerInputs>
                    <ContainerButtons>
                        {startIndex > 0 && <BackButton onClick={handleBackClick}>Anterior</BackButton>}
                        {(productType && activePage !== numberPages - 1) && <NextButton onClick={handleNextClick}>Próximo</NextButton>}
                        {activePage === numberPages - 1 && <Send onClick={handleSendSugestion}>Enviar sugestão</Send>}
                    </ContainerButtons>
                </RightContent>
            </Content>
        </Container>
    )
}