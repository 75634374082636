import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import { useState } from "react";
import ValidationLogin from '../ValidationLogin';
import {validateLogin} from '../../../middlewares'
import Button from '../Button';
import api from '../../../services/api';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    ContainerInput,
    Input,
    Label,
    ShowPassword
} from './style'


export default function FormsLogin() {

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [showPassword,setShowPassword] = useState(false)
    const [invalidInformation, setInvalidInformation] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { setUser, setToken} = useAuth();
    const navigate = useNavigate()


    useEffect(() => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('authToken') ?? '{}';

        if(user !== null && token !== null){
            try{
                jwtDecode(JSON.parse(token)?.access_token)
                setUser(JSON.parse(user));
                setToken(JSON.parse(token));
                navigate('/home')
            } catch(error){
                console.error(error)
                localStorage.removeItem('user')
                localStorage.removeItem('authToken')
            }
        }
        // eslint-disable-next-line
    },[]);  

    function togglePassword(){
        setShowPassword(!showPassword)
    }

    function checkCredentials(event: React.FormEvent){
        event.preventDefault();
        setIsLoading(true)
        if(validateLogin({email, password})){
            setInvalidInformation(false)

            const promise = api.login({email, password})
            promise
                .then((response) => {
                setUser(response.data.user)
                setToken(response.data.token)
                localStorage.setItem("user",JSON.stringify(response.data.user))
                localStorage.setItem("authToken",JSON.stringify(response.data.token))
                setIsLoading(false)
                navigate('/home')
            })

            promise.catch((error) => {
                setTimeout(() => {
                    setIsLoading(false)
                    setInvalidInformation(true)
                },300)
                console.error(error)
            })
        } else{
            setIsLoading(false)
            setInvalidInformation(true)
        }}

        

  return (
            <Container>
                <form autoComplete="off" onSubmit={checkCredentials}>
                <h1>Faça o seu login</h1>
                    <ContainerInput>
                        <Input invalidInformation={invalidInformation}
                            type="text" 
                            placeholder="Seu e-mail" 
                            name="e-mail" id='e-mail' 
                            value={email}
                            onChange={e=>setEmail(e.target.value)}
                            disabled={isLoading}
                            />
                        <Label invalidInformation={invalidInformation}
                            htmlFor="e-mail"
                            >Seu e-mail</Label>
                    </ContainerInput>
                
                <ContainerInput>
                    <Input invalidInformation={invalidInformation}
                        type={showPassword ? 'text' : 'password'} 
                        placeholder="Sua senha" name="password" 
                        id='password' 
                        value={password} 
                        onChange={e => setPassword(e.target.value)}  
                        disabled={isLoading}
                        />
                    <Label invalidInformation={invalidInformation} 
                        htmlFor="password">
                            Sua senha
                        </Label>
                        <ShowPassword invalidInformation={invalidInformation} onClick={togglePassword}>
                            {showPassword ? 'Ocultar' : 'Exibir'}
                        </ShowPassword>
                       {invalidInformation && <ValidationLogin />} 
                       <a href="esqueci-minha-senha">Esqueci minha senha</a>
                    </ContainerInput>
                    
                <Button isLoading={isLoading}/>
            </form>
        </Container>
  )
}
