import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: border-box;

    border-radius: 1rem;
    background-color: #f9f9f9;

    font-family: 'Inter', sans-serif;

    display:flex;
    flex-direction:column;
    gap: 2rem;

    padding: 2rem;
    width: 23rem;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);

`

export const Plan = styled.h1`
    font-size: 1.5rem;
    background: rgb(92,73,221);
    background: linear-gradient(87deg, rgba(92,73,221,1) 0%, rgba(167,79,255,1) 100%);

    font-weight: bold;
    color: #f9f9f9;

    padding: .8rem 3.5rem;
    border-radius: .8rem;

    text-align: center;
`

export const Text = styled.span`
    font-size: 1.2rem;
    color: #6D6D6D;
    font-weight: bold;
`

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const Order = styled.span`
    display:flex;
    font-size: 1.2rem;
    background-color: #DAD6F3;
    gap: 1rem;

    color: #6D6D6D;

    padding: 1rem 1.5rem;
    border-radius: 1rem;

`

export const OrderInfos = styled.div`
    display:flex;
    flex-direction:column;

    justify-content: space-between;
`

export const OrderTitle = styled.span`
    color: #5C49DD;
    width: 100%;

    @media(max-width: 1600px){
        font-size: 1rem;
    }
`

export const Icon = styled.div`
    background-color: #fff;
    padding: .7rem .6rem .5rem .7rem;
    border-radius: .5rem;

    display: flex;
    align-items:center;
    justify-content: center;
`

export const IconFile = styled.div`
    background-color: #fff;
    padding: .7rem;
    border-radius: .5rem;

    display: flex;
    align-items:center;
    justify-content: center;
`
export const OrderNumber = styled.h1`
    color: #5C49DD;
    width: 100%;    

    font-size: 1.8rem;
    font-weight: bold;
`

export const UpgradeButton = styled.button`
    background-color: #5C49DD;
    color: #f9f9f9;
    
    font-weight: bold;
    font-size: 1.3rem;
    font-family: 'Inter', sans-serif;

    border: none;
    border-radius: 3rem;

    padding: .5rem 0;
    cursor: pointer;

    &:hover{
        background-color: transparent;
        border: .1rem inset #5C49DD;
        color: #5C49DD;
    }

    @media(max-width: 1600px){
        font-size: 1.2rem;
    }
`   

