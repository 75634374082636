import Ajuda from "./Ajuda";
import Configuracao from "./Configuracoes";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Notificacoes from "./Notificacoes";
import Pedidos from "./Pedidos";
import Produtos from "./Produtos";
import Usuarios from "./Usuarios";
import Produto from "./Produto";
import DadosProduto from "./Produto/DadosProduto";
import EsqueciMinhaSenha from "./EsqueciMinhaSenha";
import SugestoesDeConteudo from "./SugestoesDeConteudo";
import SugerirConteudo from "./SugerirConteudo";
import NewUsuarios from "./NewUsuarios";

export {
    Ajuda,
    Configuracao,
    Dashboard,
    Login,
    Notificacoes,
    Pedidos,
    Produtos,
    Usuarios,
    Produto,
    DadosProduto,
    EsqueciMinhaSenha,
    SugestoesDeConteudo,
    SugerirConteudo,
    NewUsuarios
}