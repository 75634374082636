import styled from "styled-components";

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

h1{
    margin-top: 1.5rem;
}

`

export {
    Container
}