import { RotatingLines } from  'react-loader-spinner'
import { LoadingProps } from '../../interfaces'
import {Container} from './style'

export default function Loading({color, withText}: LoadingProps) {

  const text = !!withText 

  return (
    <Container>
      <RotatingLines
        strokeColor={color}
        strokeWidth="5"
        animationDuration="0.75"
        width="45"
        visible={true}
        />
        {text && <h1 style={{color: color}}>Carregando...</h1>}
    </Container>
  )
}

