import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import { useLocation } from 'react-router-dom';
import { pathsWithoutHeaderAndMenu } from '../../App';
import { TopbarProps } from '../../interfaces';
import {
    Container,
    Leftcontainer,
    Menu,
    Rightcontainer,
    Notification,
    Data,
    Information
} from './style'

export default function Topbar({ handleDrawerOpen }: TopbarProps) {
  const user = localStorage.getItem("user") ?? '{}';
  const name = JSON.parse(user).name ?? '';
  const email = JSON.parse(user).email ?? '';
  const urlPhotoUser= JSON.parse(user).profile_picture ?? ''

  const location = useLocation()
  if (pathsWithoutHeaderAndMenu.includes(location.pathname)) {
    return <></>;
  }

  return (
    <Container>
        <Leftcontainer>
          <Menu onClick={handleDrawerOpen}>
            <MenuIcon />
          </Menu>
          <div>
            {false && <SearchIcon/>}
          </div>
        </Leftcontainer>
        <Rightcontainer>
          {false && <Notification>
            <Badge badgeContent={1} color="primary" >
              <NotificationsIcon  />
          </Badge>
          </Notification>}
        <Data>

          <Avatar
            alt="Remy Sharp" 
            src={urlPhotoUser !== '' ? urlPhotoUser : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}
            sx={{ 
              border: '0.0625rem solid #00000050' , 
              width: '2.8125rem' , 
              height: '2.8125rem' ,
              marginLeft: '1.25rem',
              marginRight: '0.625rem'
            }}
          />
          <Information>
            <h2>{name}</h2>
            <h3>{email}</h3>
          </Information>
        </Data>
        </Rightcontainer>
    </Container>
  )
}

