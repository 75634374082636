import styled from "styled-components";


export const Label = styled.label`
    display: flex;
    flex-direction:column;
    gap: 1rem;
    
    span{
        color: #333333;
        font-size: 1rem;
        font-weight: 500;
        font-family: Inter;
    }

    textarea{
        background-color: #f9f9f9;
        border: 1px solid #6D6D6D;
        border-radius: 1rem;
        font-family: Inter;
        max-height: 4rem;

        padding: 1rem;
    }
`