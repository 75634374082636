import styled from "styled-components";

const Container = styled.div`
width: 28.57rem;
height: 10.52rem;
border-radius: 0.75rem;
display: flex;
align-items: flex-end;
justify-content: center;
position:relative;
`

const ClosedContainer = styled(Container)`
height: 14rem;
`

const ContainerWithLink = styled.div`
margin: 0 auto;
width: 25.3rem;
height: 10.52rem;
border-radius: 0.75rem;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding-bottom: 2rem;
position:relative;
h1{
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-top: 1rem;
    color: #5c49dd;
}
h2{
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1rem;
    color: #5c49dd;
    padding-top: 1rem;
    margin-bottom: -0.4rem;
}
h3{
    margin-top: 0.6rem;
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.56rem;
    color: #5c49dd;
}
`

const NegociationContainerWithLink = styled(ContainerWithLink)`
h1{
    margin-top: -2rem;
}
h2{
    padding-top: 0.5rem;
    margin-bottom: 0rem;
}
h3{
    margin-top: 0rem;
}
`

const ContainerWithLinkCompany1 = styled.div`
margin: 0 auto;
width: 25.3rem;
height: 10.52rem;
border-radius: 0.75rem;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
position:relative;
h1{
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-top: 0.8rem;
    color: #5c49dd;
}
h2{
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0.98rem;
    color: #5c49dd;
    margin-top: 0.2rem;
    margin-bottom: 0.7rem;
}
h3{
    margin-top: 0.6rem;
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.56rem;
    color: #5c49dd;
}
`

const ContainerLoading = styled.div`
margin: 0 auto;
width: 23.3rem;
height: 10.52rem;
border-radius: 0.75rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position:relative;
h1{
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.45rem;
    color: #5c49dd;
}
h2{
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0.98rem;
    margin-bottom: 0.2rem;
    color: #5c49dd;

}
h3{
    margin-top: 0.6rem;
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.56rem;
    color: #5c49dd;
}
`

const BannerImage = styled.div`
margin-right: 1.5rem;
margin-bottom: -1.7rem;
margin-top: 10rem;
margin-left: 1rem;
img{
    max-width: 10rem;
    max-height: 9.3rem;
}
`

const BannerImage2 = styled.div`
margin-right: 1.5rem;
padding-left: 1.5rem;
margin-bottom: 1rem;
display: flex;
align-items: center;
img{
    max-width: 12rem;
    max-height: 10.3rem;
}
`

const BannerImage3 = styled.div`
margin-right: 1.5rem;
padding-left: 1rem;
display: flex;
align-items: center;
margin-bottom: 0.3rem;
img{
    max-width: 12rem;
    max-height: 9rem;
}
`

const BannerImage4 = styled.div`
margin-right: 1.5rem;
padding-left: 1rem;
margin-bottom: 1rem;
display: flex;
align-items: center;
img{
    max-width: 12rem;
    min-height: 10.3rem;
}
`

const BannerImage5 = styled.div`
margin-right: 1.5rem;
margin-bottom: -1rem;
margin-top: 1rem;
margin-left: 1rem;
img{
    max-width: 14rem;
    max-height: 11.3rem;
}
`

const BannerImageStatus = styled.div`
display: flex;
align-items: center;
width: 75%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.34rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.75rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`

const BannerImageStatusCompany1 = styled.div`
display: flex;
felx-direction: colunm;
align-items: center;
justify-content: center;
width: 75%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    padding-top: 4.5rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`

const BannerImageStatusCompany2 = styled.div`
display: flex;
felx-direction: colunm;
align-items: center;
justify-content: center;
width: 75%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    padding-top: 4rem;
    margin-top: 1rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`

const BannerImageStatusCompany3 = styled.div`
display: flex;
felx-direction: colunm;
align-items: center;
justify-content: center;
width: 75%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    padding-top: 1rem;
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 1.5rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`


const BannerImageStatusCompany4 = styled.div`
display: flex;
felx-direction: colunm;
align-items: center;
justify-content: center;
width: 55%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    margin-top: 3rem;
    margin-left: 0.5rem;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.8rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`

const BannerImageStatusCompany5 = styled.div`
display: flex;
felx-direction: colunm;
align-items: center;
justify-content: center;
width: 75%;
height: 100%;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.96rem;
color: #5c49dd;
font-family: 'Hanken Grotesk', sans-serif;
word-wrap: break-word; /* Para navegadores mais antigos */
overflow-wrap: break-word; /* Para navegadores modernos */
h1{
    padding-top: 1.5rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.5rem;
    color: #5c49dd;
}
h2{
    padding-top: 0.5rem;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #5c49dd;
}
`

const BannerText = styled.div`
    font-weight: 400;
    font-size: 0.2rem;
    line-height: 1.3rem;
    color: #5c49dd;
`

const BannerButton = styled.div`
cursor: pointer;
position: absolute;
margin 0 auto;
margin-left: 1.6rem;
bottom: -3.5rem;
width: 12.78rem;
height: 2.63rem;
border-radius: 3.76rem;
background-color: #5c49dd;
box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.30);
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
`

const BannerButton2 = styled.div`
cursor: pointer;
position: absolute;
margin 0 auto;
bottom: -2.5rem;
width: 75%;
height: 2.5rem;
border-radius: 3.76rem;
background-color: #f9f9f9;
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #5c49dd;
display: flex;
align-items: center;
justify-content: left;
img{
    width:1.88rem;
    height:1.88rem;
    margin-left: 1.2rem;
    margin-right: 0.75rem;
}
`
const BannerButton3 = styled.div`
cursor: pointer;
position: absolute;
margin 0 auto;
bottom: -3.5rem;
width: 75%;
height: 2.5rem;
border-radius: 3.76rem;
background-color: #f9f9f9;
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #5c49dd;
display: flex;
align-items: center;
justify-content: left;
img{
    width:1.88rem;
    height:1.88rem;
    margin-left: 1.2rem;
    margin-right: 0.75rem;
}
`

const BannerLink = styled.div`
cursor: pointer;
width: 100%;
height: 2.5rem;
border-radius: 1.5rem;
color: #5c49dd;
font-size:1.05rem;
font-weight: 500;
background-color: #f9f9f9;
display: flex;
align-items: center;
justify-content: left;
margin-top: 1rem;
img{
    width:1.88rem;
    height:1.88rem;
    margin-left: 1.2rem;
    margin-right: 0.75rem;
}
`

const BannerLink2 = styled.div`
cursor: pointer;
width: 100%;
height: 2.5rem;
border-radius: 1.5rem;
color: #5c49dd;
font-size:1.05rem;
font-weight: 500;
background-color: #f9f9f9;
display: flex;
margin-left: 6.7rem;
margin-right: 6.7rem;
margin-bottom: -3.3rem;
margin-top: 1.8rem;
align-items: center;
justify-content: left;
img{
    width:1.88rem;
    height:1.88rem;
    margin-left: 1.2rem;
    margin-right: 0.75rem;
}
`
const BannerLinkCompany1 = styled.div`
cursor: pointer;
width: 100%;
height: 2.5rem;
border-radius: 1.5rem;
color: #5c49dd;
font-size:1.05rem;
font-weight: 500;
background-color: #f9f9f9;
display: flex;
align-items: center;
justify-content: left;
margin-top: 1.5rem;
img{
    width:1.88rem;
    height:1.88rem;
    margin-left: 1.2rem;
    margin-right: 0.75rem;
}
`
const SideButton = styled.div`
cursor: pointer;
width: 11.75rem;
height: 2.63rem;
border-radius: 3.76rem;
margin: 0 0.3rem;
margin-bottom: -1rem;
background-color: #5c49dd;
box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.30);
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
`

const ObservationSideButton = styled.div`
cursor: pointer;
width: 11.75rem;
height: 2.63rem;
border-radius: 3.76rem;
margin-bottom: -1rem;
background-color: #5c49dd;
box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.30);
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
`


const SideButton2 = styled.div`
cursor: pointer;
width: 11.75rem;
height: 2.63rem;
border-radius: 3.76rem;
margin: 0 0.3rem;
margin-bottom: -1.7rem;
background-color: #5c49dd;
box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.30);
font-weight: 500;
font-size: 1.05rem;
font-height: 1.5rem;
color: #ffffff;
display: flex;
align-items: center;
justify-content: center;
`

const SidesButton = styled.div`
display: flex;
width: 100%;
justify-content: center;
position: absolute;
left: 0rem;
bottom: 0rem;
`

const SidesButtonNegotiation = styled.div`
display: flex;
width: 100%;
justify-content: center;
position: absolute;
left: 0rem;
bottom: 2rem;
`

const ObservationButton = styled.div`
display: flex;
width: 100%;
justify-content: center;
position: absolute;
left: 0rem;
bottom: -1rem;
`

export{
Container,
ContainerWithLink,
ContainerWithLinkCompany1,
BannerImage,
BannerImage2,
BannerImage3,
BannerImage4,
BannerImage5,
BannerLink,
BannerLink2,
BannerLinkCompany1,
SideButton,
SideButton2,
SidesButton,
ContainerLoading,
BannerText,
BannerButton,
BannerButton2,
BannerButton3,
BannerImageStatus,
BannerImageStatusCompany1,
BannerImageStatusCompany2,
BannerImageStatusCompany3,
BannerImageStatusCompany4,
BannerImageStatusCompany5,
ObservationButton,
ObservationSideButton,
NegociationContainerWithLink,
SidesButtonNegotiation,
ClosedContainer
}