import { Link } from 'react-router-dom'
import {
  Container,
  ContainerInformation,
  Logo,
  Informations,
  Data
} from './style'


export default function Information() {
  return (
    <Container>
      <Link style={{textDecoration: 'none'}} to="/home">
        <Logo>
        </Logo>
        </Link>
        <ContainerInformation>
            <Informations>
                <Data>
                </Data>
            </Informations>
        </ContainerInformation>
    </Container>
  )
}
