import styled from "styled-components";


const Container = styled.div`
  width: 95%;
  background-color: #f9f9f9;
  border-radius: 1.25rem;
  box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
  display: flex;
`

const LeftContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
  }
`

const InnerContainer = styled.div`
    width: 1000%;
`

const Information = styled.div`
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    margin-top: auto;
    margin-left: 1.5rem;

    h1 {
        margin: 0;
        font-weight: 300;
        font-size: 1rem;
        color: #333333;
        margin-right: 1rem;
    }

    h2 {
        margin-top: 1rem;
        font-weight: 500;
        font-size: 1.125rem;
        color: #333333;
    }
`

const Line = styled.div`
    width: 100%;
    height: 0.0625rem;
    background-color: #6D6D6D;
`

const Data = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  margin-top: auto;
  margin-left: 1.5rem;

  h1 {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    color: #333333;
    margin-right: 1rem;
  }

  h2 {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.125rem;
    color: #333333;
  }
`;

const ContractDate = styled.div`
    width: 15rem;
    padding: 0 0.625;
`

const Sugestor = styled.div`
    width: 15rem;
    padding: 0 0.625;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerDetalhes = styled.button`
    cursor: pointer;
    width: 9.375rem;
    height: 3.125rem;
    border: 0.0625rem solid #5C49DD;
    border-radius: 1.875rem;
    background-color: #f9f9f9;
    color: #5c49dd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1.21rem;
    font-size: 1rem;
    margin-right: 0.625rem;
`

const RightContent = styled.div`
  flex: 5;
  padding: 1.5rem;
  border-left: 0.0625rem solid #6d6d6d;

  textarea {
    font-family: 'Inter', sans-serif;
    width: 100%;
    min-height: 2.45625rem;
    max-height: 4rem;
    border: none;
    background-color: transparent;
    resize: none;
    font-size: 1rem;
    color: #333333;
  }
`

const Comment = styled.div`
  padding: 1.5rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.05);


  textarea {
    font-family: 'Inter', sans-serif;
    width: 100%;
    min-height: 2.45625rem;
    border: none;
    background-color: transparent;
    resize: none;
    font-size: 1rem;
    color: #333333;
  }
`

const Company = styled.div`
    Width: 15rem;
    padding: 0 0.625;
`

export {
    Container,
    InnerContainer,
    Information,
    Line,
    Data,
    ContractDate,
    Sugestor,
    ButtonContainer,
    VerDetalhes,
    Comment,
    LeftContent,
    RightContent,
    Company
}