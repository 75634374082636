import React, { useEffect } from 'react'
import jwtDecode from 'jwt-decode';
import { useState } from "react";
import Button from '../Button';
import ButtonReset from '../ButtonReset';
import api from '../../../services/api';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    ContainerInput,
    Input,
    Label,
    ShowPassword,
} from './style'
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';


export default function FormsChangePassword() {

    const [email, setEmail] = useState('');
    const [invalidInformation, setInvalidInformation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordReseted, setPasswordReseted] = useState(false);
    const [dots, setDots] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse();
    const { setUser, setToken } = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('authToken') ?? '{}';
        const intervalId = setInterval(updateDots, 500);

        if (user !== null && token !== null) {
            try {
                jwtDecode(JSON.parse(token)?.access_token)
                setUser(JSON.parse(user));
                setToken(JSON.parse(token));
                navigate('/home')
            } catch (error) {
                console.error(error)
                localStorage.removeItem('user')
                localStorage.removeItem('authToken')
            }
        }
        // eslint-disable-next-line

        return () => clearInterval(intervalId)

    }, [navigate, setToken, setUser]);

    function setError(error: any) {
        console.error(error)
        setTypeError(false)
        if(typeof error.response?.data?.detail === 'string'){
              setErrorMessage(error.response?.data?.detail)
        }else{
              setErrorMessage('Tente novamente em instantes!')
        }
        setShowPopup(true)
    }

    function requestPasswordChangeCode(event: React.FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        setInvalidInformation(false)

        const promise = api.requestPasswordChangeCode(email)
        promise
            .then((response) => {
                setCodeSent(true)
                setIsLoading(false)
            })
        promise
            .catch((error) => {
                setInterval(() => {
                    setIsLoading(false)
                }, 300)
                setError(error)
        })
    }

    function resetPassword(event: React.FormEvent) {
        event.preventDefault();
        setIsLoading(true)

        setInvalidInformation(false)

        const body = {
            email: email,
            new_password: password,
            password_change_code: code
        }

        const promise = api.resetPassword(body)
        promise
            .then((response) => {
                setIsLoading(false)
                setPasswordReseted(true)

                setTimeout(() => {
                    navigate("/login")
                }, 3000)
                
            })
        promise
            .catch((error) => {
                    setIsLoading(false)
                
                setError(error)
            })
    }

    function togglePassword() {
        setShowPassword(!showPassword)
    }

    const updateDots = () => {
        setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
    }

    return (
        <Container>
            {!passwordReseted ? (
                <>
                    {codeSent ? (
                        <>
                            <form autoComplete="off" onSubmit={resetPassword}>
                                <h1>Redefinir senha</h1>
                                <h2>O código de autenticação foi enviado. Verifique seu e-mail.</h2>
                                <ContainerInput>
                                    <Input invalidInformation={invalidInformation}
                                        type="text"
                                        name="code" id='code'
                                        placeholder="Código de autenticação"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                        disabled={isLoading}
                                    />
                                    <Label invalidInformation={invalidInformation} htmlFor="e-mail">
                                        Códigio de autenticação
                                    </Label>
                                </ContainerInput>

                                <ContainerInput>
                                    <Input invalidInformation={invalidInformation}
                                        type={showPassword ? 'text' : 'password'}
                                        name="password" id='password'
                                        placeholder="Nova Senha"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        disabled={isLoading}
                                    />
                                    <Label invalidInformation={invalidInformation} htmlFor="password">
                                        Nova senha
                                    </Label>
                                    <ShowPassword onClick={togglePassword}>
                                        {showPassword ? 'Ocultar' : 'Exibir'}
                                    </ShowPassword>
                                </ContainerInput>
                                <ButtonReset isLoading={isLoading} />

                            </form>
                        </>
                    ) : (
                        <>
                            <form autoComplete="off" onSubmit={requestPasswordChangeCode}>
                                <h1>Redefinir senha</h1>
                                <h2>Informe seu e-mail para enviarmos um código de autenticação</h2>
                                <ContainerInput>
                                    <Input invalidInformation={invalidInformation}
                                        type="text"
                                        placeholder="Seu e-mail"
                                        name="e-mail" id='e-mail'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        disabled={isLoading}
                                    />
                                    <Label invalidInformation={invalidInformation}
                                        htmlFor="e-mail">
                                        Seu e-mail
                                    </Label>
                                </ContainerInput>
                                <Button isLoading={isLoading} />
                            </form>
                        </>
                    )}
                </>
            ) : (
                <>
                    <h1>Senha redefinida com sucesso!</h1>
                    <h2>Redirecionando para a tela de login{dots}</h2>
                </>
            )}
        </Container>
    )
}
