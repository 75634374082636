import { createContext, useState } from "react";
import { PopupTrueFalseContextProps, ProviderProps } from "../interfaces";


const PopupTrueFalseContext = createContext<PopupTrueFalseContextProps>({
    errorMessage: '',
    setErrorMessage: () => {},
    typeError: false,
    setTypeError: () => {},
    showPopup: false,
    setShowPopup: () => {}
});


export const PopupTrueFalseProvider = ({children}: ProviderProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [typeError, setTypeError] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    
        return (
            <PopupTrueFalseContext.Provider value={{
                errorMessage,
                setErrorMessage,
                typeError,
                setTypeError,
                showPopup,
                setShowPopup
            }}>
                {children}
            </PopupTrueFalseContext.Provider>
        )
}

export default PopupTrueFalseContext;