import {
    ConfirmSendOrderContainer,
    ImageConfirm,
    ConfirmTitle,
    ConfirmTextPrimary,
    ConfirmTextSecondary,
    CancelButton,
    ConfirmButton,
    ConfirmAllButtons,
    InnerContainer,
    TextConfirm
    } from './style'
import sendorder from '../../assets/images/sendorder.png'
import api from '../../services/api'
import usePopup from '../../hooks/usePopupTrueFalse';

interface ConfirmSendOrderProps{
    setConfirmSendOrder: (value: boolean) => void;
    setReload: (value: boolean) => void;
    authToken: string;
    orderId: string;
    orderLink: string;
}

export default function ConfirmSendOrder({setConfirmSendOrder, setReload, authToken, orderId, orderLink}: ConfirmSendOrderProps){
    const {setErrorMessage, setTypeError, setShowPopup} = usePopup();
    function handleSendOrderToClient(){
        const body = {
            order_id: orderId,
            order_link: orderLink
        }
        const promise = api.sendOneOrder(authToken, body)
        promise.then(() => {
            setReload(true)
            setErrorMessage("Pedido enviado com sucesso!.")
            setTypeError(true)
            setShowPopup(true)
        }).catch((error) => {
            console.error(error)
            setErrorMessage("Ocorreu um erro ao enviar pedido para o cliente. Tente novamente em instantes.")
            setTypeError(false)
            setShowPopup(true)
        }).finally(() => {
            setConfirmSendOrder(false)
        })
    }


  return (
    <ConfirmSendOrderContainer>
        <InnerContainer>
            <ImageConfirm>
                <img src={sendorder} alt="Mulher de vestido azul enviando uma carta em um parque." />
            </ImageConfirm>
            <div>
                <ConfirmTitle>
                    Tem certeza que deseja enviar o material para o cliente?
                </ConfirmTitle>
                <TextConfirm>
                    <ConfirmTextSecondary>
                        Se sim, clique em
                    </ConfirmTextSecondary>
                    <ConfirmTextPrimary>
                        'Enviar para o cliente'
                    </ConfirmTextPrimary>
                    
                </TextConfirm>
            </div>
        </InnerContainer>
        <ConfirmAllButtons>
            <CancelButton onClick={() => setConfirmSendOrder(false)}>
                Cancelar
            </CancelButton>
            <ConfirmButton onClick={handleSendOrderToClient}>
                Enviar para o cliente
            </ConfirmButton>
        </ConfirmAllButtons>

    </ConfirmSendOrderContainer>
  )
}
