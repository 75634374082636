import styled from "styled-components";
import mentionL from '../../../assets/images/mention_l.png'

const Container = styled.div`
width: 17.5rem;
`
const ContainerInformation = styled.div`
display: flex;
justify-content: center;
`

const Logo = styled.div`
width: 17.5rem;
height: 8.625rem;
background-image: url(${mentionL});
background-repeat: no-repeat;
background-position: center;
background-size: 11.875rem auto;
`

const Informations = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #00000050;
width: 15.4375rem;
height: 4.5rem;
border-radius: 0.625rem;
display: none;
`

const Data = styled.div`
h1{
    width: 9.375rem;
    color: #FFF1DE;
   }
`


export {
    Container,
    ContainerInformation,
    Logo,
    Informations,
    Data
}