import ImageLogin from './ImageLogin';
import FormsChangePassword from './FormsChangePassword';
import {
    Container,
    Image,
    Forms,
    Logo
} from "./style";

export default function EsqueciMinhaSenha() {
    
    return (
        <Container>
            <Image>
                <ImageLogin />
            </Image>
            <Forms>
                <Logo></Logo>
                <FormsChangePassword />
            </Forms>
        </Container>
    )
}