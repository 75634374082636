import { UserProps } from '../../../../interfaces'
import Avatar from '@mui/material/Avatar';
import api from '../../../../services/api';
import useAuth from '../../../../hooks/useAuth';
import {
  Container,
  Header,
  Edit,
  Delete
} from './style'
import usePopupTrueFalse from '../../../../hooks/usePopupTrueFalse';


export default function Usuario({ id, name, email, type, profile_picture, updateRegisteredUsers, setShowPopupEdit, setUserEdit, setIsLoading }: UserProps) {
    const { token } = useAuth()
    const { setShowPopup, setErrorMessage, setTypeError } = usePopupTrueFalse()
    const authToken = token?.access_token ?? ''
    const picture = profile_picture ?? ''

  function editUser(){
    setShowPopupEdit(true)
    setUserEdit()
  }

  function deleteAndUpdateUser(token: string, id: number){
    setIsLoading(true)
    const promise = api.deleteOneUser(token, id)
    promise.then(() => {
      updateRegisteredUsers();
    }).catch((error) => {
      setTypeError(false)
      console.error(error)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
    }).finally(()=>{
      setIsLoading(false)
    })

    
  }
  
  switch (type) {
    case "admin":
      type = "Administrador"
      break;
    case "company":
      type = "Compania"
      break;
    case "editor":
      type = "Redator"
      break;
    default:
      break;
  }

  return (
    <Container>
      <Header>
        <span><Avatar 
        sx={{width:30, height:30}}
        src={picture !== '' ? picture : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}></Avatar></span>
        <span>{name}</span>
        <span>{email}</span>
        <span>{type}</span>
        <span>
          <Edit onClick={editUser}>Editar</Edit>
          <Delete onClick={() => {deleteAndUpdateUser(authToken,id)}}>Deletar</Delete>
        </span>
      </Header>
    </Container>
  )
}
