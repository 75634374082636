import styled from "styled-components";

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 45rem;
height: 25rem;
background-color: blue;
z-index:100;
border-radius: 20px;
background-color: #f9f9f9;

h1{
    color: #333333;
    font-weight: 400;
    font-size: 1.75rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
    text-align: center;
}

h2{
    color: #333333;
    font-weight: 350;
    font-size: 1.25rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
    text-align: center;
}

`
const Trocar = styled.div`
position: absolute;
bottom: 1.5rem;
right: 1.5rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;
        
:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}
    
`

const InnerContainer = styled.div`
width: 100%;
height: 22rem;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Cancelar = styled.div`
position: absolute;
bottom: 1.5rem;
left: 1.5rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
color: #5c49dd;
background-color: #ffffff;
border: 0.0625rem solid #5c49dd;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #fff;
    background-color: #5c49dd;
}

`
const LongInput = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 1.3rem;
margin-bottom: 60px;

label{
    margin-bottom: 8px;
}

input{
    width: 19.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1.875rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    align-items: center;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 17.5rem 0.625rem; 
    :focus {
        outline: none;
      }
}


`
export {
Container,
InnerContainer,
Cancelar,
Trocar,
LongInput
}