import styled from "styled-components";

interface PublishedNewsProps{
    tab: string;
}

const Container = styled.div<PublishedNewsProps>`
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
background-color: #f9f9f9;
margin-bottom: 1rem;
min-height: 8rem;
position: relative;
height: auto;
padding: 0.5rem 0;
border-radius: 1.25rem;
${props => props.tab === 'infoCompany' ? 
    'width: 77rem;' : 'width: 90%;'}
    @media(max-width: 1275px){
        width: 67rem;
    }
    @media(max-width: 935px){
        width: 60rem;
    }
    @media(max-width: 835px){
        width: 32rem;
    }
`;

const Content = styled.div`
display: flex;
cursor: pointer;
margin-top: 0.5rem;
align-items: center;
justify-content: flex-start;
width: 100%;
img{
    height: 100%;
    width: 10rem;
    object-fit: cover;
    margin-right: 1rem;
    margin-left: 1rem;
}

small{
    font-family: Inter;
font-size: 1.05rem;
font-weight: 600;
line-height: 1.278rem;
letter-spacing: 0em;
text-align: left;
margin-bottom: 0.25rem;
margin-right: 1rem;
}

h5,h4{
font-family: Inter;
font-size: 1.05rem;
line-height: 1.278rem;
text-align: left;
margin-bottom: 0.25rem;
margin-right: 1rem;
}

h5{
    font-weight: 500;
}
p{
    color: #6D6D6D;
    margin-bottom: 0.25rem;
    margin-right: 1rem;
}
`

const ContentFakeTitle = styled.div`
display: flex;
cursor: pointer;
margin-top: 0.5rem;
align-items: center;
justify-content: flex-start;
width: 100%;
img{
    height: 7rem;
    width: 10rem;
    object-fit: cover;
    margin-right: 1rem;
    margin-left: 1rem;
}

h5,h4{
font-family: Inter;
font-size: 1.05rem;
line-height: 1.278rem;
text-align: left;
margin-bottom: 0.25rem;
margin-right: 1rem;
margin-top: 0.5rem;
}

h5{
    font-weight: 500;
}
`


const SkeletonContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
margin-left: 1.5rem;
gap: 0.5rem;
`

const CloseContainer = styled.div`
position: absolute;
top: 0.5rem;
right: 0.5rem;
cursor: pointer;
`

export{
    Container,
    Content,
    SkeletonContainer,
    CloseContainer,
    ContentFakeTitle
}
