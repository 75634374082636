import styled from "styled-components";
interface TypeProps{
    typePopup: boolean;
}

interface ActiveProps{
    showPopup: boolean;
}

const ToastContent = styled.div`
    display: flex;
    align-items: center;
    
`

const Toast = styled.div<ActiveProps>`
font-family: "Poppins", sans-serif;
position: fixed;
  bottom: 1.5625rem;
  right: 1.875rem;
  border-radius: 0.75rem;
  overflow: hidden;
  background: #fff;
  padding: 1.25rem 2.1875rem 1.25rem 2.1875rem;
  box-shadow: 0 0.375rem 1.25rem -0.3125 rgba(0, 0, 0, 0.1);
  z-index: 10000;
  transform: translateX(calc(100% + 1.875rem));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  ${props => props.showPopup ? 'transform: translateX(0%)': 'display: none'};

`


const Item = styled.div<TypeProps>`
display: flex;
align-items: center;
justify-content: center;
height: 2.1875rem;
min-width: 2.1875rem;
background-color: ${props => props.typePopup ? '#4070f4' : '#f73131'};
color: #fff;
font-size: 1.25rem;
border-radius: 50%;
`

const MainMessage = styled.div`
font-size: 1rem;
font-weight: 400;
color: #666666;
`

const Message = styled.div`
display: flex;
flex-direction: column;
margin: 0 1.25rem;
`

const Close = styled.div`
position: absolute;
top: 0.625rem;
right: 0.9375rem;
padding: 0.3125;
cursor: pointer;
opacity: 0.7;
:hover{
    opacity: 1;
}

`
const Title = styled.div`
font-size: 1rem;
font-weight: 400;
color: #666666;
font-weight: 600;
color: #333;
padding-bottom: 1rem;
`

export{
    Toast,
    ToastContent,
    Item,
    MainMessage,
    Message,
    Close,
    Title
}

