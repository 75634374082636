import styled from "styled-components";

interface ContainerProps{
    hasUser: boolean;
}

const Container = styled.div<ContainerProps>`
width: 100%;
height: 5.111rem;
display: flex;
:hover{
background: rgba(92, 73, 221, 0.2);
}
font-weight: 500;
font-size: 1.2rem;
line-height: 1.428rem;
border-bottom: 1px solid #e2e2e2;
color: #333333;
@media(max-width: 1735px){
    width: 100%;
}
@media(max-width: 700px){
    font-size: 1rem;
    line-height: 1.228rem;
}
`

const Image = styled.div`
width: 8%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
justify-content: center;
img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
@media(max-width: 700px){
    img{
        width: 2rem;
        height: 2rem;
    }
    min-width: 4rem;
}
`

const Name = styled.div`
width: 15%;
height: 5.187rem;
display: flex;
align-items: center;

@media(max-width: 700px){
    min-width: 7rem;
}
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
`

const Email = styled.div`
width: 18%;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
@media(max-width: 800px){
width: 28%;
}
`

const Role = styled.div`
width: 10%;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
@media(max-width: 800px){
display: none;
}
`

const Plan = styled.div`
width: 15%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 15%;
    min-width: 5rem;
}
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
@media(max-width: 800px){
display: none;
}
`

const Company = styled.div`
width: 10%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 10%;
    min-width: 5rem;
}
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
@media(max-width: 800px){
    width:15%;
}
@media(max-width: 725px){
    width: 23%;
}
`

const Segmentation = styled.div`
width: 15%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 15%;
    min-width: 5rem;
}
@media(max-width: 1400px){
font-size: 1rem;
}
@media(max-width: 960px){
font-size: 0.85rem;
}
@media(max-width: 800px){
    width:15%;
}
@media(max-width: 725px){
    display: none;
}
`

const Options = styled.div`
width: 19%;
height: 5.187rem;
display: flex;
justify-content: center;
align-items: center;
@media(max-width: 725px){
    width: 25%;
}
`

const Delete = styled.button`
cursor: pointer;
width: 6.5rem;
height: 2.5rem;
background-color: #f9f9f9;
color: #5c49dd;
border: 0.0625rem solid #5c49dd;
border-radius: 1.875rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.1875rem;
text-align: center;
margin: 0 0.625rem 0;
transition: 200ms;

:hover{
    background-color: #5c49dd;
    color: #f9f9f9;
    border: none;
}
`

const Edit = styled.button`
cursor: pointer;
width: 6.5rem;
height: 2.5rem;
background-color: #5c49dd;
color: #f9f9f9;
border-radius: 1.875rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.1875rem;
text-align: center;
border: none;
transition: 200ms;
margin: 0 0.625rem 0;


:hover{
    background-color: #f9f9f9;
    color: #5c49dd;
    border: 0.0625rem solid #5c49dd;
}
`

export {
    Container,
    Image,
    Name,
    Email,
    Role,
    Plan,
    Segmentation,
    Company,
    Options,
    Edit,
    Delete
}