import { ButtonProps } from '../../../interfaces'
import Loading from '../../../components/Loading'
import { ButtonSubmit } from './style'

export default function Button({ isLoading }: ButtonProps) {
  return (
    <ButtonSubmit type="submit">
      {isLoading ? <Loading color="white" /> : "Entrar"}
    </ButtonSubmit>
  )
}

