import styled from "styled-components";

const Container = styled.div`
cursor: pointer;
background-color: #7F6AFF;
width: 18.79rem;
height: 9.02rem;
border-radius: 10px;
position: relative;
margin-bottom: 0.75rem;
margin: 0 0.94rem 0.75rem;
z-index: 1;
`


const InnerContainer = styled.div`
display: flex;
flex-direction: column;
align-items: start;
justify-content: center;
width: 100%;
height: 100%;
z-index:2;
`

const Company = styled.div`
display: flex;
align-items: center;
justify-content: center;
span{
    margin-left: 0.4rem;
    color: #f9f9f9;
    font-family: Inter;
    font-size: 1.05rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
`

interface PriorityProps{
    priority: number
}

function ColorBackground(props: PriorityProps){
    if(props.priority === 1){
        return '#29B95F;'
    } else if(props.priority === 2){
        return '#FF9736;'
    } else if(props.priority === 3){
        return '#FF3636;'
    } else {
        return '#fff;'
    }
}

const Priority = styled.div<PriorityProps>`
display: flex;
align-items: center;
justify-content: center;
background-color: ${props => ColorBackground(props)};
border-radius: 20px;
width: 4.51rem;
height: 1.127rem;
color: #F9F9F9;
font-family: Inter;
font-size: 0.827rem;
font-style: normal;
font-weight: 400;
line-height: normal;
`

const ProductName = styled.div`
color: #f9f9f9;
font-family: Inter;
font-size: 1.05rem;
font-style: normal;
font-weight: 500;
line-height: normal;

padding-left: 1rem;
span{
    color: #6D6D6D;
font-family: Inter;
font-size: 1.05rem;
font-style: normal;
font-weight: 500;
line-height: normal;


}
`

const Base = styled.div`
color: #f9f9f9;
font-family: Inter;
font-size: 0.9rem;
font-style: normal;
font-weight: 500;
line-height: normal;
`

const DateContainer = styled(Base)`
gap: 0.4rem;
display: flex;
align-items: center;
justify-content: center;
position: relative;
`
const Warning = styled.div`
position: absolute;
top: -0.5rem;
left: 7rem;
img{
    width: 0.7rem;
}`

const EditorContainer = styled(Base)`
display: flex;
align-items: center;
justify-content: center;
span{
    margin-left: 0.4rem;
}
`

const Delegate = styled.div`
border-radius: 25px;
width: 7.76rem;
display: flex;
align-items: center;
justify-content: center;
height: 1.88rem;
background: #f9f9f9;
color: #5C49DD;
font-family: Inter;
font-size: 0.9rem;
font-style: normal;
font-weight: 500;
line-height: normal;

`

const CardTop = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 17.29rem;
position: absolute;
top: 0.977rem;
right: 0.7518rem;
`

const CardBottom = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 16.29rem;
position: absolute;
bottom: 0.977rem;
right: 1.35rem;
`

export {
    Container,
    InnerContainer,
    Company,
    Priority,
    ProductName,
    DateContainer,
    EditorContainer,
    CardTop,
    CardBottom,
    Warning,
    Delegate
}