import styled from "styled-components";

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 45rem;
height: 17rem;
background-color: blue;
z-index:100;
border-radius: 20px;
background-color: #f9f9f9;

h1{
    color: #333333;
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
}

h2{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 2rem;
}

}
`


const Voltar = styled.div`
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12.5rem;
    height: 3.125rem;
    background: #ffffff;
    color: #5c49dd;
    border: 0.0625rem solid #5c49dd;
    border-radius: 1.875rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    margin-left: 1rem;
    transition: 200ms;
    
    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`



const Confirm = styled.div`
position: absolute;
bottom: 1.5rem;
right: 1.5rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-right: 1rem;
transition: 200ms;

:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}

}
`

const ClosePopup = styled.div`
position: absolute;
top: 1.5rem;
right: 1.5rem;
cursor: pointer;
`

const LongInput = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex: 1;
textarea{
    font-family: 'Inter', sans-serif;
    padding-top: 0.5rem;
    resize: none;
    width: 31.25rem;
    height: 1.9rem;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;

    margin-bottom: 0.9375rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    @media(max-width: 1275px){
        width: 29rem;
    }
    @media(max-width: 935px){
        width: 25rem;
    }

    

}
textarea::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
    
}

`;

const InputArea = styled.div`
margin-top: 2.5rem;
`


export {
Container,
ClosePopup,
Confirm,
Voltar,
LongInput,
InputArea
}