import {
    ConfirmSendOrderContainer,
    ImageConfirm,
    ConfirmTitle,
    ConfirmTextPrimary,
    ConfirmTextSecondary,
    CancelButton,
    ConfirmButton,
    ConfirmAllButtons,
    InnerContainer,
    TextConfirm
    } from './style'
import sendorder from '../../../assets/images/sendorder.png'
import api from '../../../services/api'
import usePopup from '../../../hooks/usePopupTrueFalse';

interface ConfirmSendOrderProps{
    setConfirmSendCommunicationPlan: (value: boolean) => void;
    setReload: (value: boolean) => void;
    authToken: string;
    revisorUserId: string;
    companyUserId: string;
}

export default function ConfirmSendCommunicationPlan({setConfirmSendCommunicationPlan, setReload, authToken, companyUserId, revisorUserId}: ConfirmSendOrderProps){
    const {setErrorMessage, setTypeError, setShowPopup} = usePopup();
    function handleSendOrderToClient(){
        const body = {
            company_user_id: companyUserId,
            revisor_user_id: revisorUserId
        }
        const promise = api.sendCommunicationPlanToApproval(authToken, body)
        promise.then(() => {
            setReload(true)
            setErrorMessage("Pedido enviado com sucesso!.")
            setTypeError(true)
            setShowPopup(true)
        }).catch((error) => {
            console.error(error)
            setErrorMessage("Ocorreu um erro ao enviar pedido para o cliente. Tente novamente em instantes.")
            setTypeError(false)
            setShowPopup(true)
        }).finally(() => {
            setConfirmSendCommunicationPlan(false)
        })
    }


  return (
    <ConfirmSendOrderContainer>
        <InnerContainer>
            <ImageConfirm>
                <img src={sendorder} alt="Mulher de vestido azul enviando uma carta em um parque." />
            </ImageConfirm>
            <div>
                <ConfirmTitle>
                    Tem certeza que deseja enviar o material para o cliente?
                </ConfirmTitle>
                <TextConfirm>
                    <ConfirmTextSecondary>
                        Se sim, clique em
                    </ConfirmTextSecondary>
                    <ConfirmTextPrimary>
                        'Enviar para o cliente'
                    </ConfirmTextPrimary>
                    
                </TextConfirm>
            </div>
        </InnerContainer>
        <ConfirmAllButtons>
            <CancelButton onClick={() => setConfirmSendCommunicationPlan(false)}>
                Cancelar
            </CancelButton>
            <ConfirmButton onClick={handleSendOrderToClient}>
                Enviar para o cliente
            </ConfirmButton>
        </ConfirmAllButtons>

    </ConfirmSendOrderContainer>
  )
}
