import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, useParams} from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import * as S from './style'
import api from '../../services/api';
import { ReadCommunicationPlans } from '../../interfaces';
import StepperVertical from './StepperVertical';
import Banner from './Banner';
import setabaixo from '../../assets/images/setabaixo.png';
import setacima from '../../assets/images/setacima.png';
import { useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendCommunicationPlanToRevision from './SendCommunicationPlanToRevision';
import SolicitarAlteracao from './SolicitarAlteracao';
import InformationContainer from '../../components/InformationContainer';
import { acertarDatas } from '../../utils';
import ConfirmSendCommunicationPlan from './ConfirmSendCommunicationPlan';
import DelegateCommunicationPlan from '../../components/DelegateCommunicationPlan';

export default function PlanoDeComunicacao() {
  const { idCompanyUser } = useParams()
  const companyUserId = idCompanyUser ?? ''
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token as string ?? ''
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''
  const userId = JSON.parse(user).id ?? ''
  const [reload, setReload] = useState(true)
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [sendCommunicationPlanToRevision, setSendCommunicationPlanToRevision] = useState(false)
  const isEditorOrAdmin = typeUser === 'admin' || typeUser === 'editor'
  const isScreenSmall = useMediaQuery('(max-width:1520px)');
  const [showMoreLogs, setShowMoreLogs] = useState(false);
  const [showChangeRequest, setShowChangeRequest] = useState(false)
  const [confirmSendCommunicationPlan, setConfirmSendCommunicationPlan] = useState(false)
  const [delegateCommunicationPlan, setDelegateCommunicationPlan] = useState(false)
  const [communicationPlanInfo, setCommunicationPlanInfo] = useState<ReadCommunicationPlans>()


  useEffect(() => {
    if(reload === true){
    if(typeUser==='admin'){
          const promise = api.readOneCommunicationPlanAdmin(authToken, companyUserId)
          promise.then((response) => {
            setCommunicationPlanInfo(response.data)
          }).catch((error) => {
            console.error(error)
          }).finally(() =>{
          })
    }else if(typeUser==='editor'){
      const promise = api.readOneCommunicationPlanEditor(authToken, companyUserId)
      promise.then((response) => {
        setCommunicationPlanInfo(response.data)
      }).catch((error) => {
        console.error(error)
      }).finally(() =>{
      })
    }
    setReload(false)
  }
  },[authToken, companyUserId, typeUser, reload])


  return (
    <S.Container>
        {(sendCommunicationPlanToRevision || showChangeRequest || confirmSendCommunicationPlan || delegateCommunicationPlan) && 
      (<S.BackgroundPopup>
        {sendCommunicationPlanToRevision && <SendCommunicationPlanToRevision authToken={authToken} companyUserId={companyUserId} userId={userId} setSendCommunicationPlanToRevision={setSendCommunicationPlanToRevision} setReload={setReload}/>}
        {showChangeRequest && <SolicitarAlteracao setShowChange={setShowChangeRequest} companyUserId={companyUserId} authToken={authToken} typeUser={typeUser} setReload={setReload}/>}
        {confirmSendCommunicationPlan && <ConfirmSendCommunicationPlan revisorUserId={userId} setConfirmSendCommunicationPlan={setConfirmSendCommunicationPlan} authToken={authToken} companyUserId={companyUserId} setReload={setReload}/>}
        {delegateCommunicationPlan && <DelegateCommunicationPlan setDelegateCommunicationPlan={setDelegateCommunicationPlan} authToken={authToken} companyUserId={companyUserId} setReload={setReload}/>}
      </S.BackgroundPopup>)}
        <S.InnerContainer>
        <S.Main>
          <S.Title>Detalhes do Plano de Comunicação</S.Title>
        <S.Content tab={''}> 
            <S.Information tab={''}>               
                {(typeUser === 'editor' || typeUser === 'admin') && 
                <h3>
                  Cliente: <span>{`${communicationPlanInfo?.user?.name}`}</span>
                </h3>}
                {typeUser === 'admin' && (
                  <h3>
                  Redator: {`${communicationPlanInfo?.communication_plan_editor?.name}`} <S.EditButton onClick={() => setDelegateCommunicationPlan(true)}><EditIcon sx={{ color: "#6D6D6D", fontSize: 12 }}/></S.EditButton>
                </h3>
                )}
                <br/>


                <S.Data>
                  <S.InfoData>
                  {!(communicationPlanInfo?.communication_plan_internal_change_requests && communicationPlanInfo?.communication_plan_internal_change_requests.length > 0 && isEditorOrAdmin) && <h1>Não há solicitação de alteração por parte do Admin na plataforma. Por favor, verificar o link do Google Sheets.</h1>}
                  </S.InfoData>
                  {(communicationPlanInfo?.communication_plan_internal_change_requests && communicationPlanInfo?.communication_plan_internal_change_requests.length > 0 && isEditorOrAdmin) && communicationPlanInfo?.communication_plan_internal_change_requests.map((change) => {
                    return(
                      <InformationContainer 
                        key={change.id} fieldName={`Mudança interna solicitada em ${acertarDatas(change.created_at)}`} value={change.description} />
                    )
                  })}
                   <S.InfoData>
                    {!(communicationPlanInfo?.communication_plan_internal_change_requests && communicationPlanInfo?.communication_plan_internal_change_requests.length > 0 && isEditorOrAdmin) && <h1>Não há solicitação de alteração por parte do Cliente na plataforma. Por favor, verificar o link do Google Sheets.</h1>}
                  </S.InfoData>

                  {((communicationPlanInfo?.communication_plan_change_requests && communicationPlanInfo?.communication_plan_change_requests.length > 0)) && communicationPlanInfo?.communication_plan_change_requests.map((change) => {
                    return(
                      <InformationContainer 
                        key={change.id} fieldName={`Mudança solicitada em ${acertarDatas(change.created_at)}`} value={change.description} />
                    )
                  })}

                </S.Data>
                
                <S.Line></S.Line>
                
                <S.Buttons>
                  <S.LeftButtons>
                    <Link style={{textDecoration: 'none'}} to="/home">
                        <S.Back><ArrowBackIcon/>Voltar</S.Back>
                    </Link>
                  </S.LeftButtons>
                </S.Buttons>
            </S.Information>
            
        </S.Content>
        </S.Main>

        <S.RightBar tab={''}>
          <S.Sidepopup>
          <S.InfoStatus>
              <h1>Status do Plano de Comunicação</h1>
              <S.CardStatus>
                <Banner 
                typeUser={typeUser} 
                etapa={communicationPlanInfo?.communication_plan_status?.status ?? ''}
                authToken={authToken}
                companyUserId={companyUserId ?? ''}
                communicationPlanLink={communicationPlanInfo?.communication_plan_url ?? ''}
                setDelegateCommunicationPlan={setDelegateCommunicationPlan}
                setSendCommunicationPlanToRevision={setSendCommunicationPlanToRevision}
                setShowChange={setShowChangeRequest}
                inputRef={inputRef}
                setReload={setReload}
                productName={'Plano de Comunicação'}
                revisorUserId={communicationPlanInfo?.communication_plan_revisor?.id}
                userId={userId}
                setConfirmSendCommunicationPlan={setConfirmSendCommunicationPlan}
                planCompany={'Plano'}
                />
              </S.CardStatus>
            </S.InfoStatus>
              <S.Stepper>
                <S.StepperConntent>
                  <StepperVertical 
                  communication_plan_logs={communicationPlanInfo?.communication_plan_logs ?? []} 
                  canShowAllLogs={!isScreenSmall}
                  />
                  {!showMoreLogs && isScreenSmall && <h2 onClick={() => setShowMoreLogs(true)}><img src={setabaixo} alt="Seta"/>Ver todos os status</h2>}
                  {showMoreLogs && (
                    <>
                    {showMoreLogs && isScreenSmall &&  (
                      <S.AllLogsContainer>
                        <h2 onClick={() => setShowMoreLogs(false)}><img src={setacima} alt="Seta"/>Mostrar menos</h2>
                        <S.AllLogsContent>
                          <StepperVertical 
                              communication_plan_logs={communicationPlanInfo?.communication_plan_logs ?? []} 
                              canShowAllLogs={isScreenSmall}
                              />
                        </S.AllLogsContent>
                      </S.AllLogsContainer>
                    )}
                    </>
                  )}
                </S.StepperConntent>
              </S.Stepper>
          </S.Sidepopup>
        </S.RightBar>
        </S.InnerContainer>
    </S.Container>
  )
}
