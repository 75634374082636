import styled from "styled-components";

const ButtonSubmit = styled.button`
margin-top: 3.125rem; 
padding: 0.625rem 3.125rem;
font-family: Roboto, sans-serif;
font-weight: 500;
font-size: 2rem;
color: #f9f9f9;
background-color: #5C49DD;
border: none;
box-shadow: rgb(0, 0, 0) 0rem 0rem 0rem 0rem;
border-radius: 3.125rem;
transition : 500ms;
transform: translateY(0);
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
`

export {
    ButtonSubmit
}