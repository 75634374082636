import { useState } from 'react';
import { useParams } from 'react-router-dom';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import api from '../../services/api';
import Loading from '../Loading';
import {
    Container,
    ClosePopup,
    Confirm,
    Voltar,
    LongInput,
    InputArea
  } from './style'

interface InsertPlanProps{
    setShowInsertPlan: (value: boolean) => void;
    updateFuncion: () => void;
    CompanyId?: number;
}

export default function InsertPlan({setShowInsertPlan, updateFuncion, CompanyId}: InsertPlanProps) {
  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token ?? ''
  const { idCompany } = useParams()
  const companyId = idCompany ?? CompanyId?.toString() ?? ''
    
  const [urlPlan, setUrlPlan] = useState('')

  function patchUrlCommunicationPlan(){
    setLoading(true)
    const body = {
      "communication_plan": urlPlan
    }

    const promise = api.addCommunicationPlan(authToken, companyId, body)
    promise.then(() => {
      setLoading(false)
      setTypeError(true)
      setErrorMessage('Plano de comunicação adicionado com sucesso!')
      setShowPopup(true)
      setShowInsertPlan(false)
      updateFuncion()
    }).catch((error) => {
      console.error(error)
      setLoading(false)
      setErrorMessage('Erro ao adicionar plano de comunicação! Tente novamente em instantes.')
      setShowPopup(true)
      setShowInsertPlan(false)
    })

  }

    return (
        <Container>
          {loading ? 
            (<Loading color='black' />) : 
            (<>
              <ClosePopup onClick={() => setShowInsertPlan(false)}>
                x
              </ClosePopup>
              <h1>Qual é o link do plano de comunicação?</h1>
              <InputArea>
                <LongInput>
                  <textarea
                  placeholder=''
                  name="field" id='field'
                  value={urlPlan}
                  onChange={e => setUrlPlan(e.target.value)}
                  />
                </LongInput>
              </InputArea>
              <div>
                <Voltar onClick={() => setShowInsertPlan(false)}>
                  Voltar
                </Voltar>
                <Confirm onClick={patchUrlCommunicationPlan}>
                  Confirmar
                </Confirm>
              </div>
            </>)}
        </Container>
      )
}
