import {
    Toast,
    ToastContent,
    Item,
    MainMessage,
    Message,
    Close,
    Title
} from './style'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TrueFalsePopupProps } from '../../interfaces';
import { useLocation } from 'react-router-dom';

export default function PopupTrueFalse({active, type, message, setActive}: TrueFalsePopupProps) {

  const location = useLocation();

  return (
    <Toast showPopup={(location.pathname !== '/login' && location.pathname !== '/') && active}>
        <ToastContent>
            <Item typePopup={type}>{type ? <CheckIcon/> : <CloseIcon/>}</Item>
            <Message>
                <Title>{type ? 'Sucesso!' : 'Tivemos um problema...'}</Title>
                <MainMessage>{message}</MainMessage>
            </Message>
        </ToastContent>
        <Close onClick={() => setActive(false)} ><CloseIcon/></Close>
    </Toast>
  )
}
