import { useState } from 'react';
import { AddSegmentationProps } from '../../interfaces'
import {
    Container, 
    InnerContainer,
    LongInput,
    Cancelar,
    Trocar
} from './style'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../services/api';
import usePopup from '../../hooks/usePopupTrueFalse';

export default function AddSegmentation({authToken, setShowAddSegmentationPopup, setSegmentationsOptions}: AddSegmentationProps) {
    const [segmentation, setSegmentation] = useState('')
    const {setErrorMessage, setTypeError, setShowPopup} = usePopup();

    function addSegmentation(){
        const body = {
            "segmentation": segmentation 
        }

        const promise = api.addSegmentation(authToken, body)
        promise.then((response) => {

            api.readSegmentations(authToken)
            .then((response) => {
                setSegmentationsOptions(response.data)
                setShowAddSegmentationPopup(false)
            }).catch((error) => {
                console.error(error)
                setErrorMessage("Ocorreu um erro ao recarregar as opções de segmentação. Tente novamente em instantes.")
                setTypeError(false)
                setShowPopup(true)
            })

        }).catch((error) => {
            console.error(error)
            setErrorMessage("Ocorreu um erro ao adicionar a segmentação. Tente novamente em instantes.")
            setTypeError(false)
            setShowPopup(true)
        }).finally(() => {

        })
    }

    return (
        <Container>
        <h1>Atenção</h1>
        <h2>Verifique se a segmentação já não foi adicionada</h2>
        <InnerContainer>
        <LongInput>
            <label htmlFor="newPlan">Nova segmentação:</label>
            <input type="text" value={segmentation} onChange={e => setSegmentation(e.target.value)}></input>
            </LongInput>
        </InnerContainer>
        
        <div>
        <Cancelar onClick={() => setShowAddSegmentationPopup(false)}><ArrowBackIcon/>Cancelar</Cancelar>
        <Trocar onClick={addSegmentation}>Adicionar</Trocar>
        </div>
        
    </Container>
    )
}