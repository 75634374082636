import styled from "styled-components";

const DragAndDropContainer = styled.div`
margin-top: 1.5rem;
h1{
    color: #333333;
    font-size: 1.2rem;
    line-height: 1.45rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
`

interface InnerDragAndDropProps {
    isUploading: boolean;
}

const InnerDragAndDrop = styled.div<InnerDragAndDropProps>`
cursor: pointer;
position: relative;
width: 26.315rem;
height: 14.66rem;
border: 1px dashed #6D6D6D;
border-radius: 0.625rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${props => props.isUploading ? '#6d6d6d30' : '#6d6d6d0d'};
h4{
    color: #6d6d6d;
    font-size: 1.052;
    font: inter;
    line-height: 1.27rem;
}
span{
    color: #5C49DD;
    text-decoration: underline;
}
input{
    ${props => props.isUploading ? 'display: none;' : ''}
    position: absolute;
    width: 26.315rem;
    height: 14.66rem;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}
@media(max-width: 1675px){
    width: 20rem;
    input{
        width: 20rem;
    }
}
@media(max-width: 1605px){
    width: 18rem;
    input{
        width: 18rem;
    }
}
@media(max-width: 835px){
    input{
        width: 26.315rem;
    margin-bottom: 1rem;
    }
    width: 26.315rem;
    margin-bottom: 1rem;
}
`

export {
    DragAndDropContainer,
    InnerDragAndDrop
    }