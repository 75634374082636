import styled from "styled-components";


const Container = styled.div`
width: 17.5rem;
height: 100vh;

background-color: #F9F9F9;
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
`

const Line = styled.div`
height: 0.0625rem;
width: 17.5rem;
background-color: #6D6D6D50;
margin-bottom: 1.5625rem;
`

export {
    Container,
    Line
}


