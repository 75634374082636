import { ChangeEvent, useEffect, useRef } from 'react';
import { InputProps } from '../../interfaces';

import {
    Container,
    InnerContainer,
    LongInput,
    SupMandatoryField,
    Tooltip
} from './style'

export default function Input({description, fieldName, value , inputs, setInputs, indice, example, is_required, disabled, tab}: InputProps) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        adjustTextareaHeight(); 
      }, []);


    function adjustTextareaHeight() {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }

      function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        inputs[indice].value = event.target.value;
        setInputs([...inputs]);
        adjustTextareaHeight();
      }
        
    return(
        <>
            <Container>
                <InnerContainer>
                <LongInput tab={tab}>
                <label htmlFor="field">{fieldName}{is_required && <SupMandatoryField>*<Tooltip>Campo obrigatório!</Tooltip></SupMandatoryField>}</label>
                <h2>{description}</h2>
                <h3>
                <div dangerouslySetInnerHTML={{ __html: example ?? '' }} />
                </h3>
                <textarea
                    ref={textareaRef}  
                    disabled={disabled && true}
                    placeholder=''
                    name="field" id='field'
                    value={value}
                    onChange={handleChange} 
                    />
                </LongInput>
                </InnerContainer>
            </Container>
        </>
    )
}