import styled from 'styled-components';
import mentionFundo from '../../assets/images/mention_fundo.png'

const Container = styled.div`
background-color: #E5E5E5;
min-height: calc(100vh - 4rem);
height: auto;
width: calc(100vw - 17.5rem);
@media(max-width: 1200px){
    width: 100vw;
}
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
position: relative;
padding-bottom: 2rem;
@media(max-width: 1425px){
    padding-bottom: 3rem;
}
`

const Title = styled.div`
font-weight: 600;
font-size: 1.5rem;
line-height: 1.8rem;
color: #333333;
width: 108.27rem;
margin-top: 3.45rem;
margin-bottom: 1.73rem;
@media(max-width: 1735px){
    width: 90%;
}
display: flex;
justify-content: center;
align-items: center;
`
const Content = styled.div`
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
width: 108.27rem;
min-height: 55.789rem;
height: auto;
display: flex;
padding-top: 2.25rem;
@media(max-width: 1650px){
    width: 90%;
}
@media(max-width: 1425px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.MuiStepIcon-root.Mui-completed{
    color:  #5C49DD;  
}
.MuiStepIcon-root.Mui-active{
    color:  #5C49DD;  
}
`

const LeftContent = styled.div`
width: 52.48rem;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
img{
    width: 36.46rem;
    height: 21.05rem;
}
`

const RightContent = styled.div`
width: 55.78rem;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const Line = styled.div`
height: 53rem;
width: 1px;
background: rgba(109, 109, 109, 0.40);
@media(max-width: 1425px){
    margin: 2rem 0;
    width: 95%;
    height: 1px;
}

`

const ProductName = styled.div`
color: #5C49DD;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 4.88rem;
margin-bottom: 0.75rem;
width: 36.46rem;
`

const ProductDescription  = styled.div`
width: 36.46rem;
color: #333;
font-family: Inter;
font-size: 1.8rem;
font-style: normal;
font-weight: 300;
line-height: normal;
`

const ContainerInputs = styled.div`
font-size: 1rem;
margin: 3rem auto 1.875rem;
width: 31.875rem;
height: 40rem;
@media(max-width: 700px){
        width: 26.25rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
`

interface TitleInputProps{
    file: boolean;
}

const TitleInput = styled.div<TitleInputProps>`
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.42rem;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
    ${props => !props.file ? 'margin-bottom: 2rem;' : ''}
`

const DefaultButton = styled.div`
width: 12.01rem;
height: 3.75rem;
border-radius: 30px;
font-family: Inter;
font-size: 1.2rem;
font-weight: 500;
line-height: 1.42rem;
letter-spacing: 0em;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
transition: 0.2s;
:hover{
    opacity: 0.8;
}
`

const NextButton = styled(DefaultButton)`
background: #5C49DD;
color: #f9f9f9;

`

const BackButton = styled(DefaultButton)`
color: #5C49DD;
background: #f9f9f9;
border: 1px solid #5C49DD;
`

const ContainerButtons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 2rem;
width: 31.875rem;
@media(max-width: 1650px){
    width: 50%;
}
@media(max-width: 700px){
        width: 26.25rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
margin-left: 15rem;
`
const FilesContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
margin-bottom: 2.5rem;

`

const DragAndDropContainer = styled.div`
margin-top: 1.5rem;
h1{
    color: #333333;
    font-size: 1.2rem;
    line-height: 1.45rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
`

const InnerDragAndDrop = styled.div`
cursor: pointer;
width: 26.315rem;
height: 14.66rem;
border: 1px dashed #6D6D6D;
border-radius: 0.625rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #6d6d6d0d;
h4{
    color: #6d6d6d;
    font-size: 1.052;
    font: inter;
    line-height: 1.27rem;
}
span{
    color: #5C49DD;
    text-decoration: underline;
}
`
interface AllFilesProps{
    isOpen: boolean;
}


const AllFiles = styled.div<AllFilesProps>`
margin-top: 1rem;
width: 19rem;
height: 14.66rem;;
display: flex;
flex-direction: column;
align-items; flex-start;
justify-content: space-between;

h2{
    color: #333333;
    font-size: 1.2rem;
    line-height: 1.45rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
h4{
    color: #5C49DD;
    font-size: 1.052;
    line-height: 1.27rem;
    cursor: pointer;
    ${props => props.isOpen ? 'margin-bottom: 1rem;' : ''}
}
@media(max-width: 835px){
    margin-left: 0rem;
}
`

const InnerFiles = styled.div`
z-index: 200;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding-top: 0.5rem;
width: 80%;
max-width: 80rem;
min-height: 2.45625rem;
height: auto;
margin-top: 0.3125rem;
margin-bottom: 0.9375rem;
@media(max-width: 1500px){
    margin-left: 0;
    margin-right: 0;
}
@media(max-width: 835px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

`

const AllFilesContainer = styled.div`
padding-top: 1rem;
padding-left: 1rem;
width: 21.4285rem;
height: auto;
max-height: 19.924rem;
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
overflow-y: hidden;

`
const AllFilesContent = styled.div`
overflow-y: scroll;
height: 14rem;
margin-bottom: 1rem;
::-webkit-scrollbar{
	width: 0.6rem;
	background-color: #f9f9f9;
}

`
const BackgroundPopup = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index:9999;
`

const LoadingContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
width: 108.27rem;
height: 55.789rem;
padding-top: 2.25rem;

h1,h2{
    color: #6D6D6D;
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.42rem;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1rem;
}

@media(max-width: 1650px){
    width: 90%;
}

`

export {
    Container,
    Title,
    Content,
    LeftContent,
    RightContent,
    Line,
    ProductName,
    ProductDescription,
    ContainerInputs,
    NextButton,
    BackButton,
    ContainerButtons,
    FilesContainer,
    DragAndDropContainer,
    InnerDragAndDrop,
    AllFiles,
    InnerFiles,
    AllFilesContainer,
    AllFilesContent,
    BackgroundPopup,
    TitleInput,
    LoadingContainer
}