import styled from "styled-components"

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 45rem;
height: 25rem;
background-color: blue;
z-index:100;
border-radius: 20px;
background-color: #f9f9f9;

h1{
    color: #333333;
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
    
}

button{
position: absolute;
bottom: 1.5rem;
right: 1.5rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}

}
`

const InnerContainer = styled.div`
    width: 100%;
    height: 22rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const LongInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17rem;
    textarea{
        resize: none;
        width: 38rem;
        height: 12rem;
        border-radius: 0.625rem;
        padding: 0.3125rem;
        margin-bottom: 1.25rem;
        border: 0.0625rem solid #00000050;
        font-family: 'Inter', sans-serif;
    }
`

const ClosePopup = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
`

export {
    Container,
    InnerContainer,
    LongInput,
    ClosePopup
}