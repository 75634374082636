import styled from "styled-components";
import seta from '../../assets/images/seta.png'


const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 45rem;
height: 30rem;
background-color: blue;
z-index:100;
border-radius: 20px;
background-color: #f9f9f9;

h1{
    color: #333333;
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
}

`
const Delegar = styled.div`

    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12.5rem;
    height: 3.125rem;
    background: #5c49dd;
    color: #ffffff;
    border-radius: 1.875rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    border: none;
    margin-left: 1.875rem;
    transition: 200ms;
    
    :hover{
        color: #5c49dd;
        background-color: #f9f9f9;
        border: 0.0625rem solid #5c49dd;
    }
    
`

const InnerContainer = styled.div`
width: 100%;
height: 22rem;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Input = styled.div`
width: 100%;
margin-top: 0.5rem;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1.5rem;
input{
    font-family: 'Inter', sans-serif;
    width: 38rem;
    height: 2.85625rem;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;

    padding-left: 0.625rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    @media(max-width: 700px){
        width: 18.75rem;
    }
    

}
input::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
    

}
`

const ClosePopup = styled.div`
position: absolute;
top: 1.5rem;
right: 1.5rem;
cursor: pointer;
`

const ListEditors = styled.div`
padding: 0.25rem 0 0.25rem;
background-color: #ffffff;
width: 40rem;
height: 14rem;
border: 0.0625rem solid #00000050;
border-radius: 0.625rem;
overflow-y: scroll;
::-webkit-scrollbar {
    display: none;
  };
-ms-overflow-style: none; /* IE and Edge */
scrollbar-width: none; /* Firefox */
`
const Back = styled.div`
position: absolute;
bottom: 1.5rem;
left: 1.5rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
color: #5c49dd;
background-color: #ffffff;
border: 0.0625rem solid #5c49dd;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #fff;
    background-color: #5c49dd;
}

`
const LongInput = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: space-between;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;

select{
    width: 19.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1.875rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    align-items: center;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${seta}) no-repeat center right #FFF;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 17.5rem 0.625rem; 
    :focus {
        outline: none;
      }
}

`

export {
Container,
ClosePopup,
ListEditors,
InnerContainer,
Input,
Back,
Delegar,
LongInput
}