import Infoproducts from './InfoProdutos'
import { useParams } from 'react-router-dom'
import DadosProduto from './DadosProduto'
import api from '../../services/api'
import { ReadOneProduct } from '../../interfaces'
import {
  Container,
  Title,
  Content,
  InnerContainer,
  BackgroundPopup,
} from './style'
import { useEffect, useState } from 'react'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import UpgradePlanPopup from '../../components/UpgradePlanPopup'


export default function Produto() {
  const { idProduto } = useParams()
  const idUrlProduto = idProduto ?? ''
  const token = localStorage.getItem('authToken') ?? '{}';
  const authToken = JSON.parse(token).access_token ?? ''
  const [loading, setLoading] = useState(true)
  const [showInputs, setShowInputs] = useState(false)
  const [infoProduct, setInfoProduct] = useState<ReadOneProduct>({id:0, product:'', product_name: '', description: '', short_description: '', deadline_in_days: 0, required_fields:[],picture:''})
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse();
  const [upgradePlan, setUpgradePlan] = useState(false)

  useEffect(() => {
    const promise = api.readOneProduct(authToken,Number(idUrlProduto))
  promise.then((response) => {
    setInfoProduct(response.data)
  }).catch((error) => {
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
  }).finally(() => {
    setLoading(false)
  })
   // eslint-disable-next-line
  },[authToken, idUrlProduto])
  


  return (
    <Container>

    {(upgradePlan) && 
      (<BackgroundPopup>
        {upgradePlan && <UpgradePlanPopup setUpgradePlan={setUpgradePlan}/>}
      </BackgroundPopup>)}

      {!showInputs && <Title>Produtos Disponíveis</Title>}
      {showInputs && <Title>Informações Necessárias</Title>}
      <Content>
        <InnerContainer>
          {!showInputs && <Infoproducts idProduto={idUrlProduto} setShowInputs={setShowInputs} infoProduct={infoProduct} loading={loading}/>}
          {showInputs && <DadosProduto setUpgradePlan={setUpgradePlan} idProduto={idUrlProduto} inputs={infoProduct.required_fields}/>}
        </InnerContainer>
      </Content>
    </Container>
  )
}


