
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { acertarDatas } from "../../utils";
import { OrderProps } from "../../interfaces";

export default function StepperLine({orderlogs}: OrderProps ) {

  /*const [estado, setEstado] = useState(0)
  const orders = orderlogs || [{id:'a',status:'',created_at:''}]
  
  const steps: Array<any> = []
  const open: any = []
  const in_production: any = []
  const awaiting_approval: any = []
  const closed: any = []

  for(const order of orders){
    if(order.status === 'open') open.push(order);
    else if(order.status === 'in_production') in_production.push(order);
    else if(order.status === 'awaiting_approval') awaiting_approval.push(order);
    else if(order.status === 'closed') closed.push(order)
  }
 
  useEffect(() => {
    if(open.length !== 0) {
      steps.push(open[0])
      setEstado(1)
    }
    if(in_production.length !== 0){
      steps.push(in_production[in_production.length - 1])
      setEstado(2)
    } else {
      steps.push({id:'b',status:'Em produção',created_at:'Em breve'})
    }
  
    if((awaiting_approval.length !== 0) && (in_production.length === awaiting_approval.length)){
      steps.push(awaiting_approval[awaiting_approval.length - 1])
      setEstado(3)
    } else {
      steps.push({id:'c',status:'Aguardando aprovação',created_at:'Em breve'})
    }
  
    if(closed.length !== 0){
      steps.push(closed[0])
      setEstado(4)
    } else{
      steps.push({id:'d',status:'Finalizado',created_at:'Em breve'})
    }
  },[])
  console.log(steps) */

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper 
      activeStep={orderlogs?.length} 
      alternativeLabel>
        {orderlogs?.map((label) => (
          <Step key={label.id}>
            <StepLabel>
                <h1>{label.status.name}</h1>
                <h1>{(label.created_at.includes("Em breve") ? (
                  label.created_at
                ): (
                  acertarDatas(label.created_at)
                ))}</h1>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
