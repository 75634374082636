import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'


const Container = styled.div`
background-color: #E5E5E5;
    min-height: calc(100vh - 4rem);
    height: auto;
    width: 100%;
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right ;
    background-size: 16.5625rem auto;
    background-color: rgba(0, 0, 0, 0.1);
`

const InnerContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;

`

const Content = styled.div`
margin-top: 1.875rem;
display: flex;

`

const Update = styled.div`
position: relative;
`

const Title = styled.div`
color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3.625rem;
`

const BackgroundPopup = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index:9999;
`

export {
    Container,
    Title,
    Content,
    InnerContainer,
    Update,
    BackgroundPopup
}