import { useState, useEffect } from 'react';
import api from '../../services/api';
import { ReadSugestedOrders } from '../../interfaces';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import InfoSugestoes from './InfoSugestoes';
import {
    Container,
    Title,
    InnerContainer,
    Buttons,
    SugerirConteudo
} from './style';
import { Link } from 'react-router-dom';

export default function SugestoesDeConteudo() {

    const token = localStorage.getItem("authToken") ?? '{}';
    const authToken = JSON.parse(token).access_token ?? '';
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? '';
    const [sugestoes, setSugestoes] = useState<ReadSugestedOrders[]>([])
    const {setErrorMessage, setTypeError} = usePopupTrueFalse()

    useEffect(() => {

        const setError = (error: any) => {
            setTypeError(false)
            if(typeof error.response?.data?.detail === 'string') {
                setErrorMessage(error.response?.data?.detail)
            } else {
                setErrorMessage('Tente novamente em instantes!')
            }
        }

        if(typeUser === 'company'){
            const promise = api.readOwnSugestedOrders(authToken)
            promise.then((response) => {
                setSugestoes(response.data)
            }).catch((error) => {
                setError(error)
            }).finally(() => {
                
            })
        }

        if(typeUser === 'admin'){
            const promise = api.readSugestedOrders(authToken)
            promise.then((response) => {
                setSugestoes(response.data)
            }).catch((error) => {
                setError(error)
            }).finally(() => {
                
            })
        }

        if(typeUser === 'editor'){
            const promise = api.readSugestedOrdersMadeByMe(authToken)
            promise.then((response) => {
                setSugestoes(response.data)
            }).catch((error) => {
                setError(error)
            }).finally(() => {
                
            })
        }
    }, [authToken, typeUser, setTypeError, setErrorMessage])

    return  (
        <Container>
            <Title>Sugestões de conteúdo</Title>

            {(typeUser === 'admin' || typeUser === 'editor') && (
                <Buttons>
                    <Link style={{textDecoration: 'none'}} to="/sugerir-conteudo">
                        <SugerirConteudo>
                            Nova sugestão
                        </SugerirConteudo>
                    </Link>
                </Buttons>  
            )}
    
            <InnerContainer>
                {sugestoes?.map((sugestao) => {
                    return <InfoSugestoes sugestao={sugestao}/>
                })}
            </InnerContainer>
        </Container>
    )
}