import styled from 'styled-components';

export const ModalWindow = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	overflow-y: scroll;
	width: 100vw;
	height: 100vh;
	background-color: rgba(63, 63, 63, .66);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		background-color: rgba(127, 127, 127, 0.125);
	}
`

export const ModalOverlay = styled.div`
	margin: .5rem;
	background-color: #f9f9f9;
	border-radius: 1rem;

    @media (max-width:585px) {
		margin: 0;
		width: 100%;
    }
`


