import { useEffect, useState } from 'react';
import Pagination from "@mui/material/Pagination";
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from "@mui/material/Stack";
import Users from './Users'
import {
    Container,
    Title,
    InnerContainer,
    Header,
    Companies,
    Image,
    Name,
    Email,
    ContainerPagination,
    Plan,
    Segmentation,
    Options,
    Role,
    SearchContainer,
    SearchInnerContainer,
    ContainerButton,
    PopupContainer,
    PopupEditContainer,
    LoadingContainer
} from './style'
import api from '../../services/api';
import { AllUsers } from '../../interfaces';
import { Search } from '@mui/icons-material';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import Popup from './Popup';
import PopupEdit from './PopupEdit';

export default function NewUsuarios() {
    const [users, setUsers] = useState<AllUsers[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('')
    const totalElements = users.length;
    const itemPorPage = 9;
    const totalPages = Math.ceil(totalElements / itemPorPage);
    const startIndex = (currentPage - 1) * itemPorPage;
    const endIndex = Math.min(startIndex + itemPorPage - 1, totalElements - 1);
    const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
    const [isLoading, setIsLoading] = useState(false)
    const [showPopupAddUser, setShowPopupAddUser] = useState(false)
    const [showPopupEdit, setShowPopupEdit] = useState(false)
    const [userEdit, setUserEdit] = useState({id:0, name:'', email:'', profile_picture:'', role:{id:0, name:''}})
    const filteredUsers = users.filter((user) => {
    const filterLowerCase = filter.toLowerCase();  
        return (
            (user?.name?.toLowerCase().includes(filterLowerCase) || 
            user?.email?.toLowerCase().includes(filterLowerCase) ||
            ((user?.company && user?.name) &&
              (
                user?.company?.segmentation?.segmentation?.toLowerCase().includes(filterLowerCase) ||
                user?.name.toLowerCase().includes(filterLowerCase) ||
                user?.company?.plan?.plan_name?.toLowerCase().includes(filterLowerCase)
              ))
            )
          );
        });
    const currentItems = filteredUsers.slice(startIndex, endIndex + 1);
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''


    useEffect(() => {
        const promise = api.readAllUsers(authToken)
        promise.then((response) => {
            setUsers(response.data)
        }).catch((error) => {
            console.error(error)
        })
    },[authToken])


    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
      };

      function updateRegisteredUsers(){
        setIsLoading(true)
        const promise = api.readAllUsers(authToken)
        promise.then((response) => {
          setUsers(response.data)
        }).catch((error) => {
          setTypeError(false)
          console.error(error)
        if(typeof error.response?.data?.detail === 'string'){
              setErrorMessage(error.response?.data?.detail)
        }else{
              setErrorMessage('Tente novamente em instantes!')
        }
        setShowPopup(true)
        }).finally(()=>{
          setIsLoading(false)
        })
      }
    
      function handlePopup(){
        setShowPopupAddUser(!showPopupAddUser)
      }

  return (
    <Container>
      <Title>Usuários</Title>
            <SearchContainer>
            <SearchInnerContainer>
                <Search/>
                <input type="text"
                placeholder="Procure um usuário por nome, email, plano, ..."
                value={filter}
                onChange={e => setFilter(e.target.value)}/>
                </SearchInnerContainer>
                <ContainerButton>
                    <button onClick={handlePopup}>Adicionar usuário</button>
                </ContainerButton>
            </SearchContainer>
      <InnerContainer>
        {showPopupAddUser && <PopupContainer>
            <Popup updateRegisteredUsers={updateRegisteredUsers} showPopupAddUser={showPopupAddUser} setShowPopupAddUser={setShowPopupAddUser}/>
        </PopupContainer>}
        {showPopupEdit && <PopupEditContainer>
            <PopupEdit updateRegisteredUsers={updateRegisteredUsers} showPopupEdit={showPopupEdit} setShowPopupEdit={setShowPopupEdit} userEdit={userEdit}/>
        </PopupEditContainer>}
        {isLoading && <LoadingContainer>Carregando...</LoadingContainer>}
        {(!showPopupAddUser && !showPopupEdit && !isLoading) &&
        <>
        <Header>
            <Image></Image>
            <Name>
                Nome 
            </Name>
            <Email>
                Email
            </Email>
            <Role>
                Tipo
            </Role>
            <Plan>
                Plano
            </Plan>
            <Segmentation>
                Segmentação
            </Segmentation>
            <Options>
                Opções
            </Options>
        </Header>
        <Companies>
            <Users user={currentItems[0]} setUserEdit={() => setUserEdit(currentItems[0])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[0]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[1]} setUserEdit={() => setUserEdit(currentItems[1])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[1]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[2]} setUserEdit={() => setUserEdit(currentItems[2])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[2]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[3]} setUserEdit={() => setUserEdit(currentItems[3])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[3]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[4]} setUserEdit={() => setUserEdit(currentItems[4])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[4]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[5]} setUserEdit={() => setUserEdit(currentItems[5])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[5]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[6]} setUserEdit={() => setUserEdit(currentItems[6])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[6]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[7]} setUserEdit={() => setUserEdit(currentItems[7])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[7]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
            <Users user={currentItems[8]} setUserEdit={() => setUserEdit(currentItems[8])} setIsLoading={setIsLoading} setShowPopupEdit={setShowPopupEdit} key={currentItems[8]?.id} updateRegisteredUsers={updateRegisteredUsers}/>
        </Companies>
        <ContainerPagination>
            <Stack spacing={2}>
                <Pagination
                renderItem={(item) => (
                    <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    />
                )}
                color="primary" 
                count={totalPages} 
                defaultPage={1}
                page={currentPage} 
                onChange={handleChange} />
            </Stack>
        </ContainerPagination>
        </>}
      </InnerContainer>
    </Container>
  )
}
