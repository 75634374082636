import styled from 'styled-components';
import mentionFundo from '../../../assets/images/mention_fundo.png'

export const ContentInputs = styled.div`
display: flex;
flex-wrap: wrap;
height: auto;
textarea:disabled {
  background-color: #fff;
  color: #000;
  
}

@media(max-width: 1275px){
    width: 62rem;
}

@media(max-width: 935px){
    width: 54rem;
}

@media(max-width: 835px){
    display: flex;
    flex-direction: column;
    width: 30rem;
}

@media(max-width: 700px){
    width: 30rem;
}
`

export const ContainerInputs = styled.div`
display: flex;
max-width: 104.8rem;
width: auto;
align-items: center;
justify-content: center;
display: inline-block;
height: auto;
@media(max-width: 1380px){
    width: 72rem;
}
@media(max-width: 1275px){
    width: 63rem;
}
@media(max-width: 935px){
    width: 54rem;
}

`

export const Buttons = styled.div`
padding-top: 2rem;
display: flex;
align-items: center;
justify-content: space-between;
`

export const ChangeButtons = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

export const Voltar = styled.button`
cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

export const Container = styled.div`
background-color: #E5E5E5;
min-height: calc(100vh - 4rem);
height: auto;
width: 100%;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right ;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-bottom: 4rem;
`

export const Title = styled.div`
font-weight: 600;
font-size: 1.5rem;
line-height: 1.8rem;
color: #333333;
width: 81rem;
margin-bottom: 2.7rem;
margin-top: 4.075rem;
    @media(max-width: 1380px){
        width: 72rem;
    }
    @media(max-width: 1275px){
        width: 63rem;
        
    }
    @media(max-width: 935px){
        width: 54rem;
    }
    @media(max-width: 835px){
        width: 32rem;
    }

`

interface InnerContainerProps {
    isLoading: boolean;
}

export const InnerContainer = styled.div<InnerContainerProps>`
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    width: 77rem;
    min-height: 43.1875rem;
    height: auto;
    margin-top: 2.625rem;
    margin-bottom: 2.5rem;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    margin-left: 0.625rem;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    @media(max-width: 1380px){
        width: 72rem;
    }
    @media(max-width: 1275px){
        width: 63rem;
        
    }
    @media(max-width: 935px){
        width: 54rem;
    }
    @media(max-width: 835px){
        width: 32rem;
    }

`
export const InformationContainer = styled.div`
padding-top: 1rem;
display: flex;
padding-bottom: 1.5rem;
`
export const CompanyImage = styled.div`
margin-right: 2rem;
`

export const CompanyName = styled.div`
display:flex;
align-items: center;
justify-content: center;
h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 2rem;
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`

export const CompanyPlan = styled.div`
display:flex;
align-items: center;
justify-content: center;
margin-right: 2rem;

h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 0.5rem;
    @media (min-width: 2000px) {
        width: 3rem;
        padding-right: 3rem;
    }
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`

export const CompanyOrders = styled.div`
display:flex;
align-items: center;
justify-content: center;
h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 2rem;
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`
export const CompanyDetails = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
@media(max-width: 1380px){
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    }
`

interface CompanyComunicationProps {
    hasPlan: boolean;
}


export const ComunicationButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
width: 19.75rem;
height: 2.65625rem;
border: none;
border-radius: 5.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1.1rem;
line-height: 1.21rem;
text-align: center;
@media(max-width: 835px){
    width: 18.75rem;
    margin-right: 2rem;
    text-align: center;
}
`

export const ChangePlanButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
width: 1.75rem;
height: 1.6rem;
border: none;
border-radius: 20.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1.1rem;
line-height: 1.21rem;
text-align: center;
@media(max-width: 835px){
    width: 18.75rem;
    margin-right: 2rem;
    text-align: center;
}
`


interface CompanyComunicationProps {
    hasPlan: boolean;
}

export const CompanyComunication = styled.div`
display:flex;
align-items: center;
justify-content: center;

h1{
    display: flex;
    align-items: flex-end;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    color: #5C49DD;
    margin-right: 1.5rem;
}
`

export const ComunicationSpan = styled.span`
font-weight: 300;
font-size: 1.125rem;
line-height: 1.5125rem;
color: #333333;
`
export const ComunicationPlanSpan = styled.span<CompanyComunicationProps>`
${props => props.hasPlan ? 'cursor: pointer;' : ''}
font-weight: 500;
color: #5C49DD;
display: flex;
align-items: center;
margin-left: 0.6rem;
`
export const Save = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
`

export const Discard = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: 0.1rem solid #5C49DD;
border-radius: 1.875rem;
background-color: #f9f9f9;
color: #5C49DD;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-right: 0.625rem;
`

export const ChooseTab = styled.div`
margin-top: 0.5rem;
margin-bottom: 2rem;
font-weight: 500;
font-size: 1.2rem;
span{
    cursor: pointer;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    :hover{
        color: #5c49dd;
    }
}
`

export const Line = styled.div`
    height: 0.0625rem;
    width: 100%;
    background-color: #6D6D6D50;
    margin-bottom: 0.78125rem;
    margin-top: 0.78125rem;
`

export const CompanySummariesContainer = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 1380px){
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
        }
`

export const CompanySummariesContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;


    textarea {
        font-family: 'Inter', sans-serif;
        padding: 0.5rem;
        resize: none;
        width: 100%;
        max-width: 80rem;
        min-height: 2.45625rem;
        height: auto;
        border: 0.0625rem solid #6D6D6D50;
        border-radius: 0.625rem;
    }
`

export const CompanySummary = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem; 
    margin-bottom: 1rem; 
    background-color: #fff;

`

export const CreatedAt = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
`;

export const Summary = styled.div`
    display: flex;
    align-items: flex-start; /* Alinhe o texto à esquerda */
    width: 50%;

    textarea {
        font-family: 'Inter', sans-serif;
        padding: 0.5rem;
        resize: none;
        width: 100%;
        max-width: 80rem;
        min-height: 2.45625rem;
        max-height: 4rem;
        height: auto;
        border: 0.0625rem solid #6D6D6D50;
        border-radius: 0.625rem;
    }
`;

export const SummaryTitle = styled.div`
    font-size: 1.3rem;
    font-weight: 500; /* Defina um peso de fonte mais forte */
    margin-bottom: 0.5rem; /* Reduza a margem inferior */
`;

export const Author = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
`

export const EditButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
    margin-right: 2rem;
`

export const SettingsButton = styled.div`
    :hover{
        cursor: pointer;
    }
`

export const DeleteButton = styled.div`
    :hover{
        cursor: pointer;
    }
`

export const NewSummaryButtonContainer = styled.div`
    width: 100%;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    display: flex;
    gap: 1rem;
    justify-content: end;
`

export const NewButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 10.75rem;
    height: 2.65625rem;
    border: none;
    border-radius: 1.875rem;
    background-color: #5C49DD;
    color:#f9f9f9;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
`

export const VoltarContainer = styled.div`

`

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    padding: .3rem 1rem;

    background-color: #DAD6F3;
    border-radius: .5rem;

    color: #5C49DD;
    font-weight: bold;
`
