import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api'
import {
  Container,
  ClosePopup,
  ListEditors,
  InnerContainer,
  Input,
  Back,
  Delegar
} from './style'
import EditorLayout from './EditorLayout';
import Loading from '../Loading';
import { DelegateOrderProps, EditorProps } from '../../interfaces';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';


export default function EditDelegateOrder({authToken, orderId, setDelegateOrder, setReload}: DelegateOrderProps){
  const [editors, setEditors] = useState<EditorProps[]>([])
  const [selectedUser, setSelectedUser] = useState<EditorProps | null>(null)
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse()
  const filteredEditors = editors.filter((editor) => 
    editor.user.name.toLowerCase().includes(filter.toLowerCase())
  )


  useEffect(() => {

    const promise = api.readEditors(authToken)
    promise.then((response) => {
      setEditors(response.data)
    }).catch((error) => {
      console.error(error)
    })
  },[authToken])

  
  function editDelegateService(){
    if(!selectedUser){
      setTypeError(false)
      setErrorMessage('Selecione um usuário para delegar esse pedido.')
      setShowPopup(true)
      return
    }
    setLoading(true)
    const data = {
        user_id: selectedUser?.user.id
    };
    const promise = api.editDelegateService(authToken, orderId, data)
    promise.then(() => {
      setDelegateOrder(false)
      setReload(true)
    }).catch((error) => {
      console.error(error)
    })
    setLoading(false)
  }

    if(loading){
      return(
        <Container>
          <Loading color="white" withText={true} />
        </Container>
      )
    }
  return(
    <Container>
    <h1>Para quem você deseja delegar?</h1>
    <ClosePopup onClick={() => setDelegateOrder(false)}><CloseIcon/></ClosePopup>
    <InnerContainer>
      <Input>
      <input
        type="text"
        placeholder="Digite o nome do usuário"
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      </Input>
      <ListEditors>
        {filteredEditors.map((editor) => {
          return(
            <div key={editor.id} onClick={() => setSelectedUser(editor)}>
              <EditorLayout key={editor.id} editor={editor} selectedUser={selectedUser}/>
            </div>
          )
        })}
      </ListEditors>
    </InnerContainer>
    
    <div>
    <Back onClick={() => setDelegateOrder(false)}><ArrowBackIcon/>Voltar</Back>
    <Delegar onClick={editDelegateService}>Editar redator</Delegar>
    </div>
    
</Container>
  )
}