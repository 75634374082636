import styled from "styled-components";

const Container = styled.div`
@media(max-width: 1550px){
    padding-bottom: 2.5rem;
}
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`

const Content = styled.div`
    box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    margin-top: 2.625rem;
    width: 90%;
    min-height: 43.1875rem;
    height: auto;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    margin-left: 0.625rem;
    display: flex;
    position: relative;
    > img{
        border-bottom-left-radius: 1.25rem;
        border-top-left-radius: 1.25rem;
        height: 43.1875rem;
        width: 25rem;
        object-fit: cover;
    }

    @media(max-width: 1550px){
        flex-direction: column;
        width: 90%;
        height: 100%;
        min-height: 40rem;
        justify-content: center;
        align-items: center;
        > img{
            display: none;
        }
    }
`

const Information = styled.div`
margin: 1.875rem 2rem 1.4375rem 2.8125rem;
padding-bottom: 3.125rem;
> h1{
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    line-height: 2.420625rem;
    margin-bottom: 1.1875rem;
}
> h3{
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.5125rem;
    color: #333333;
    strong{
        font-weight: bold;
    }
}
`

const Data = styled.div`
display: flex;
img{
    width: 5.375rem;
    height: 5.375rem;
}
justify-content: space-around;
margin-top: 3.125rem;

@media(max-width: 1550px){
    margin-bottom: 1.875rem;
}

@media (max-width: 750px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    
}

@media(max-width: 520px){
    padding-bottom: 2.5rem;
}
`

const Vector = styled.div`
display: flex;
margin-top: 0.625rem;
margin-bottom: 0.625rem;
`

const Text = styled.div`
margin-left: 0.625rem;
h1{
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.5125rem;
    color: #333333;
}
h2{
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.420625rem;
    color: #5C49DD;
}
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Buttons = styled.div`
position: absolute;
right: 1.875rem;
bottom: 1.25rem;
display: flex;
@media(max-width: 520px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`

const FinishOrder = styled.button`
@media(max-width: 520px){
margin-left: 0rem;
margin-top: 1.25rem;
}
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 13.75rem;
height: 3.125rem;
background: #5C49DD;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #5C49DD;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5C49DD;
}

`

const Back = styled.button`
cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

export {
    Container,
    Content,
    Information,
    Data,
    Vector,
    Text,
    Buttons,
    Back,
    FinishOrder
}