import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'


interface AbaProps{
    aba: number
}

const Container = styled.div<AbaProps>`
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right ;
    background-size: 16.5625rem auto;
    background-color: #E5E5E5;
    height: auto;
    min-height: calc(100vh - 4rem);
    ${props => props.aba === 0 ? '': 'padding-bottom: 4rem'};
    width: calc(100vw - 17.5rem);
    @media(max-width: 1200px){
        width: 100%;
    }
`

const Title = styled.div`
    color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3.5rem;
`

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 2.5rem;
    @media(max-width: 1320px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
}
`

const Dados = styled.div`
    width: 93.75rem;
    height: auto;
    min-height: 20rem;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    @media(max-width: 1530px){
        width: 83rem;
    }
    @media(max-width: 1390px){
        width: 79rem;
    }
    @media(max-width: 1330px){
        width: 60rem;
    }
    @media(max-width:835px){
        width:40rem;
    }
    @media(max-width: 565px){
        width: 33rem;
    }


`

export {
    Container,
    Title,
    InnerContainer,
    Dados
}