import styled from "styled-components";

const Container = styled.div`

    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 1600px){
        height: 100%;
        padding-bottom: 2.5rem;
    }
    @media(max-width: 1200px){
        width: 100%;
        
    }
`


const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 1320px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
}
`

const ContainerInputs = styled.div`
font-size: 1rem;
margin: 3rem auto 1.875rem;
width: 31.875rem;
@media(max-width: 700px){
        width: 26.25rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
`

const Dados = styled.div`
    box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    position: relative;
    width: 95%;
    min-height: 46.875rem;
    height: auto;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    margin-bottom: 10rem;
    @media(max-width: 1320px){
        height: auto;
        padding-bottom: 4rem;
        width: 37.5rem;
        margin-left: 0;
    }
    @media(max-width: 700px){
        width: 28.125rem;
        
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
`

const Button = styled.button`
position: absolute;
bottom: 0.9375rem;
right: 0.9375rem;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}

`

const Back = styled.button`
position: absolute;
bottom: 0.9375rem;
right: 14.5rem;
cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

const LoadingContainer = styled.div`
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
position: relative;
width: 95%;
min-height: 46.875rem;
height: auto;
background-color: #f9f9f9;
border-radius: 1.25rem;
margin-bottom: 10rem;
display: flex;
align-items: center;
justify-content: center;
@media(max-width: 1320px){
    height: auto;
    padding-bottom: 4rem;
    width: 37.5rem;
    margin-left: 0;
}
@media(max-width: 700px){
    width: 28.125rem;
    
}
@media(max-width: 470px){
    width: 21.875rem;
}
`

export {
    Container,
    InnerContainer,
    ContainerInputs,
    Dados,
    Button,
    Back,
    LoadingContainer
}