import Card from './Card'
import { Container, ContainerLoading} from './style'
import api from '../../../services/api'
import useAuth from '../../../hooks/useAuth'
import { ProductsInfo } from '../../../interfaces'
import { useEffect, useState } from 'react'
import { Loading } from '../../../components'
import artigo from '../../../assets/images/artigo-de-opniao.png'
import pressRelease from '../../../assets/images/press-release.png'
import sugestao from '../../../assets/images/sugestao-de-pauta.png'

export default function Cards() {
  
  const {token} = useAuth()
  const authToken = token?.access_token ?? ''
  const [products, setProducts] = useState<ProductsInfo[]>([{id:0, product:'', product_name:'', descripton:'', short_description:'', deadline_in_days:0, picture:''}])
  const [loading, setLoading] = useState(true)

  useEffect(()=> {
    const promise = api.readProducts(authToken)
    promise.then((response) => {
    setProducts(response.data)
  }).catch((error) => {
    console.error(error)
  }).finally(() =>{
    setLoading(false)
  })
// eslint-disable-next-line 
  },[])

  if (loading) {
    return (
      <ContainerLoading>
        <Loading color="black" withText={true} />
      </ContainerLoading>
    );
  }

  return (
    <Container>

      {products.map((product) => {
        if (product.product_name === "Artigo de Opinião") {
          return (
            <Card
              key={product.id}
              id={product.id}
              description={product.descripton}
              product={product.product}
              product_name={product.product_name}
              short_description={product.short_description}
              deadline_in_days={product.deadline_in_days}
              picture={artigo}
            />
          );
        }

        if (product.product_name === "Press Release") {
          return (
            <Card
              key={product.id}
              id={product.id}
              description={product.descripton}
              product={product.product}
              product_name={product.product_name}
              short_description={product.short_description}
              deadline_in_days={product.deadline_in_days}
              picture={pressRelease}
            />
          );
        }

        

        if (product.product_name === "Sugestão de Pauta") {
          return (
        
            <Card
              key={product.id}
              id={product.id}
              description={product.descripton}
              product={product.product}
              product_name={product.product_name}
              short_description={product.short_description}
              deadline_in_days={product.deadline_in_days}
              picture={sugestao}
            />
        
          )
        }


        return null
      })}
    </Container>
  );
}