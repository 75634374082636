import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png';

interface TabProps{
    tab: string;
}

const Container = styled.div`
    position: relative;
    width: 100%;
    min-height: calc(100vh - 4rem);
    height: calc(auto + 20rem);
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 16.5625rem auto;
    background-color: rgba(0, 0, 0, 0.1);
    background-color: #E5E5E5;
`

const Title = styled.div`
    color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-top: 3.75rem;
`

const InnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: center;
    @media(max-width: 1520px){
        flex-direction: column-reverse;
        align-items: center;
    }
`

const Main = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Content = styled.div<TabProps>`
    box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    ${props => props.tab === 'infoCompany' ? 'width: 77rem;' : 'width: 90%;'}
    min-height: 43.1875rem;
    height: auto;
    margin-top: 2.625rem;
    margin-bottom: 2.5rem;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    margin-left: 0.625rem;
    display: flex;
    @media(max-width: 1275px){
        width: 67rem;
    }
    @media(max-width: 935px){
        width: 60rem;
    }
    @media(max-width: 835px){
        width: 32rem;
    }
`

const Information = styled.div<TabProps>`
    ${props => props.tab === 'infoCompany' ? '' : 'width: 100%;'}
    min-height: 40rem;
    height: auto;
    margin: 1.875rem 2rem 1.4375rem 2.8125rem;
    > h1{
        font-weight: 500;
        font-size: 1.5rem;
        color: #333333;
        line-height: 2.420625rem;
        margin-bottom: 1.1875rem;
        > span{
            color: #6D6D6D;
        }
    }
    > h3{
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 1.5125rem;
        padding-left: 2rem;
        color: #333333;
        strong{
            font-weight: bold;
        }

        > span{
            font-weight: 500;
            color: #5C49DD;
            cursor: pointer;
        }
    }
`

const Data = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 750px){
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
`

const Line = styled.div`
    height: 0.0625rem;
    width: 98%;
    background-color: #6D6D6D50;
    margin-bottom: 0.78125rem;
    margin-top: 0.78125rem;
`

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 0.625rem;
    margin-bottom: 0.9375rem;
    margin-top: 2.5rem;

    @media(max-width: 895px){
        button{
            width: 7rem;
        }
    }

    @media(max-width: 620px){
        flex-direction: column-reverse;
        justify-content: center;
        padding-right: 0;
    }
`

const LeftButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Back = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

const Comment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    textarea {
        font-family: 'Inter', sans-serif;
        padding: 1rem; 
        resize: vertical; 
        width: 100%;
        max-width: 60rem; 
        min-height: 6rem; 
        border: 0.125rem solid #6D6D6D50;
        border-radius: 1rem; 
        margin-top: 1rem; 
        margin-bottom: 1.5rem;
        background-color: #f4f4f4; 
        color: #333;
        font-size: 1rem; 
        transition: border-color 0.3s, box-shadow 0.3s; 
        &:focus {
            border-color: #4285f4; 
            box-shadow: 0 0 0.5rem rgba(66, 133, 244, 0.25);
        }
        @media (max-width: 1500px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    h1 {
        color: #333;
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
`

const RightButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const Approve = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 15rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;
    font-size: 1rem;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

const BackgroundPopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
`

const Delete = styled(Approve)`
border: 0.0625rem solid #f9f9f9;
background-color: #5c49dd;
color: #f9f9f9;
transition: 200ms;   

:hover{
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    color:#5C49DD;
}

`

export {
    Container,
    Title,
    InnerContainer,
    Main,
    Content,
    Information,
    Data,
    Line,
    Buttons,
    LeftButtons,
    Back,
    Comment,
    RightButtons,
    Approve,
    BackgroundPopup,
    Delete
}