import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Loading } from '../../../components';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import api from '../../../services/api';
import {
  Container,
  ClosePopup,
  LongInput
} from './style'

interface SolicitarAlteracaoProps{
    setShowChange: any;
    idPedido: string;
    authToken: string;
    typeUser: string;
    setReload: (value: boolean) => void;
}

export default function SolicitarAlteracao({setShowChange, idPedido, authToken, typeUser, setReload}: SolicitarAlteracaoProps) {
  const [change, setChange] = useState('')
  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()

  function promiseHandling(promise: Promise<any>){
    promise.then(() => {
      setLoading(false)
      setReload(true)
    }).catch((error) => {
      console.error(error)
      setTypeError(false)
      if(typeof error.response?.data?.detail === 'string'){
            setErrorMessage(error.response?.data?.detail)
      }else{
            setErrorMessage('Tente novamente em instantes!')
      }
      setShowPopup(true)
      
    }).finally(() => {
      setShowChange(false)
    })
  }

  function sendChange(){
    if(change.length === 0){
       // eslint-disable-next-line
      {/* Adicionar um tratamento de dados */}
    }
    setLoading(true)
    const body = {
      description: change
    } 
    if(typeUser === 'company'){
      const promise = api.changeOrder(authToken, idPedido, body)
      promiseHandling(promise)

    } else if(typeUser === 'admin'){
      const promise = api.requestInternalChanges(authToken, idPedido, body)
      promiseHandling(promise)

    }    
  }


  if(loading){
    return(
      <Container>
        <Loading color='black' withText={true}/>
      </Container>
    )
  }

  return (
    <Container>
        <h1>O que você deseja alterar no pedido?</h1>
        <ClosePopup onClick={() => setShowChange(false)}><CloseIcon/></ClosePopup>
        <LongInput>
            <textarea placeholder='Digite seu pedido de alteração...'
            value={change}
            onChange={e => setChange(e.target.value)}></textarea>
        </LongInput>
        <button onClick={sendChange}>Solicitar Alteração</button>
    </Container>
  )
}
