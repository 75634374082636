import { createContext, useEffect, useState } from 'react'
import { ProviderProps, User, TokenObject, AuthContextProps } from '../interfaces';

const AuthContext = createContext<AuthContextProps>({
    user: {id: 0, name: '', profile_picture:'', email: '', role:{id: 0, name: ''}},
    setUser: () => {},
    token: {access_token:'', token_type: ''},
    setToken: () => {},

})

export const AuthProvider = ({ children }: ProviderProps) => {

    const authToken = JSON.parse(localStorage.getItem('authToken') ?? '{}')
    const authUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    const [user, setUser] = useState<User | null>(authUser);
    const [token, setToken] = useState<TokenObject | null>(authToken);

    useEffect(() => {
        const localToken = localStorage.getItem('authToken')
        const localUser = localStorage.getItem('user')
        if(localToken && localUser){
            setToken(JSON.parse(localToken))
            setUser(JSON.parse(localUser))
        } 
    },[])


    return (

        <AuthContext.Provider 
            value={{
            user, 
            setUser,
            token, 
            setToken
            }}
        >
            {children}
        </AuthContext.Provider>
    )
};

export default AuthContext;