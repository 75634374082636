import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`
const ContainerLoading = styled.div`
    width: 100%;
    height: 50rem;
`

export {
    Container,
    ContainerLoading
}