import Loading from '../../../components/Loading'
import {
  Container,
  InnerContainer,
  Close,
  FormsContainer,
  LongInput
} from "./style"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
interface PopupEditProps{
  showPopupEdit: boolean;
  setShowPopupEdit: any;
  userEdit: any
  updateRegisteredUsers: any;
}

interface userEdit {
  name: string;
  email: string;
  role_id: string;
  password?: string
  
}

export default function PopupEdit({showPopupEdit, setShowPopupEdit, userEdit, updateRegisteredUsers}: PopupEditProps) {
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token ?? ''
  const [nameInEdit, setNameInEdit] = useState(userEdit?.name)
  const [emailInEdit, setEmailInEdit] = useState(userEdit?.email)
  const [roleInEdit, setRoleInEdit] = useState(userEdit?.role?.id)
  const [passwordInEdit, setPasswordInEdit] = useState('')
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    setNameInEdit(userEdit?.name)
    setEmailInEdit(userEdit?.email)
    setRoleInEdit(userEdit?.role?.id)
    setPasswordInEdit('')
  },[userEdit])

  function closePopup(){
    setShowPopupEdit(false)
      setNameInEdit(userEdit?.name)
      setEmailInEdit(userEdit?.email)
      setRoleInEdit(userEdit?.role?.id)
      setPasswordInEdit('')
  }

  function updateUser(){
    setIsLoading(true)
    const body: userEdit = {
      name: nameInEdit,
      email: emailInEdit,
      role_id: roleInEdit
    }

    if(passwordInEdit.length !== 0){
      body["password"] = passwordInEdit
    }

    const promise = api.editOneUser(authToken, userEdit?.id, body)
    promise.then(() => {
      updateRegisteredUsers()
    }).catch((error) => {
      setTypeError(false)
      console.error(error)
      if(typeof error.response?.data?.detail === 'string'){
            setErrorMessage(error.response?.data?.detail)
      }else{
            setErrorMessage('Tente novamente em instantes!')
      }
      setShowPopup(true)
    }).finally(() => {
      setIsLoading(false)
      closePopup()
    })
  }

  return (
    <Container>
    <InnerContainer>

    <Close onClick={closePopup}>
      <CloseIcon />

    </Close>
        <FormsContainer>
        <LongInput>
          <label htmlFor="userName">Nome do usuário</label>
          <input
            autoComplete='off' 
            type="text" 
            placeholder="Nome do Usuário" 
            name="userName" id='userName' 
            value={nameInEdit}
            onChange={e => {
              setNameInEdit(e.target.value)}
            }
            />
          </LongInput>
          <LongInput>
          <label htmlFor="userEmail">Email do usuário</label>
          <input
            autoComplete='off' 
            type="text" 
            placeholder="Email do Usuário" 
            name="userEmail" id='userEmail' 
            value={emailInEdit}
            onChange={e => {
              setEmailInEdit(e.target.value)}
            }
            />
          </LongInput>
          <LongInput>
          <label htmlFor="newPassword">Nova senha</label>
          <input
            autoComplete='off' 
            type="text" 
            placeholder="Nova senha do Usuário" 
            name="newPassword" id='newPassword' 
            value={passwordInEdit}
            onChange={e => setPasswordInEdit(e.target.value)}/>
          </LongInput>
          <LongInput>
          <label htmlFor="typeUser">Tipo de usuário</label>
          <select value={roleInEdit} 
              onChange={e => {
                setRoleInEdit(e.target.value)
                }} name="typeUser" placeholder=''>
              <option value="">  </option>
              <option value="1">Administrador</option>
              <option value="2">Compania</option>
              <option value="3">Redator</option>
          </select>
            </LongInput>
            <button  onClick={updateUser}>Editar usuário</button>
          {isLoading && <Loading color="black" withText={true}/>}
        </FormsContainer>
    </InnerContainer>
  </Container>
  )
}
