import Cards from './Cards'
import * as S from './style'
import PageLayoutClient from '../../components/layout/PageLayoutClient';
import HorizontalGrabbableBlock from '../../components/HorizontalGrabbableBlock/HorizontalGrabbableBlock';

export default function Produtos() {
  return(
    <PageLayoutClient>
        <S.Title>
            <h2>Produtos Disponíveis</h2>
        </S.Title>

        <HorizontalGrabbableBlock>
            <S.Content>
                <Cards/>
            </S.Content>
        </HorizontalGrabbableBlock>
        
    </PageLayoutClient>
  )
}
