import {
    Container,
    Title,
    Text
} from './style'

export default function SugestaoPauta() {
  return (
    <Container>
        <Title>Sugestão de Pauta</Title>
        <Text>
            <p>
            Uma sugestão de pauta é uma proposta de ideia de reportagem ou matéria 
            jornalística que é sugerida por uma fonte externa ao veículo de comunicação. 
            O objetivo é sugerir um tema que possa ser relevante para a audiência do veículo 
            de comunicação e que ainda não tenha sido coberto.
            </p>
            <p>
            Para elaborar é preciso ter conhecimento do perfil do veículo de comunicação 
            e do público que ele atinge. É importante que o conteúdo seja pertinente, atual 
            e de interesse público, além de estar dentro da temática que o veículo de 
            comunicação cobre. Por exemplo, uma sugestão de pauta de um canal de televisão 
            que cobre esportes deve ser relacionada ao tema esportivo.
            </p>
            <p>
            As informações devem ser claras e objetivas. Ela deve apresentar dados e argumentos 
            que justifiquem a importância do assunto e oferecer fontes para uma possível entrevista. 
            Além disso, é importante que seja enviada com antecedência para que a equipe de produção 
            tenha tempo de avaliar a ideia e tomar uma decisão.
            </p>
            <p>
            A estrutura pode variar, mas geralmente ela inclui os seguintes elementos:
            </p>
            <ul>
                    <li>Título: uma frase curta e objetiva que resume o tema da sugestão de pauta.</li>
                    <li>Introdução: um parágrafo que apresenta o contexto e a importância do tema que será abordado na sugestão de pauta.</li>
                    <li>Desenvolvimento: um ou mais parágrafos que explicam o assunto em detalhes, apresentando dados, fatos e informações relevantes que possam sustentar a ideia.</li>
                    <li>Sugestão: uma proposta clara e objetiva do que poderia ser abordado na matéria jornalística, incluindo possíveis fontes de informação, dados adicionais e sugestões de abordagem do tema.</li>
                    <li>Contato: informações de contato da pessoa ou organização responsável pela sugestão de pauta, incluindo nome, telefone, e-mail e cargo.</li>
                    <li>Dados e estatísticas: são informações numéricas que ajudam a dar contexto e relevância para a notícia.</li>
                    <li>Contatos de imprensa: são os dados de contato da pessoa responsável por fornecer mais informações sobre a notícia divulgada.</li>
                </ul>
            <p>
            Caso a sugestão de pauta seja aceita, a equipe de produção do veículo de 
            comunicação pode entrar em contato com a fonte para esclarecer dúvidas, 
            obter mais informações e combinar uma entrevista. É importante que a fonte 
            esteja disponível para responder às demandas da equipe de produção, pois isso 
            pode ajudar a construir um bom relacionamento e colaboração futura.
            </p>
            <p>
            Por fim, é importante lembrar que nem todas são aceitas, pois depende do interesse 
            e da necessidade do veículo de comunicação no momento. Por isso, é importante ser 
            persistente e enviar com frequência, sempre com conteúdo relevante e interessante para 
            a audiência do veículo de comunicação.
            </p>
        </Text>
    </Container>
  )
}
