import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'

export const Container = styled.div`
    background-color: #E5E5E5;
    height: calc(100vh - 4rem);
    width: calc(100vw - 16.5rem);
    overflow: scroll;
    @media(max-width: 1200px){
        width: 100vw;
    }
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 17rem auto;
    background-color: rgba(0, 0, 0, 0.1);
    
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: start;
    height: auto;
    padding: 3rem;    
`

export const HeaderContainer = styled.div`
    display:flex;
    align-items: center;
    gap: 1rem;
`

export const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
`

export const TagBeta = styled.div`
    background-color: #5C49DD;
    color: #f9f9f9;
    padding: .5rem 1rem;
    border-radius: 1rem;
 
`

export const Results = styled.div`
    display: flex;
    flex-wrap:wrap;
    gap: 2rem;
`

export const NoticeContainer = styled.div`
    display: flex;
    flex-direction:column;

    align-items:center;
    justify-content:center;

    gap: 2rem;

    background-color: #f9f9f9;
    padding: 5rem 0;
    border-radius:1rem;

    width:100%;
    height:100%;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);

    h1{
        font-size: 1.5rem;
        font-weight:bold;
        color:#333333
    }
    span{
        font-size: 1rem;
        color:#333333

    }
`

export const HeaderFilters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    width:100%;
`

export const Box = styled.div`
    display: flex;
    align-items:center;

    gap: 2rem;
    
`
export const LastSearch = styled.span`
    font-size: 1rem;
    color: #6D6D6D;

    span{
        color: #333333;
        font-weight:bold;
    }
`
export const OrderBy = styled.div`
    display: flex;
    gap:1rem;
    align-items:center;

    p{
        color: #6D6D6D;
        font-size: 1rem;
    }
`
export const SelectOrderBy = styled.select`

    background-color: #F9F9F9;
    border: none;
    border-radius:2rem;
    font-family:Inter;
    color: #6D6D6D;
    padding: .2rem .5rem;
`
export const SearchInput = styled.div`
    display: flex;
    background-color: #F9F9F9;
    border: none;
    border-radius:.5rem;
    font-family:Inter;
    padding: .5rem .5rem;

    input{
        background-color: transparent;
        border: none;
        font-family:Inter;
        outline:transparent;
        width: 20rem;
    }
`
