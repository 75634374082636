import { useMemo } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { OptionProps } from '../../interfaces';
import {
  Container,
  InnerContainer,
  Text
} from './style'

export default function Option({ iconName, propriedade, href }: OptionProps) {
  const Icon = useMemo(() => {
    switch (iconName) {
      case 'cart':
        return require('@mui/icons-material/ShoppingCart').default;
      case 'pendingActions':
        return require('@mui/icons-material/PendingActions').default;
      case 'person':
        return require('@mui/icons-material/Person').default;
      case 'settings':
        return require('@mui/icons-material/Settings').default;
      case 'help':
        return require('@mui/icons-material/Help').default;
      case 'manageAccounts':
        return require('@mui/icons-material/ManageAccounts').default;
      case 'business':
        return require('@mui/icons-material/Business').default;
      case 'barChart':
        return require('@mui/icons-material/BarChart').default;
      case 'notificationsNone':
        return require('@mui/icons-material/NotificationsNone').default;
      case 'adminPanelSettings':
        return require('@mui/icons-material/AdminPanelSettings').default;
      case 'group':
        return require('@mui/icons-material/Group').default;
      case 'mode':
        return require('@mui/icons-material/Mode').default;
      case 'logout':
        return require('@mui/icons-material/Logout').default;
      case 'Apartment':
        return require('@mui/icons-material/Apartment').default;
      case 'bulb':
        return require('@mui/icons-material/Lightbulb').default;
        case 'fire':
            return require('@mui/icons-material/LocalFireDepartment').default;
      default:
        return null;
    }
  }, [iconName]);

  const location = useLocation()
  const navigate = useNavigate()
  const logout = iconName === "logout" ? true : false;
  const isThisPage = location.pathname?.includes(href);

  
    function removeLocalStorage(){
      if(logout){
        localStorage.removeItem("authToken")
        localStorage.removeItem("user")
        localStorage.removeItem("planInfo")
        localStorage.removeItem("orders")
        navigate('/login')
      }
    }

  return (
    <Container>
      <Link style={{textDecoration: 'none'}} to={`${logout? "/login" : href}`}>
          <InnerContainer 
            onClick={removeLocalStorage}
            logout={logout}
            isThisPage={isThisPage}>
            <div>
              {Icon && <Icon/>}
            </div>
            <Text>
              {propriedade}
            </Text>
            </InnerContainer>
      </Link>
    </Container>
  );
}































/*
import React from 'react'
import styles from '@/../styles/dashboard/sidebar/Option.module.scss'
import Icon from '@mui/material/Icon';
import { ShoppingCart, PendingActions, Person, Settings, Help, Logout } from '@mui/icons-material';



interface OptionProps {
    iconName: string;
    iconColor: string;
    label: string;
  }

export default function Option({ iconName, iconColor, label }: OptionProps) {
    const IconComponent = iconMap[iconName];

  return (
     <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.icon}>
          <Icon style={{ color: iconColor }}>
            <IconComponent />
          </Icon>
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  )
}

const iconMap: Record<string, any> = {
    shopping_cart: ShoppingCart,
    pending_actions: PendingActions,
    person: Person,
    settings: Settings,
    help: Help,
    logout: Logout,
  };



*/