import styled from 'styled-components'

interface InnerContainerProps {
    selected: boolean;
}

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

const InnerContainer = styled.div<InnerContainerProps>`
display: flex;
cursor: pointer;
margin: 0.25rem 0 0.25rem;
background-color: ${props => props.selected ? '#c4c4c4' : '#e5e5e5'};
width: 39rem;
height: 3rem;
border-radius: 0.625rem;
`

const Image = styled.div`
width: 5rem;
display: flex;
align-items: center;
justify-content: center;
img{
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
}
`

const Name = styled.div`
width: 15rem;
display: flex;
align-items: center;
justify-content: center;
`

const Email = styled.div`
width: 19rem;
display: flex;
align-items: center;
justify-content: center;
`

export {
    Container,
    InnerContainer,
    Image,
    Name,
    Email,
}