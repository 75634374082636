import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'
import seta from '../../assets/images/seta.png'

const Container = styled.div`
background-color: #E5E5E5;
min-height: calc(100vh - 4rem);
height: auto;
width: 100%;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
`

const ContainerLoading = styled.div`
height: calc(100vh - 4rem);
display: flex;
align-items: center;
justify-content: center;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);

`

const Title = styled.div`
color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3.5rem;
    padding-bottom: 2.625rem;
`

const InnerContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    padding: 1rem 2rem;
    gap: 1rem;
`

const LongInput = styled.div`
flex: 1;
display: flex;
max-width: 21rem;
align-items: center;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;

label{
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

select{
    min-width: 12.5rem;
    width: 14.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${seta}) no-repeat center right #FFF;
    font-size: 0.9375rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 11.5rem 0.625rem; 

}


input::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
}
`
const Filter = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding-bottom: 0.8rem;
@media(max-width: 1650px){
    flex-wrap: wrap;
}
`

const SearchContainer = styled.div`
position: relative;
width:28.64rem;
margin-bottom: 0.95rem;
`

const SearchContent = styled.div`
border-radius: 10px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
width: 24.64rem;
height: 3rem;
display: flex;
align-items: center;
justify-content: flex-start;

input{
    width: 20rem;
    border: none;
    background: #f9f9f9;
    height: 2rem;
    padding-left: 1rem;

    :focus{
        outline: none;
    }

    ::placeholder{
        color: rgba(109, 109, 109, 0.40);
        font-family: Inter;
        font-size: 1.2rem;
        font-weight: 500;
    }
}

svg{
    margin-left: 1rem;
    color: #6D6D6D;
    width: 1.27rem;
    height: 1.27rem;
}
`

const SearchButton = styled.div`
border-radius: 10px;
background: #5C49DD;
width: 6.24rem;
height: 3rem;
color: #FFF;
font-family: Inter;
font-size: 1.2rem;
font-weight: 500;
position: absolute;
top: 0;
right: 0;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`

const CompanyFilter = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-left: 2.5%;
margin-right: calc(2.5% - 1.875rem);
@media(max-width: 1500px){
    margin-right: 2.5%;
}
`

export {
    Container,
    InnerContainer,
    Title,
    LongInput,
    Filter,
    ContainerLoading,
    SearchContent,
    SearchContainer,
    SearchButton,
    CompanyFilter
}