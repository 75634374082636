
import {Link} from 'react-router-dom'
import * as S from './style'
import { ProductsInfo } from '../../../../interfaces'

export default function Card({id, product_name, short_description,picture}: ProductsInfo) {

    const handleTextButton = () => {
        switch (product_name) {
            case "Press Release":
                return "Peça seu press release";

            case "Sugestão de Pauta":
                return "Peça sua sugestão de pauta";

            case "Artigo de Opinião":
                return "Peça seu artigo de opinião";

            default: 
                return "Pedir";
        }
    }
    
  return (
    <S.Container>
      <S.Image>
        <img src={picture} alt="Artigo de opinião"/>
      </S.Image>
      <S.Data>
        <S.Information>
          <h1>{product_name}</h1>
          <h3><div dangerouslySetInnerHTML={{ __html: short_description }} /></h3>
        </S.Information>
        <S.ButtonContainer>
            <Link style={{textDecoration: 'none'}} to={`/produtos/${id}`}>
              <S.MoreInformation>{handleTextButton()}</S.MoreInformation>
            </Link>
        </S.ButtonContainer>
      </S.Data>
    </S.Container>
  )
}
