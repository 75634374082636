import * as S from './style'
import Launch from '../../../assets/images/launch.svg'

interface BannerProps{
  plan: string | undefined
}

export default function Banner({plan}: BannerProps) {

  const nextPlan = plan === "StartUp" ? "Premium" : plan === "Premium" ? "Enterprise" : '';
  const nextTotalOrders = plan === "StartUp" ? '3 conteúdos' : plan === "Premium" ? 'conteúdos ilimitados' : '';
  const urlWhatsapp = 'https://api.whatsapp.com/send?phone=5511985082539'

  function openWhatsapp(){
    window.open(urlWhatsapp,'_blank')
  }
  
  return (
    <S.Container>
        {plan !== "Enterprise"  &&
            <S.InnerContainer>
                <S.Title>
                    Contrate o Plano {nextPlan} e tenha acesso a {nextTotalOrders} por mês!
                </S.Title>
                <S.Button onClick={openWhatsapp}>
                    Contrate agora
                </S.Button>
            </S.InnerContainer>
        }
        { plan === "Enterprise" &&
            <S.InnerContainer>
                <S.Title>
                    Bem-vindo ao Plano Enterprise!
                </S.Title>
                <S.Title>
                    Aproveite mais conteúdos e alcance novos patamares!
                </S.Title>
            </S.InnerContainer>
        }
        <S.Launch src={Launch} width={200}/>
    </S.Container>
  )
}
