import styled from "styled-components";

const LessDetails = styled.div`
cursor: pointer;
display: flex;
align-items: center;
font-weight: 500;
font-style: italic;
font-size: 1rem;
line-height: 1.27rem;
color: #5C49DD;
margin-bottom: 0.5rem;
img{
    width: 1rem;
    margin-right: 0.5rem;
}
`

const MoreDetails = styled.div`
position: absolute;
cursor: pointer;
display: flex;
align-items: center;
font-weight: 500;
font-style: italic;
font-size: 1rem;
line-height: 1.27rem;
color: #5C49DD;
margin-bottom: 0.5rem;
img{
    width: 1rem;
    margin-right: 0.5rem;
}
`


const StepperContainer = styled.div`
cursor: pointer;

}
`

interface CommentBoxProps {
    showDetails: boolean;
    hasMessage: boolean;
    hasComment: boolean;
    showThisComment: boolean;
}

function getMarginTopCommentBox({showDetails, hasMessage, hasComment, showThisComment}: CommentBoxProps) {
    if(hasMessage && hasComment){
        return "1rem"
    } else if(hasMessage && !hasComment){
        return "1rem"
    } else {
        if(hasComment && !showDetails && !showThisComment){
            return "1.7rem"
        } else if(hasComment && showDetails && !showThisComment){
            return "1.7rem"
        }
    }
}


const CommentBox = styled.div<CommentBoxProps>`
margin-top: ${props => {
        return getMarginTopCommentBox(props)
}};
width: 22.93rem;
height:2.63rem;
border-radius: 0.75rem;
background-color: #6d6d6d1a;
display: flex;
align-items: center;
`

const CommentInput = styled.div`
input{
    width: 19.1rem;
    height: 2.63rem;
    background-color: #ffffff00;
    border: none;
    padding-left: 1.2rem;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 1.27rem;
    :focus {
        outline: none;
      }
    ::placeholder {
        font-weight: 400;
        font-size: 1.05rem;
        line-height: 1.27rem;
      }
}


`

const CommentButton = styled.div`
cursor: pointer;
width: 2.63rem;
height: 2.63rem;
border-radius: 0.75rem;
background-color: #5C49DD;
display: flex;
align-items: center;
justify-content: center;
img{
    width: 1.33rem;
}
`
interface MessageContainerProps {
    showDetails: boolean;
    showThisComment: boolean;
    hasComment: boolean;
}

function getMarginTopMessageContainer({showDetails, showThisComment, hasComment}: MessageContainerProps) {
    if(showDetails && !showThisComment && hasComment){
        return "2rem" 
    } else if(!showThisComment && !hasComment){
        return "0rem"
    } else if(!showDetails && !showThisComment && hasComment){
        return "2rem"
    } else if(showDetails && showThisComment){
        return "1rem"
    }
}

const MessageContainer = styled.div<MessageContainerProps>`
font-size: 0.8rem;
margin-top: ${props => getMarginTopMessageContainer(props)};

`

const CommentText = styled.div`
position: relative;
`

const RemoveCommentText = styled.div`
cursor: pointer;
svg{
    width: 1rem;
}
border-radius: 50%;
background-color: #6d6d6d30;
width: 1.2rem;
height: 1.2rem;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: -0.3rem;
right: -0.5rem;
`

export {
LessDetails,
StepperContainer,
MoreDetails,
CommentBox,
CommentInput,
CommentButton,
MessageContainer,
CommentText,
RemoveCommentText
}