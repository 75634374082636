import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, useParams} from 'react-router-dom'
import Loading from '../../components/Loading';
import { useEffect, useRef, useState } from 'react';
import * as S from './style'
import api from '../../services/api';
import { ReadOneOrder } from '../../interfaces';
import SolicitarAlteracao from './SolicitarAlteracao';
import { acertarDatas } from '../../utils';
import PopupConfirmation from '../../components/PopupConfirmation';
import CompanyInformation from './CompanyInformation';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import InformationContainer from '../../components/InformationContainer';
import StepperVertical from '../../components/StepperVertical';
import Files from './Files';
import Banner from './Banner';
import SendOrderToRevision from '../../components/SendOrderToRevision';
import DragAndDropUploader from '../../components/DragAndDrop';
import setabaixo from '../../assets/images/setabaixo.png';
import setacima from '../../assets/images/setacima.png';
import { useMediaQuery } from '@mui/material';
import PopupConfirmationComment from '../../components/PopupConfirmationComment';
import ConfirmSendOrder from '../../components/ConfirmSendOrder';
import PublishedNews from './PublishedNews';
import AddPublishedNews from '../../components/AddPublishedNews';
import PopupGPT from '../../components/PopupGPT';
import PopupConfirmationPublishedNews from '../../components/PopupConfirmationPublishedNews/PopupConfirmationComment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PopupDeleteConfirmation from '../../components/PopupDeleteConfirmation';
import EditIcon from '@mui/icons-material/Edit';
import EditDelegateOrder from '../../components/EditDelegateOrder';
import DelegateOrder from '../../components/DelegateOrder';

export default function PedidoDetalhado() {
  const { idPedido } = useParams()
  const idUrlPedido = idPedido ?? ''
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token as string ?? ''
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''
  const userId = JSON.parse(user).id ?? ''
  const [loading, setLoading] = useState(true)
  const [etapa, setEtapa] = useState('')
  const [tab, setTab] = useState('infoOrder')
  const [infoOrder, setInfoOrder] = useState<ReadOneOrder>()
  const [userOrderFiles, setUserOrderFiles] = useState<OrderFile[]>()
  const [publishedNews, setPublishedNews] = useState<PublishedNews[]>([])
  const [orderLink, setOrderLink] = useState('')
  const [showChange, setShowChange] = useState(false)
  const [showConfirmDeleteFile, setShowConfirmDeleteFile] = useState(false)
  const [fileInDeleteConfirmation, setFileInDeleteConfirmation] = useState('')
  const [reload, setReload] = useState(true)
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [delegateOrder, setDelegateOrder] = useState(false)
  const [editDelegateOrder, setEditDelegateOrder] = useState(false)

  const [sendOrderToRevision, setSendOrderToRevision] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
  const [showMoreFiles, setShowMoreFiles] = useState(false)
  const isEditorOrAdmin = typeUser === 'admin' || typeUser === 'editor'
  const isScreenSmall = useMediaQuery('(max-width:1520px)');
  const [showMoreLogs, setShowMoreLogs] = useState(false);
  const canSeeDragAndDrop = typeUser === 'company' || typeUser === 'admin'
  const [showConfirmDeleteComment, setShowConfirmDeleteComment] = useState(false)
  const [commentInDeleteConfirmation, setCommentInDeleteConfirmation] = useState('')
  const [planCompany, setPlanCompany] = useState('')
  const [confirmDeletePublishedNews, setConfirmDeletePublishedNews] = useState(false)
  const canRemovePublishedNews = typeUser === 'admin' || typeUser === 'editor'
  const [confirmSendOrder, setConfirmSendOrder] = useState(false)
  const [addPublishedNews, setAddPublishedNews] = useState(false)
  const [showPopupGPT, setShowPopupGPT] = useState(false)
  const [showDeleteOrder, setShowDeleteOrder] = useState(false)

  const [publishedNewsInConfirmation, setPublishedNewsInConfirmation] = useState<PublishedNewsInConfirmation>({id: 0, title: ''})
  const statusUntilApproval = ['open', 'delegated', 'in_production', 'awaiting_approval_internal', 'awaiting_changes_internal']
  const thisStatusIsUntilApproval = statusUntilApproval.includes(infoOrder?.status?.status ?? '')

  interface OrderFile{
    id: number;
    file_url: string;
  }

  interface PublishedNewsInConfirmation{
    id: number,
    title: string
  }

  interface PublishedNews{
    id: number;
    url: string;
  }

  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
  }

  useEffect(() => {
    if(reload === true){
    if(typeUser === 'company'){
    const promise = api.readOneOrder(authToken, idUrlPedido)
    promise.then((response) => {
      const userFiles = response.data?.order_files
      setUserOrderFiles([...userFiles])
      setOrderLink(response.data?.ready_order[0]?.order_link)
      setInfoOrder(response.data)
      setPublishedNews(response.data?.published_news)
      setEtapa(response.data.status.status)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })}
    else if(typeUser==='admin'){
      const promise = api.readOneOrderAdmin(authToken, idUrlPedido)
      promise.then((response) => {
        setPlanCompany(response.data?.company?.plan?.plan_name ?? '')
        const userFiles = response.data?.order_files
        setUserOrderFiles([...userFiles])
        setOrderLink(response.data?.ready_order[0]?.order_link)
        setInfoOrder(response.data)
        setEtapa(response.data.status.status)
        setPublishedNews(response.data?.published_news)
      }).catch((error) => {
        setError(error)
      }).finally(() =>{
        setLoading(false)
      })
    } else if(typeUser === 'editor'){
      const promise = api.readOneOrderDelegated(authToken, idUrlPedido)
      promise.then((response) => {
        setPlanCompany(response.data?.company?.plan?.plan_name ?? '')
        const userFiles = response.data?.order_files
        setUserOrderFiles([...userFiles])
        setOrderLink(response.data?.ready_order[0]?.order_link)
        setInfoOrder(response.data)
        setEtapa(response.data.status.status)
        setPublishedNews(response.data?.published_news)
      }).catch((error) => {
        setError(error)
      }).finally(() => {
        setLoading(false)
      })
    }
    setReload(false)
  }
    // eslint-disable-next-line
  },[authToken, idUrlPedido, typeUser, reload])

  


  if(loading){
    return (
      <S.Container>
      <Loading color="black" withText={true}/>
      </S.Container>
    )
  }

  return (
    <S.Container>
      {(showChange || showConfirmDeleteFile || delegateOrder || sendOrderToRevision || showConfirmDeleteComment || confirmSendOrder || addPublishedNews || confirmDeletePublishedNews || showPopupGPT || showDeleteOrder || editDelegateOrder) && 
      (<S.BackgroundPopup>
        {showChange && <SolicitarAlteracao setShowChange={setShowChange} authToken={authToken} idPedido={idUrlPedido} typeUser={typeUser} setReload={setReload}/>}
        {showConfirmDeleteFile && <PopupConfirmation typeUser={typeUser} setReload={setReload} authToken={authToken} setShowConfirmDelete={setShowConfirmDeleteFile} fileInDeleteConfirmation={fileInDeleteConfirmation} orderId={idPedido ?? ''}/>}
        
        {delegateOrder && <DelegateOrder setDelegateOrder={setDelegateOrder} authToken={authToken} orderId={idUrlPedido} setReload={setReload}/>}
        {editDelegateOrder && <EditDelegateOrder setDelegateOrder={setEditDelegateOrder} authToken={authToken} orderId={idUrlPedido} setReload={setReload}/>}
        
        {sendOrderToRevision && <SendOrderToRevision setSendOrderToRevision={setSendOrderToRevision} authToken={authToken} orderId={idUrlPedido} userId={userId} setReload={setReload}/>}
        {showConfirmDeleteComment && <PopupConfirmationComment setReload={setReload} authToken={authToken} userId={userId} setShowConfirmDelete={setShowConfirmDeleteComment} commentInDeleteConfirmation={commentInDeleteConfirmation} orderId={idPedido ?? ''}/>}
        {confirmSendOrder && <ConfirmSendOrder setConfirmSendOrder={setConfirmSendOrder} authToken={authToken} orderId={idUrlPedido} orderLink={orderLink} setReload={setReload} />}
        {addPublishedNews && <AddPublishedNews setAddPublishedNews={setAddPublishedNews} authToken={authToken} orderId={idUrlPedido} setReload={setReload}/>}
        {confirmDeletePublishedNews && <PopupConfirmationPublishedNews setConfirmDeletePublishedNews={setConfirmDeletePublishedNews} publishedNewsInConfirmation={publishedNewsInConfirmation} publishedNews={publishedNews} setPublishedNews={setPublishedNews} authToken={authToken} userId={userId} setShowConfirmDelete={setConfirmDeletePublishedNews} orderId={idPedido ?? ''}/>}
        {showPopupGPT && <PopupGPT order={infoOrder} setShowPopupGPT={setShowPopupGPT}  authToken={authToken} setReload={setReload}/>}
        {showDeleteOrder && <PopupDeleteConfirmation typeUser={typeUser} authToken={authToken} setShowConfirmDelete={setShowDeleteOrder} orderId={idPedido ?? ''}/>}
      </S.BackgroundPopup>)}

        
        <S.InnerContainer>
        <S.Main>
        {publishedNews?.length > 0 && (
        <>
        <S.Title>Matérias publicadas</S.Title>
          <S.PublishedNewsContainer>
          
          {publishedNews?.map(item => {
            return(
              <PublishedNews 
                  canRemovePublishedNews={canRemovePublishedNews} 
                  setPublishedNewsInConfirmation={setPublishedNewsInConfirmation}
                  setConfirmDeletePublishedNews={setConfirmDeletePublishedNews} 
                  authToken={authToken} 
                  orderId={idUrlPedido} 
                  key={item.id} 
                  id={item.id} 
                  url={item.url} 
                  tab={tab} />
            )
          })}
          </S.PublishedNewsContainer>
          </>)}
          <S.Title>Detalhes do pedido</S.Title>
        <S.Content tab={tab}> 
            <S.Information tab={tab}>
            {tab === 'infoCompany' && 
          <CompanyInformation setTab={setTab} tab={tab} authToken={authToken} idCompany={infoOrder?.company?.id}/>}
            {tab === 'infoOrder' && <>
                <h1>
                  {`${infoOrder?.product.product_name}`} <span>{`#${infoOrder?.id}`}</span> 
                </h1>
                {typeUser === 'company' && <h3>
                <div dangerouslySetInnerHTML={{ __html: infoOrder?.product.description ?? '' }} />
                </h3>}
                
                {(typeUser === 'editor' || typeUser === 'admin') && 
                <h3>
                  Cliente: <span onClick={() => setTab('infoCompany')}>{`${infoOrder?.company?.user?.name} >`}</span>
                </h3>}
                {typeUser === 'admin' && 
                     infoOrder?.editor?.user?.name ?
                        <h3>
                            Redator: {`${infoOrder?.editor?.user?.name}`} <S.EditButton onClick={() => setEditDelegateOrder(true)}><EditIcon sx={{ color: "#6D6D6D", fontSize: 12 }}/></S.EditButton>
                        </h3>
                        :
                        <h3>
                            Redator: Nenhum redator vinculado
                        </h3>
                    
                }
                <br/>
                
                <S.Data>
                  {infoOrder?.order_info.map((info) => {
                    return(
                      <InformationContainer 
                        key={info.id} fieldName={info.field.field_name} value={info.value} />
                    )
                  })}

                  {(infoOrder?.order_internal_change_requests && infoOrder?.order_internal_change_requests.length > 0 && isEditorOrAdmin) && infoOrder?.order_internal_change_requests.map((change) => {
                    return(
                      <InformationContainer 
                        key={change.id} fieldName={`Mudança interna solicitada em ${acertarDatas(change.created_at)}`} value={change.description} />
                    )
                  })}

                  {((infoOrder?.change_requests && infoOrder?.change_requests.length > 0)) && infoOrder?.change_requests.map((change) => {
                    return(
                      <InformationContainer 
                        key={change.id} fieldName={`Mudança solicitada em ${acertarDatas(change.created_at)}`} value={change.description} />
                    )
                  })}

                </S.Data>
                
                
                <S.FilesContainer>
                  <S.InnerFiles>
                    {canSeeDragAndDrop && <S.DragAndDropContainer>
                      <h1>Arquivos extras</h1>
                      <DragAndDropUploader 
                      authToken={authToken} 
                      orderId={idPedido ?? ''}
                      setReload={setReload}
                       />
                    </S.DragAndDropContainer>}

                    <S.AllFiles canSeeDragAndDrop={canSeeDragAndDrop} filesLength={userOrderFiles?.length ?? 0} isOpen={showMoreFiles}>
                      <div>
                    <h2>Arquivos adicionados</h2>
                    {(showMoreFiles === false) && userOrderFiles && userOrderFiles?.slice(0, 3)?.map((file) => {
                      return(
                        <Files key={file.id} file={file.file_url} typeUser={typeUser} orderId={idUrlPedido} setFileInDeleteConfirmation={setFileInDeleteConfirmation} setShowConfirmDelete={setShowConfirmDeleteFile}/>
                      )
                    })}
                    {(showMoreFiles === true) && userOrderFiles && (
                      <S.AllFilesContainer>
                        <h4 onClick={() => setShowMoreFiles(false)}>Mostrar menos</h4>
                        <S.AllFilesContent>
                        {userOrderFiles?.map((file) => {
                          return(
                            <Files key={file.id} file={file.file_url} typeUser={typeUser} orderId={idUrlPedido} setFileInDeleteConfirmation={setFileInDeleteConfirmation} setShowConfirmDelete={setShowConfirmDeleteFile}/>
                          )
                          })}
                        </S.AllFilesContent>
                      </S.AllFilesContainer>
                    )}
                    {userOrderFiles && userOrderFiles?.length === 0 && (<>
                    Nenhum arquivo adicionado
                    </>)}
                    </div>
                    {userOrderFiles && userOrderFiles?.length > 3 && (showMoreFiles === false) && <h4 onClick={() => setShowMoreFiles(true)}>Mostrar todos</h4>}
                    </S.AllFiles>
                  </S.InnerFiles>
                </S.FilesContainer>
                
                <S.Line></S.Line>
                
                <S.Buttons>
                  <S.LeftButtons>
                    <Link style={{textDecoration: 'none'}} to="/pedidos">
                        <S.Back><ArrowBackIcon/>Voltar</S.Back>
                    </Link>
                  </S.LeftButtons>
                </S.Buttons>
                </>}
            </S.Information>
            
        </S.Content>
        </S.Main>

        <S.RightBar tab={tab}>
          <S.Sidepopup>
          <S.InfoStatus>
              <h1>Status do Pedido</h1>
              <S.Timestep>
                  <S.Timer>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <rect width="25" height="25" rx="6.71667" fill="#F9F9F9"/>
                  <path d="M12.3507 5.28564C8.78734 5.28564 5.88477 8.06455 5.88477 11.4761C5.88477 14.8877 8.78734 17.6666 12.3507 17.6666C15.9141 17.6666 18.8167 14.8877 18.8167 11.4761C18.8167 8.06455 15.9141 5.28564 12.3507 5.28564ZM12.3507 6.52374C15.2152 6.52374 17.5235 8.73366 17.5235 11.4761C17.5235 14.2186 15.2152 16.4285 12.3507 16.4285C9.48623 16.4285 7.17796 14.2186 7.17796 11.4761C7.17796 8.73366 9.48623 6.52374 12.3507 6.52374ZM11.7041 7.76184V11.7324L14.48 14.39L15.3943 13.5146L12.9973 11.2198V7.76184H11.7041Z" fill="#5C49DD"/>
                  </svg>
                </S.Timer>
                <p>{thisStatusIsUntilApproval ? `Prazo de redação do material` :`Prazo desta etapa:`}</p>
                <p><span>{acertarDatas(infoOrder?.deadline ?? '')}</span></p>
              </S.Timestep>
              <S.CardStatus>
                <Banner 
                    typeUser={typeUser} 
                    etapa={etapa}
                    authToken={authToken}
                    orderId={idPedido ?? ''}
                    orderLink={orderLink}
                    setDelegateOrder={setDelegateOrder}
                    setSendOrderToRevision={setSendOrderToRevision}
                    setShowChange={setShowChange}
                    inputRef={inputRef}
                    setReload={setReload}
                    productName={infoOrder?.product.product_name}
                    revisorUserId={infoOrder?.revisor_user_id}
                    userId={userId}
                    setConfirmSendOrder={setConfirmSendOrder}
                    setAddPublishedNews={setAddPublishedNews}
                    setShowPopupGPT={setShowPopupGPT}
                    planCompany={planCompany}
                />
              </S.CardStatus>
              {typeUser === 'admin' && <S.RemoveOrderButton onClick={() => setShowDeleteOrder(true)}>
                Apagar pedido <DeleteForeverIcon/>
              </S.RemoveOrderButton>}
            </S.InfoStatus>
              <S.Stepper>
                <S.StepperConntent>
                  <StepperVertical 
                  authToken={authToken} 
                  orderId={idPedido ?? ''} 
                  setReload={setReload} 
                  orderlogs={infoOrder?.order_logs} 
                  typeUser={typeUser} 
                  inputRef={inputRef}
                  canShowAllLogs={!isScreenSmall}
                  setComment={setCommentInDeleteConfirmation}
                  setConfirmDeleteComment={setShowConfirmDeleteComment}
                  />
                  {!showMoreLogs && isScreenSmall && <h2 onClick={() => setShowMoreLogs(true)}><img src={setabaixo} alt="Seta"/>Ver todos os status</h2>}
                  {showMoreLogs && (
                    <>
                    {showMoreLogs && isScreenSmall &&  (
                      <S.AllLogsContainer>
                        <h2 onClick={() => setShowMoreLogs(false)}><img src={setacima} alt="Seta"/>Mostrar menos</h2>
                        <S.AllLogsContent>
                          <StepperVertical 
                              authToken={authToken} 
                              orderId={idPedido ?? ''} 
                              setReload={setReload} 
                              orderlogs={infoOrder?.order_logs} 
                              typeUser={typeUser} 
                              inputRef={inputRef}
                              canShowAllLogs={isScreenSmall}
                              setComment={setCommentInDeleteConfirmation}
                              setConfirmDeleteComment={setShowConfirmDeleteComment}
                              />
                        </S.AllLogsContent>
                      </S.AllLogsContainer>
                    )}
                    </>
                  )}
                </S.StepperConntent>
              </S.Stepper>
          </S.Sidepopup>
        </S.RightBar>
        </S.InnerContainer>
    </S.Container>
  )
}
