import { ReadMyOrders } from '../../../interfaces'
import * as S from './style'

import FileCheck from '../../../assets/icons/file-check.svg'
import File from '../../../assets/icons/file.svg'

interface InformationHomeProps {
    plan: string | undefined;
    orders: ReadMyOrders[] | null;
}


export default function InformationHome({plan, orders}: InformationHomeProps) {

//   const finishedOrders = orders?.filter((order) => order.status.status === 'in_negotiation').length;
  const filteredOrders = orders?.filter(order => {
    const createdAt = new Date(order.created_at);
    const currentDate = new Date();
    return createdAt.getMonth() === currentDate.getMonth();
  }).length;

  const totalOrders = plan === "StartUp" ? 1 : plan === "Premium" ? 3 : plan === "Enterprise Agência" ? 5 : 0;
  const availableOrders = Number(totalOrders) - Number(filteredOrders);

  const currentDate = new Date();
  const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  if (currentDate.getMonth() === 11) {
    nextMonth.setFullYear(currentDate.getFullYear() + 1);
  }
  const nextMonthString = nextMonth.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

  const urlWhatsapp = 'https://api.whatsapp.com/send?phone=5511985082539'
  function openWhatsapp(){
    window.open(urlWhatsapp,'_blank')
  }
  return (
    <S.Container>
        <S.Plan>{plan}</S.Plan>


        <S.OrderContainer>
            <S.Text>Este mês</S.Text>

            <S.Order>
                <S.Icon><img src={FileCheck} width={25} alt='file'/></S.Icon>
                <S.OrderInfos>
                    <S.OrderTitle>Pedidos feitos</S.OrderTitle>
                    <S.OrderNumber>{filteredOrders}</S.OrderNumber>
                </S.OrderInfos>
            </S.Order>

            { (plan === 'StartUp' || plan === 'Premium') &&
                <S.Order>
                    <S.IconFile><img src={File} width={23} alt='file'/></S.IconFile>
                    <S.OrderInfos>
                        <S.OrderTitle>Pedidos disponíveis</S.OrderTitle>
                        <S.OrderNumber>{availableOrders}</S.OrderNumber>
                    </S.OrderInfos>
                </S.Order>
            }
        </S.OrderContainer>

        { (plan === 'StartUp' || plan === 'Premium') &&
            <>
                <S.UpgradeButton onClick={openWhatsapp}>
                    Liberar mais pedidos
                </S.UpgradeButton>
                
                <h2>*Novos pedidos disponíveis em: {nextMonthString}</h2>
            </>
        }
    </S.Container>
  )
}
