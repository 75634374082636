import { Link } from 'react-router-dom'
import { ReadMyOrders } from '../../../interfaces'
import { acertarDatas } from '../../../utils'
import {
    Container,
    InnerContainer,
    ProductName,
    DateContainer,
    CardTop,
    CardBottom,
    Delegate
} from './style'
import CardNewHomeSvg from './Svg'

interface CardNewHomeProps{
  order: ReadMyOrders
}

export default function CardNewHome({order}: CardNewHomeProps) {

    return (
        <Container>
        <Link style={{textDecoration: 'none'}} to={`/pedidos/${order?.id}`}>
            
            <InnerContainer>
                <CardTop>
                <ProductName>{order?.product?.product_name} <span>#{order?.id}</span></ProductName>
                </CardTop>
                <CardBottom>
                <DateContainer>
                    <CardNewHomeSvg/> {acertarDatas(order?.deadline)}
                    <Delegate>Ver detalhes</Delegate>
                </DateContainer>
                </CardBottom>
            </InnerContainer>
            </Link>
        </Container>
    )
}
