import styled from "styled-components";

const FilesContainer = styled.div`
height: 3rem;
margin-bottom: 0.65rem;
cursor: pointer;
width: 18.79rem;
border-radius: 20px;
background-color: #5C49DD1A;
display: flex;
align-items: center;
justify-content: space-between;
img{
    width: 1.88rem;
    height: 1.88rem;
    margin-left: 1.25rem;
    margin-right: 0.5rem;
}

span{
    font-size: 1.5rem;
    color: #6D6D6D;
    margin-right: 1.25rem;
    cursor: pointer;
}
svg{
    color: #6d6d6d95;
    margin-right: 0.5rem;
}

`

const FilesContent = styled.div`
display: flex;
align-items: center;
`

export {
FilesContainer,
FilesContent
}