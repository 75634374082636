import styled from "styled-components";

export const Container = styled.div`
   width: 25rem;
`
export const ProductName = styled.div`
    border-radius: .8rem .8rem 0 0;
    background-color: #070060;
    
    color: #F9F9F9;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;

    padding: .8rem 0 1rem 0;
    
   -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
`
export const InnerContainer = styled.div`
    position: relative;
    top: -.4rem;
    background-color: #F9F9F9;
    border-radius: .8rem;

    display:flex;
    flex-direction:column;
    justify-content: space-between;

    padding: 1rem;
    height: 20rem;
    
   -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
   
`
export const PriorityContainer = styled.div`
    width: 20%;
`

export const PriorityTag = styled.div<{$priority:string}>`
    color: #F9F9F9;
    text-align: center;
    font-size: .8rem;

    padding: .2rem .5rem;
    border-radius: 2rem;

    background-color: ${({ $priority }) => {
        switch ($priority) {
            case 'Baixa':
                return '#29B95F';
            case 'Média':
                return '#FF9736';
            case 'Alta':
                return '#F03636';
            default:
                return 'inherit';
        }
    }};
`
export const Details = styled.div`
    border: 1px solid #6D6D6D;
    border-radius: .5rem;
    padding: .5rem;
    height: 5rem;
    overflow-y: auto;

    span{
        line-height: 1.3rem;
    }

`
export const Infos = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
   
`
export const InfoBox = styled.div`
    display:flex;
    flex-direction:column;
    gap: .5rem;
   
`
export const Title = styled.div`
    display: flex;
    align-items:center;
    gap: .2rem;

`
export const Content = styled.div`
    display: flex;
    align-items:center;
    gap: .3rem;

    position: relative;
    left: 1.2rem;

    span{
        font-weight: 500;
        max-width: 8rem;

        line-height: 1rem;
    }
`
export const Warning = styled.div`
    img{
        width: .8rem;
    }
`

export const Button = styled.button`
    background-color: #5C49DD;
    border:none;
    border-radius: 2rem;
    padding: .5rem 0;
    cursor: pointer;

    a{
        color: #f9f9f9;
        font-family: Inter;
    }

    &:hover{
        background-color: #070060;
    }
`


export const BackgroundPopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index:9999;
`

export const EditButton= styled.button`
    background-color:transparent;
    border:none;
    cursor: pointer;
    &:hover{
        color:#5C49DD;
    }
`