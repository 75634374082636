import {
  Container,
  Image
} from './style'

export default function Dashboard() {
  return (
    <Container>
      <Image></Image>
    </Container>
  )
}
