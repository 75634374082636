import {
    Container,
    Title,
    Text
} from './style'

export default function RelacaoPublica() {
  return (
    <Container>
        <Title>Relações Públicas</Title>
        <Text>
            <p>
            Relações Públicas é uma área da comunicação que tem como objetivo gerenciar e manter 
            relacionamentos positivos e duradouros entre uma organização e seus públicos de interesse, 
            como clientes, fornecedores, colaboradores, comunidade, governo, entre outros.
            </p>
            <p>
            A importância de PR para as organizações está no fato de que elas ajudam a 
            construir e manter a imagem positiva da empresa perante seus públicos de interesse.
             Através de estratégias de comunicação e relacionamento, podem ajudar a empresa 
             a se destacar em um mercado competitivo, a construir uma reputação sólida e a 
             fortalecer sua marca.
            </p>
            <p>
            Qualquer empresa, seja ela de grande ou pequeno porte, pode usufruir das Relações Públicas. 
            É especialmente importante para empresas que buscam se estabelecer em um mercado 
            competitivo e para aquelas que desejam se comunicar de forma eficaz com seus públicos 
            de interesse.
            </p>
            <p>
            Além disso, há muitos benefícios trazidos pelo PR, confira: 
            </p>
            <p>
            Fortalecimento da imagem da empresa: ajuda a construir e manter uma imagem positiva da 
            empresa perante seus públicos de interesse. Aumentando seu destaque em um mercado 
            competitivo e atraindo mais clientes.
            </p>
            <p>
            Aumento da visibilidade da marca: aumenta a visibilidade de sua marca 
            e cria um relacionamento mais próximo com seus públicos de interesse.
            </p>
            <p>
            Construção de relacionamentos duradouros: ajudam a empresa a construir relacionamentos duradouros com seus clientes, 
            fornecedores, colaboradores e comunidade 
            </p>
            <p>
            Gerenciamento de crises: importantes para o gerenciamento de crises. Elas podem ajudar a empresa a se comunicar 
            de forma eficaz com seus públicos de interesse em momentos de crise e a minimizar os impactos negativos para a imagem da empresa.
            </p>
            <p>
            Em resumo, as Relações Públicas são fundamentais para a construção e manutenção de uma imagem positiva da empresa perante 
            seus públicos de interesse. Essa área da comunicação pode ajudar a empresa a se destacar 
            em um mercado competitivo, a fortalecer sua marca e a construir relacionamentos duradouros 
            com seus públicos de interesse.
            </p>
            </Text>
    </Container>
  )
}
