import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'

interface SendOrderProps{
    hasLink: boolean
}

const Container = styled.div`
position: relative;
width: 100%;
min-height: calc(100vh - 4rem);
height: calc(auto + 20rem);
padding-bottom: 5rem;
display: flex;
flex-direction: column;
align-items: center;
background-image: url(${mentionFundo});
background-repeat: no-repeat;
background-position: bottom right ;
background-size: 16.5625rem auto;
background-color: rgba(0, 0, 0, 0.1);
background-color: #E5E5E5;

`

const Title = styled.div`
color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-top: 3.75rem;
`


const Line = styled.div`
    height: 0.0625rem;
    width: 98%;
    background-color: #6D6D6D50;
    margin-bottom: 0.78125rem;
    margin-top: 0.78125rem;
`

const Stepper = styled.div`
display: flex;
align-items: flex-start;
justify-content: center;
width: 100%;
margin-top: 1rem;
margin-bottom: 0.625rem;
@media(max-width: 1520px){
    width: 30rem;
    padding-top: 5rem;
    margin-top: 0rem;
}
@media(max-width: 835px){
    padding-top: 2rem;
}
h3{
    color: #333333;
    background-color: #6d6d6d1a;
    font-weight: 400;
    line-height: 1.27rem;
    padding: 0.8rem;
    border-radius: 0.75rem;
    margin-bottom: 0.5rem; 
    word-wrap: break-word; /* Para navegadores mais antigos */
    overflow-wrap: break-word; /* Para navegadores modernos */
}
h1, h2{
    color: #6d6d6d;
    font-weight: 500;
    line-height: 1.445rem;

}



h2{
    font-size: 0.9rem;
}

h1{
    font-size: 1.1rem;
}
.MuiStepIcon-root.Mui-completed {
    color: #5C49DD;
}


`

const BackgroundPopup = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index:9999;
`

interface TabProps{
    tab: string;
}

const Content = styled.div<TabProps>`
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
    ${props => props.tab === 'infoCompany' ? 
    'width: 77rem;' : 'width: 90%;'}
 
    height: auto;
    margin-top: 2.625rem;
    margin-bottom: 2.5rem;
    background-color: #f9f9f9;
    border-radius: 1.25rem;
    margin-left: 0.625rem;
    display: flex;
    @media(max-width: 1275px){
        width: 67rem;
    }
    @media(max-width: 935px){
        width: 60rem;
    }
    @media(max-width: 835px){
        width: 32rem;
    }

`

const Information = styled.div<TabProps>`

${props => props.tab === 'infoCompany' ? 
    '' : 'width: 100%;'}

height: auto;
margin: 1.875rem 2rem 1.4375rem 2.8125rem;
> h1{
    font-weight: 500;
    font-size: 1.5rem;
    color: #333333;
    line-height: 2.420625rem;
    margin-bottom: 1.1875rem;
    > span{
        color: #6D6D6D;
    }
}
> h3{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-left: 2rem;
    color: #333333;
    strong{
        font-weight: bold;
    }

> span{
    font-weight: 500;
    color: #5C49DD;
    cursor: pointer;
}
}

`
const EditButton= styled.button`
    background-color:transparent;
    border:none;
    cursor: pointer;
    &:hover{
        color:#5C49DD;
    }
`

const Data = styled.div`
display: flex;
flex-direction: column;
@media (max-width: 750px){
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
`

const InfoData = styled.div`
margin: 1.5rem 0 1.5rem;
padding-left: 2.5rem;
font-size: 1rem;
`

const Back = styled.button`
cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
    
`

const Buttons = styled.div`
display: flex;
justify-content: flex-start;
padding-right: 0.625rem;
margin-bottom: 0.9375rem;
margin-top: 2.5rem;

@media(max-width: 895px){
    button{
        width: 7rem;
    }
}

@media(max-width: 620px){
    flex-direction: column-reverse;
    justify-content: center;
    padding-right: 0;
}
`

const LeftButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const FinishOrder = styled.button`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}
`


const SendOrder = styled.button<SendOrderProps>`
${props => props.hasLink ? 'cursor: pointer' : ''};
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: ${props => props.hasLink ? '#5c49dd' : '#bfbfbf'};
color: ${props => props.hasLink ? '#ffffff' : '#6e6d6d'};
border-radius: 1.875rem;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1.875rem;
transition: 200ms;

:hover{
    color: ${props => props.hasLink ? '#5c49dd' : '#6e6d6d'};
    background-color: ${props => props.hasLink ? '#f9f9f9' : '#bfbfbf'};
    ${props => props.hasLink ? 'border: 0.0625rem solid #5c49dd' : ''};
}
`


const RightButtons = styled.div`
display: flex;
@media(max-width: 1550px){
    padding-right: 2rem;
}
@media(max-width: 620px){
    padding-bottom: 1rem;
    padding-right: 0;
    width: 29rem;
}
`;


const Main = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
interface RightBarProps{
    tab: string;
}

const RightBar = styled.div<RightBarProps>`
@media(max-width: 1795px){
    ${props => props.tab === 'infoCompany' && 'display: none;'}
}
width: 36.9230rem;
height: auto;
@media(max-width: 1520px){
    width: 90%;
    display: ${props => props.tab === 'infoCompany' ? 'none' : 'flex'};
    justify-content: center;
}

@media(max-width: 920px){
    width: 100%;
    display: ${props => props.tab === 'infoCompany' ? 'none' : 'flex'};;
    align-items: center;
    justify-content: center;
}
`
const Sidepopup = styled.div`
position: relative;
width: 34.28rem;
min-height: 23.46rem;
height: auto;
border-radius: 1.5384rem;
background-color: #f9f9f9;
margin-right: 2.153rem;
margin-top: 7.625rem;
padding-bottom: 4rem;
box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
@media(max-width: 920px){
    width: 90%;
    margin-right: 0;
    margin-top: 0;
    height: 20rem;
}
@media(max-width: 1520px){
    display: flex;
    width: 63rem;
    align-items: flex-start;
    justify-content: center;
    min-height: 14.5rem;
    height: auto;
    padding-bottom: 1rem;
    margin-right: 0;
}
@media(max-width: 835px){
    flex-direction: column;
    align-items: center;
    width: min-content;
}
`
const InnerContainer = styled.div`
position: relative;
width: 100%;
height: auto;
display: flex;
align-items: start;
justify-content: center;
@media(max-width: 1520px){
    flex-direction: column-reverse;
    align-items: center;
}
`


const FilesContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
margin-bottom: 2.5rem;

`

const DragAndDropContainer = styled.div`
margin-top: 1.5rem;
h1{
    color: #333333;
    font-size: 1.2rem;
    line-height: 1.45rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
`

const InnerDragAndDrop = styled.div`
cursor: pointer;
width: 26.315rem;
height: 14.66rem;
border: 1px dashed #6D6D6D;
border-radius: 0.625rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #6d6d6d0d;
h4{
    color: #6d6d6d;
    font-size: 1.052;
    font: inter;
    line-height: 1.27rem;
}
span{
    color: #5C49DD;
    text-decoration: underline;
}
`
interface AllFilesProps{
    isOpen: boolean;
    canSeeDragAndDrop: boolean;
    filesLength: number;
}

function allFilesLength(length: number, canSeeDragAndDrop: boolean){
    if(canSeeDragAndDrop){
        return '14.66rem;'
    } else{
        if(length === 0){
            return '3.5rem;'
        } else if(length === 1){
            return '5.5rem;'
        } else if(length === 2){
            return '10rem;'
        } else if(length === 3){
            return '13.3rem;'
        } else {
            return '14.66rem;'
        }
    }
}

const AllFiles = styled.div<AllFilesProps>`
margin-left: ${props => props.canSeeDragAndDrop ? '2.25rem' : '0rem'};
width: 19rem;
height: ${props => allFilesLength(props.filesLength, props.canSeeDragAndDrop)};
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;

h2{
    color: #333333;
    font-size: 1.2rem;
    line-height: 1.45rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
h4{
    color: #5C49DD;
    font-size: 1.052;
    line-height: 1.27rem;
    cursor: pointer;
    ${props => props.isOpen ? 'margin-bottom: 1rem;' : ''}
}
@media(max-width: 835px){
    margin-left: 0rem;
}
`

const PublishedNewsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 2.5rem;
`


const InnerFiles = styled.div`
display: flex;
align-items: flex-end;
justify-content: flex-start;
padding-top: 0.5rem;
width: 80%;
max-width: 80rem;
min-height: 2.45625rem;
height: auto;
margin-top: 0.3125rem;
margin-bottom: 0.9375rem;
@media(max-width: 1500px){
    margin-left: 0;
    margin-right: 0;
}
@media(max-width: 835px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


`
const InfoStatus = styled.div`
display: flex;
flex-direction: column;
align-items: center;
@media(max-width: 1520px){
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-top: 1rem;
}

>h1{
    padding-top:1.95rem;
    margin-bottom: 1.25rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.82rem;
    color: #333333;
    width: 28.57rem;
    @media(max-width: 1520px){
        padding-top: 0.4rem;
    }
}
`

const CardStatus = styled.div`
width: 29.57rem;
height: 14.85rem;
background: #5c49dd33;
border-radius: 0.75rem;
margin-bottom: 2.5rem;
@media(max-width: 835px){
    margin-bottom: 1rem;
}
`

const AllFilesContainer = styled.div`
padding-top: 1rem;
padding-left: 1rem;
width: 21.4285rem;
height: auto;
max-height: 19.924rem;
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
overflow-y: hidden;
`
const AllFilesContent = styled.div`
overflow-y: scroll;
height: 14rem;
margin-bottom: 1rem;
::-webkit-scrollbar{
	width: 0.6rem;
	background-color: #f9f9f9;
}

`

const StepperConntent = styled.div`
position: relative;
h2{
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-style: italic;
    font-size: 1rem;
    line-height: 1.27rem;
    color: #5C49DD;
    margin-bottom: 0.5rem;
    img{
        width: 1rem;
        margin-right: 0.5rem;
    }  
}
`

const AllLogsContainer = styled.div`
padding-top: 1rem;
padding-left: 1rem;
border-radius: 20px;
background: #F9F9F9;
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
overflow-y: hidden;
position: absolute;
top: 5rem;
`
const AllLogsContent = styled.div`
max-height: 20rem;
overflow-y: scroll;
margin: 0 1rem;
margin-bottom: 1rem;
::-webkit-scrollbar{
	width: 0.6rem;
	background-color: #f9f9f9;
}
`

const Timestep = styled.div`
display: flex;
align-items: center;
width: 28.57rem;
height: 3rem;
background: #5c49dd33;
border-radius: 0.75rem;
margin-bottom: 0.75rem;
@media(max-width: 835px){
    margin-bottom: 1rem;
}

p{
color: #5C49DD;
font-family: Inter;
font-size: 1.05rem;
font-style: normal;
font-weight: 400;
line-height: normal;

span{
    font-weight: 600;
    margin-left: 0.5rem;
}
}

`

const Timer = styled.div`
height: 3rem;
display: flex;
align-items: center;
margin-left: 1.127rem;
margin-right: 0.676rem;
`

const RemoveOrderButton = styled.button`
margin-top: -1.5rem;
background-color: #070060;
width: 10rem;
height: 2rem;
border-radius: 50px;
border: none;
cursor: pointer;
width: 12.78rem;
height: 2.63rem;
box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.30);
font-weight: 500;
font-size: 1.05rem;
display: flex;
align-items: center;
justify-content: center;
gap: 0.1rem;
color: #f9f9f9;
margin-left: 1rem;
`

export {
    EditButton,
    Container,
    Content,
    Information,
    Data,
    Buttons,
    Back,
    FinishOrder,
    Title,
    Stepper,
    Line,
    RightButtons,
    SendOrder,
    BackgroundPopup,
    LeftButtons,
    Sidepopup,
    Main,
    InnerContainer,
    RightBar,
    AllFiles,
    FilesContainer,
    DragAndDropContainer,
    InnerFiles,
    InnerDragAndDrop,
    InfoStatus,
    CardStatus,
    AllFilesContainer,
    AllFilesContent,
    StepperConntent,
    AllLogsContainer,
    AllLogsContent,
    PublishedNewsContainer,
    Timestep,
    Timer,
    RemoveOrderButton,
    InfoData
  }