import {
    ConfirmUpgradePlanContainer,
    ImageConfirm,
    ConfirmTitle,
    ConfirmTextPrimary,
    ConfirmTextSecondary,
    CancelButton,
    ConfirmButton,
    ConfirmAllButtons,
    InnerContainer,
    TextConfirm
    } from './style'
import imageUpgradePlan from '../../assets/images/image_upgrade_plan.png'

interface UpgradePlanPopupProps{
    setUpgradePlan: (value: boolean) => void;
  }
  
export default function UpgradePlanPopup({setUpgradePlan}: UpgradePlanPopupProps){

    const urlWhatsapp = 'https://api.whatsapp.com/send?phone=5511985082539'

    function openWhatsapp(){
        window.open(urlWhatsapp,'_blank')
    }

  return (
    <ConfirmUpgradePlanContainer>
        <InnerContainer>
            <ImageConfirm>
                <img src={imageUpgradePlan} alt="Mulher de vestido azul enviando uma carta em um parque." />
            </ImageConfirm>
            <div>
                <ConfirmTitle>
                    Você atingiu o número máximo de pedidos para o seu plano.
                </ConfirmTitle>
                <TextConfirm>
                    <ConfirmTextSecondary>
                        Para fazer um <span>upgrade</span>, clique em
                    </ConfirmTextSecondary>
                    <ConfirmTextPrimary>
                        'Falar com especialista'
                    </ConfirmTextPrimary>
                    
                </TextConfirm>
            </div>
        </InnerContainer>
        <ConfirmAllButtons>
            <CancelButton onClick={() => setUpgradePlan(false)}>
                Cancelar
            </CancelButton>
            <ConfirmButton onClick={openWhatsapp}>
                Falar com especialista
            </ConfirmButton>
        </ConfirmAllButtons>

    </ConfirmUpgradePlanContainer>
  )
}
