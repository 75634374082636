export function acertarDatas(data: string){
    const ano = data.substring(0,4)
    const mes = data.substring(5,7)
    const dia = data.substring(8,10)
    return  `${dia}/${mes}/${ano}`
}

export function formatarData(dataString: string): string {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
  
    return `${dia}/${mes}/${ano}`;
  }
  