import { useEffect, useRef } from 'react';
import { InformationProps } from '../../interfaces';
import {
    Container,
    InnerContainer,
    LongInput,
} from './style'

export default function InformationContainer({description, fieldName, value}: InformationProps) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        adjustTextareaHeight(); 
      }, []);


    function adjustTextareaHeight() {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }
        
    return(
        <>
            <Container>
                <InnerContainer>
                <LongInput>
                <h1>{fieldName}</h1>
                <h2>{description}</h2>
                <textarea
                    ref={textareaRef}  
                    disabled={true}
                    placeholder=''
                    name="field" id='field'
                    value={value}
                    />
                </LongInput>
                </InnerContainer>
            </Container>
        </>
    )
}
