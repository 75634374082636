import styled from "styled-components";

const Container = styled.div`
@media(max-width: 1170px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 4.375rem;
}
span{
    color: #f5ff76;
    font-size: 3.125rem;
}

img{
    width: 31.875rem;
    height: auto;
    @media(max-width: 1170px){
    width: 80%;
    height: auto;
}
}
h1{
    @media(max-width: 1170px){
    display: none;
}
    font-weight: 400;
    font-size: 2.5rem;
    color: #f9f9f9;
    width: 28.125rem;
    line-height: 3.2575rem;
    height: 10.75rem;
    margin-left: 2.1875rem;
}
`

export {
    Container
}