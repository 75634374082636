import styled from "styled-components";

interface InnerProps{
    logout: boolean;
    isThisPage: boolean;
}

const Container = styled.div`
width: 17.5rem;
height: 2.65625rem;
display: flex;
justify-content: center;
align-items: center;
margin-top: 0.3125rem;
margin-bottom: 0.3125rem;
`

const InnerContainer = styled.li<InnerProps>`
display: flex;
align-items: center;
width: 15.625rem;
height: 2.65625rem;
border-radius: 0.625rem;
padding-left: 0.9375rem;
color: ${props => props.logout ? '#f03636': '#6d6d6d'};

:hover{
    background-color: #5C49DD;
    color: #F9F9F9;
    cursor: pointer;
}

background-color: ${props => props.isThisPage ? '#5c49dd': ''};
color: ${props => props.isThisPage ? '#f9f9f9': ''};

`
const Text = styled.div`
margin-left: 1.25rem;
font-size: 1rem;

`
export {
    Container,
    InnerContainer,
    Text
}