import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { Logs, OrderPropsStepper } from '../../interfaces';
import { acertarDatas } from '../../utils';
import { StepIcon } from '@mui/material';
import { CommentBox, CommentButton, CommentInput, CommentText, LessDetails, MessageContainer, MoreDetails, RemoveCommentText, StepperContainer } from './style';
import setacima from '../../assets/images/setacima.png';
import setabaixo from '../../assets/images/setabaixo.png';
import send from '../../assets/images/send.png';
import api from '../../services/api';
import { Close } from '@mui/icons-material';

export default function StepperVertical({ orderlogs, orderId, authToken, inputRef, typeUser, canShowAllLogs, setReload, setComment, setConfirmDeleteComment}: OrderPropsStepper) {
  const [showDetails, setShowDetails] = useState(true);
  const [newComment, setNewComment] = useState<string>("")
  const isEditorOrAdmin = (typeUser === 'admin' || typeUser === 'editor');
  const canSeeCommentBox = isEditorOrAdmin 
  let filtredActiveStep: Logs[] = []
  if(typeUser === "company"){
    filtredActiveStep = orderlogs?.filter((step) => 
    step?.status?.company_message?.length > 0) ?? []
  } else if(typeUser === "admin"){
    filtredActiveStep = orderlogs?.filter((step) => 
    step?.status?.admin_message?.length > 0) ?? []
  } else if(typeUser === "editor"){
    filtredActiveStep = orderlogs?.filter((step) => 
    step?.status?.editor_message?.length > 0) ?? []
  }if(!canShowAllLogs){
    filtredActiveStep = [filtredActiveStep[filtredActiveStep.length - 1]];
  } 

  const [activeStep, setActiveStep] = useState<number | null>(filtredActiveStep ? filtredActiveStep?.length - 1 : 0);

  const statusClientCanSee = [
    'open',
    'in_production',
    'awaiting_approval',
    'awaiting_changes',
    'in_negotiation',
    'closed'
  ]

  function addComment(){
    const body={
      comment: newComment
    }
    const promise = api.addComment(authToken, orderId, body)
    promise.then(() => {
      setReload(true)
      setNewComment("")
    })
  }

  const MoreDetailsClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setShowDetails(true);
    setActiveStep(index)
  }

  const LessDetailsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setShowDetails(false);
    setActiveStep(null)
  }

  function deleteComment(comment: any){
    setComment(comment)
    setConfirmDeleteComment(true)
  }

  return (
    <Box sx={{ width: "26rem" }}>
      <Stepper activeStep={activeStep ?? 0} orientation="vertical">
        {filtredActiveStep?.map((step, index) => (
          <Step key={step.id} 
          completed={true}
          active = {true}>
            <StepperContainer>
            <StepLabel StepIconComponent={StepIcon}>
              {typeUser === 'admin' && <h1>{step?.status.admin_message}</h1>}
              {typeUser === 'editor' && <h1>{step?.status.editor_message}</h1>}
              {typeUser === 'company' && <h1>{step?.status.company_message}</h1>}
              <h2>
                {step?.created_at.includes("Em breve")
                  ? step?.created_at
                  : acertarDatas(step?.created_at)}
              </h2>
            </StepLabel>
            </StepperContainer>
            <StepContent>
              {(step?.order_logs_comments?.length > 0) && (
                <>
                  {(activeStep === index) && (
                    <>
                      <LessDetails onClick={(e) => LessDetailsClick(e)}><img src={setacima} alt="Seta"/>Ocultar detalhes</LessDetails>
                      {(showDetails) && (
                        <Typography>
                          <>
                          {step?.order_logs_comments?.map((comment) => (
                            <CommentText>
                              {isEditorOrAdmin && <RemoveCommentText>
                                <Close onClick={() => (deleteComment(comment))}/>
                              </RemoveCommentText>}
                              <h3 key={comment?.id}>{comment?.comment}</h3>
                            </CommentText>
                          ))}
                          </>
                        </Typography>
                      )}
                    </>
                  )}
                  {(activeStep !== index) && (
                    <>
                      <MoreDetails onClick={(e) => MoreDetailsClick(e,index)}><img src={setabaixo} alt="Seta"/>Ver mais</MoreDetails>
                  </>)}
                </>
              )}
              {(index === filtredActiveStep?.length - 1 && canSeeCommentBox) && (<>
                  {statusClientCanSee.includes(step?.status?.status) && <MessageContainer 
                    showDetails={showDetails}
                    showThisComment={index === activeStep}
                    hasComment={step?.order_logs_comments?.length > 0}
                    >
                      Atenção, seu comentário será exibido para o cliente
                      </MessageContainer>}
                    <CommentBox 
                    hasMessage={statusClientCanSee.includes(step?.status?.status)} 
                    showDetails={showDetails}
                    hasComment={step?.order_logs_comments?.length > 0}
                    showThisComment={index === activeStep}>
                      <CommentInput>
                          <input type="text" ref={inputRef} placeholder="Escreva um comentário" value={newComment} onChange={(e) => setNewComment(e.target.value)}/>
                      </CommentInput>
                      <CommentButton onClick={addComment}>
                          <img src={send} alt="Enviar"/>
                      </CommentButton>
                    </CommentBox></>)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
