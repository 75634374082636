import {
    Container,
    InnerContainer,
    Dados,
    ContainerInputs,
    Button,
    Back,
    LoadingContainer
  } from './style'
import {RequiredFieldsProduct } from '../../../interfaces'
import Input from '../../../components/Input'
import { useState } from 'react'
import api from '../../../services/api'
import { Link, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse'
import { Loading } from '../../../components'

interface DadosProdutoProps{
  inputs: RequiredFieldsProduct[]
  idProduto: string
  setUpgradePlan: (value: boolean) => void;
}
  
export default function DadosProduto({ inputs, idProduto, setUpgradePlan}: DadosProdutoProps) {

  const [inputsProduct, setInputsProduct] = useState([...inputs])
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token ?? ''
  const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse();
  const navigate = useNavigate()

  function MakeOrder(){
    setIsLoading(true)
    if(ValidateForms()) {
      const newData: Array<object> = []
      inputsProduct?.forEach((input: RequiredFieldsProduct) => {
        newData.push({
          field_id: input.id,
          value: input.value
         })
      })
      const promise = api.makeOneOrder(authToken,newData,idProduto)
      promise.then(()=>{
        setIsLoading(false)
        setErrorMessage('Pedido realizado com sucesso!')
        setTypeError(true)
        setShowPopup(true)
        navigate("/pedidos")
      }).catch((error)=>{
        setIsLoading(false)
        setTypeError(false)
        console.error(error)
        if(error.response?.status === 429){
          setUpgradePlan(true)
        }else {
            if(typeof error.response?.data?.detail === 'string'){
            setErrorMessage(error.response?.data?.detail)
          }else{
            setErrorMessage('Tente novamente em instantes!')
          }
          setShowPopup(true)
        }
      })
    } else {
      setIsLoading(false)
      setTypeError(false)
      setErrorMessage('Você não respondeu todas as informações obrigatórias!')
      setShowPopup(true)
    }
   } 

  function ValidateForms(){
    let requiredInputs: Array<RequiredFieldsProduct> = []
    requiredInputs = inputsProduct.filter(json => json.hasOwnProperty("is_required") && json.is_required);

    let isValid = true
    requiredInputs?.forEach((input: RequiredFieldsProduct) => {
      if (!input.value || input.value.length <= 0) {
        isValid = false;
      }
    })

    return isValid
  }

  if(isLoading){
    return(
    <Container>
      <InnerContainer>
          <LoadingContainer>
             <Loading color='black' withText={true}/>
          </LoadingContainer>
      </InnerContainer>
    </Container>)
  }
  
  return (
    <Container>
   <InnerContainer>
     <Dados>
      <ContainerInputs>
      {inputsProduct.map((input, indice) => {
        return(
          <Input
            key={input.id} 
            input={input}
            description={input.short_description}
            example={input.example}
            value={input.value}
            fieldName={input.field_name}
            inputs={inputsProduct}
            id={input.id}
            setInputs={setInputsProduct}
            indice={indice} 
            is_required={input.is_required}/>
        )
      })}
      </ContainerInputs>
      
      <Link style={{textDecoration: 'none'}} to={`/produtos`}>
                      <Back><ArrowBackIcon />Voltar</Back>
      </Link>
      <Button onClick={MakeOrder}>Finalizar pedido</Button>
      
     </Dados>
   </InnerContainer>
 </Container>

  )
}
