import styled from "styled-components"
import mentionFundo from '../../assets/images/mention_fundo.png'

const Container = styled.div`
    background-color: #E5E5E5;
    min-height: calc(100vh - 4rem);
    height: auto;
    width: calc(100vw - 17.5rem);
    @media(max-width: 1200px){
        width: 100vw;
    }
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 16.5625rem auto;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-bottom: 2rem;
    @media(max-width: 1425px){
        padding-bottom: 3rem;
    }
`

const Title = styled.div`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #333333;
    width: 108.27rem;
    margin-top: 3.45rem;
    margin-bottom: 1.73rem;
    @media(max-width: 1735px){
        width: 90%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
`

const Content = styled.div`
    border-radius: 20px;
    background: #F9F9F9;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
    width: 108.27rem;
    min-height: 55.789rem;
    height: auto;
    display: flex;
    padding-top: 2.25rem;
    @media(max-width: 1650px){
        width: 90%;
    }
    @media(max-width: 1425px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;
    }

    
`

const LeftContent = styled.div`
    width: 52.48rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        width: 36.46rem;
        height: 21.05rem;
    }
`

const RightContent = styled.div`
    width: 52.48rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Line = styled.div`
    height: 53rem;
    width: 1px;
    background: rgba(109, 109, 109, 0.40);
    @media(max-width: 1425px){
        margin: 2rem 0;
        width: 95%;
        height: 1px;
    }
`

const FormsContent = styled.div`
    font-size: 1rem;
    height: 25rem;
    margin: 3rem auto 1.875rem;
    width: 31.875rem;
    @media(max-width: 700px){
        width: 26.25rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
`

const LongInput = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    font-size: 1rem;

    input{
        width: 18.75rem;
        height: 2.65625rem;
        border: 0.0625rem solid #6D6D6D50;
        border-radius: 0.625rem;
        display: flex;
        flex-direction: column;
        margin-top: 0.3125rem;
        margin-right: 1.875rem;
        padding-left: .625rem;
        margin-left: 1.875rem;
        @media(max-width: 1500px){
            margin-left: 0;
            margin-right: 0;
        }
    }

    label{
        font-size: 1.3rem;
    }
`

const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3.5rem;

    label{
        font-size: 1.3rem;
    }

    textarea{
        font-family: 'Inter', sans-serif;
        padding-top: 0.5rem;
        resize: none;
        width: 100%;
        max-width: 80rem;
        min-height: 2.45625rem;
        max-height: 4rem;
        height: auto
        border: 0.0625rem solid #6D6D6D50;
        border-radius: 0.625rem;
        display: flex;
        flex-direction: column;
        margin-top: 1.3rem;
        
        
        margin-bottom: 0.9375rem;
        @media(max-width: 1500px){
            margin-left: 0;
            margin-right: 0;
        }
    }
    textarea::placeholder{
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.21rem;
        color: #6D6D6D;
        text-align: start;
    }
`

const ContainerInputs = styled.div`
    font-size: 1rem;
    margin: 3rem auto 1.875rem;
    width: 31.875rem;
    height: 40rem;
    @media(max-width: 700px){
            width: 26.25rem;
        }
        @media(max-width: 470px){
            width: 21.875rem;
        }
`

const ContainerButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    width: 31.875rem;
    @media(max-width: 1650px){
        width: 50%;
    }
    @media(max-width: 700px){
            width: 26.25rem;
        }
        @media(max-width: 470px){
            width: 21.875rem;
        }
    margin-left: 15rem;
`

const DefaultButton = styled.div`
    width: 12.01rem;
    height: 3.75rem;
    border-radius: 30px;
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.42rem;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
    :hover{
        opacity: 0.8;
    }
`

const BackButton = styled(DefaultButton)`
    color: #5C49DD;
    background: #f9f9f9;
    border: 1px solid #5C49DD;
`

const NextButton = styled(DefaultButton)`
    background: #5C49DD;
    color: #f9f9f9;
`

const Send = styled(DefaultButton)`
    background: #5C49DD;
    color: #f9f9f9;
`

export {
    Container,
    Title,
    Content,
    LeftContent,
    Line,
    FormsContent,
    LongInput,
    RightContent,
    CommentContainer,
    ContainerInputs,
    ContainerButtons,
    BackButton,
    NextButton,
    Send
}