import { ReactNode } from "react"
import Info from "../../assets/icons/info-circle.svg"

// Style CSS
import "./Tooltip.css"

// https://codepen.io/aepicos/pen/bGvMqov
// https://codepen.io/serkan-cetinkaya/pen/vYRBOyv?editors=0100
// https://codepen.io/pure-css/pen/bddggP
// https://codepen.io/tutsplus/pen/WROvdG
// https://codepen.io/wadehammes/pen/mpJMPP

type TooltipProps = {
	tooltipAlign?: string
	tooltipContent: string | ReactNode
	children?: ReactNode
}

export default function Tooltip({
	children,
	tooltipAlign,
	tooltipContent
}: TooltipProps) {
	return (
		<div className="tooltip">
			<button className="tooltip-button" aria-describedby="description-two">
				{children || (<img src={Info} alt="info"/>)}
			</button>
			<div
				className={`tooltip-content ${tooltipAlign || "top"}`}
				id="description-two"
				role="tooltip"
			>
				{tooltipContent}
			</div>
		</div>
	)
}
