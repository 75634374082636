import { BrowserRouter, Routes, Route} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { 
    Ajuda, Configuracao, Login, Notificacoes, Dashboard, 
    Pedidos, Produtos, NewUsuarios, EsqueciMinhaSenha, SugestoesDeConteudo, SugerirConteudo} from './pages'
import PedidoDetalhado from "./pages/PedidoDetalhado";
import SugestaoDetalhada from "./pages/SugestaoDetalhada";
import Home from "./pages/Home";
import PressRelease from "./pages/HomePage/PressRelease";
import ArtigoOpiniao from "./pages/HomePage/ArtigoOpiniao";
import RelacaoPublica from "./pages/HomePage/RelacoesPublicas";
import SugestaoPauta from "./pages/HomePage/SugestaoPauta";
import PopupTrueFalse from "./components/PopupTrueFalse";
import usePopupTrueFalse from "./hooks/usePopupTrueFalse";
import Empresas from "./pages/Empresas";
import CompanyInformation from "./pages/DetailedCompany/CompanyInformation";
import NewHome from "./pages/NewHome";
import useAuth from "./hooks/useAuth";
import NewOrder from "./pages/NewOrder";
import Resultados from "./pages/Resultados";
import PlanoDeComunicacao from "./pages/PlanoDeComunicacao";
export const pathsWithoutHeaderAndMenu = ['/','/login'];

export default function App() {

  const {errorMessage, typeError, showPopup, setShowPopup} = usePopupTrueFalse();
  const {user} = useAuth();
  const isUserOrAdmin = user?.role?.name === 'admin' || user?.role?.name === 'editor';

  return (
      <BrowserRouter>
        <PopupTrueFalse type={typeError} active={showPopup} message={`${errorMessage}`} setActive={setShowPopup}/>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/ajuda" element={<ProtectedRoute component={Ajuda} />} />
              <Route
                path="/home"
                element={
                  isUserOrAdmin ? (
                    <ProtectedRoute component={NewHome} />
                  ) : (
                    <ProtectedRoute component={Home} />
                  )
                }
              />
              <Route path="/configuracoes" element={<ProtectedRoute component={Configuracao} />} />
              <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
              <Route path="/notificacoes" element={<ProtectedRoute component={Notificacoes} />} />
              <Route path="/pedidos" element={<ProtectedRoute component={Pedidos} />} />
              <Route path="/produtos" element={<ProtectedRoute component={Produtos} />} />
              <Route path="/usuarios" element={<ProtectedRoute component={NewUsuarios} />} />
              <Route path="/produtos/:idProduto" element={<ProtectedRoute component={NewOrder} />} />
              <Route path="/pedidos/:idPedido" element={<ProtectedRoute component={PedidoDetalhado} />} />
              <Route path="/pressrelease" element={<ProtectedRoute component={PressRelease} />} />
              <Route path="/artigodeopiniao" element={<ProtectedRoute component={ArtigoOpiniao} />} />
              <Route path="/relacoespublicas" element={<ProtectedRoute component={RelacaoPublica} />} />
              <Route path="/comunicacao/:idCompanyUser" element={<ProtectedRoute component={PlanoDeComunicacao} />} />
              <Route path="/sugestaodepauta" element={<ProtectedRoute component={SugestaoPauta} />} />
              <Route path="/empresas" element={<ProtectedRoute component={Empresas} />} />
              <Route path="/empresas/:idCompany" element={<ProtectedRoute component={CompanyInformation} />} />
              <Route path="/sugestoes" element={<ProtectedRoute component={SugestoesDeConteudo} />} />
              <Route path="/sugestoes/:idSugestao" element={<ProtectedRoute component={SugestaoDetalhada} />}/>
              <Route path="sugerir-conteudo" element={<ProtectedRoute component={SugerirConteudo} />} />
              <Route path="/esqueci-minha-senha" element={<EsqueciMinhaSenha />} />
              <Route path="/neworder" element={<ProtectedRoute component={NewOrder} />} />
              <Route path="/resultados" element={<ProtectedRoute component={Resultados} />} />
            </Routes>
      </BrowserRouter>
  );
}


