import { useEffect, useState } from 'react';
import Pagination from "@mui/material/Pagination";
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import Company from './Company'
import {
    Container,
    Title,
    InnerContainer,
    Header,
    Companies,
    Image,
    Name,
    Email,
    ContainerPagination,
    IconSpan,
    RegistrationDate,
    Plan,
    Segmentation,
    SearchContainer,
    SearchInnerContainer
} from './style'
import api from '../../services/api';
import { ReadCompany } from '../../interfaces';
import { Search } from '@mui/icons-material';

export default function Empresas() {
    const [companies, setCompanies] = useState<ReadCompany[]>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isGrowingDate, setIsGrowingDate] = useState(false);
    const [filter, setFilter] = useState('')
    const totalElements = companies.length;
    const itemPorPage = 9;
    const totalPages = Math.ceil(totalElements / itemPorPage);
    const startIndex = (currentPage - 1) * itemPorPage;
    const endIndex = Math.min(startIndex + itemPorPage - 1, totalElements - 1);
    const filteredCompanies = companies.filter((company) => 
    company.user.name.toLowerCase().includes(filter.toLowerCase()))
    const currentItems = filteredCompanies.slice(startIndex, endIndex + 1);
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token ?? ''
    
    useEffect(() => {
        const promise = api.readCompanies(authToken)
        promise.then((response) => {
            setCompanies(response.data)
        }).catch((error) => {
            console.error(error)
        })
    },[authToken])

    
    function handleSort() {
        const sortedCompanies = [...companies].sort((a, b) => {
            const dateA = new Date(a.user.created_at ?? '');
            const dateB = new Date(b.user.created_at ?? '');
            if (isGrowingDate) {
                return dateA.getTime() - dateB.getTime();
            } else {
                return dateB.getTime() - dateA.getTime();
            }
        })
        setCompanies([...sortedCompanies]);
        setIsGrowingDate(!isGrowingDate);
      }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
      };

    

  return (
    <Container>
      <Title>Empresas</Title>
      <SearchContainer>
        <SearchInnerContainer>
            <Search/>
            <input type="text"
             placeholder="Pesquisar empresa"
             value={filter}
             onChange={e => setFilter(e.target.value)}/>
            </SearchInnerContainer>
        </SearchContainer>
      <InnerContainer>
        <Header>
            <Image></Image>
            <Name>
                Nome 
            </Name>
            <Email>
                Email
            </Email>
            <RegistrationDate>
                <>Data de cadastro {isGrowingDate ? 
                (<IconSpan onClick={handleSort}><ExpandLessIcon sx={{width: 25, height: 25}}/></IconSpan>) : 
                (<IconSpan onClick={handleSort}><ExpandMoreIcon sx={{width: 25, height: 25}}/></IconSpan>)} </>
            </RegistrationDate>
            <Plan>
                Plano
            </Plan>
            <Segmentation>
                Segmentação
            </Segmentation>
        </Header>
        <Companies>
            <Company company={currentItems[0]}/>
            <Company company={currentItems[1]}/>
            <Company company={currentItems[2]}/>
            <Company company={currentItems[3]}/>
            <Company company={currentItems[4]}/>
            <Company company={currentItems[5]}/>
            <Company company={currentItems[6]}/>
            <Company company={currentItems[7]}/>
            <Company company={currentItems[8]}/>
        </Companies>
        <ContainerPagination>
            <Stack spacing={2}>
                <Pagination
                renderItem={(item) => (
                    <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    />
                )}
                color="primary" 
                count={totalPages} 
                defaultPage={1}
                page={currentPage} 
                onChange={handleChange} />
            </Stack>
        </ContainerPagination>
      </InnerContainer>
    </Container>
  )
}
