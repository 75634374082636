import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 20.67rem;
padding: 0 ;
height: auto;
min-height: 4.58rem;
border-radius: 10px;
background: rgba(249, 249, 249, 0.40);
box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
margin: 0 0.563rem;
`
export const Title = styled.div`
    display: flex;
    justify-content: space-around;

    text-align: center;
    padding: 1rem 0;
    color: #333;
    font-family: Inter;
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 18.79rem;
    span{
        color: #5C49DD;
    font-family: Inter;
    font-size: 1.35rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
`

export const Button = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
`
