/* eslint-disable */
import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import * as S from './style';
import Tooltip from '../../components/Tooltip/Tooltip';
import Card from './Card';
import { Results } from './interface';
import NoticeImg from '../../assets/images/notice-resultados.svg';
import api from '../../services/api';
import { Loading } from '../../components';
import { ReadCompany } from '../../interfaces';

export default function Resultados() {
    const authToken = JSON.parse(localStorage.getItem("authToken") ?? '{}').access_token || '';
    const user = JSON.parse(localStorage.getItem("user") ?? '{}');
    const typeUser = user.role?.name || '';

    const [companyId, setCompanyId] = useState<number | undefined>();
    const [keywords, setKeywords] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<Results[]>([]);
    const [companies, setCompanies] = useState<ReadCompany[]>([]);
    const [orderBy, setOrderBy] = useState<number>(1);
    const [companySelected, setCompanySelected] = useState<number | undefined>(0);
    const [initialDataFetched, setInitialDataFetched] = useState(false);

    function mostRecentSearchDate(): string {
        if (results.length === 0) {
            return "";
        }

        // const datasSearchAt: number[] = results.map(result => new Date(result.search_at).getTime());
        // const timestampMaisRecente: number = Math.max(...datasSearchAt);

        const dataMaisRecente = new Date();

        const dia = String(dataMaisRecente.getDate()).padStart(2, '0');
        const mes = String(dataMaisRecente.getMonth() + 1).padStart(2, '0');
        const ano = dataMaisRecente.getFullYear();

        return `${dia}/${mes}/${ano}`;
    }

    function orderByDate(results: Results[], order: number): Results[] {
        const sortedResults = [...results];
    
        if (order === 1) {
            sortedResults.sort((a, b) => new Date(b.publish_at).getTime() - new Date(a.publish_at).getTime());
        } else if (order === 2) {
            sortedResults.sort((a, b) => new Date(a.publish_at).getTime() - new Date(b.publish_at).getTime());
        }
    
        return sortedResults;
    }
    
    const sortedResults = orderByDate(results, orderBy);
    
    function searchResults(): Results[] {
        const filteredResults = sortedResults.filter(row => 
            row.media.toLowerCase().includes(keywords.toLowerCase()) &&
            (companySelected === 0 || row.company_id === companySelected)
        );
    
        return filteredResults;
    }   

    useEffect(() => {
        let initialCompanyId;
        if (typeUser === "admin") {
            initialCompanyId = companySelected ?? (companies.length > 0 ? companies[0].id : undefined);
        } else if (typeUser === "company") {
            initialCompanyId = user?.company?.id;
        }
        setCompanyId(initialCompanyId);
    }, [typeUser, user, companies, companySelected]);

    useEffect(() => {
        if (typeUser === "admin") {
            const promise = api.readCompanies(authToken);
            promise.then((response) => {
                setCompanies(response.data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [authToken, typeUser]);

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            const promise = typeUser === "admin" ? api.getAllClips(authToken) : api.getCompanyClips(authToken, companyId);
            promise.then((response: any) => {
                setResults(response.data);
            }).catch((error: any) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [authToken, companyId, typeUser]);

    useEffect(() => {
        if (typeUser === "admin" && companies.length > 0) {
            setCompanySelected(companies[0].id);
        }
    }, [typeUser, companies]);

    return (
        <S.Container>
            {loading ? (
                <Loading color="black" withText={true} />
            ) : (
                <S.InnerContainer>
                    <S.HeaderContainer>
                        <S.Title>Meus Resultados</S.Title>
                        <S.TagBeta>Versão Beta</S.TagBeta>
                        <Tooltip
                            tooltipAlign="right"
                            tooltipContent={<p>Lembre-se de que resultados de mídias impressas (jornais e revistas) e de rádio e TV não aparecerão aqui!</p>}
                        />
                    </S.HeaderContainer>
                    <S.HeaderFilters>
                        <S.Box>
                            <S.LastSearch>
                                Última pesquisa em: <span>{mostRecentSearchDate()}</span>
                            </S.LastSearch>
                            <S.OrderBy>
                                <p>Ordernar por</p>
                                <S.SelectOrderBy value={orderBy} onChange={(e) => setOrderBy(Number(e.target.value))}>
                                    <option value={1}>Mais recente</option>
                                    <option value={2}>Mais antigo</option>
                                </S.SelectOrderBy>
                            </S.OrderBy>
                            {typeUser === "admin" && (
                                <S.OrderBy>
                                    <p>Empresa</p>
                                    <S.SelectOrderBy value={companySelected} onChange={(e) => setCompanySelected(Number(e.target.value))}>
                                        <option value={0}>Todas as empresas</option>
                                        {companies.map(company => (
                                            <option key={company.id} value={company.id}>{company.user.name}</option>
                                        ))}
                                    </S.SelectOrderBy>
                                </S.OrderBy>
                            )}
                        </S.Box>
                        <S.SearchInput>
                            <Search sx={{ color: "#6D6D6D", fontSize: 20 }} />
                            <input
                                placeholder='Buscar por veículo'
                                value={keywords}
                                onChange={(e) => setKeywords(e.target.value)}
                            />
                        </S.SearchInput>
                    </S.HeaderFilters>
                    {searchResults().length > 0 ? (
                    <S.Results>
                        {searchResults().map(result => (
                            <Card results={result} />
                        ))}
                    </S.Results>
                ) : (
                    <S.NoticeContainer>
                        <h1>Ainda não há resultados para você, mas não se preocupe, em breve eles aparecerão aqui!</h1>
                        <img src={NoticeImg} alt="noticia-imagem" />
                        <span>Lembre-se de que quanto mais pedidos de material você fizer maior a sua chance de aparecer na mídia!</span>
                    </S.NoticeContainer>
                )}
            </S.InnerContainer>
        )}
    </S.Container>
    );
}
