import {
    Buttons,
    Cancel,
    ClosePopup,
    Confirm,
    ConfirmText,
    Container,
    Subtitle,
    Title,
    Text,
    BackText,
    NextText,
    LeftButtons,
    LoadingContainer
} from './style'
import { ReadOneOrder } from '../../interfaces'
import Input from '../Input'
import { useEffect, useRef, useState } from 'react'
import usePopupTrueFalse from "../../hooks/usePopupTrueFalse";
import { Close } from '@mui/icons-material'
import api from '../../services/api'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { RotatingLines } from 'react-loader-spinner'
interface PopupGPTProps {
    order: ReadOneOrder | undefined
    authToken: string
    setShowPopupGPT: (value: boolean) => void
    setReload: (value: boolean) => void
}

export default function PopupGPT({order, authToken, setShowPopupGPT, setReload}: PopupGPTProps){
    const [showTexts, setShowTexts] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingDocs, setLoadingDocs] = useState(false)
    const {setErrorMessage, setTypeError, setShowPopup} = usePopupTrueFalse();
    const orderId = String(order?.id) ?? ''
    const [texts, setTexts] = useState<string[]>([])
    const [activeText, setActiveText] = useState(0)
    const canGoForward = activeText < texts.length - 1;
    const canGoBackward = activeText > 0;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const oldInputs = order?.order_info.map((info) => {
        return(
            {
                field_id: info.field.id,
                field_name: info.field.field_name,
                value: info.value,
                field: info.field
            }
        )
    })
    const [newInputs, setNewInputs] = useState(oldInputs)
    
    function handleStartProduction(){
        setLoadingDocs(true)
        const body={
            text: texts[activeText],
            inputs: returnInputs()
        }
        const promise = api.startOneOrderProduction(authToken, orderId, body)
        promise.then(() => {
            setShowPopupGPT(false)
            setReload(true)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setLoadingDocs(false)
        })
    }

    function setError(error: any){
        console.error(error)
        setTypeError(false)
        if(typeof error.response?.data?.detail === 'string'){
              setErrorMessage(error.response?.data?.detail)
        }else{
              setErrorMessage('Tente novamente em instantes!')
        }
        setShowPopup(true)
      }

    function returnInputs(){
        const inputsToSend = newInputs?.map((input) => {
            return(
                {
                    field_id: input.field_id,
                    value: input.value
                }
            )
        })
        return inputsToSend
    }

    function generateTextWithGPT(){
        setLoading(true)

        const body={
            inputs: returnInputs()
        }

        const promise = api.generateTextWithGPT(authToken, orderId, body)
        promise.then((response) => {
            setTexts([...texts, response.data])
            setShowTexts(true)
        }).catch((error) => {
            console.error(error)
            setError(error)
        }).finally(() => {
            setLoading(false)
            adjustTextareaHeight()
        })
    }
    
    const handleNextClick = () => {
        if (canGoForward) {
            setActiveText(state => state + 1);
            adjustTextareaHeight()
          }
    };

    const handleBackClick = () => {
        if (canGoBackward) {
            setActiveText(state => state - 1);
            adjustTextareaHeight()
          }
    }
    
    useEffect(() => {
        adjustTextareaHeight()
        if (texts.length > 0) {
            setActiveText(texts.length - 1);
          }
    },[texts.length])


    function adjustTextareaHeight() {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }

    return(
        <Container>
            {(loading || loadingDocs )? 
            <> 
            <LoadingContainer>
            <RotatingLines
                strokeColor={'#6d6d6d'}
                strokeWidth="5"
                animationDuration="0.75"
                width="35"
                visible={true}
                />
                {loading ? 
                <>
                    <h1>Estamos criando um template para você...</h1>
                    <h2>Em breve, ele aparecerá para você.</h2>
                </>:
                <>
                    <h1>Estamos criando o seu Docs com o template escolhido...</h1>
                    <h2>Em breve, ele aparecerá para você.</h2>
                </>}
            </LoadingContainer>
            </> 
            :(<>
            <ClosePopup onClick={() => setShowPopupGPT(false)}>
                <Close />
            </ClosePopup>
            {canGoForward && <NextText onClick={handleNextClick}>
                <ArrowForwardIosIcon />
            </NextText>}
            {canGoBackward && <BackText onClick={handleBackClick}>
                <ArrowBackIosNewIcon />
            </BackText>}
            
                {showTexts && 
                <>
                    <Text
                    ref={textareaRef}
                    disabled={true}
                    value={texts[activeText]}
                    />

                    <Buttons>
                        <Cancel onClick={() => setShowPopupGPT(false)}>Cancelar</Cancel>
                        <LeftButtons>
                            <Confirm onClick={generateTextWithGPT}>Novo texto</Confirm>
                            <ConfirmText onClick={handleStartProduction}>Escolher texto</ConfirmText>
                        </LeftButtons>
                    </Buttons>
                </>}
                {!showTexts && 
                <>
                <Title>Gerador de texto via Chat GPT</Title>
                <Subtitle>Essas foram as informações fornecidas pelo cliente. Entretanto, podem não ser boas o suficiente para gerar um texto via inteligência artificial. Por favor, especifique melhor essas informações para que o GPT consiga ter mais dados e trabalhar de forma mais eficiente.</Subtitle>
                {newInputs && newInputs?.map((info, indice) => {
                return (<Input 
                    disabled={false}
                    key={info.field_id} 
                    id={info.field_id} 
                    indice={indice} 
                    description={''} 
                    fieldName={info.field.field_name} 
                    value={info.value} 
                    input={info} 
                    inputs={newInputs} 
                    setInputs={setNewInputs}/>)
                })}
                
            <Buttons>
                <Cancel onClick={() => setShowPopupGPT(false)}>Cancelar</Cancel>
                <Confirm onClick={generateTextWithGPT}>Confirmar</Confirm>
            </Buttons>
            </>}
            </>)}
        </Container>
    )
}