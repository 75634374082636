import { FilesContainer, FilesContent } from "./style"
import download from '../../../assets/images/download.png'
import CloseIcon from '@mui/icons-material/Close';
interface FileProps{
  file: string
  orderId: string
  setFileInDeleteConfirmation: (value: string) => void;
  setShowConfirmDelete: (value: boolean) => void;
  typeUser: string;
}

const CONTAINER_NAME = process.env.REACT_APP_AZURE_CONTAINER_NAME

export default function Files({file, orderId, typeUser, setFileInDeleteConfirmation, setShowConfirmDelete}: FileProps) {
  const canDeleteFile = typeUser === 'admin' || typeUser === 'company'

  let nameFile = ''
    if(file?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}_`)){
      nameFile = file?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}_`)[1]
    } else if(file?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/order_file_${orderId}_`)){
      nameFile = file?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/order_file_${orderId}_`)[1]
    } else if(file?.includes(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}/`)){
      nameFile = file?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/${orderId}/`)[1]
    } else{
      nameFile = file?.split(`mention.blob.core.windows.net/${CONTAINER_NAME}/`)[1]
    }

    function confirmDeleteFile(event: any){
      event.stopPropagation()
      setFileInDeleteConfirmation(file)
      setShowConfirmDelete(true)
    }
  

    function limitarString(string: string, limite: number) {
        if (string?.length <= limite) {
          return string; 
        } else {
          return string?.slice(0, limite) + '...'; 
        }
      }

    function abrirURLNovaAba(url: string) {
        window.open(url, '_blank');
      }

  return (
    <FilesContainer onClick={() => abrirURLNovaAba(file)}>
      <FilesContent>
        <img 
          src={download} 
          title="Download" 
          alt=""
        /> 
          {limitarString(nameFile, 20)} 
          </FilesContent>
          {canDeleteFile && <div onClick={(e) => {
          confirmDeleteFile(e)
          }}>
            <CloseIcon/>
          </div>}
    </FilesContainer>
  )
}
