import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 20rem;
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: .5rem;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: .8rem;
`
export const Media = styled.span`
    font-weight: bold;
    font-size: .9rem;
`
export const Subtitle = styled.span`
    font-weight: 500;
    font-size: .9rem;
    color: #333333;
`
export const Date = styled.span`
    display:flex;
    align-items:center;
    gap: .5rem;
    color: #333333;

`
export const Button = styled.button`
    background-color: #5C49DD;

    color: #f9f9f9;
    font-family: Inter;

    border: none;
    border-radius: 2rem;

    padding: .5rem;

    cursor: pointer;

    a{
        text-decoration: none;
        color: #f9f9f9;
        font-family: Inter;
    }

`

export const ImageContent = styled.div<{$image: string}>`
    width: 100%;
    height: 10rem;
    background-image: url(${props => props.$image});
    background-size: cover;
    border-radius: .4rem;
`;