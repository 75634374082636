import styled from "styled-components";
import mentionFundo from '../../assets/images/mention_fundo.png'

const Container = styled.div`
    background-color: #E5E5E5;
    min-height: calc(100vh - 4rem);
    height: auto;
    width: 100%;
    background-image: url(${mentionFundo});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 16.5625rem auto;
    background-color: rgba(0, 0, 0, 0.1);
`

const Title = styled.div`
    color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3.5rem;
    padding-bottom: 2.625rem;
`

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 2rem;
    margin-right: 5%;
`

const SugerirConteudo = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 14rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

export {
    Container,
    Title,
    InnerContainer,
    Buttons,
    SugerirConteudo
}