import { Link } from 'react-router-dom'
import { ReadCompany } from '../../../interfaces'
import { acertarDatas } from '../../../utils'
import {
    Container,
    Image,
    Name,
    Email,
    Date,
    Plan,
    Segmentation
} from './style'

interface CompanyProps {
  company: ReadCompany
}

interface CompanyDataProps {
  company: ReadCompany
  hasUser: boolean
}

export default function Company({ company }: CompanyProps) {
  
  const hasUser = !!company

  if(hasUser) {
    return (
      <Link style={{textDecoration: 'none'}} to={`/empresas/${company?.id}`}>
          <CompanyData company={company} hasUser={hasUser} />
      </Link>
    )
  } else{
    return(
      <CompanyData company={company} hasUser={hasUser}/>
    )
  }
}

function CompanyData({ company, hasUser }: CompanyDataProps) {
  return(
    <Container hasUser={hasUser} plan={company?.plan?.plan_name}>
      <Image>
        {company?.user.name && <img 
        src={company?.user.profile_picture !== null ? 
        company?.user.profile_picture : 
        'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'} 
        alt="" />}
      </Image>
      <Name>
        {company?.user.name && company?.user.name} 
      </Name>
      <Email>
        {company?.user.email && company?.user.email}
      </Email>
      <Date>
        {company?.user.created_at && acertarDatas(company?.user.created_at ?? '')}
      </Date>
      <Plan>
        {company?.plan.plan_name && company?.plan.plan_name}
      </Plan>
      <Segmentation>
        {company?.segmentation?.segmentation && company?.segmentation.segmentation}
      </Segmentation>
    </Container>
  )
}