import {
    Container,
    Title,
    Text
} from './style'

export default function PressRelease() {
  return (
    <Container>
        <Title>Press Release</Title>
        <Text>
            <p>
                O press release, também conhecido como comunicado de imprensa, 
                é um documento oficial utilizado por empresas, organizações e 
                indivíduos para divulgar uma notícia ou anúncio relevante para a mídia. 
                O objetivo é obter a atenção da imprensa para que a notícia seja publicada 
                em veículos de comunicação, como jornais, revistas, sites de notícias, rádio e 
                televisão.
            </p>
            <p>
            O press release é um tipo de conteúdo informativo que é redigido em formato de texto 
            jornalístico, com linguagem objetiva e clara. Ele contém informações relevantes sobre 
            o assunto divulgado, como o título, o subtítulo, o lead (primeiro parágrafo do texto), 
            o corpo do texto, citações de porta-vozes, dados e estatísticas relevantes, 
            além dos contatos de imprensa da empresa ou organização.
            </p>
            <p>
                A estrutura é padronizada e segue um modelo básico, que inclui os seguintes elementos:
            </p>
            <ul>
                    <li>Título: é o resumo da notícia, escrito de forma clara e objetiva.</li>
                    <li>Subtítulo: é opcional e ajuda a complementar o título.</li>
                    <li>Lead: é o primeiro parágrafo do texto e deve conter as informações mais importantes da notícia.</li>
                    <li>Corpo do texto: é onde são apresentadas as informações mais detalhadas sobre o assunto divulgado.</li>
                    <li>Citações de porta-vozes: são trechos em que são citados os responsáveis pela notícia divulgada.</li>
                    <li>Dados e estatísticas: são informações numéricas que ajudam a dar contexto e relevância para a notícia.</li>
                    <li>Contatos de imprensa: são os dados de contato da pessoa responsável por fornecer mais informações sobre a notícia divulgada.</li>
                </ul>
            <p>
            O envio pode ser feito através de um e-mail ou por um sistema de distribuição de notícias. 
            Aqui na Mention, nossos envios são automatizados através da nossa plataforma especializadas 
            em distribuir press releases para diversos veículos de comunicação. É um material importante 
            para a divulgação de notícias relevantes e de interesse público, sendo uma ferramenta útil 
            para empresas e organizações que desejam obter cobertura jornalística sobre suas atividades 
            e projetos.
            </p>
        </Text>
    </Container>
  )
}
