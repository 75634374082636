import styled from "styled-components";
import seta from '../../../assets/images/seta.png'

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

const InnerContainer = styled.div`
width: 100%;
height: 51.87rem;
border-radius: 1.5rem;
background-color: #f9f9f9;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
position: relative;
z-index: 100;
`

const LongInput = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: space-between;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;
input{
    width: 18.75rem;
    height: 2.65625rem;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1.875rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
}

select{
    width: 19.5rem;
    height: 2.65625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #6D6D6D50;
    background-color: white;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1.875rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
    align-items: center;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${seta}) no-repeat center right #FFF;
    font-size: 0.9375rem;
    padding-right: 1.25rem;
    background-size: 1.25rem;
    background-position: 17.5rem 0.625rem; 

}


input::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
}
`

const FormsContainer = styled.div`
width: max-content;

    button{
        width: 10.75rem;
        height: 2.65625rem;
        border: none;
        border-radius: 1.875rem;
        background-color: #5C49DD;
        color:#f9f9f9;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.21rem;
        margin-top: 0.3125rem;
        cursor: pointer;
    }

`
const Close = styled.div`
position: absolute;
right: 1.5625rem;
top: 1.5625rem;
cursor: pointer;
`

const Icon = styled.div`
    cursor: pointer;
`

const BackgroundPopup = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index:9999;   
`

const CheckBoxInput = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: space-between;
margin: 0.9375rem 0 0.9375rem;
font-size: 1rem;
input{
    width: 18.75rem;
    height: 2.65625rem;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-left: 1.875rem;
    @media(max-width: 1500px){
        margin-left: 0;
        margin-right: 0;
    }
}


input::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
}
`

export {
    Container,
    InnerContainer,
    Close,
    FormsContainer,
    LongInput,
    Icon,
    BackgroundPopup,
    CheckBoxInput
}