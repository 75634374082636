import { ReadSugestedOrders } from '../../../interfaces';
import { Link } from 'react-router-dom';
import {
    Container,
    LeftContent,
    Data,
    ContractDate,
    ButtonContainer,
    VerDetalhes,
    Comment,
    RightContent,
    Information,
    Company,
    Sugestor
} from './style';
import { acertarDatas } from '../../../utils';
import { useRef, useEffect } from 'react';

interface InfoSugestaoProps {
    sugestao: ReadSugestedOrders
}

export default function InfoSugestoesCompany({ sugestao }: InfoSugestaoProps) {
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? '';
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        adjustTextareaHeight();
    }, []);

    function adjustTextareaHeight() {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    return (
        <Container>
            <LeftContent>
                <h1>{'#' + sugestao.id + ' - ' + sugestao.product.product_name}</h1>
                
                {(typeUser === 'admin' || typeUser === 'editor') ? (
                    <Information>
                        <Company>
                            <h1>Empresa:</h1>
                            <h2>{sugestao?.company?.user?.name}</h2>
                        </Company>
                            
                        <Sugestor>
                            <h1>Quem sugeriu a pauta:</h1>
                            <h2>{sugestao?.user?.name}</h2> 
                        </Sugestor>
                    </Information>
                ) : undefined}
                
                <Data>
                    <ContractDate>
                        <h1>Data da sugestão:</h1>
                        <h2>{acertarDatas(sugestao.created_at)}</h2>
                    </ContractDate>
                    <ButtonContainer>
                        <Link style={{ textDecoration: 'none' }} to={`/sugestoes/${sugestao.id}`}>
                            <VerDetalhes>Ver detalhes</VerDetalhes>
                        </Link>
                    </ButtonContainer>
                </Data>
            </LeftContent>
            <RightContent>
                <Comment>
                    <textarea ref={textareaRef} disabled>
                        {sugestao?.comment}
                    </textarea>
                </Comment>

            </RightContent>
        </Container>
    )
}