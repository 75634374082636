import {AxiosError} from 'axios';

export function handleInvalidCredentials(error: any): Promise<AxiosError> {
    if (error.response && error.response.status === 401 && error.response.data.detail === "Could not validate credentials") {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        window.location.href = '/login';
    } 

    return Promise.reject(error)
}