import { Close } from '@mui/icons-material';
import { useState } from 'react';
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import api from '../../../services/api';
import Loading from '../../Loading';
import {
  Container,
  ClosePopup,
  ConfirmDelete,
  Voltar,
  InnerContainer
} from './style'

interface PopupConfirmationProps{
  setShowConfirmDelete: (value: boolean) => void;
  orderId: string;
  userId: string;
  publishedNewsInConfirmation: PublishedNewsInConfirmation
  authToken: string;
  publishedNews: PublishedNews[];
  setPublishedNews: (value: PublishedNews[]) => void;
  setConfirmDeletePublishedNews: (value: boolean) => void;
}

interface PublishedNews {
url: string,
id: number,
}

interface PublishedNewsInConfirmation{
  id: number,
  title: string
}

export default function PopupConfirmationPublishedNews({orderId, publishedNews, publishedNewsInConfirmation, authToken, setConfirmDeletePublishedNews, setShowConfirmDelete, setPublishedNews}: PopupConfirmationProps) {

  const [loading, setLoading] = useState(false)
  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse();
  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
        setErrorMessage(error.response?.data?.detail)
    }else{
        setErrorMessage('Tente novamente em instantes!')
        }
      setShowPopup(true)
  }

  function deletePublishedNews(){
    setLoading(true)
    const promise = api.RemovePublishedNews(authToken, orderId, publishedNewsInConfirmation.id)
    promise.then(() => {
      const newPublishedNews = publishedNews.filter(publishedNew => publishedNew.id !== publishedNewsInConfirmation.id)
      setPublishedNews(newPublishedNews)
      setConfirmDeletePublishedNews(false)
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  if(loading){
    return(
      <Container>
        <Loading color="black" />
      </Container>
    )
  }

  return (
    <Container>
      <ClosePopup onClick={() => setShowConfirmDelete(false)}>
        <Close />
      </ClosePopup>
      <h1>Você realmente quer apagar o comentário?</h1>
      <InnerContainer>
        <h2>{publishedNewsInConfirmation?.title}</h2>

      </InnerContainer>
      

      <div>
        <Voltar onClick={() => {setShowConfirmDelete(false)}}>
          Voltar
        </Voltar>
        <ConfirmDelete onClick={deletePublishedNews}>
          Confirmar
        </ConfirmDelete>
      </div>
    </Container>
  )
}
