import styled from 'styled-components';

const ContentInputs = styled.div`
display: flex;
flex-wrap: wrap;
height: auto;
textarea:disabled {
  background-color: #fff;
  color: #000;
}

@media(max-width: 1275px){
    width: 62rem;
}

@media(max-width: 935px){
    width: 54rem;
}

@media(max-width: 835px){
    display: flex;
    flex-direction: column;
    width: 30rem;
}

@media(max-width: 700px){
    width: 30rem;
}
`

const ContainerInputs = styled.div`
display: flex;
max-width: 104.8rem;
width: auto;
align-items: center;
justify-content: center;
display: inline-block;
height: auto;

`

const Buttons = styled.div`
padding-top: 2rem;
`

const Voltar = styled.button`
cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#5C49DD;
    width: 9.5625rem;
    height: 3.125rem;
    border-radius: 1.875rem;
    border: 0.0625rem solid #5C49DD;
    background-color: #f9f9f9;
    transition: 200ms;

    :hover{
        border: 0.0625rem solid #f9f9f9;
        background-color: #5c49dd;
        color: #f9f9f9;
    }
`

const InformationContainer = styled.div`
padding-top: 1rem;
display: flex;
padding-bottom: 1.5rem;
`
const CompanyImage = styled.div`
margin-right: 2rem;
`

const CompanyName = styled.div`
display:flex;
align-items: center;
justify-content: center;
h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 2rem;
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`

const CompanyPlan = styled.div`
display:flex;
align-items: center;
justify-content: center;
h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 2rem;
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`

const CompanyOrders = styled.div`
display:flex;
align-items: center;
justify-content: center;
h1{
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    padding-right: 2rem;
    color: #333333;
}
span{
    font-weight: 500;
    color: #5C49DD;
}
`
const CompanyDetails = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
@media(max-width: 936px){
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    }
`

interface CompanyComunicationProps {
    hasPlan: boolean;
}


const ComunicationButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
width: 19.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1.1rem;
line-height: 1.21rem;
text-align: center;
@media(max-width: 835px){
    width: 18.75rem;
    margin-right: 2rem;
    text-align: center;
}
`


interface CompanyComunicationProps {
    hasPlan: boolean;
}

const CompanyComunication = styled.div`
display:flex;
align-items: center;
justify-content: center;

h1{
    display: flex;
    align-items: flex-end;
    font-size: 1.125rem;
    line-height: 1.5125rem;
    color: #5C49DD;
    margin-right: 1.5rem;
}
`

const ComunicationSpan = styled.span`
font-weight: 300;
font-size: 1.125rem;
line-height: 1.5125rem;
color: #333333;
`
const ComunicationPlanSpan = styled.span<CompanyComunicationProps>`
${props => props.hasPlan ? 'cursor: pointer;' : ''}
font-weight: 500;
color: #5C49DD;
display: flex;
align-items: center;
margin-left: 0.6rem;
`

export {
    ContentInputs,
    ContainerInputs,
    Buttons,
    Voltar,
    InformationContainer,
    CompanyImage,
    CompanyName,
    CompanyPlan,
    CompanyOrders,
    CompanyComunication,
    CompanyDetails,
    ComunicationButton,
    ComunicationSpan,
    ComunicationPlanSpan
}