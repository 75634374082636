import { ButtonResetProps } from '../../../interfaces'
import Loading from '../../../components/Loading'
import { ButtonSubmit } from './style'


export default function ButtonReset({ isLoading }: ButtonResetProps) {
    return (
        <ButtonSubmit type="submit">
            {isLoading ? <Loading color="white"/> : "Redefinir senha"}
        </ButtonSubmit>
    )
}