import styled from "styled-components";

interface ContainerProps{
    hasUser: boolean;
    plan?: string;
}

const Container = styled.div<ContainerProps>`
width: 100%;
height: 5.111rem;
display: flex;
:hover{
${props => props.hasUser ? 'cursor: pointer;' : ''}
background: rgba(92, 73, 221, 0.2);
}
${props => props.plan === 'Inativo' ? 'background: rgba(255, 0, 0, 0.15);' : props.plan === 'Plano Pausado' ? 'background: rgba(255, 165, 0, 0.2);' : ''}
font-weight: 500;
font-size: 1.2rem;
line-height: 1.428rem;
border-bottom: 1px solid #e2e2e2;
color: #333333;
@media(max-width: 1735px){
    width: 100%;
}
@media(max-width: 700px){
    font-size: 1rem;
    line-height: 1.228rem;
}
`

const Image = styled.div`
width: 10%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
justify-content: center;
img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
@media(max-width: 700px){
    img{
        width: 2rem;
        height: 2rem;
    }
    min-width: 4rem;
}
`

const Name = styled.div`
width: 25%;
min-width: 9.5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 25%;
    min-width: 9.5rem;
}
@media(max-width: 700px){
    min-width: 7rem;
}
`

const Email = styled.div`
width: 25%;
min-width: 16rem;
height: 5.187rem;
display: flex;
align-items: center;
`

const Date = styled.div`
width: 20%;
min-width: 16rem;
height: 5.187rem;
display: flex;
align-items: center;
`

const Plan = styled.div`
width: 10%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 10%;
    min-width: 5rem;
}
@media(max-width: 764px){
    display: none;
}
`

const Segmentation = styled.div`
width: 10%;
min-width: 5rem;
height: 5.187rem;
display: flex;
align-items: center;
@media(max-width: 1735px){
    width: 10%;
    min-width: 5rem;
}
@media(max-width: 764px){
    display: none;
}
`

export {
    Container,
    Image,
    Name,
    Email,
    Date,
    Plan,
    Segmentation
}