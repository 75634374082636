import { useState } from 'react'
import { ReadMyOrders, ReadCommunicationPlans } from '../../../interfaces'
import CardNewHome from '../CardNewHome'
import * as S from './style'
import OrderUp from '../../../assets/icons/arrow-up-wide-narrow.svg'
import OrderDown from '../../../assets/icons/arrow-down-narrow-wide.svg'
import CardCommunicationPlan from '../CardCommunicationPlan'

interface RowProps{
  orders: ReadMyOrders[] | undefined
  status: string
  communication_plans: ReadCommunicationPlans[] | undefined
  setDelegateOrder: (value: boolean) => void
  setOrderIdInDelegate: (value: string) => void
  setCompanyUserIdInDelegate: (value: string) => void
  setDelegateCommunicationPlan: (value: boolean) => void
}

export default function Row({status, orders, communication_plans, setDelegateOrder, setOrderIdInDelegate, setCompanyUserIdInDelegate, setDelegateCommunicationPlan}: RowProps) {        
    const ordersPlusCommunicationPlans = (orders?.length ?? 0) + (communication_plans?.length ?? 0)
    const [orderByDate, setOrderByDate] = useState(true)
    
    const sortOrdersByDate = (orderA: ReadMyOrders, orderB: ReadMyOrders) => {
        const dateA = new Date(orderA.deadline);
        const dateB = new Date(orderB.deadline);
    
        return orderByDate ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      };


    if (status === "Delegado" && orders !== undefined) {
        orders.sort((a, b) => sortOrdersByDate(a, b) || a.id - b.id);
    }

  return (
    <S.Container>
        <S.Title><p>{status} <span>({ordersPlusCommunicationPlans})</span></p>
            <S.Button 
                onClick={() => setOrderByDate(!orderByDate)}
                title={orderByDate ? "Mais recentes" : "Mais antigos"}
            >
                {orderByDate ? <img src={OrderUp} width={20} alt="orderup"/> : <img src={OrderDown} width={20} alt="orderdown"/> }
            </S.Button>
        </S.Title>
        
        {communication_plans?.map(item => {
          return(
            <CardCommunicationPlan key={item.id} status={status} communication_plan={item} setDelegateCommunicationPlan={setDelegateCommunicationPlan} setCompanyUserIdInDelegate={setCompanyUserIdInDelegate}/>
          )
        })}
    
        {orders?.sort(sortOrdersByDate).map(item => {
          return(
            <CardNewHome key={item.id} order={item} setDelegateOrder={setDelegateOrder} setOrderIdInDelegate={setOrderIdInDelegate}/>
          )
        })}
    </S.Container>
  )
}