import styled from "styled-components";
import mentionL from '../../assets/images/mention_l.png'

const Container = styled.div`
display: flex;
@media(max-width: 1170px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`

const Image = styled.div`
display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 50vw;
        background-color: #5C49DD;
        @media(max-width: 1170px){
            display: none;
        }
`

const Forms = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
width: 50vw;
background-color: #f9f9f9;
@media(max-width: 1170px){
    width: 100vw;
    flex-direction: column;
}
`

const Logo = styled.div`
@media(max-width: 1170px){
    background-color: #f9f9f9;
    width: 21.875rem;
    height: 7.5rem;
    background-image: url(${mentionL});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18.75rem auto;
    margin-bottom: 4.6875rem;
} 
`

export {
    Container,
    Image,
    Forms,
    Logo
}