import { useState } from 'react'
import Informations from './Information'
import {
  Container,
  Title,
  InnerContainer,
  Dados
} from './style'

export default function Configuracao() {
  const [aba, setAba] = useState(0)
  return (
    <Container aba={aba}>
       <Title>Minha conta</Title>
      <InnerContainer>
        <Dados>
          <Informations aba={aba} setAba={setAba}/>
        </Dados>
      </InnerContainer>
    </Container>

  )
}
