import mentionLogo from '../../../assets/images/mention_logo.png'
import { Container } from './style'

export default function ImageLogin() {
  return (
    <Container>
        <img src={mentionLogo} alt="Logo Mention"/>
        <h1>Fazendo seu negócio ser <span>notado.</span></h1>
    </Container>
  )
}
