export default function CardNewHomeSvg() {
    return (
      <>
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
        >
          <path
            d="M4.75 1V3.25M10.75 1V3.25M1.375 6.3175H14.125M10.5212 9.775H10.528M10.5212 12.025H10.528M7.74625 9.775H7.75375M7.74625 12.025H7.75375M4.9705 9.775H4.978M4.9705 12.025H4.978M14.5 5.875V12.25C14.5 14.5 13.375 16 10.75 16H4.75C2.125 16 1 14.5 1 12.25V5.875C1 3.625 2.125 2.125 4.75 2.125H10.75C13.375 2.125 14.5 3.625 14.5 5.875Z"
            stroke="#6D6D6D"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    )
  }
  
  
  