import styled from "styled-components";

export const Container = styled.div`
    box-shadow: 0.3125rem 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
`

export const Image = styled.div`
img{
    width: 22.95rem;
    height: 10rem;
    border-radius: .5rem .5rem 0 0;
    object-fit: cover;
}
`

export const Data = styled.div`
    background-color: #f9f9f9;
    width: 21rem;
    height: 10rem;
    position: relative;

    padding: 2rem 1rem;
    border-radius: 0 0 .5rem .5rem;

    display:flex;
    flex-direction:column;
    align-items: start;
    justify-content:space-between;
    gap: 1rem;
    height: 10rem;

`

export const Information = styled.div`
    display:flex;
    flex-direction: column;
    gap: .5rem;

    h1{
        font-weight: 600;
        color: #333333;

        font-size: 1rem;
    }
    h3{
        font-size: .9rem;
        line-height: 1.5rem;
        color: #333333;
        strong {
            font-weight: bold;
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MoreInformation = styled.button`
    background-color: #5C49DD;
    color:#f9f9f9;
    position: absolute;
    bottom: 1rem;
    gap: 1rem;

    border-radius: 2rem;
    border: none;

    cursor: pointer;

    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-weight: bold;

    padding: .3rem 1.5rem;
`