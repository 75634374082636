import React, { useState, ChangeEvent, DragEvent } from "react";
import usePopup from "../../hooks/usePopupTrueFalse";
import api from "../../services/api";
import { InnerDragAndDrop } from "./style";


interface DragAndDropUploaderProps{
    authToken: string;
    orderId: string;
    setReload: (value: boolean) => void;
}

function DragAndDropUploader({authToken, orderId, setReload}:DragAndDropUploaderProps) {
  const [isUploading, setIsUploading] = useState(false);
  const { setErrorMessage, setTypeError, setShowPopup} = usePopup()
  
  function handleFileUpload(file: File){
    if (file) {
      const body = new FormData();
      body.append("files", file);

      const promise = api.sendOrderFiles(authToken, orderId, body)
      promise.then(()=>{
        setReload(true)
      }).catch(() => {
         setTypeError(false)
         setErrorMessage("Erro ao enviar arquivo")
          setShowPopup(true)
      }).finally(() => {
        setIsUploading(false);
      })
    }
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsUploading(true);
      handleFileUpload(event.target.files[0]);
    }
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        setIsUploading(true);
        handleFileUpload(event.dataTransfer.files[0]);
    }
  };


  return (
    <div>
      <InnerDragAndDrop
      isUploading={isUploading}
      onDrop={handleFileDrop}
      onDragOver={(event) => event.preventDefault()}
      >
            {isUploading && (
              <>
                <h4>Enviando arquivo...</h4>
              </>
            )}

            {!isUploading && (
            <>
            <h4>Arraste e solte o arquivo aqui</h4>
            <h4>ou</h4>
            <h4><span>Escolha do seu computador</span></h4>
            </>)}
            <input type="file" onChange={handleFileSelect} />
        </InnerDragAndDrop>
    </div>
  );
};


export default DragAndDropUploader;
