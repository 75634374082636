import {
    Container,
    Title,
    Content,
    LeftContent,
    RightContent,
    Line,
    ProductName,
    ProductDescription,
    ContainerInputs,
    NextButton,
    BackButton,
    ContainerButtons,
    FilesContainer,
    InnerFiles,
    DragAndDropContainer,
    AllFiles,
    AllFilesContainer,
    AllFilesContent,
    BackgroundPopup,
    TitleInput,
    LoadingContainer
} from './style'

import form from '../../assets/images/form.png'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse';
import { ReadOneProduct, RequiredFieldsProduct } from '../../interfaces';
import api from '../../services/api';
import Input from '../../components/Input';
import DragAndDropUploaderNewOrder from '../../components/DragAndDropNewOrder';
import Files from './Files';
import UpgradePlanPopup from '../../components/UpgradePlanPopup';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { RotatingLines } from  'react-loader-spinner'

export default function NewOrder() {
    const { idProduto } = useParams()
    const idUrlProduto = idProduto ?? ''
    const token = localStorage.getItem('authToken') ?? '{}';
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? ''
    const authToken = JSON.parse(token).access_token ?? ''
    const [loading, setLoading] = useState(true)
    const [inputs, setInputs] = useState<RequiredFieldsProduct[]>([])
    const [infoProduct, setInfoProduct] = useState<ReadOneProduct>({id:0, product:'', product_name: '', description: '', short_description: '', deadline_in_days: 0, required_fields:[],picture:''})
    const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse();
    const [upgradePlan, setUpgradePlan] = useState(false)
    const [startIndex, setStartIndex] = useState(0);
    const [files, setFiles] = useState(false);
    const [userOrderFiles, setUserOrderFiles] = useState<File[]>([])
    const [showMoreFiles, setShowMoreFiles] = useState(false)
    const [activePage, setActivePage] = useState(0)
    const fieldsPerPage = 3; 
    const numberPages = Math.ceil(inputs.length / fieldsPerPage) + 1;
    const navigate = useNavigate()
    const canSendOneOrder = typeUser === 'company'
    const handleNextClick = () => {
        setStartIndex(startIndex + fieldsPerPage);
        setActivePage(state => state + 1)
        if(startIndex + fieldsPerPage >= inputs.length){
            setFiles(true)
        }
    };

    const handleBackClick = () => {
        setStartIndex(startIndex - fieldsPerPage);
        setActivePage(state => state - 1)
        if(startIndex - fieldsPerPage < inputs.length){
            setFiles(false)
        }
    }

    useEffect(() => {
        const promise = api.readOneProduct(authToken,Number(idUrlProduto))
      promise.then((response) => {
        setInfoProduct(response.data)
        setInputs(response.data.required_fields)
      }).catch((error) => {
        console.error(error)
        setTypeError(false)
        if(typeof error.response?.data?.detail === 'string'){
              setErrorMessage(error.response?.data?.detail)
        }else{
              setErrorMessage('Tente novamente em instantes!')
        }
        setShowPopup(true)
      }).finally(() => {
        setLoading(false)
      })
       // eslint-disable-next-line
      },[authToken, idUrlProduto])
    

    function handleCreateNewOrder(){
        setLoading(true)
        if(ValidateForms()) {
          const newData: Array<object> = []
          inputs?.forEach((input: RequiredFieldsProduct) => {
            newData.push({
              field_id: input.id,
              value: input.value
             })
          })
          const promise = api.makeOneOrder(authToken,newData,idUrlProduto)
          promise.then((response)=>{
              if (userOrderFiles.length > 0) {
                const body = new FormData();
                userOrderFiles.forEach((file, index) => {
                  body.append(`files`, file);
              })
                const otherPromise = api.sendOrderFiles(authToken, response.data.id, body)
                otherPromise.then(()=>{
                  navigate(`/pedidos/${response.data.id}`)
                })
                .catch(() => {
                   setTypeError(false)
                   setErrorMessage("Erro ao enviar os arquivo. Envie-os novamente!")
                   navigate(`/pedidos/${response.data.id}`)
                  setShowPopup(true)
                })
              }else {
                navigate(`/pedidos/${response.data.id}`)
              }
          })
          .catch((error)=>{
            setLoading(false)
            setTypeError(false)
            console.error(error)
            if(error.response?.status === 429){
              setUpgradePlan(true)
            }else {
                if(typeof error.response?.data?.detail === 'string'){
                setErrorMessage(error.response?.data?.detail)
              }else{
                setErrorMessage('Tente novamente em instantes!')
              }
              setShowPopup(true)
            }
          })
        } else {
          setLoading(false)
          setTypeError(false)
          setErrorMessage('Você não respondeu todas as informações obrigatórias!')
          setShowPopup(true)
        }
     } 
    
    function ValidateForms(){
        let requiredInputs: Array<RequiredFieldsProduct> = []
        requiredInputs = inputs.filter(json => json.hasOwnProperty("is_required") && json.is_required);
    
        let isValid = true
        requiredInputs?.forEach((input: RequiredFieldsProduct) => {
          if (!input.value || input.value.length <= 0) {
            isValid = false;
          }
        })
        return isValid
    }

  if(loading){
    return(
    <Container>
      <Title>Produtos Disponíveis</Title>
      <LoadingContainer>
      <RotatingLines
        strokeColor={'#6d6d6d'}
        strokeWidth="5"
        animationDuration="0.75"
        width="35"
        visible={true}
        />
        <h1>Estamos finalizando o seu pedido...</h1>
        <h2>Em breve, você será redirecionado para o seu pedido.</h2>
      </LoadingContainer>
    </Container>)
  }


  return (
    <Container>
      {(upgradePlan) && 
      (<BackgroundPopup>
        {upgradePlan && <UpgradePlanPopup setUpgradePlan={setUpgradePlan}/>}
      </BackgroundPopup>)}
      <Title>Produtos Disponíveis</Title>
        <Content>
            <LeftContent>
                <img src={form} alt="form" />
                <ProductName>
                  {infoProduct?.product_name} 
                </ProductName>
                <ProductDescription>
                <div dangerouslySetInnerHTML={{ __html: infoProduct?.description }} />
                </ProductDescription>
            </LeftContent>
            <Line></Line>
            <RightContent>
            <ContainerInputs>

            <Box sx={{ width: '100%', marginBottom: '2.5rem' }}>
                <Stepper activeStep={activePage} alternativeLabel>
                    {getNSteps(numberPages).map(item => {
                        return item
                    })}
                </Stepper>
            </Box>
            
            {!files && <TitleInput file={files}>Informações Necessárias</TitleInput>}
            {files && <TitleInput file={files}>Arquivos Extras</TitleInput>}

            {!files && infoProduct.required_fields.slice(startIndex, startIndex + fieldsPerPage).map((input, index) => (
                <Input
                    key={input.id} 
                    input={input}
                    description={input.short_description}
                    example={input.example}
                    value={input.value}
                    fieldName={input.field_name}
                    inputs={inputs}
                    id={input.id}
                    setInputs={setInputs}
                    indice={startIndex + index} 
                    is_required={input.is_required}
                />
            ))}
            {files && <>
              
              <FilesContainer>
                  <InnerFiles>
                     <DragAndDropContainer>
                      <DragAndDropUploaderNewOrder 
                      authToken={authToken} 
                      orderId={idUrlProduto ?? ''}
                      setReload={() => {}}
                      setUserOrderFiles={setUserOrderFiles}
                      userOrderFiles={userOrderFiles}
                       />
                    </DragAndDropContainer>
                    <AllFiles 
                    isOpen={showMoreFiles}>
                      <div>
                    <h2>Arquivos adicionados</h2>
                    {(showMoreFiles === false) && userOrderFiles && userOrderFiles?.slice(0, 3)?.map((file, index) => {
                      return(
                        <>
                        <Files 
                          key={index} 
                          file={file} 
                          setUserOrderFiles={setUserOrderFiles} 
                          userOrderFiles={userOrderFiles}/>
                        </>
                      )
                    })}

                    {(showMoreFiles === true) && userOrderFiles && (
                      <AllFilesContainer>
                        <h4 onClick={() => setShowMoreFiles(false)}>Mostrar menos</h4>
                        <AllFilesContent>
                        {userOrderFiles?.map((file, index) => {
                          return(
                            <Files 
                            key={index} 
                            file={file} 
                            setUserOrderFiles={setUserOrderFiles} 
                            userOrderFiles={userOrderFiles}/>
                          )
                          })}
                        </AllFilesContent>
                      </AllFilesContainer>
                    )}
                    {userOrderFiles && userOrderFiles?.length === 0 && (<>
                    Nenhum arquivo adicionado
                    </>)}
                    </div>
                    {userOrderFiles && userOrderFiles?.length > 3 && (showMoreFiles === false) && <h4 onClick={() => setShowMoreFiles(true)}>Mostrar todos</h4>}
                    </AllFiles>
                  </InnerFiles>
                </FilesContainer>

          </>}
          </ContainerInputs>
            <ContainerButtons>
                {startIndex > 0 && <BackButton onClick={handleBackClick}>Anterior</BackButton>}
                {!files && <NextButton onClick={handleNextClick}>Próximo</NextButton>}
                {files && canSendOneOrder && <NextButton onClick={handleCreateNewOrder}>Enviar</NextButton>}
            </ContainerButtons>
            </RightContent>
        </Content>
    </Container>
  )
}

function getNSteps(n: number) {
  const components = [];
  
  for (let i = 0; i < n; i++) {
    components.push(
    <Step>
      <StepLabel></StepLabel>
    </Step>);
  }

  return components;
}