import { useState } from 'react'
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import {
    Container,
    ClosePopup,
    LongInput
} from './style'
import CloseIcon from '@mui/icons-material/Close'
import { Loading } from '../../../components';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';

interface SolicitarProducaoProps{
    setShowRequestProduction: any;
    authToken: string;
    idSugestao: string;
}

export default function SolicitarProducao({setShowRequestProduction, authToken, idSugestao}: SolicitarProducaoProps) {

    const [info, setInfo] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setTypeError, setErrorMessage, setShowPopup } = usePopupTrueFalse()

    function promiseHandling(promise: Promise<any>) {
        promise.then((response) => {
            setLoading(false)
            const idPedido = response.data.id
            navigate('/pedidos/' + idPedido)
        }).catch((error) => {
            setTypeError(false)
            if(typeof error.response?.data?.detail === 'string') {
                setErrorMessage(error.response?.data?.detail)
            } else{ 
                setErrorMessage('Tente novamente em instantes!')
            }
            setShowPopup(true)
            setLoading(false)
            setShowRequestProduction(false)
        })
    }

    function approveSugestedOrder(){
        setLoading(true)

        const body = {
            additional_info: info
        }
        
        const promise = api.approveSugestedOrder(authToken, body, idSugestao)
        promiseHandling(promise)
    }

    if(loading){
        return(
          <Container>
            <Loading color='black' withText={true}/>
          </Container>
        )
      }

    return (
        <Container>
            <h1>Solicitar produção de conteúdo</h1>
            <ClosePopup onClick={() => setShowRequestProduction(false)}><CloseIcon/></ClosePopup>
            <LongInput>
                <textarea
                    placeholder="Adicione as informações pedidas/inforações adicionais..."
                    value={info}
                    onChange={e => setInfo(e.target.value)} />
            </LongInput>
            <button onClick={approveSugestedOrder}>Solicitar produção</button>
        </Container>
    )
}