import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReadMyOrders } from '../../../interfaces'
import { acertarDatas } from '../../../utils'
import warning from '../../../assets/images/aviso.png'
import {
    Container,
    InnerContainer,
    Company,
    Priority,
    ProductName,
    DateContainer,
    EditorContainer,
    CardTop,
    CardBottom,
    Warning,
    Delegate,
} from './style'
import CardNewHomeSvg from './Svg'

interface CardNewHomeProps{
  order: ReadMyOrders
  setDelegateOrder: (value: boolean) => void
  setOrderIdInDelegate: (value: string) => void
}

export default function CardNewHome({order, setDelegateOrder, setOrderIdInDelegate}: CardNewHomeProps) {
  const pictureCompany = order?.company?.user?.profile_picture ?? ''
  const pictureEditor = order?.editor?.user?.profile_picture ?? ''
  const isLateDate = (order && new Date(order.deadline) < new Date());
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''
  const isAdmin = typeUser === 'admin'

  function handleDelegate(e: any){
    e.preventDefault()
    e.stopPropagation()
    setOrderIdInDelegate(order.id as unknown as string)
    setDelegateOrder(true)
  }

  return (
    <Container>
      <Link style={{textDecoration: 'none'}} to={`/pedidos/${order?.id}`}>
        <InnerContainer>
            <CardTop>
            <Company><Avatar 
              sx={{width:20, height:20}}
              src={pictureCompany !== '' ? pictureCompany : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}></Avatar><span>{order?.company?.user?.name}</span></Company>
            {order?.priorities?.priority && <Priority priority={order?.priorities?.priority ?? 0}>{order?.priorities?.name}</Priority>}
            </CardTop>
            <ProductName>{order?.product?.product_name} <span>#{order?.id}</span></ProductName>
            <CardBottom>
                <DateContainer>
                  {(isLateDate) && (<Warning>
                    <img src={warning} alt="Pedido atrasado" />
                </Warning>)}
                  <CardNewHomeSvg/> {acertarDatas(order?.deadline)}
                </DateContainer>
                {order?.editor?.user?.name && <EditorContainer><Avatar 
                sx={{width:20, height:20}}
                src={pictureEditor !== '' ? pictureEditor : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}></Avatar><span>{order?.editor?.user?.name}</span>  </EditorContainer>}
                {!order?.editor?.user?.name && isAdmin && <EditorContainer onClick={(e) => handleDelegate(e)}><Delegate>Delegar pedido</Delegate></EditorContainer>}
            </CardBottom>
        </InnerContainer>
        </Link>
    </Container>
  )
}