import { useState, useEffect } from 'react'
import { Loading } from '../../components'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import { CompanyProps, EditorProps, ReadMyOrders } from '../../interfaces'
import api from '../../services/api'
// import InfoPedidos from './InfoPedidos'
import SearchIcon from '@mui/icons-material/Search';
import {
  Container,
  InnerContainer,
  Title,
  LongInput,
  Filter,
  ContainerLoading,
  SearchContainer,
  SearchContent,
  CompanyFilter,
} from './style'
import NewInfoPedidos from './NewInfoPedidos'

export default function Pedidos() {

  const token = localStorage.getItem("authToken") ?? '{}'
  const authToken = JSON.parse(token).access_token ?? ''
  const [loading, setLoading] = useState(true)
  const [pedidos, setPedidos] = useState<ReadMyOrders[]>([])
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''
  const [sortOrder, setSortOrder] = useState('mostRecent')
  const [editors, setEditors] = useState([])
  const [companies, setCompanies] = useState([])
  const [searchOrders, setSearchOrders] = useState('')
  const [reload, setReload] = useState(true)

  const {setErrorMessage, setShowPopup, setTypeError} = usePopupTrueFalse()
  const [filters, setFilters] = useState({
    status:'',
    name: '',
    deadline: '',
    editor:''
  })
  
  function setError(error: any){
    console.error(error)
    setTypeError(false)
    if(typeof error.response?.data?.detail === 'string'){
          setErrorMessage(error.response?.data?.detail)
    }else{
          setErrorMessage('Tente novamente em instantes!')
    }
    setShowPopup(true)
  }

  useEffect(() => {
    if(typeUser === 'company'){
      const promise = api.readMyOrders(authToken)
      promise.then((response) => {
        setPedidos(response.data)
      }).catch((error) => {
        setError(error)
      }).finally(() => {
        setLoading(false)
      })
    setReload(false)

    } 
    
    else if(typeUser === 'admin'){

      Promise.all([
        api.readAllOrdersAdmin(authToken), 
        api.readCompanies(authToken),
        api.readEditors(authToken)]).then((responses)=>{
          setPedidos(responses[0].data)
          setCompanies(responses[1].data)
          setEditors(responses[2].data)
        }).catch((error) => {
          setError(error)
        }).finally(() => {
          setLoading(false)
        })
    setReload(false)

    }

    else if(typeUser === 'editor'){

      Promise.all([
      api.readMyDelegatedOrders(authToken),
      api.readCompanies(authToken)]).then((responses)=>{
        setPedidos(responses[0].data)
        setCompanies(responses[1].data)
      }).catch((error) => {
        setError(error)
      }).finally(() => {
        setLoading(false)
      })
    setReload(false)

    }

    // eslint-disable-next-line 
  },[authToken, typeUser, reload])



  const handleFilterChange = (filter: string, value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }))
  }
  
  let filteredData: ReadMyOrders[] = []

  if(typeUser !== 'company'){

    filteredData = pedidos?.filter((item) => {
    if(filters.status !== '' && item.status.status !== filters.status) {
      return false
    }
    if(filters.name !== '' && item.company?.user?.name !== filters.name) {
      return false
    }
    if(filters.editor !== '' && item.editor?.user?.name !== filters.editor) {
      return false
    }
    if(searchOrders !== ''){
      return String(item.id).includes(searchOrders)
    }
    else{
      return true;
    }}).sort((a,b) => {
      const dateA = new Date(a.deadline);
      const dateB = new Date(b.deadline);
      return sortOrder === "mostRecent"
      ? dateB.getTime() - dateA.getTime() || a.id - b.id
      : sortOrder === "oldest"
      ? dateA.getTime() - dateB.getTime() || a.id - b.id
      : 0;
    });
  }

  if(typeUser === 'company'){

      filteredData = pedidos?.filter((item) => {
      if(filters.status !== '' && filters.status === 'open'){
        if(item.status.status === 'open' || item.status.status === 'delegated'){
          return true
        } else{
          return false
        }
      } else if(filters.status !== '' && filters.status === 'in_production'){
        if(item.status.status === 'in_production' || item.status.status === 'awaiting_approval_internal' || item.status.status === 'awaiting_changes_internal'){
          return true
        } else{
          return false
        }
      } else if(filters.status !== '' && item.status.status !== filters.status){
        return false
      }
      if(searchOrders !== ''){
        return String(item.id).includes(searchOrders)
      }
      else{
        return true;
      }}).sort((a,b) => {
        const dateA = new Date(a.deadline);
        const dateB = new Date(b.deadline);
        return sortOrder === "mostRecent"
        ? dateB.getTime() - dateA.getTime() || a.id - b.id
        : sortOrder === "oldest"
        ? dateA.getTime() - dateB.getTime() || a.id - b.id
        : 0;
      });
  }

  if(loading){
    return (
    <ContainerLoading>
      <Loading color="black" withText={true}/>
    </ContainerLoading>)
  }

  return (
    <Container>
      {typeUser === 'company' ? 
      (<Title>Meus pedidos</Title>)
      :<Title>Pedidos realizados</Title>
      }

      {(typeUser !== 'company') && <Filter>
      <LongInput>
          <label htmlFor="status">Status</label>
          <select value={filters.status} onChange={e => handleFilterChange('status',e.target.value)} name="status" placeholder=''>
              <option value="">  </option>
              <option value="open">Aberto </option>
              <option value="delegated">Delegado</option>
              <option value="in_production">Em produção</option>
              <option value="awaiting_approval_internal">Revisão interna</option>
              <option value="awaiting_changes_internal">Agaurdando alteraçõs - internas </option>
              <option value="awaiting_approval">Aguardando aprovação do cliente</option>
              <option value="awaiting_changes">Aguardando mudanças - cliente</option>
              <option value="in_negotiation">Em negociação</option>
          </select>
            </LongInput>
            <LongInput>
          <label htmlFor="deadline">Deadline</label>
          <select value={sortOrder} onChange={e => setSortOrder(e.target.value)} name="deadline" placeholder=''>
              <option value="mostRecent">Mais recentes</option>
              <option value="oldest">Mais antigas</option>
          </select>
            </LongInput>
            <LongInput>
          <label htmlFor="client">Empresa</label>
          <select value={filters.name} onChange={e => handleFilterChange('name',e.target.value)}  name="client" placeholder=''>
              <option value="">  </option>
              {companies.map((company: CompanyProps) => {
                return <option key={company.id} value={company?.user.name}>{company?.user.name}</option>
              })}
          </select>
            </LongInput>
            {typeUser === 'admin' && <LongInput>
          <label htmlFor="editor">Redator</label>
          <select value={filters.editor} onChange={e => handleFilterChange('editor',e.target.value)}  name="editor" placeholder=''>
              <option value="">  </option>
              {editors.map((editor: EditorProps) => {
                return <option key={editor.id} value={editor?.user.name}>{editor?.user.name}</option>
              })}
          </select>
            </LongInput>}
      </Filter>}
      
      {typeUser === 'company' && (
        <CompanyFilter>
        <SearchContainer>
          <SearchContent>
            <SearchIcon/> <input type="text" value={searchOrders} onChange={e => setSearchOrders(e.target.value)} placeholder="Buscar pedido pelo ID" />
          </SearchContent>
        </SearchContainer>

      <Filter>
          <LongInput>
              <label htmlFor="status">Status</label>
              <select value={filters.status} onChange={e => handleFilterChange('status',e.target.value)} name="status" placeholder=''>
                  <option value="">  </option>
                  <option value="open">Aberto</option>
                  <option value="in_production">Em produção</option>
                  <option value="awaiting_approval">Aguardando aprovação</option>
                  <option value="awaiting_changes">Aguardando mudanças</option>
                  <option value="in_negotiation">Em negociação</option>
              </select>
            </LongInput>
        </Filter>
        </CompanyFilter>
      )}

      <InnerContainer>
        {filteredData?.length === 0 ? 
          ((typeUser === 'company' && (searchOrders === '' && filters.status === '')) ? 
          (<h2> Você não tem pedidos :( </h2>) : 
          <h2>Sem resultados</h2>):
        filteredData?.map((pedido) => {
          return <NewInfoPedidos key={pedido.id} pedido={pedido} priority_value={pedido?.priorities?.priority} authToken={authToken} setReload={setReload}/>
        })}
      </InnerContainer>
    </Container>
  )
}
