import styled from "styled-components";

export const Container = styled.div`
    background: rgb(92,73,221);
    background: linear-gradient(0deg, rgba(92,73,221,1) 0%, rgba(167,79,255,1) 100%);
    
    display:flex;
    justify-content: space-between;

    border-radius: 1rem;

    padding-left: 3rem;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);

    
`

export const Launch = styled.img`
    border-radius: 0 1rem 1rem 0;
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction:column;
    align-items:start;
    justify-content: center;
    gap: 2rem;
`

export const Title = styled.h1`
    color: #F9F9F9;
    font-size: 1.5rem;
`

export const Button = styled.button`
    color: #5C49DD;
    background-color: #F9F9F9;

    font-weight: bold;

    border: none;
    border-radius: 2rem;

    padding: 1rem;

    -webkit-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    -moz-box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);
    box-shadow: 0px 0px 34px -16px rgba(0,0,0,.5);

    font-family: 'Inter', sans-serif;

    cursor:pointer;

`