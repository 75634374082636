import styled from "styled-components";

const Container = styled.div`
display: flex;
`

const Leftcontainer = styled.div`

`

const Rightcontainer = styled.div`
width: 100%
`

export{
    Container,
    Leftcontainer,
    Rightcontainer
}
