import { useMediaQuery } from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import { Sidebar, Topbar } from "../../components";
import { Navigate, useLocation } from 'react-router-dom';
import Standby from '../Standby';
import jwtDecode from "jwt-decode";
import {
    Container, Rightcontainer, Leftcontainer} from './style'
import { ProtectedRouteProps } from '../../interfaces';



const ProtectedRoute = ({ component: Component, ...rest }: ProtectedRouteProps): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false)
  const [permission, setPermission] = useState(true)
  const isScreenSmall = useMediaQuery('(max-width:1200px)');
  const location = useLocation()
  const url = location.pathname

  
    function handleDrawerOpen(){
      setShowSidebar(true);
    }

  useEffect(() => {
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token)?.access_token
    const user = localStorage.getItem("user") ?? '{}'
    const typeUser = JSON.parse(user)?.role?.name
    const urlBoolean = ((url === '/usuarios') && typeUser !== 'admin')

    try{
      jwtDecode(authToken)
      setPermission(!urlBoolean)
      setAuthenticated(true)
    } catch(error){
      setAuthenticated(false)
    }
    setTimeout(() => {
      setLoading(false);
    }, 500)
    // eslint-disable-next-line
  }, []);

  
 
  if (loading || !permission) {
    return <Standby />;
  }


  return authenticated ? 
  ( 
    <Container>
      <Leftcontainer>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} isScreenSmall={isScreenSmall} />
      </Leftcontainer>
      <Rightcontainer>
        <Topbar handleDrawerOpen={handleDrawerOpen} />
         <Component {...rest} /> 
      </Rightcontainer>
    </Container> 
  )
  : <Navigate to="/login" replace={true}/> ;
};

export default ProtectedRoute;