import { AdminLayoutProps } from '../../../../interfaces';
import {
    Container,
    InnerContainer,
    Image,
    Name,
    Email
} from './style'

export default function EditorLayout({editor, selectedUser}: AdminLayoutProps) {
    const picture = editor?.profile_picture ?? ''
  return (
    <Container>
        <InnerContainer selected={selectedUser && selectedUser.id === editor.id ? true : false}>
            <Image>
                <img src={picture !== '' ? picture : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'} alt="Foto de perfil do editor"/>
            </Image>
            <Name>
                {editor?.name}
            </Name>
            <Email>
                {editor?.email}
            </Email>
        </InnerContainer>
    </Container>
  )
}
