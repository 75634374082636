import { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api'
import {
  Container,
  ClosePopup,
  InnerContainer,
  Input,
  Back,
  Delegar,

} from './style'
import Loading from '../Loading';
import { AddPublishedNewsProps } from '../../interfaces';

export default function AddPublishedNews({authToken, orderId, setAddPublishedNews, setReload}: AddPublishedNewsProps){

  const [urlPublishedNews, setUrlPublishedNews] = useState('')
  const [loading, setLoading] = useState(false)

  function handleAddPublishedNews(){
    setLoading(true)
    const body = {
      "url": urlPublishedNews
    }
    const promise = api.AddPublishedNews(authToken, orderId, body)
    promise.then(() => {
      setReload(true)
      setAddPublishedNews(false)
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      setLoading(false)
    })

  }

    if(loading){
      return(
        <Container>
          <Loading color="black" withText={true} />
        </Container>
      )
    }

  return(
    <Container>
    <h1>Adicione o link da matéria.</h1>
    <ClosePopup onClick={() => setAddPublishedNews(false)}><CloseIcon/></ClosePopup>
    <InnerContainer>
      <Input>
      <input
        type="text"
        placeholder="https://link-da-materia.com.br"
        value={urlPublishedNews}
        onChange={e => setUrlPublishedNews(e.target.value)}
      />
      </Input>
    </InnerContainer>
    <div>
    <Back onClick={() => setAddPublishedNews(false)}><ArrowBackIcon/>Voltar</Back>
    <Delegar onClick={handleAddPublishedNews}>Salvar publicação</Delegar>
    </div>
    
</Container>
  )
}