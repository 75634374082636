import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReadCommunicationPlans } from '../../../interfaces'
import {
    Container,
    InnerContainer,
    Company,
    ProductName,
    EditorContainer,
    CardTop,
    CardBottom,
    Delegate,
} from './style'

interface CardCommunicationPlanProps{
  status: string
  communication_plan: ReadCommunicationPlans
  setDelegateCommunicationPlan: (value: boolean) => void
  setCompanyUserIdInDelegate: (value: string) => void
}

export default function CardCommunicationPlan({status, communication_plan, setDelegateCommunicationPlan, setCompanyUserIdInDelegate}: CardCommunicationPlanProps) {
  const pictureCompany = communication_plan?.user?.profile_picture ?? ''
  const pictureEditor = communication_plan?.communication_plan_editor?.profile_picture ?? ''
  const user = localStorage.getItem("user") ?? '{}';
  const typeUser = JSON.parse(user).role.name ?? ''
  const isAdmin = typeUser === 'admin'

  function handleDelegate(e: any){
    e.preventDefault()
    e.stopPropagation()
    setCompanyUserIdInDelegate(communication_plan.user.id as unknown as string)
    setDelegateCommunicationPlan(true)
  }

  return (
    <Container>
      <Link style={{textDecoration: 'none'}} to={`/comunicacao/${communication_plan?.user?.id}`}>
        <InnerContainer>
            <CardTop>
            <Company><Avatar 
              sx={{width:20, height:20}}
              src={pictureCompany !== '' ? pictureCompany : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}></Avatar><span>{communication_plan?.user?.name}</span></Company>
            </CardTop>
            <ProductName>Plano de Comunicação</ProductName>
            <CardBottom>
                {communication_plan?.communication_plan_editor?.name && <EditorContainer><Avatar 
                sx={{width:20, height:20}}
                src={pictureEditor !== '' ? pictureEditor : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}></Avatar><span>{communication_plan?.communication_plan_editor?.name}</span>  </EditorContainer>}
                {!communication_plan?.communication_plan_editor?.name && isAdmin && status === 'Aberto' && <EditorContainer onClick={(e) => handleDelegate(e)}><Delegate>Delegar Plano</Delegate></EditorContainer>}
            </CardBottom>
        </InnerContainer>
        </Link>
    </Container>
  )
}