import styled from "styled-components";

const Container = styled.div`
width: 100%;
border-radius: 0.625rem;
height: 2.1875rem;
display: flex;
align-items: center;
justify-content: center;
background-image: lightgray;


span{
    margin: 0rem auto;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

`

const Delete = styled.button`
cursor: pointer;
width: 5rem;
height: 1.875rem;
background-color: #f9f9f9;
color: #5c49dd;
border: 0.0625rem solid #5c49dd;
border-radius: 1.875rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.1875rem;
text-align: center;
margin: 0 0.625rem 0;
transition: 200ms;

:hover{
    background-color: #5c49dd;
    color: #f9f9f9;
    border: none;
}
`

const Edit = styled.button`
cursor: pointer;
width: 5rem;
height: 1.875rem;
background-color: #5c49dd;
color: #f9f9f9;
border-radius: 1.875rem;
font-size: 1rem;
font-weight: 500;
line-height: 1.1875rem;
text-align: center;
border: none;
transition: 200ms;
margin: 0 0.625rem 0;


:hover{
    background-color: #f9f9f9;
    color: #5c49dd;
    border: 0.0625rem solid #5c49dd;
}
`

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

   
    
`

export {
    Container,
    Header,
    Edit,
    Delete
}