import api from "../../../services/api";
import {
  ContentInputs,
  ContainerInputs,
  Buttons,
  Voltar,
  InformationContainer,
  CompanyImage,
  CompanyName,
  CompanyPlan,
  CompanyOrders,
  CompanyComunication,
  CompanyDetails,
  ComunicationButton,
  ComunicationSpan,
  ComunicationPlanSpan
} from './style'
import Input from '../../../components/Input';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState, useCallback} from "react";
import { CompanyData, InputsProps } from "../../../interfaces";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReadMyOrders } from '../../../interfaces'
import { BackgroundPopup } from "../style";
import InsertPlan from "../../../components/InsertPlan";
import LaunchIcon from '@mui/icons-material/Launch';
interface CompanyInformationProps{
    idCompany: number | undefined;
    authToken: string;
    setTab: (value: string) => void;
    tab: string;
}



export default function CompanyInformation({idCompany, authToken, setTab, tab}: CompanyInformationProps){

    const [inputs, setInputs] = useState([])
    const [companyInfo, setCompanyInfo] = useState<CompanyData>({})
    const [companyOrders, setCompanyOrders] = useState<ReadMyOrders[]>([])
    const [communicationPlan, setCommunicationPlan] = useState('')
    const [showInsertPlan, setShowInsertPlan] = useState(false)
    const id = idCompany ?? ''

    const readCompanyInfo = useCallback(() => {
      const promise = api.readCompanyInfo(authToken, id.toString())
      promise.then((response) => {
          setInputs(response.data.company_fields)
          setCompanyInfo(response.data.company_data)
          setCompanyOrders(response.data.company_orders)
          setCommunicationPlan(response.data.communication_plan)
      }).catch((error) => {
          console.log(error)
      })
    }, [authToken, id])

    useEffect(() => {
      readCompanyInfo()
    },[authToken, id, readCompanyInfo])

    const filteredOrders = companyOrders?.filter(order => {
      const createdAt = new Date(order.created_at);
      const currentDate = new Date();
      return createdAt.getMonth() === currentDate.getMonth();
    }).length;
    
    function openPlan(){
      if(!!communicationPlan){
        window.open(communicationPlan, '_blank')
      }
    }

    return(
        <>
        {(showInsertPlan) && 
      (<BackgroundPopup>
        {showInsertPlan && <InsertPlan updateFuncion={readCompanyInfo} setShowInsertPlan={setShowInsertPlan} CompanyId={idCompany}/>}
      </BackgroundPopup>)}
        <ContainerInputs>
        <CompanyDetails>
        <InformationContainer>
          <CompanyImage>
            <Avatar 
              sx={{width:40, height:40}}
              src={companyInfo.user?.profile_picture ? companyInfo.user?.profile_picture : 'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png'}>
            </Avatar>
          </CompanyImage>
          <CompanyName>
            <h1><span>Cliente:</span> {companyInfo.user?.name}</h1>
          </CompanyName>
          <CompanyPlan>
            <h1><span>Plano:</span> {companyInfo.plan?.plan_name}</h1>
          </CompanyPlan>
          <CompanyOrders>
            <h1><span>Pedidos esse mês:</span> {filteredOrders}</h1>
          </CompanyOrders>
        </InformationContainer>
          <CompanyComunication>
            <h1><ComunicationSpan>Plano de comunicação:</ComunicationSpan> <ComunicationPlanSpan onClick={openPlan} hasPlan={!!communicationPlan}>{!!communicationPlan ? 'Acessar plano' : 'Ainda não cadastrado'} {!!communicationPlan && <LaunchIcon/>}</ComunicationPlanSpan></h1>
          <ComunicationButton onClick={() => setShowInsertPlan(true)}>
            {!!communicationPlan ? `Editar plano de comunicação` : `Adicionar plano de comunicação`}
          </ComunicationButton>
          </CompanyComunication>
        </CompanyDetails>
          <ContentInputs>
            {inputs?.map((input: InputsProps, indice: number) => {
              return (<Input 
                disabled={true}
                key={input.field_id} 
                id={input.field_id} 
                indice={indice} 
                description={input.description} 
                fieldName={input.field_name} 
                value={input.value} 
                input={input} 
                inputs={inputs} 
                setInputs={setInputs}
                tab={tab}/>
                )
            })}
          </ContentInputs>
          </ContainerInputs>

          <Buttons>
            <Voltar onClick={() => setTab('infoOrder')}><ArrowBackIcon/>Voltar</Voltar>
          </Buttons>
        </>
    )
}