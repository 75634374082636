import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { acertarDatas } from '../../../utils';
import { StepIcon } from '@mui/material';
import { StepperContainer } from './style';

import { CommunicationPlanPropsStepper } from '../../../interfaces';

export default function StepperVertical({ communication_plan_logs, canShowAllLogs}: CommunicationPlanPropsStepper) {
  let filtredActiveStep = communication_plan_logs ?? []
  if(!canShowAllLogs){
    filtredActiveStep = [filtredActiveStep[filtredActiveStep.length - 1]];
  } 

  return (
    <Box sx={{ width: "26rem"}}>
      <Stepper orientation="vertical">
        {filtredActiveStep?.map((step) => (
          <Step key={step.id} 
          completed={true}
          sx={{height: '4rem', marginTop: '-1rem'}}
          active = {true}>
            <StepperContainer>
            <StepLabel StepIconComponent={StepIcon}>
              <h1>{stepperMessage(step?.status?.name)}</h1>
              <h2>
                {step?.created_at.includes("Em breve")
                  ? step?.created_at
                  : acertarDatas(step?.created_at)}
              </h2>
            </StepLabel>
            </StepperContainer>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}


function stepperMessage(status: string) {
  switch (status) {
    case "Aberto":
      return "Novo plano de comunicação";
    case "Delegado":
      return "Plano encaminhado ao redator ✅";
    case "Em produção":
      return "Plano em produção 📝";
    case "Aguardando revisão interna":
      return "Plano em revisão 👀";
    case "Aguardando mudanças (interno)":
      return "Alterações pós-revisão em andamento ⚙️";
    case "Aguardando aprovação":
      return "Aguardando aprovação do cliente ⏳";
    case "Aguardando mudanças":
      return "O cliente pediu algumas alterações 📝";
    case "Aprovado":
      return "Plano de Comunicação aprovado 🏆";
    default:
      return "Concluído";
  }
}