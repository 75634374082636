import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../services/api'
import {
  Container,
  ClosePopup,
  ListEditors,
  InnerContainer,
  Input,
  Back,
  Delegar
} from './style'
import EditorLayout from './EditorLayout';
import Loading from '../../../components/Loading';
import { AdminProps, SendCommunicationPlanToRevisionProps } from '../../../interfaces';


export default function SendCommunicationPlanToRevision({authToken, companyUserId, userId, setSendCommunicationPlanToRevision, setReload}: SendCommunicationPlanToRevisionProps){
  const [admins, setAdmins] = useState<AdminProps[]>([])
  const [selectedUser, setSelectedUser] = useState<AdminProps | null>(null)
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const filteredAdmins = admins.filter((admin) => 
    admin?.name.toLowerCase().includes(filter.toLowerCase())
  )

  useEffect(() => {

    const promise = api.getAllAdmins(authToken)
    promise.then((response) => {
      setAdmins(response.data)
    }).catch((error) => {
      console.error(error)
    })
  },[authToken])


  function sendOrderToRevision(){
    if(!selectedUser) return
    setLoading(true)
    const body = {
      revisor_user_id: selectedUser?.id,
    }
    const promise = api.sendCommunicationPlanToInternasApproval(authToken, companyUserId, body)
    promise.then(() => {
      setSendCommunicationPlanToRevision(false)
      setReload(true)
    }).catch((error) => {
      console.error(error)
    })
    setLoading(false)
  }

    if(loading){
      return(
        <Container>
          <Loading color="white" withText={true} />
        </Container>
      )
    }

  return(
    <Container>
    <h1>Para quem você deseja delegar?</h1>
    <ClosePopup onClick={() => setSendCommunicationPlanToRevision(false)}><CloseIcon/></ClosePopup>
    <InnerContainer>
      <Input>
      <input
        type="text"
        placeholder="Digite o nome do usuário"
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      </Input>
      <ListEditors>
        {filteredAdmins.map((admin) => {
          return(
            <div key={admin.id} onClick={() => setSelectedUser(admin)}>
              <EditorLayout key={admin.id} editor={admin} selectedUser={selectedUser}/>
            </div>
          )
        })}
      </ListEditors>
    </InnerContainer>
    <div>
    <Back onClick={() => setSendCommunicationPlanToRevision(false)}><ArrowBackIcon/>Voltar</Back>
    <Delegar onClick={sendOrderToRevision}>Delegar</Delegar>
    </div>
    
</Container>
  )
}