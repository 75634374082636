import { Item, ReadMyOrders } from '../../../interfaces'
import { Link } from 'react-router-dom';
import warning from '../../../assets/images/aviso.png'

import * as S from './style'
import { acertarDatas } from '../../../utils';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import EditDelegateOrder from '../../../components/EditDelegateOrder';
// import EditIcon from '@mui/icons-material/Edit';

// import { useState } from 'react';

interface InfoPedidosProps{
    pedido: ReadMyOrders;
    priority_value?: number;
    authToken: string;
    setReload: any;
}
export default function NewInfoPedidos({pedido, priority_value, authToken, setReload}: InfoPedidosProps) {
    const user = localStorage.getItem("user") ?? '{}';
    const typeUser = JSON.parse(user).role.name ?? '';
    const priority = priority_value === 3 ? 'Alta' : priority_value === 2 ? 'Média' : 'Baixa';
    const canSeePriority = (typeUser === 'admin' || typeUser === 'editor');
    const isEditorOrAdmin = (typeUser === 'admin' || typeUser === 'editor');
    const isNotClosed = (pedido.status.status !== 'closed');
    const isLateDate = (new Date(pedido.deadline) < new Date());
    const showIsLate = isEditorOrAdmin && isNotClosed && isLateDate;
    const statusUntilApproval = ['open', 'delegated', 'in_production', 'awaiting_approval_internal', 'awaiting_changes_internal']
    // const [editDelegateOrder, setEditDelegateOrder] = useState(false)
    const thisStatusIsUntilApproval = statusUntilApproval.includes(pedido.status.status)



    let infoToShow: string | undefined;

    switch (pedido.product.product_name) {
      case 'Artigo de Opinião':
        const informacoesAdicionaisArtigo = pedido.order_info.find(
          (item: Item) => item.field.field_name === 'Assunto'
        );
        infoToShow = informacoesAdicionaisArtigo?.value;
        break;
  
      case 'Press Release':
        const pressReleaseDado = pedido.order_info.find(
          (item: Item) => item.field.field_name === 'Notícia'
        );
        infoToShow = pressReleaseDado?.value;
        break;
  
      case 'Sugestão de Pauta':
        const sugestaoAssunto = pedido.order_info.find(
          (item: Item) => item.field.field_name === 'Assunto'
        );
        infoToShow = sugestaoAssunto?.value;
        break;
  
      default:
        infoToShow = undefined;
    }

    let orderStatus

    

    switch(pedido.status.status) {
      case 'open':
        orderStatus = 'Aberto'
        break;
      case 'delegated':
        if(typeUser === 'admin'){
            orderStatus = 'Delegado'
        } else if(typeUser === 'editor'){
            orderStatus = 'Delegado'
        } else if(typeUser === 'company'){
            orderStatus = 'Aberto'
        }
        break;
      case 'in_production':
        orderStatus = 'Em produção'
        break;
      case 'awaiting_approval_internal':
        if(typeUser === 'admin'){
            orderStatus = 'Aguardando revisão interna'
        } else if(typeUser === 'editor'){
            orderStatus = 'Aguardando revisão interna'
        } else if(typeUser === 'company'){
            orderStatus = 'Em produção'
        }
        break;
      case 'awaiting_changes_internal':
        if(typeUser === 'admin'){
            orderStatus = 'Aguardando mudanças (interno)'
        } else if(typeUser === 'editor'){
            orderStatus = 'Aguardando mudanças (interno)'
        } else if(typeUser === 'company'){
            orderStatus = 'Em produção'
        }
        break;
      case 'awaiting_approval':
        if(typeUser === 'admin'){
            orderStatus = 'Aguardando aprovação do cliente'
        } else if(typeUser === 'editor'){
            orderStatus = 'Aguardando aprovação do cliente'
        } else if(typeUser === 'company'){
            orderStatus = 'Aguardando aprovação'
        }
        break;
      case 'awaiting_changes':
        if(typeUser === 'admin'){
            orderStatus = 'Aguardando mudanças (cliente)'
        } else if(typeUser === 'editor'){
            orderStatus = 'Agurdando mudanças (cliente)'
        } else if(typeUser === 'company'){
            orderStatus = 'Aguardando mudanças'
        }
        break;
      case 'in_negotiation':
        if(typeUser === 'admin'){
            orderStatus = 'Em negociação'
        } else if(typeUser === 'editor'){
            orderStatus = 'Em negociação'
        } else if(typeUser === 'company'){
            orderStatus = 'Em negociação'
        }
        break;
      case 'closed':
        orderStatus = 'Pedido publicado'
        
        break;

      default:
        orderStatus = 'Em análise';
    }
    
  return (
    <S.Container>
        {/* {(editDelegateOrder) && (
            <S.BackgroundPopup>
                {editDelegateOrder && <EditDelegateOrder setDelegateOrder={setEditDelegateOrder} authToken={authToken} orderId={(pedido.id).toString()} setReload={setReload}/>}
            </S.BackgroundPopup>
        )} */}
        <S.ProductName>
            {pedido.product.product_name + " #" + pedido.id}
        </S.ProductName>

        <S.InnerContainer>
            {(canSeePriority && priority_value) ? 
                <S.PriorityContainer>
                    <S.PriorityTag $priority={priority}>
                        {priority}
                    </S.PriorityTag>
                </S.PriorityContainer> 
                :
                <S.PriorityContainer>
                    <S.PriorityTag $priority={"undefined"}>
                    </S.PriorityTag>
                </S.PriorityContainer> 
            } 
            <S.Details>
                <span>
                    {infoToShow}
                </span>
            </S.Details> 
            <S.Infos>
                {(typeUser === 'admin' || typeUser === 'editor')  &&
                <S.InfoBox>
                    <S.Title>
                        <BusinessIcon sx={{ color: "#6D6D6D", fontSize: "1rem" }} />
                        <span>Cliente</span>
                    </S.Title>
                    <S.Content><span>{pedido.company?.user?.name}</span></S.Content>
                </S.InfoBox>
                }
                {(typeUser === 'admin' || typeUser === 'editor') &&
                    <S.InfoBox>
                        
                        <S.Title>
                            <PersonIcon sx={{ color: "#6D6D6D", fontSize: "1rem" }} />
                            <span>Redator responsável</span>
                        </S.Title>
                        <S.Content>
                            {(orderStatus !== 'open' && (typeUser === 'admin' || typeUser === 'editor') &&  pedido?.editor?.user?.name) &&
                                <span>
                                    {`${pedido?.editor?.user?.name}`} 
                                </span>
                            }
                            {(!pedido?.editor?.user?.name) &&
                                <span>
                                    Nenhum redator vinculado
                                </span>
                            }
                        </S.Content>
                    </S.InfoBox>
                }
                <S.InfoBox>
                    <S.Title>
                        <CheckCircleIcon sx={{ color: "#6D6D6D", fontSize: "1rem" }} />
                        <span>Etapa</span>
                    </S.Title>
                    <S.Content><span>{orderStatus}</span></S.Content>
                </S.InfoBox>
                <S.InfoBox>
                    <S.Title>
                        <CalendarTodayIcon sx={{ color: "#6D6D6D", fontSize: "1rem" }} />
                        <h1>{thisStatusIsUntilApproval ? `Prazo de redação do material` :`Deadline desta etapa`}</h1>
                    </S.Title>
                    <S.Content>
                        {pedido.status.status === "awaiting_approval" && (
                            <span>Aguardando sua aprovação</span>
                        )}

                        {pedido.status.status !== "awaiting_approval" && (
                            <span>{acertarDatas(pedido.deadline)}</span>
                        )}
                         {(showIsLate) && (
                            <S.Warning>
                                <img src={warning} alt="Pedido atrasado" />
                            </S.Warning>
                        )}
                    </S.Content>
                </S.InfoBox>
            </S.Infos>

            
                <S.Button>
                    <Link style={{textDecoration: 'none'}} to={`/pedidos/${pedido.id}`} >
                        Ver detalhes
                    </Link>
                </S.Button>
        </S.InnerContainer>
    </S.Container>

  )
}
