import {
    Container,
    Title,
    Text
} from './style'

export default function ArtigoOpiniao() {
  return (
    <Container>
        <Title>Artigo de Opinião</Title>
        <Text>
            <p>
            Um artigo de opinião é um texto jornalístico em que o autor expõe 
            seu ponto de vista e opinião sobre um determinado assunto. Esse tipo de 
            texto tem como objetivo persuadir e convencer o leitor sobre determinado tema, 
            apresentando argumentos e fatos que sustentem a posição defendida pelo autor.
            </p>
            <p>
            Este é um dos gêneros mais comuns no jornalismo opinativo e pode ser encontrado 
            em diversos veículos de comunicação, como jornais, revistas, blogs e sites de 
            notícias. Ele pode ser escrito por jornalistas, especialistas em determinado 
            assunto ou até mesmo por pessoas comuns que queiram expressar sua opinião sobre 
            um tema.
            </p>
            <p>
            A estrutura de um artigo de opinião geralmente é composta por três partes principais: a introdução, o desenvolvimento e a conclusão.
            </p>
            <p>
            Na introdução, o autor deve apresentar o tema e sua posição sobre ele, 
            de forma clara e objetiva. É importante que essa parte do texto seja capaz 
            de captar a atenção do leitor e despertar seu interesse para continuar lendo.
            </p>
            <p>
            No desenvolvimento, o autor apresenta seus argumentos e evidências que 
            sustentam sua posição. É fundamental que esses argumentos sejam bem fundamentados 
            e embasados em fatos, dados e informações confiáveis. Além disso, é importante que 
            o autor considere possíveis argumentos contrários e os refute de forma convincente.
            </p>
            <p>
            Por fim, a conclusão deve reforçar a posição do autor e, se possível, 
            apresentar alguma solução ou sugestão de ação para o tema abordado. 
            É importante que essa parte do texto seja clara e concisa, deixando uma impressão 
            duradoura no leitor.
            </p>
            <p>
            Para escrever um bom artigo de opinião, é necessário ter conhecimento sobre o 
            tema abordado e pesquisar informações confiáveis para fundamentar seus argumentos. 
            Além disso, é preciso ter habilidades de escrita e argumentação, além de ser capaz 
            de se comunicar com clareza e objetividade.
            </p>
            <p>
            Por se tratar de um gênero jornalístico opinativo, o artigo de opinião pode gerar debates 
            e discussões entre os leitores, o que o torna uma ferramenta importante para a troca 
            de ideias e para a formação de opinião pública.
            </p>
        </Text>
    </Container>
  )
}
