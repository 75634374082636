import styled from "styled-components";

const Container = styled.div`
    position: relative;
    padding-bottom: 5rem;
    height: auto;
    margin-left: 2.9375rem;
    margin-right: 1.875rem;
    margin-top: 4rem;
    margin-bottom: 2rem;

    
    
`
const Image = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
`

const Logo = styled.div`
@media(max-width: 720px){
    img{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }
}
`
const ChooseTab = styled.div`
margin-top: 2.5rem;
font-weight: 500;
span{
    cursor: pointer;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    :hover{
        color: #5c49dd;
    }
}

`

const ChangeLogo = styled.button`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 400;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1rem;
transition: 200ms;
`

const DeleteLogo = styled.button`
    width: 10.75rem;
    height: 3.125rem;
    border: 0.0625rem solid #5C49DD;
    border-radius: 1.875rem;
    background-color: #f9f9f9;
    color: #5c49dd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1.21rem;
    font-size: 1rem;
    margin-left: 0.5625rem;
`

const Line = styled.div`
    height: 0.0625rem;
    width: 95%;
    background-color: #6D6D6D50;
    margin-bottom: 0.78125rem;
    margin-top: 0.78125rem;
`

const InformationsAccount = styled.div`
    
    @media(max-width: 1320px){
        width: 100%;
    }
`;

const Buttons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
position: absolute;
bottom: 0.625rem;
right: 0.625rem;
`

const Discard = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: 0.1rem solid #5C49DD;
border-radius: 1.875rem;
background-color: #f9f9f9;
color: #5C49DD;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
margin-right: 0.625rem;
`

const Save = styled.button`
cursor: pointer;
width: 10.75rem;
height: 2.65625rem;
border: none;
border-radius: 1.875rem;
background-color: #5C49DD;
color:#f9f9f9;
font-weight: 500;
font-size: 1rem;
line-height: 1.21rem;
`

const ContentInputs = styled.div`
margin-top: 1.5625rem;
padding: 0 0.625rem;
display: flex;
align-items:center;
justify-content: center;
flex-wrap: wrap;
width: 90%;


`

const ContainerInputs = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 80%;

`

const LabelInputPhoto = styled.label`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
width: 12.5rem;
height: 3.125rem;
background: #5c49dd;
color: #ffffff;
border-radius: 1.875rem;
font-weight: 400;
font-size: 1rem;
line-height: 1.21rem;
border: none;
margin-left: 1rem;
transition: 200ms;

:hover{
    color: #5c49dd;
    background-color: #f9f9f9;
    border: 0.0625rem solid #5c49dd;
}
`

const PhotoInput = styled.input.attrs({type: 'file'})`
background-color: #5c49dd;
display: none;
`

const ContainerLoading = styled.div`
width: 100%;
min-height: 20rem;
display: flex;
align-items: center;
justify-content: center;
`

export {
    InformationsAccount,
    ContainerInputs,
    ContainerLoading,
    Buttons,
    Discard,
    Save,
    ContentInputs,
    Container,
    Image,
    Logo,
    ChangeLogo,
    DeleteLogo,
    Line,
    ChooseTab,
    PhotoInput,
    LabelInputPhoto
}