import styled from "styled-components";

const Logout = styled.div`
position: fixed;
left: 0rem;
bottom: 1.875rem;
@media(max-height: 620px){
position: static;
} 
`
export {
    Logout
}