import styled from "styled-components";

const Container = styled.div`
    color: #ff0000;
    margin-top: 1.25rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export {
    Container
}