import styled from "styled-components";



const Container = styled.div`
margin-right: 0.8rem;
@media(max-width: 700px){
        width: 26.25em;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
`

const InnerContainer = styled.div`

`

interface LongInputProps{
    tab?: string
}

const LongInput = styled.div<LongInputProps>`
flex: 1;
textarea{
    font-family: 'Inter', sans-serif;
    padding-top: 0.5rem;
    resize: none;
    width: 32.25rem;
    min-height: 2.45625rem;
    max-height: 4rem;
    height: auto;
    border: 0.0625rem solid #6D6D6D50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.3125rem;
    margin-right: 1.875rem;
    padding-left: 0.625rem;
    margin-bottom: 0.9375rem;

    @media(max-width: 1275px){
        width: 27rem;
    }
    @media(max-width: 935px){
        width: 23rem;
    }

    

}
textarea::placeholder{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.21rem;
    color: #6D6D6D;
    text-align: start;
    

}
h2{
    margin-top: 0.625rem;
    width: 31.25rem;
    font-size: 0.875rem;
    font-weight: 300;
    @media(max-width: 1275px){
        width: 29rem;
    }
    @media(max-width: 935px){
        width: 25rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
}
h3{  
    margin-top: 0.625rem;
    color: #6d6d6d;
    width: 31.25rem;
    font-size: 0.75rem;
    strong{
        font-weight: bold;
    }
    @media(max-width: 1275px){
        width: 29rem;
    }
    @media(max-width: 935px){
        width: 25rem;
    }
    @media(max-width: 700px){
        width: 18.75rem;
    }
    @media(max-width: 470px){
        width: 21.875rem;
    }
    
}
`

const SupMandatoryField = styled.sup`
  position: relative;
  display: inline-block;
  margin-right: 4px;
  color: red;

  &::after {
    position: absolute;
    top: 0;
    right: -12px;
  }
`

const Tooltip = styled.span`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  white-space: nowrap;

  ${SupMandatoryField}:hover & {
    visibility: visible;
  }
`

export {
    Container,
    InnerContainer,
    LongInput,
    SupMandatoryField,
    Tooltip
}