import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
    Container,
    Title,
    InnerContainer,
    Main,
    Content,
    Information,
    Data,
    Buttons,
    LeftButtons,
    Back,
    Comment,
    Approve,
    RightButtons,
    BackgroundPopup,
    Delete
} from './style'
import api from '../../services/api'
import usePopupTrueFalse from '../../hooks/usePopupTrueFalse'
import { ReadOneSugestedOrder } from '../../interfaces'
import InformationContainer from '../../components/InformationContainer'
import { Loading } from '../../components'
import SolicitarProducao from './SolicitarProducao'
import PopupDeleteSugestion from './RemoverSugestao'

export default function SugestaoDetalhada() {

    const { idSugestao } = useParams()
    const idUrlSugestao = idSugestao ?? ''
    const user = localStorage.getItem("user") ?? '{}'
    const typeUser = JSON.parse(user).role.name ?? ''
    const token = localStorage.getItem("authToken") ?? '{}'
    const authToken = JSON.parse(token).access_token as string ?? ''
    const [loading, setLoading] = useState(true)
    const [infoSugestedOrder, setInfoSugestedOrder] = useState<ReadOneSugestedOrder>()
    const [reload, setReload] = useState(true)
    const [showRequestProduction, setShowRequestProduction] = useState(false)
    const [showRemoveSugestedOrder, setShowRemoveSugestedOrder] = useState(false)
    const {setErrorMessage, setTypeError} = usePopupTrueFalse()
    const [tab, setTab] = useState('infoSugestedOrder')
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    function adjustTextareaHeight() {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    useEffect(() => {
        adjustTextareaHeight()
    })

    useEffect(() => {

        const setError = (error: any) => {
            setTypeError(error)
            if(typeof error.response?.data?.detail === 'string'){
                setErrorMessage(error.response?.data?.detail)
            } else {
                setErrorMessage('Tente novamente em instantes!')
            }
        }

        if(reload === true) {
            if(typeUser === 'admin'){
                const promise = api.readSugestedOrder(authToken, idUrlSugestao)
                promise.then((response) => {
                    setInfoSugestedOrder(response.data)
                }).catch((error) => {
                    setError(error)
                }).finally(() => {
                    setLoading(false)
                })
            }

            if(reload === true) {
                if(typeUser === 'company'){
                    const promise = api.readOwnSugestedOrder(authToken, idUrlSugestao)
                    promise.then((response) => {
                        setInfoSugestedOrder(response.data)
                    }).catch((error) => {
                        setError(error)
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            }

            if(reload === true) {
                if(typeUser === 'editor'){
                    const promise = api.readSugestedOrderMadeByMe(authToken, idUrlSugestao)
                    promise.then((response) => {
                        setInfoSugestedOrder(response.data)
                    }).catch((error) => {
                        setError(error)
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            }

            setReload(false)
        }
        
        
    },[authToken, idUrlSugestao, typeUser, reload, setErrorMessage, setTypeError])

    function handleRemoveSugestedOrder() {
        setShowRemoveSugestedOrder(true)
    }

    if(loading){
        return (
            <Container>
                <Loading color="black" withText={true}/>
            </Container>
        )
    }

    return (
        <>
            {(showRequestProduction || showRemoveSugestedOrder) &&
                (<BackgroundPopup>
                    {showRequestProduction && <SolicitarProducao setShowRequestProduction={setShowRequestProduction} authToken={authToken} idSugestao={idUrlSugestao} />}
                    {showRemoveSugestedOrder && <PopupDeleteSugestion setShowConfirmDelete={setShowRemoveSugestedOrder} authToken={authToken} sugestedOrderId={idUrlSugestao} />}
                </BackgroundPopup>)}
            <Container>
                <Title>Detalhes da sugestão</Title>
                <InnerContainer>
                    <Main>
                        <Content tab={tab}>
                            <Information tab={tab}>
                                {tab === 'infoSugestedOrder' && <>
                                    <h1>
                                        {`${infoSugestedOrder?.product.product_name}`} <span>{`#${infoSugestedOrder?.id}`}</span> 
                                    </h1>

                                    {(typeUser === 'company') && 
                                        <h3>
                                            <div dangerouslySetInnerHTML={{__html: infoSugestedOrder?.product.description ?? ''}} />                                
                                        </h3>
                                    }

                                    {(typeUser === 'editor' || typeUser === 'admin') &&
                                        <h3>
                                            Cliente: <span onClick={() => setTab('infoCompany')}>{`${infoSugestedOrder?.company?.user?.name}`}</span>
                                        </h3>
                                    }

                                    {(typeUser === 'admin') &&
                                        <h3>
                                            Quem sugeriu a pauta: {`${infoSugestedOrder?.user?.name}`}
                                        </h3>
                                    }

                                    <Comment>
                                        <h1>Temos uma sugestão de conteúdo para você!</h1>
                                        <textarea ref={textareaRef} disabled>
                                            {infoSugestedOrder?.comment}
                                        </textarea>
                                    </Comment>

                                    <Data>
                                        {infoSugestedOrder?.sugested_order_infos.map((info) => {
                                            return(
                                                <InformationContainer
                                                    key={info.id} fieldName={info.field.field_name} value={info.value} />
                                            )
                                        })}
                                    </Data>

                                    <Buttons>

                                        <LeftButtons>
                                            <Link style={{textDecoration: 'none'}} to="/sugestoes">
                                                <Back>
                                                    <ArrowBackIcon/>
                                                    Voltar
                                                </Back>
                                            </Link>
                                        </LeftButtons>

                                        {(typeUser === 'company') && (
                                            <RightButtons>
                                                <Approve onClick={() => setShowRequestProduction(true)}>
                                                    Solicitar produção
                                                </Approve>
                                            </RightButtons>
                                        )}
                                        {(typeUser === 'editor' || typeUser === 'admin') && (
                                            <RightButtons>
                                                <Delete onClick={handleRemoveSugestedOrder}>
                                                    Apagar sugestão
                                                </Delete>
                                            </RightButtons>
                                        )}
                                        
                                    </Buttons>
                                </>}
                            </Information>
                        </Content>
                    </Main>
                </InnerContainer>
            </Container>
        </>
    )
}