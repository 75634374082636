import * as S from "./style"
import Tooltip from "../../Tooltip/Tooltip"

import Link from "../../../assets/icons/link-circle.svg"
import Check from "../../../assets/icons/check-circle.svg"

import { useEffect, useState } from "react"
import Modal from "../../Modal"
import { CommunicationPlan } from "../../../interfaces"
import api from "../../../services/api"
import usePopupTrueFalse from '../../../hooks/usePopupTrueFalse';
import { RotatingLines } from "react-loader-spinner"
import Textarea from "../../Inputs/TextArea"

export default function ComunicationPlan(){

    const [loading, setLoading] = useState(false)
    const {setShowPopup, setErrorMessage, setTypeError} = usePopupTrueFalse()

    const [requestChangeModal, setRequestChangeModal] = useState(false)
    const [aproveModal, setAproveModal] = useState(false)

    const [changes, setChanges] = useState("")

    const [communicationPlan, setCommunicationPlan] = useState<CommunicationPlan>()
      const token = localStorage.getItem("authToken") ?? '{}'
      const authToken = JSON.parse(token).access_token ?? ''

    const communication_plan_status = communicationPlan?.communication_plan_status ?? ''
    const link = communicationPlan?.communication_plan_url ?? ''

    function openUrl() {
        window.open(link, '_blank')
    }
    
    function readComunicationPlan(){
    const promise = api.readPlanInfo(authToken)
    promise.then((response) => {
        setCommunicationPlan(response.data?.communication_plan)
    })
    }

    function approveCommunicationPlan(){
        setLoading(true)
        const promise = api.approveCommunicationPlan(authToken)
        promise.then((response) => {
            readComunicationPlan()
        }).catch((error) => {
            setTypeError(false)
            if(error?.response?.data?.message){
                setErrorMessage(error.response.data.message)
            } else{
                setErrorMessage('Erro ao aprovar o Plano de Comunicação. Tente novamente mais tarde.')
            }
            setShowPopup(true)
        }).finally(() => {
            setAproveModal(false)
            setLoading(false)
        })
    }

    function requestChangeCommunicationPlan(){
        setLoading(true)
        const body={
            description: changes
        }
        const promise = api.requestChangesCommunicationPlan(authToken, body)
        promise.then((response) => {
            readComunicationPlan()
        }).catch((error) => {
            setTypeError(false)
            if(error?.response?.data?.message){
                setErrorMessage(error.response.data.message)
            } else{
                setErrorMessage('Erro ao solicitar alteração no Plano de Comunicação. Tente novamente mais tarde.')
            }
            setShowPopup(true)
        }).finally(() => {
            setRequestChangeModal(false)
            setLoading(false)
        })
    }

    useEffect(() => {
        readComunicationPlan()
        // eslint-disable-next-line
      },[authToken])

    return(
        <S.Container>
            <S.TooltipContainer>
                <S.Text>Plano de Comunicação</S.Text>
                <Tooltip
                    tooltipContent={
                        <S.TooltipContent>
                            <p>O plano de comunicação é um documento preparado pelo nosso time para você.</p>
                            <p>Ele explica a nossa metodologia, mostra objetivos de comunicação e sugestões de temas para trabalharmos.</p>
                        </S.TooltipContent>
                    }
                />
            </S.TooltipContainer>

            {communication_plan_status === 'awaiting_kickoff' && 
            <S.Main>
                <S.Title>Aguardando Kickoff</S.Title>
                <S.Description>
                    Após a reunião de entrada, iniciaremos a elaboração do seu plano de comunicação.
                </S.Description>
            </S.Main>
            }

            {communication_plan_status === 'open' && 
            <S.Main>
                <S.Title>Estamos elaborando o seu plano de comunicação</S.Title>
                <S.Description>
                    Ele estará disponível em até 5 dias úteis após sua reunião de entrada.
                </S.Description>
            </S.Main>
            }

            {communication_plan_status === 'delegated' && 
            <S.Main>
                <S.Title>Estamos elaborando o seu plano de comunicação</S.Title>
                <S.Description>
                    Ele estará disponível em até 5 dias úteis após sua reunião de entrada.
                </S.Description>
            </S.Main>
            }

            
            {communication_plan_status === 'in_production' && 
            <S.Main>
                <S.Title>Estamos elaborando o seu plano de comunicação</S.Title>
                <S.Description>
                    Ele estará disponível em até 5 dias úteis após sua reunião de entrada.
                </S.Description>
            </S.Main>
            }

            {communication_plan_status === 'awaiting_approval_internal' && 
            <S.Main>
                <S.Title>Estamos elaborando o seu plano de comunicação</S.Title>
                <S.Description>
                    Ele estará disponível em até 5 dias úteis após sua reunião de entrada.
                </S.Description>
            </S.Main>
            }

            {communication_plan_status === 'awaiting_changes_internal' && 
            <S.Main>
                <S.Title>Estamos elaborando o seu plano de comunicação</S.Title>
                <S.Description>
                    Ele estará disponível em até 5 dias úteis após sua reunião de entrada.
                </S.Description>
            </S.Main>
            }

            {communication_plan_status === 'awaiting_approval' && 
            <>
                <S.Main>
                    <S.TitleSimple>Acesse seu <S.Title>Plano de Comunicação</S.Title> no link abaixo:</S.TitleSimple>
                    <S.ButtonComunicationPlan onClick={openUrl}>
                        <img src={Link} alt="link"/>
                        Plano de Comunicação
                    </S.ButtonComunicationPlan>
                </S.Main>
                <S.Button $style="outline" onClick={() => setRequestChangeModal(true)}>
                    Solicitar Alteração
                </S.Button>
                <S.Button $style="fill" onClick={() => setAproveModal(true)}>
                    Aprovar
                </S.Button>
            </>
            }

            {communication_plan_status === 'awaiting_changes' &&
            <S.Main>
                <S.Title>Estamos trabalhando nas alterações solicitadas 🚧</S.Title>
                <S.Description>
                    Aguarde enquanto nossa equipe efetua as alterações pedidas!
                </S.Description>
            </S.Main>
            } 
            
            {/* <>
                <S.Main>
                    <S.TitleSimple>As alterações estão prontas! Acesse seu novo <S.Title>Plano de Comunicação</S.Title></S.TitleSimple>
                    <S.ButtonComunicationPlan onClick={openUrl}>
                       <img src={Link} alt="link"/>
                        Plano de Comunicação
                    </S.ButtonComunicationPlan>
                </S.Main>
                <S.Button $style="outline">
                    Solicitar Alteração
                </S.Button>
                <S.Button $style="fill" onClick={() => setAproveModal(true)}>
                    Aprovar
                </S.Button>
            </> */}

            {communication_plan_status === 'approved' && 
                <S.Main>
                    <S.TitleSimple>Acesse seu <S.Title>Plano de Comunicação</S.Title> abaixo.</S.TitleSimple>
                    <S.ButtonComunicationPlan onClick={openUrl}> 
                        <img src={Link} alt="link"/>
                        Plano de Comunicação
                    </S.ButtonComunicationPlan>
                </S.Main>
            }
             { requestChangeModal &&

                <Modal onClose={() => setRequestChangeModal(false)}>
                    <S.Modal>
                        <Textarea
                            label="O que deseja que seja alterado no seu Plano de Comunicação?"
                            placeholder="Descreva as alterações desejadas."
                            cols={80}
                            onChange={(e) => setChanges(e.target.value)}
                            value={changes}
                        />

                        <S.ButtonsModal>
                            <S.Button $style="outline" onClick={() => setRequestChangeModal(false)}>
                                Cancelar
                            </S.Button>
                            <S.Button $style="fill" onClick={() => requestChangeCommunicationPlan()}>
                                Alterar
                            </S.Button>
                        </S.ButtonsModal>
                    </S.Modal>
                </Modal>

            } 

            { aproveModal &&

                <Modal onClose={() => setAproveModal(false)}>
                    {loading ? 
                        (<>
                            <S.LoadingContainer>
                            <RotatingLines
                                strokeColor={'#6d6d6d'}
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="35"
                                visible={true}
                                />
                                <h1>Estamos aprovando o seu plano de comunicação.</h1>
                            </S.LoadingContainer>
                        </>) 
                        : 
                        (<S.Modal>
                            <div>
                                <img src={Check} alt="check" width={18}/>
                                <h1>Aprove seu Plano de Comunicação!</h1>
                            </div>

                            <p>Lembre-se de que, uma vez aprovado, não será possível solicitar mais alterações.</p>
                            <p>Se estiver satisfeito com seu <b>Plano de Comunicação</b>, clique em <b>‘Aprovar’</b>.</p>

                            <S.ButtonsModal>
                                <S.Button $style="outline" onClick={() => setAproveModal(false)}>
                                    Cancelar
                                </S.Button>
                                <S.Button $style="fill" onClick={() => approveCommunicationPlan()}>
                                    Aprovar
                                </S.Button>
                            </S.ButtonsModal>
                        </S.Modal>)
                    }
                </Modal>

            }
        </S.Container>


    )
}